import { ReportData, ReportInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/report_pb";
import { Box, Collapse, Divider, Grid, IconButton, SvgIcon, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { formatISO } from "date-fns";
import { convertTimestampObjectToDate } from "../../common/utils/formatter";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import CdsLogo from "../../assets/logo300dark.png";
import { ColumnSortFilterConfig } from "../core/data/ListData";
import { ListProjectReports } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { SortState } from "../../common/table/DataTable";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";

export interface ReportProps {
    reportData: ReportData.AsObject;
    reportInfo: ReportInfo.AsObject;
}

interface CollapsibleHeaderProps {
    title: string;
    children: React.ReactNode;
    indent?: number;
}

// ======================
// CollapsibleHeader
// ======================
export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = (p) => {
    const { title, children } = p;
    const theme = useTheme();

    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? <IoIosArrowUp /> : <IoIosArrowDown />;

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: theme.palette.cirrus.main,
                    color: "white",
                    "@media print": {
                        color: "black",
                    },
                    "@media screen": {
                        padding: 2,
                        marginRight: 4,
                    },
                }}
            >
                <Box
                    sx={{
                        "@media print": {
                            display: "block",
                        },
                        "@media screen": {
                            display: "flex",
                            justifyContent: "space-between",
                        },
                    }}
                >
                    <Typography variant={"h5"}>{title}</Typography>
                    <Box
                        sx={{
                            "@media print": {
                                display: "none",
                            },
                        }}
                    >
                        <IconButton onClick={() => setExpanded(!expanded)}>{icon}</IconButton>
                    </Box>
                </Box>
            </Box>
            <Collapse in={expanded} orientation={"vertical"}>
                {children}
            </Collapse>
        </Box>
    );
};

// ======================
// CollapsibleSubheader
// ======================

export const CollapsibleSubheader: React.FC<CollapsibleHeaderProps> = (p) => {
    const { title, children, indent } = p;
    const theme = useTheme();

    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? <IoIosArrowUp /> : <IoIosArrowDown />;

    return (
        <Box ml={indent ?? 0}>
            <Box
                sx={{
                    "@media screen": {
                        padding: 2,
                        marginRight: 2,
                        backgroundColor: theme.palette.cirrusGray.main,
                        border: `1px solid ${theme.palette.cirrusGray.dark}`,
                    },
                }}
            >
                <Box
                    sx={{
                        "@media print": {
                            display: "block",
                        },
                        "@media screen": {
                            display: "flex",
                            justifyContent: "space-between",
                        },
                    }}
                >
                    <Typography variant={"h6"}>{title}</Typography>
                    <Box
                        sx={{
                            "@media print": {
                                display: "none",
                            },
                        }}
                    >
                        <IconButton onClick={() => setExpanded(!expanded)}>
                            <SvgIcon htmlColor={"black"}>{icon}</SvgIcon>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Collapse in={expanded} orientation={"vertical"}>
                {children}
            </Collapse>
        </Box>
    );
};

// ======================
// ReportFooter
// ======================

interface ReportFooterProps {
    reportInfo: ReportInfo.AsObject;
}

export const ReportFooter: React.FC<ReportFooterProps> = (p) => {
    const { reportInfo } = p;
    return (
        <>
            <Box pr={4} pl={4} pt={2}>
                <Divider />
            </Box>
            <Box pr={6} pl={6} pb={6} pt={2}>
                <Typography variant={"caption"}>
                    <Grid container>
                        <Grid xs={2}>{"Report ID:"}</Grid>
                        <Grid xs={10}>{reportInfo.reportId}</Grid>
                        <Grid xs={2}>{"Generated At:"}</Grid>
                        <Grid xs={10}>{formatISO(convertTimestampObjectToDate(reportInfo?.createdAt))}</Grid>
                        <Grid xs={2}>{"Generated By:"}</Grid>
                        <Grid xs={10}>
                            {getUserFullNameFromObject(reportInfo?.createdBy)} {`(${reportInfo?.createdBy.email})`}
                        </Grid>
                        <Grid xs={2}>{"Notes:"}</Grid>
                        <Grid xs={10}>{reportInfo?.systemNotes}</Grid>
                        <Grid xs={2}>{"User Notes:"}</Grid>
                        <Grid xs={10}>{reportInfo?.userNotes}</Grid>
                    </Grid>
                </Typography>
                <Box pt={2} textAlign={"center"}>
                    <Box pb={1}>
                        <Typography variant={"body2"} fontWeight={600}>
                            {"End of Report"}
                        </Typography>
                    </Box>

                    <img src={CdsLogo} alt={"Cirrus Data Solutions Inc."} width={"150"} height={"auto"} />
                </Box>
            </Box>
        </>
    );
};

export const getReportListSortFilterConfig = (): ColumnSortFilterConfig<ListProjectReports.Request, ListProjectReports.Request.Sort, any, any> => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListProjectReports.Request.Sort.Field.CREATED_AT,
                label: "Creation Time",
            },
            sortFields: [
                {
                    fieldId: ListProjectReports.Request.Sort.Field.CREATED_AT,
                    label: "Creation Time",
                },
                {
                    fieldId: ListProjectReports.Request.Sort.Field.VERSION,
                    label: "Version",
                },
                {
                    fieldId: ListProjectReports.Request.Sort.Field.SESSION_ID,
                    label: "Session ID",
                },
                {
                    fieldId: ListProjectReports.Request.Sort.Field.SYSTEM_ID,
                    label: "System ID",
                },
            ],
            getSortParam: (s: SortState) => new ListProjectReports.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value),
            defaultSortDesc: true,
        },
        filter: [
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.CREATED_AT,
                label: "Created",
                filterType: "date",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setCreatedAt(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.CREATED_BY,
                label: "Created By",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setCreatedBy(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.USER_NOTES,
                label: "User Notes",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setUserNotes(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.SYSTEM_NOTES,
                label: "System Notes",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setSystemNotes(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.SYSTEM_ID,
                label: "System ID",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setSystemId(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.SESSION_ID,
                label: "Session ID",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setSessionId(f));
                },
            },
            {
                fieldId: ListProjectReports.Request.Filter.FieldCase.VERSION,
                label: "Version",
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjectReports.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjectReports.Request.Filter().setVersion(f));
                },
            },
        ],
    };
};
