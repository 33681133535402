// ======================
// CmoMigrationSessionDetailsVolumeTab
// ======================

import { useGetCmoSessionVolumeDetails, useListCmoSessionVolumes } from "../cmo_migration_hooks";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryListTable } from "../../../common/table/QueryListTable";
import { CmoMigrationSessionVolume, CmoVolume } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import {
    Box,
    Card,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { MdKeyboardDoubleArrowRight, MdOutlineSearch } from "react-icons/md";
import { formatDurationFromSeconds, formatKnownDataType, formatProtoDurationObject, formatTitleCase, KnownDataType } from "../../../common/utils/formatter";
import { getCmoMigrationQosImpactLevelLabel, getCmoMigrationSessionVolumeStatusLabel } from "../CmoMigrationCommon";
import { QuickTipButton } from "../../help/HelpCommon";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { DarkFlatCard } from "../../../common/card/DarkCard";
import { FaAngleDoubleDown, FaAngleDoubleRight } from "react-icons/fa";
import { CmoVolumeType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_volume_type_pb";
import { LightDivider } from "../../../common/misc";
import { OutlinedCard } from "../../../common/card/OutlinedCard";
import { BsDot } from "react-icons/bs";
import { TruncatedText } from "../../../common/text/TruncatedText";

interface CmoMigrationSessionDetailsVolumeTabProps {
    sessionId: string;
}

export const CmoMigrationSessionDetailsVolumeTab: React.FC<CmoMigrationSessionDetailsVolumeTabProps> = (p) => {
    const { sessionId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const queryResult = useListCmoSessionVolumes(sessionId, pageIndex, pageSize);

    return (
        <Box pt={1}>
            <QueryListTable
                listComponent={CmoMigrationSessionVolumeCard}
                data={queryResult.data?.itemsList || []}
                error={queryResult.error}
                isLoading={queryResult.isLoading}
                pageCount={queryResult.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                componentProps={{ sessionId: sessionId }}
            />
        </Box>
    );
};

// ======================
// CmoMigrationSessionVolumeCard
// ======================

interface CmoMigrationSessionVolumeCardProps {
    data: CmoMigrationSessionVolume.AsObject;
    componentProps?: { sessionId: string };
}

const CmoMigrationSessionVolumeCard: React.FC<CmoMigrationSessionVolumeCardProps> = (p) => {
    const { data, componentProps } = p;
    const theme = useTheme();
    const dialogState = useDialogState();

    return (
        <>
            <Box pb={2}>
                <Card>
                    <LinearProgress variant={"determinate"} value={data.volumeStats.progressPercentage} />
                    <Grid container p={2} columnSpacing={[2, 2, 2, 8, 8]} rowSpacing={2} alignItems={"center"}>
                        <Grid item xs={1} md={1}>
                            <IconButton
                                onClick={dialogState.open}
                                sx={{
                                    backgroundColor: theme.palette.cirrus.main,
                                    borderRadius: 2,
                                    border: "1px solid rgba(255, 255, 255, .2)",
                                }}
                            >
                                <MdOutlineSearch size={20} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                            <Box textAlign={"center"}>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {data.source.deviceDisplayName}
                                </Typography>
                                <Typography variant={"caption"}>{formatKnownDataType(data.source.capacity, KnownDataType.CAPACITY)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1} lg={1}>
                            <Box textAlign={"center"}>
                                <MdKeyboardDoubleArrowRight size={24} />
                            </Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                            <Box textAlign={"center"}>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {data.destination.deviceDisplayName}
                                </Typography>
                                <Typography variant={"caption"}>{formatKnownDataType(data.destination.capacity, KnownDataType.CAPACITY)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container p={2} alignItems={"center"} spacing={3} justifyContent={"space-between"}>
                        <Grid item xs={12} md={2} justifyContent={"center"}>
                            <Box textAlign={"center"}>
                                <Typography variant={"h5"} fontWeight={600}>
                                    {getCmoMigrationSessionVolumeStatusLabel(data.volumeStatus)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Stack direction={"column"} alignItems={"center"}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Sync Progress - ${formatKnownDataType(data.volumeStats.progressPercentage, KnownDataType.PERCENT)}`}
                                    </Typography>
                                    <QuickTipButton
                                        tooltipColor={"black"}
                                        title={
                                            <Box p={1}>
                                                <Typography variant={"caption"}>
                                                    {`Total Transferred: ${formatKnownDataType(data.volumeStats.totalTransferred, KnownDataType.CAPACITY)}`}
                                                </Typography>
                                                <br />
                                                <Typography variant={"caption"}>
                                                    {`Total Sync Time: ${formatProtoDurationObject(data.totalActiveMigrationTime)}`}
                                                </Typography>
                                                <br />
                                                <Typography variant={"caption"}>
                                                    {`Total Data Reduced: ${formatKnownDataType(
                                                        data.volumeStats.totalThinData + data.volumeStats.totalThinCompressionSavings,
                                                        KnownDataType.CAPACITY
                                                    )}`}
                                                </Typography>
                                            </Box>
                                        }
                                        iconSize={14}
                                    />
                                </Stack>

                                <Typography>
                                    {`${formatKnownDataType(data.volumeStats.totalMigrated, KnownDataType.CAPACITY)} / ${formatKnownDataType(
                                        data.volumeStats.totalData,
                                        KnownDataType.CAPACITY
                                    )}`}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Stack direction={"column"} alignItems={"center"}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Total Changes (New)`}
                                    </Typography>
                                </Stack>

                                <Typography>
                                    {`${formatKnownDataType(data.volumeStats.totalChanged, KnownDataType.CAPACITY)} (${formatKnownDataType(
                                        data.volumeStats.totalCurrentChangedData,
                                        KnownDataType.CAPACITY
                                    )})`}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Stack direction={"column"} alignItems={"center"}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {"Migration Rate"}
                                    </Typography>
                                    <QuickTipButton
                                        tooltipColor={"black"}
                                        title={
                                            <Box p={1}>
                                                <Typography variant={"caption"}>
                                                    {`Average Migration Rate: ${formatKnownDataType(
                                                        data.volumeStats.averageThroughput,
                                                        KnownDataType.THROUGHPUT
                                                    )}`}
                                                </Typography>
                                                <br />
                                                <Typography variant={"caption"}>
                                                    {`Average Transfer Rate: ${formatKnownDataType(
                                                        data.volumeStats.averageTransferThroughput,
                                                        KnownDataType.THROUGHPUT
                                                    )}`}
                                                </Typography>
                                            </Box>
                                        }
                                        iconSize={14}
                                    />
                                </Stack>
                                <Typography>{`${formatKnownDataType(data.volumeStats.currentThroughput, KnownDataType.THROUGHPUT)}`}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Stack direction={"column"} alignItems={"center"}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {"Remaining"}
                                    </Typography>
                                </Stack>
                                <Typography>{`${formatKnownDataType(data.volumeStats.totalRemaining, KnownDataType.CAPACITY)}`}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            {dialogState.isOpen && <VolumeMigrationDetailsDialog dialogState={dialogState} volumeId={data.source.uuid} sessionId={componentProps.sessionId} />}
        </>
    );
};

// ======================
// VolumeMigrationDetailsDialog
// ======================

interface VolumeMigrationDetailsDialogProps {
    dialogState: DialogState;
    sessionId: string;
    volumeId: string;
}

export const VolumeMigrationDetailsDialog: React.FC<VolumeMigrationDetailsDialogProps> = (p) => {
    const { dialogState, volumeId, sessionId } = p;
    const fullScreen = useShouldDialogFullScreen();
    const queryResult = useGetCmoSessionVolumeDetails(sessionId, volumeId);
    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={"md"} fullWidth fullScreen={fullScreen}>
            <DialogTopBar dialogState={dialogState} title={"Volume Migration Details"} />
            <QueryResultWrapper queryResult={queryResult}>
                <DialogContent>
                    {fullScreen ? (
                        <Stack width={"100%"} direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                            <VolumeDetailsCard data={queryResult.data?.volume.sessionVolume.source} />
                            <FaAngleDoubleDown />
                            <VolumeDetailsCard data={queryResult.data?.volume.sessionVolume.destination} />
                        </Stack>
                    ) : (
                        <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item flexGrow={1}>
                                <VolumeDetailsCard data={queryResult.data?.volume.sessionVolume.source} />
                            </Grid>
                            <Grid item>
                                <FaAngleDoubleRight />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <VolumeDetailsCard data={queryResult.data?.volume.sessionVolume.destination} />
                            </Grid>
                        </Grid>
                    )}

                    <Box pt={2}>
                        <VolumeActivityInfo data={queryResult.data?.volume.sessionVolume} />
                    </Box>
                    <Box pt={2}>
                        <VolumePathInfo
                            sourcePaths={queryResult.data?.volume.sessionVolume.source.pathsList}
                            destinationPaths={queryResult.data?.volume.sessionVolume.destination.pathsList}
                        />
                    </Box>
                </DialogContent>
            </QueryResultWrapper>
        </Dialog>
    );
};

// ======================
// VolumeDetailsCard
// ======================

interface VolumeDetailsCardProps {
    data: CmoVolume.AsObject;
}

const VolumeDetailsCard: React.FC<VolumeDetailsCardProps> = (p) => {
    const { data } = p;
    return (
        <DarkFlatCard sx={{ width: "100%" }}>
            <Stack p={2} alignItems={"center"} justifyContent={"center"} spacing={1}>
                <Typography variant={"body2"} color={"textSecondary"}>
                    {formatTitleCase(
                        Object.keys(CmoVolumeType.CmoVolumeType).find(
                            (k) => CmoVolumeType.CmoVolumeType[k as keyof typeof CmoVolumeType.CmoVolumeType] === data.type
                        )
                    )}
                </Typography>
                <Typography variant={"h6"}>{data.deviceName}</Typography>
                <Typography>{data.uuid}</Typography>
                <Stack direction={"row"} spacing={1}>
                    <Chip size={"small"} color={"primary"} label={formatKnownDataType(data.capacity, KnownDataType.CAPACITY)} />
                    <Chip size={"small"} label={`${data.vendor} ${data.product} ${data.rev}`} />
                </Stack>
            </Stack>
            <LightDivider />
            <Stack direction={"row"} alignItems={"center"} p={2} spacing={4}>
                <Box>
                    <Typography color={"textSecondary"}>{"No. of Blocks"}</Typography>
                    <Typography>{data.capacity / data.sectorSize}</Typography>
                </Box>
                <Box>
                    <Typography color={"textSecondary"}>{"Block Size"}</Typography>
                    <Typography>{formatKnownDataType(data.sectorSize, KnownDataType.CAPACITY)}</Typography>
                </Box>
                <Box>
                    <Typography color={"textSecondary"}>{"Host"}</Typography>
                    <Typography>{data.hostName || "--"}</Typography>
                </Box>
            </Stack>
        </DarkFlatCard>
    );
};

// ======================
// VolumeActivityInfo
// ======================

interface VolumeActivityInfoProps {
    data: CmoMigrationSessionVolume.AsObject;
}

const VolumeActivityInfo: React.FC<VolumeActivityInfoProps> = (p) => {
    const { data } = p;
    return (
        <Box>
            <Box pb={1}>
                <Typography variant={"h6"}>{"Activity Info"}</Typography>
            </Box>
            <OutlinedCard>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Current Activity"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {getCmoMigrationSessionVolumeStatusLabel(data.volumeStatus)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Data Migrated"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {`${formatKnownDataType(data.volumeStats.totalMigrated, KnownDataType.CAPACITY)} / ${formatKnownDataType(
                                    data.volumeStats.totalData,
                                    KnownDataType.CAPACITY
                                )}`}
                            </TableCell>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Duty Cycle"}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }} align={"right"}>
                                {formatKnownDataType(data.activeMigrationTimePercentage, KnownDataType.PERCENT)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Mode"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                align={"right"}
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {getCmoMigrationQosImpactLevelLabel(data.currentQosLevel)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Data Remaining"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {formatKnownDataType(data.volumeStats.totalRemaining, KnownDataType.CAPACITY)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}` }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Current Throughput"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {formatKnownDataType(data.volumeStats.currentThroughput, KnownDataType.THROUGHPUT)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Time Remaining"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: "none",
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {!!data.endTime
                                    ? "Finished"
                                    : data.volumeStats.currentThroughput > 0
                                    ? formatDurationFromSeconds(Math.round(data.volumeStats.totalRemaining / data.volumeStats.currentThroughput))
                                    : "--"}
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Data Changed"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: "none",
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                                align={"right"}
                            >
                                {formatKnownDataType(data.volumeStats.totalCurrentChangedData, KnownDataType.CAPACITY)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography color={"textSecondary"} variant={"body2"}>
                                    {"Average Throughput"}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }} align={"right"}>
                                {formatKnownDataType(data.volumeStats.averageThroughput, KnownDataType.THROUGHPUT)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </OutlinedCard>
        </Box>
    );
};

// ======================
// VolumePathInfo
// ======================

interface VolumePathInfoProps {
    sourcePaths: Array<CmoVolume.Path.AsObject>;
    destinationPaths: Array<CmoVolume.Path.AsObject>;
}

const VolumePathInfo: React.FC<VolumePathInfoProps> = (p) => {
    const { sourcePaths, destinationPaths } = p;
    const theme = useTheme();
    const getTableRow = (p: CmoVolume.Path.AsObject, type: "Source" | "Destination", isLastRow?: boolean) => {
        return (
            <TableRow key={p.pathId}>
                <TableCell
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                        borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                    }}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <BsDot size={22} color={p.online ? theme.palette.success.main : theme.palette.error.main} />
                        {p.pathId}
                    </Stack>
                </TableCell>
                <TableCell
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                        borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                    }}
                >
                    {type}
                </TableCell>
                <TableCell
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                        borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                    }}
                >
                    {p.applianceName}
                </TableCell>
                <TableCell
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                        borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                        wordBreak: "break-word",
                    }}
                >
                    {p.initiator}
                </TableCell>
                <TableCell
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                        borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                        wordBreak: "break-word",
                    }}
                >
                    {p.target}
                </TableCell>
                <TableCell
                    align={"right"}
                    sx={{
                        borderBottom: (t: Theme) => (!isLastRow ? `1px solid ${t.palette.border.main}` : "none"),
                    }}
                >
                    {p.lun}
                </TableCell>
            </TableRow>
        );
    };
    return (
        <Box>
            <Box pb={1}>
                <Typography variant={"h6"}>{"Path Info"}</Typography>
            </Box>
            <OutlinedCard>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {"Path"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {"Path Type"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {"CMO System"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {"Initiator"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                    borderRight: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                }}
                            >
                                {"Target"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: (t: Theme) => `1px solid ${t.palette.border.main}`,
                                    color: (t: Theme) => t.palette.text.secondary,
                                }}
                            >
                                {"LUN"}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sourcePaths.map((p, i) => {
                            return getTableRow(p, "Source");
                        })}
                        {destinationPaths.map((p, i) => {
                            const isLastRow = i === destinationPaths.length - 1;
                            return getTableRow(p, "Destination", isLastRow);
                        })}
                    </TableBody>
                </Table>
            </OutlinedCard>
        </Box>
    );
};
