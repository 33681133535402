// ======================
// CmoMigrationSessionsListTable
// ======================

import { alpha, Avatar, Box, Card, Grid, LinearProgress, linearProgressClasses, Link, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { useListCmoMigrationSessions } from "../cmo_migration_hooks";
import { QueryListTable } from "../../../common/table/QueryListTable";
import { CmoMigrationSessionBasicInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { useIsDesktop } from "../../layout/MainLayout";
import {
    getCmoMigrationSessionStatusColor,
    getCmoMigrationSessionStatusLabel,
    getCmoMigrationSessionTypeLabel,
    useLinearProgressStyle,
    useNavigateToCmoMigrationSessionDetails,
    useNavigateToCmoSystemDetails,
} from "../CmoMigrationCommon";
import { DarkFlatCard } from "../../../common/card/DarkCard";
import { convertTimestampObjectToDate, formatKnownDataType, formatTitleCase, KnownDataType } from "../../../common/utils/formatter";
import { CmoMigrationSessionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_type_pb";
import { getAxesMaxValue } from "../../../common/utils/statsUtil";
import { LabelChip } from "../../labels/labelCommon";
import { DeployedDeploymentsIcon } from "../../project/ProjectCommon";
import { CmoQueryKeys } from "../../../common/QueryKeys";

interface CmoMigrationSessionsListTableProps {
    projectId: string;
    systemId?: string;
}

export const CmoMigrationSessionsListTable: React.FC<CmoMigrationSessionsListTableProps> = (p) => {
    const { projectId, systemId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const queryResult = useListCmoMigrationSessions(projectId, pageIndex, pageSize, systemId);

    return (
        <QueryListTable
            listComponent={CmoMigrationSessionListCard}
            data={queryResult.data?.itemsList || []}
            error={queryResult.error}
            isLoading={queryResult.isLoading}
            pageCount={queryResult.data?.pagerMeta?.totalPages || 0}
            pagination={{ pageIndex, pageSize }}
            setPagination={setPagination}
            queryKey={CmoQueryKeys.listCmoMigrationSessions}
            refetch={queryResult.refetch}
        />
    );
};

// ======================
// CmoMigrationSessionListCard
// ======================

interface CmoMigrationSessionListCardProps {
    data: CmoMigrationSessionBasicInfo.AsObject;
}

export const CmoMigrationSessionListCard: React.FC<CmoMigrationSessionListCardProps> = (p) => {
    const { data } = p;
    const isDesktop = useIsDesktop();
    return (
        <Box pb={2}>
            <Card>
                {isDesktop ? (
                    <Grid p={2} container spacing={3} alignItems={"center"}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <CmoMigrationSessionProgressCard sessionInfo={data} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={8} xl={9}>
                            <CmoMigrationSessionInfoSection sessionInfo={data} />
                        </Grid>
                    </Grid>
                ) : (
                    <Stack p={2} spacing={2}>
                        <CmoMigrationSessionInfoSection sessionInfo={data} />
                        <CmoMigrationSessionProgressCard sessionInfo={data} />
                    </Stack>
                )}
            </Card>
        </Box>
    );
};

// ======================
// CmoMigrationSessionProgressCard
// ======================

interface CmoMigrationSessionProgressCardProps {
    sessionInfo: CmoMigrationSessionBasicInfo.AsObject;
}

export const CmoMigrationSessionProgressCard: React.FC<CmoMigrationSessionProgressCardProps> = (p) => {
    const { sessionInfo } = p;
    const sessionStatus = getCmoMigrationSessionStatusLabel(sessionInfo.sessionStatus);
    const theme = useTheme();

    return (
        <DarkFlatCard
            sx={{
                border: `2px solid ${getCmoMigrationSessionStatusColor(sessionInfo.sessionStatus, theme)}`,
            }}
        >
            <Box p={2}>
                <Stack direction={"row"} spacing={1} alignItems={"flex-end"} pb={1}>
                    <Typography variant={"h5"} lineHeight={1}>
                        {sessionInfo.sessionStats.progressPercentage.toFixed(2)}
                        {"%"}
                    </Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {`(${formatKnownDataType(sessionInfo.sessionStats.totalMigrated, KnownDataType.CAPACITY)}/${formatKnownDataType(
                            sessionInfo.sessionStats.totalData,
                            KnownDataType.CAPACITY
                        )} migrated)`}
                    </Typography>
                </Stack>
                <LinearProgress
                    sx={{
                        height: "10px",
                        borderRadius: 10,
                        backgroundColor: alpha(getCmoMigrationSessionStatusColor(sessionInfo.sessionStatus, theme), 0.2),
                        [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 10,
                            backgroundColor: getCmoMigrationSessionStatusColor(sessionInfo.sessionStatus, theme),
                        },
                    }}
                    variant={"determinate"}
                    value={sessionInfo.sessionStats.progressPercentage}
                />
                <Box pt={1}>
                    <Typography variant={"h6"}>{sessionStatus}</Typography>
                </Box>
            </Box>
        </DarkFlatCard>
    );
};

// ======================
// CmoMigrationSessionInfoSection
// ======================

interface CmoMigrationSessionInfoSectionProps {
    sessionInfo: CmoMigrationSessionBasicInfo.AsObject;
}

export const CmoMigrationSessionInfoSection: React.FC<CmoMigrationSessionInfoSectionProps> = (p) => {
    const { sessionInfo } = p;
    const theme = useTheme();
    const goToDetailsPage = useNavigateToCmoMigrationSessionDetails();
    const gotoSystemDetailsPage = useNavigateToCmoSystemDetails();
    const migrationRateMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [sessionInfo.sessionStats.currentThroughput]);
    const linearProgressStyle = useLinearProgressStyle(true);
    return (
        <>
            <Box pb={1}>
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                        <Grid container spacing={0} alignItems={"center"}>
                            <Grid item pr={1}>
                                <Stack direction={"row"} spacing={1} alignItems={"baseline"}>
                                    {!sessionInfo.deploymentConnected ? (
                                        <Typography variant={"h6"} fontWeight={800} color={"textSecondary"}>
                                            {`#${sessionInfo.localSessionId}`}
                                        </Typography>
                                    ) : (
                                        <Link onClick={() => goToDetailsPage(sessionInfo.globalSessionId)} variant={"h6"} fontWeight={800}>
                                            {`#${sessionInfo.localSessionId}`}
                                        </Link>
                                    )}

                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {sessionInfo.migrationTypeDisplay} {"Migration"}
                                    </Typography>
                                </Stack>
                            </Grid>
                            {sessionInfo.labelsList.map((label) => {
                                return (
                                    <Grid item pr={0.5}>
                                        <LabelChip label={label.name} color={label.color} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>

                    <Box textAlign={"right"}>
                        <Typography variant={"subtitle2"}>
                            {`Started ${formatKnownDataType(convertTimestampObjectToDate(sessionInfo.createdAt), KnownDataType.DATE_RELATIVE)}`}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <Box pb={1}>
                <Typography color={"textSecondary"}>{sessionInfo.description === "N/A" ? "No description" : sessionInfo.description}</Typography>
            </Box>
            <Grid p={0} container>
                <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Box pb={2}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <Avatar
                                variant={"rounded"}
                                sx={{
                                    borderRadius: 3,
                                    background: theme.palette.cirrus.main,
                                    border: `1.5px solid rgba(255,255,255,0.2)`,
                                }}
                            >
                                <DeployedDeploymentsIcon sx={{ color: "white" }} />
                            </Avatar>
                            <Box>
                                <Link onClick={() => gotoSystemDetailsPage(sessionInfo.deployment.systemId)} sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                                    {sessionInfo.deployment.systemName}
                                </Link>
                                <Typography variant={"subtitle2"} color={"textSecondary"}>
                                    {formatKnownDataType(sessionInfo.totalCapacity, KnownDataType.CAPACITY)} {`(${sessionInfo.totalVolumes} volumes)`}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Box>
                        <Box pb={1}>
                            <LinearProgress
                                variant={"determinate"}
                                sx={{ ...linearProgressStyle }}
                                value={(sessionInfo.sessionStats.currentThroughput / migrationRateMaxValue) * 100}
                            />
                            <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`Current Migration Rate: ${formatKnownDataType(sessionInfo.sessionStats.currentThroughput, KnownDataType.THROUGHPUT)}`}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`${formatKnownDataType(migrationRateMaxValue, KnownDataType.THROUGHPUT)}`}
                                </Typography>
                            </Stack>
                        </Box>

                        <LinearProgress
                            sx={{
                                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                                [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: (t: Theme) => t.palette.primary.light,
                                    borderRight: "3px solid white",
                                },
                                "& .MuiLinearProgress-dashed": {
                                    backgroundImage: "none",
                                    animation: "none",
                                },
                                "& .MuiLinearProgress-bar2Buffer": {
                                    backgroundColor: theme.palette.secondary.main,
                                    borderRight: "none",
                                },
                            }}
                            variant={"buffer"}
                            valueBuffer={sessionInfo.sessionStats.totalBase > 0 ? 100 : 0}
                            value={
                                sessionInfo.sessionStats.totalBase + sessionInfo.sessionStats.totalChanged
                                    ? (sessionInfo.sessionStats.totalBase / (sessionInfo.sessionStats.totalBase + sessionInfo.sessionStats.totalChanged)) * 100
                                    : 0
                            }
                        />
                        <Stack direction={"row"} justifyContent={"space-between"} pb={1}>
                            <Box>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`Total Base: ${formatKnownDataType(sessionInfo.sessionStats.totalBase, KnownDataType.CAPACITY)}`}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                                <Typography variant={"h6"} sx={{ lineHeight: 1 }}></Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`Total Changed: ${formatKnownDataType(sessionInfo.sessionStats.totalChanged, KnownDataType.CAPACITY)}`}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
