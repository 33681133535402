import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Grid,
    ListSubheader,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import * as React from "react";
import { TableLoadingIndicator } from "./DataTable";
import { RefreshButton } from "../CommonIcons";
import { getPageSelectionList, QueryTableProps } from "./QueryTableCommon";
import { EmptyTableContent } from "./TableCommon";
import { QueryTableFilter } from "./QueryTableFilter";
import { QueryTableSort } from "./QueryTableSort";
import { useGlobalTableSortFilterState } from "./TableFilterState";

export const QueryTable = <TData, TError>(p: QueryTableProps<TData, TError>) => {
    const { refetch, title, queryKey, error, isLoading, isError, data, pageCount, columns, pagination, setPagination, emptyTableConfig } = p;
    const globalSortFilterState = useGlobalTableSortFilterState();
    const refreshButton = (
        <Box pr={2}>
            <RefreshButton onClick={() => refetch()} variant={"outlined"} color={"inherit"} />
        </Box>
    );

    const getColumnVisibility = () => {
        const colVisibility: { [key: string]: boolean } = {};
        p.hiddenColumns?.forEach((c) => {
            colVisibility[c] = false;
        });
        return colVisibility;
    };
    const table = useReactTable<TData>({
        data: data || [],
        getCoreRowModel: getCoreRowModel(),
        columns: columns,
        pageCount: pageCount,
        state: {
            pagination,
            columnVisibility: getColumnVisibility(),
        },
        onPaginationChange: setPagination,
        manualPagination: true,
    });

    if (isError) {
        return (
            <Box width={"100%"} p={2}>
                <Alert severity={"error"} action={refreshButton}>
                    <AlertTitle>Error Encountered</AlertTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>{(error as unknown as Error)?.message}</Box>
                    </Box>
                </Alert>
            </Box>
        );
    }

    const tableContent = (
        <>
            <Table>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell key={header.id} variant={"head"}>
                                    <Box sx={(header.column.columnDef.meta as any)?.style}>
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {isLoading && <TableLoadingIndicator />}

                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id} hover>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} variant={"body"}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {data?.length === 0 && !isLoading && (
                        <TableRow>
                            <TableCell colSpan={columns.length} align={"center"}>
                                <Box pt={6} pb={6}>
                                    <EmptyTableContent emptyTableConfig={emptyTableConfig} isFilterActive={globalSortFilterState.getIsFilterActive(queryKey)} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );

    const paginationContent =
        (pagination && data?.length) > 0 ? (
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"} pt={2} pb={2} pr={2} width={"100%"}>
                <Pagination
                    count={table.getPageCount()}
                    page={table.getState().pagination.pageIndex}
                    onChange={(event, page) => {
                        table.setPageIndex(page - 1);
                    }}
                    showFirstButton
                    showLastButton
                />
                <Typography variant={"caption"} color={"textSecondary"}>
                    {"Go To Page: "}
                </Typography>
                <Box>
                    <Select
                        value={table.getState().pagination.pageIndex}
                        variant={"outlined"}
                        MenuProps={{
                            slotProps: {
                                paper: {
                                    sx: {
                                        height: "200px",
                                        "&::-webkit-scrollbar": {
                                            width: "10px",
                                            backgroundColor: "transparent",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            borderRadius: "10px",
                                            border: "2px solid transparent",
                                            backgroundColor: "rgba(255,255,255,.1)",
                                            backgroundClip: `content-box`,
                                        },
                                    },
                                },
                            },
                        }}
                        slotProps={{}}
                        onChange={(event) => {
                            table.setPageIndex(Number(event.target.value) - 1);
                        }}
                        sx={{ height: "35px", lineHeight: 0, width: "70px" }}
                        renderValue={(value: unknown) => <Typography variant={"caption"}>{value as React.ReactNode}</Typography>}
                    >
                        {getPageSelectionList(table.getPageCount()).map((p, i) => {
                            return (
                                <MenuItem key={i} value={p}>
                                    {p}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            </Stack>
        ) : null;

    return (
        <>
            <Grid container justifyContent={"space-between"} mb={2}>
                <Grid item>
                    <QueryTableFilter queryKey={queryKey} refetchQuery={refetch} />
                </Grid>
                <Grid item>
                    <QueryTableSort queryKey={queryKey} refetchQuery={refetch} />
                </Grid>
            </Grid>
            <TableContainer component={p.tableComponent ?? Card}>
                {!!title && <ListSubheader>{title}</ListSubheader>}
                {tableContent}
                {paginationContent}
            </TableContainer>
        </>
    );
};
