// ======================
// GlobalDeploymentsMapScreen
// ======================

import { useListDeploymentsGeolocationInfo } from "./map_hooks";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Avatar, Box, Card, CardContent, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { ListDeploymentsGeolocationInfo } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { DeploymentsGeolocationListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { useIsDesktop } from "../layout/MainLayout";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { KeyValuePair } from "../../common/text/CommonTypeFormats";
import React from "react";
import { CircleMarker, LayerGroup, MapContainer, Marker, TileLayer } from "react-leaflet";
import L, { LatLngBoundsExpression } from "leaflet";
import "./map_styles.css";
import { CmcIcon, CmoIcon } from "../project/ProjectCommon";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { LightDivider } from "../../common/misc";
import { mockListDeploymentsGeolocationInfo } from "../core/testutils/fixtures/MockDeploymentService";

interface GlobalDeploymentsMapScreenProps {
    projectId: string;
}

export const GlobalDeploymentsMapScreen: React.FC<GlobalDeploymentsMapScreenProps> = (p) => {
    const queryResult = useListDeploymentsGeolocationInfo(p.projectId);
    const isDesktop = useIsDesktop();

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Global Deployments Locations"} />
            <Box pb={2}>
                <Typography variant={"body2"} color={"textSecondary"}>
                    {"A geographical overview of all your CMO and CMC deployments."}
                </Typography>
                <QueryResultWrapper queryResult={queryResult}>
                    {queryResult.data?.pagerMeta.totalItems === 0 ? (
                        <Box pt={2}>
                            <Card>
                                <CardContent>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Typography variant={"body2"} color={"textSecondary"}>
                                            {"No deployments found."}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    ) : (
                        <>
                            {isDesktop ? (
                                <Grid container spacing={3} pt={2}>
                                    <Grid item xs={4}>
                                        <LocationsCardList data={queryResult.data} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Stack direction={"column"} spacing={3}>
                                            <LocationOverviewSection data={queryResult.data} isDesktop={isDesktop} />
                                            <LocationMap data={queryResult.data} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Stack direction={"column"} spacing={3} pt={2}>
                                    <LocationOverviewSection data={queryResult.data} isDesktop={isDesktop} />
                                    <LocationMap data={mockListDeploymentsGeolocationInfo.toObject()} />
                                    <LocationsCardList data={queryResult.data} />
                                </Stack>
                            )}
                        </>
                    )}
                </QueryResultWrapper>
            </Box>
        </ScreenContainer>
    );
};

// ======================
// LocationsCardList
// ======================

interface LocationsCardListProps {
    data: ListDeploymentsGeolocationInfo.Response.AsObject;
}

export const LocationsCardList: React.FC<LocationsCardListProps> = (p) => {
    const { data } = p;
    return (
        <Stack direction={"column"} spacing={3}>
            {data.itemsList.map((location, i) => {
                return <LocationCard key={i} location={location} />;
            })}
        </Stack>
    );
};

// ======================
// LocationCard
// ======================

interface LocationCardProps {
    location: DeploymentsGeolocationListItem.AsObject;
}

export const LocationCard: React.FC<LocationCardProps> = (p) => {
    const { location } = p;
    const theme = useTheme();
    return (
        <Card>
            <CardContent>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Avatar
                        sx={{
                            background: theme.palette.cirrus.main,
                            border: `1.5px solid rgba(255,255,255,.2)`,
                        }}
                    >
                        {location.countryFlag}
                    </Avatar>
                    <Box>
                        <Typography variant={"body1"} fontWeight={600}>
                            {location.region} ({location.cmcDeployments + location.cmoDeployments})
                        </Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {location.countryName}
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction={"column"} spacing={2} pt={2}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"Cirrus Migrate Cloud (CMC)"}
                    </Typography>
                    <Card sx={{ background: theme.palette.cirrus.main }}>
                        <CardContent>
                            <KeyValuePair label={"Windows"} value={location.totalCmcWindows} />
                            <KeyValuePair label={"Linux"} value={location.totalCmcLinux} />
                        </CardContent>
                    </Card>
                </Stack>
                <Stack direction={"column"} spacing={2} pt={2}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"Cirrus Migrate On-Premises (CMO)"}
                    </Typography>
                    <Card sx={{ background: theme.palette.cirrus.main }}>
                        <CardContent>
                            <KeyValuePair label={"Systems"} value={location.cmoDeployments} />
                            <KeyValuePair label={"Active Nexus"} value={location.totalCmoActiveNexus} />
                            <KeyValuePair label={"Storage Inserted"} value={formatKnownDataType(location.totalStorageInserted, KnownDataType.CAPACITY)} />
                        </CardContent>
                    </Card>
                </Stack>
            </CardContent>
        </Card>
    );
};

// ======================
// LocationMap
// ======================

interface LocationMapProps {
    data: ListDeploymentsGeolocationInfo.Response.AsObject;
}

export const LocationMap: React.FC<LocationMapProps> = (p) => {
    const { data } = p;
    const avgLat = data.itemsList.reduce((acc, cur) => acc + cur.geoGroupLat, 0) / data.itemsList.length;
    const avgLong = data.itemsList.reduce((acc, cur) => acc + cur.geoGroupLong, 0) / data.itemsList.length;
    const largestLat = data.itemsList.reduce((acc, cur) => (cur.geoGroupLat > acc ? cur.geoGroupLat : acc), data.itemsList[0].geoGroupLat);
    const largestLong = data.itemsList.reduce((acc, cur) => (cur.geoGroupLong > acc ? cur.geoGroupLong : acc), data.itemsList[0].geoGroupLong);
    const smallestLat = data.itemsList.reduce((acc, cur) => (cur.geoGroupLat < acc ? cur.geoGroupLat : acc), data.itemsList[0].geoGroupLat);
    const smallestLong = data.itemsList.reduce((acc, cur) => (cur.geoGroupLong < acc ? cur.geoGroupLong : acc), data.itemsList[0].geoGroupLong);
    const bounds: LatLngBoundsExpression = [
        [smallestLat, smallestLong],
        [largestLat, largestLong],
    ];

    return (
        <Card>
            <Box height={400}>
                <MapContainer center={[avgLat, avgLong]} bounds={bounds} maxZoom={5} scrollWheelZoom={true} style={{ height: "400px", zIndex: 0 }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {data.itemsList.map((location, i) => {
                        var icon = L.divIcon({
                            html: `<span class="txt">${location.cmcDeployments + location.cmoDeployments}</span>`,
                            className: "circle-with-txt",
                        });
                        return (
                            <LayerGroup>
                                <CircleMarker fillOpacity={1} center={[location.geoGroupLat, location.geoGroupLong]} />
                                <Marker key={i} icon={icon} position={[location.geoGroupLat, location.geoGroupLong]}></Marker>
                            </LayerGroup>
                        );
                    })}
                </MapContainer>
            </Box>
        </Card>
    );
};

// ======================
// LocationOverviewSection
// ======================

interface LocationOverviewSectionProps {
    data: ListDeploymentsGeolocationInfo.Response.AsObject;
    isDesktop: boolean;
}

export const LocationOverviewSection: React.FC<LocationOverviewSectionProps> = (p) => {
    const { data, isDesktop } = p;
    const theme = useTheme();
    const totalsCard = (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Stack direction={"column"} spacing={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography variant={"h4"} fontWeight={"600"}>
                            {getTotalDeployments(data.itemsList)}
                        </Typography>
                        <Typography>{"Total Deployments"}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography variant={"h4"} fontWeight={"600"} color={theme.palette.success.main}>
                            {getTotalDeployments(data.itemsList, true)}
                        </Typography>
                        <Typography color={theme.palette.success.main}>{"Online Deployments"}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );

    const productsCard = (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Stack direction={"column"} justifyContent={"center"} height={"100%"}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12} lg={6}>
                            <Stack direction={"row"} spacing={2}>
                                <Stack direction={"column"} spacing={2}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                        <Avatar
                                            variant={"rounded"}
                                            sx={{
                                                borderRadius: 3,
                                                background: theme.palette.cirrus.main,
                                                border: `1.5px solid ${theme.palette.primary.main}`,
                                            }}
                                        >
                                            <CmcIcon sx={{ color: "white" }} />
                                        </Avatar>
                                        <Stack direction={"column"}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <Typography variant={"h6"} fontWeight={"600"}>
                                                    {getTotalDeployments(data.itemsList, false, "cmc", "windows")}
                                                </Typography>
                                                <Typography>{"Windows CMC"}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <Typography variant={"h6"} fontWeight={"600"}>
                                                    {getTotalDeployments(data.itemsList, false, "cmc", "linux")}
                                                </Typography>
                                                <Typography>{"Linux CMC"}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Box height={"100%"}>
                                    <Divider orientation={"vertical"} />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Avatar
                                    variant={"rounded"}
                                    sx={{
                                        borderRadius: 3,
                                        background: theme.palette.cirrus.main,
                                        border: `1.5px solid ${theme.palette.primary.main}`,
                                    }}
                                >
                                    <CmoIcon sx={{ color: "white" }} />
                                </Avatar>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"h6"} fontWeight={"600"}>
                                        {getTotalDeployments(data.itemsList, false, "cmo")}
                                    </Typography>
                                    <Typography>{"Deployed CMO"}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );

    return isDesktop ? (
        <Box>
            <Grid p={0} container spacing={2}>
                <Grid item xs={4}>
                    {totalsCard}
                </Grid>
                <Grid item xs={8}>
                    {productsCard}
                </Grid>
            </Grid>
        </Box>
    ) : (
        <Stack direction={"column"} spacing={3}>
            {totalsCard}
            {productsCard}
        </Stack>
    );
};

const getTotalDeployments = (data: Array<DeploymentsGeolocationListItem.AsObject>, onlineOnly?: boolean, product?: "cmc" | "cmo", os?: "windows" | "linux") => {
    let total = 0;
    data.forEach((location) => {
        if (onlineOnly) {
            if (!product) {
                total += location.onlineDeployments;
            }
            if (product === "cmc") {
                if (!os) {
                    total += location.totalOnlineCmcWindows + location.totalOnlineCmcLinux;
                } else if (os === "windows") {
                    total += location.totalOnlineCmcWindows;
                } else if (os === "linux") {
                    total += location.totalOnlineCmcLinux;
                }
            }
        } else {
            if (!product) {
                total += location.cmcDeployments + location.cmoDeployments;
            }
            if (product === "cmc") {
                if (!os) {
                    total += location.totalCmcWindows + location.totalCmcLinux;
                } else if (os === "windows") {
                    total += location.totalCmcWindows;
                } else if (os === "linux") {
                    total += location.totalCmcLinux;
                }
            }
            if (product === "cmo") {
                total += location.cmoDeployments;
            }
        }
    });
    return total;
};
