import { CollapsibleHeader, CollapsibleSubheader, ReportFooter, ReportProps } from "../ReportCommon";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { getHostEnvDisplayName } from "../../galaxymigrate/GalaxyMigrateCommon";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { getOsDisplayName } from "../../project/ProjectCommon";
import { getLicenseDurationLeftString } from "../../galaxymigrate/hostLicense/HostLicenseCommon";
import { GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import CdsCloudLogo from "../../../assets/Cirrus_Data_Cloud_Logo_Large_Dark.png";
import * as React from "react";

// ======================
// HostConfigurationReport
// ======================

export const HostConfigurationReport: React.FC<ReportProps> = (p) => {
    const { reportData, reportInfo } = p;
    return (
        <Box>
            <Box p={4} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h4"}>{`Cirrus Data Cloud`}</Typography>
                    <Typography variant={"h4"}>{`Host Configurations Report`}</Typography>
                </Box>
                <Box>
                    <Box>
                        <img src={CdsCloudLogo} width={"200"} height={"auto"} alt={"Cirrus Data Cloud"} />
                    </Box>
                </Box>
            </Box>
            <CollapsibleHeader title={"1. Project"}>
                <Box pl={2} pt={1} pb={2}>
                    <Grid container>
                        <Grid item xs={4}>
                            {`Project:`}
                        </Grid>
                        <Grid item xs={8}>
                            {reportInfo.hcrMeta.projectName}
                        </Grid>
                        <Grid item xs={4}>
                            {`Project ID:`}
                        </Grid>
                        <Grid item xs={8}>
                            {reportInfo.hcrMeta.projectId}
                        </Grid>
                        <Grid item xs={4}>
                            {`Total Online Hosts:`}
                        </Grid>
                        <Grid item xs={8}>
                            {reportInfo.hcrMeta.totalOnlineHosts}
                        </Grid>
                        <Grid item xs={4}>
                            {`Host OS's:`}
                        </Grid>
                        <Grid item xs={8}>
                            {reportInfo.hcrMeta.osCountersList.map((os, i) => {
                                return `${os.count} ${getOsDisplayName(os.osType)}${i < reportInfo.hcrMeta.osCountersList.length - 1 ? ", " : ""}`;
                            })}
                        </Grid>
                        <Grid item xs={4}>
                            {`Host Environments:`}
                        </Grid>
                        <Grid item xs={8}>
                            {reportInfo.hcrMeta.envCountersList.map((env, i) => {
                                return `${env.count} ${getHostEnvDisplayName(env.environment.value)}${
                                    i < reportInfo.hcrMeta.envCountersList.length - 1 ? ", " : ""
                                }`;
                            })}
                        </Grid>
                    </Grid>
                </Box>
            </CollapsibleHeader>
            <CollapsibleHeader title={"2. Hosts"}>
                <Stack p={4} spacing={4}>
                    {reportData.hcr.hostDataList.map((host, i) => {
                        return (
                            <CollapsibleSubheader key={host.systemId} title={`${i + 1}. ${host.systemName}`}>
                                <Box pt={2}>
                                    <Typography variant={"h6"}>{`Host Details`}</Typography>
                                    <Box pt={2}>
                                        <Typography fontWeight={600}>Host Information</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Host Environment:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {getHostEnvDisplayName(host.hostDetails.info.deployment.hostEnvironment.value)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"System Timezone:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {host.hostDetails.info.deployment.systemTimezone}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"CPU:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {host.hostDetails.info.cpu}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Memory:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(host.totalCapacity, KnownDataType.CAPACITY)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box pt={2}>
                                    <Typography fontWeight={600}>Connection Information</Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {"Connection Endpoint:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {host.hostDetails.info.deployment.cdcEndpoint}
                                        </Grid>
                                        {host.hostDetails.info.deployment.cdcRelayServerAddress && (
                                            <>
                                                <Grid item xs={4}>
                                                    {"Relay Server Endpoint:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {host.hostDetails.info.deployment.cdcRelayServerAddress}
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={4}>
                                            {"Last Check-In:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(
                                                convertTimestampObjectToDate(host.hostDetails.info.deployment.lastCheckin),
                                                KnownDataType.DATE
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Latency:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(host.hostDetails.info.deployment.connectionLatency, KnownDataType.DURATION_MILLISECONDS)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Registered At:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(
                                                convertTimestampObjectToDate(host.hostDetails.info.deployment.registeredAt),
                                                KnownDataType.DATE
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={2}>
                                    <Typography fontWeight={600}>Software Information</Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {"CMC Version / mTDI Version:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {`${host.hostDetails.info.deployment.version} / ${host.hostDetails.info.mtdiVersion || "N/A"}`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"System ID:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {host.hostDetails.info.deployment.systemId}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Operating System:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {host.hostDetails.info.osClass}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Kernel Version:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {host.hostDetails.info.kernel}
                                        </Grid>
                                    </Grid>
                                </Box>
                                {host.hostDetails.info.helperNode && (
                                    <Box pt={2}>
                                        <Typography fontWeight={600}>Helper Node Information</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Helper Node Configured:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {host.hostDetails.info.helperNodeConfigured ? "Yes" : "No"}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Helper Node Type:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {getHostEnvDisplayName(host.hostDetails.info.helperNodeType.value)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                <Box pt={2}>
                                    <Typography fontWeight={600}>License Information</Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {"License Activated:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!!host.hostDetails.info.deployment.license
                                                ? formatKnownDataType(
                                                      convertTimestampObjectToDate(host.hostDetails.info.deployment.license.activatedAt),
                                                      KnownDataType.DATE
                                                  )
                                                : "No"}
                                        </Grid>
                                        {!!host.hostDetails.info.deployment.license && (
                                            <>
                                                <Grid item xs={4}>
                                                    {"Capacity Consumed:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(
                                                        host.hostDetails.info.deployment.license?.migrationCapacityConsumed,
                                                        KnownDataType.CAPACITY
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Capacity Remaining:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(
                                                        host.hostDetails.info.deployment.license?.migrationCapacityRemaining,
                                                        KnownDataType.CAPACITY
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Time Remaining:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {getLicenseDurationLeftString(
                                                        convertTimestampObjectToDate(host.hostDetails.info.deployment.license?.expireAt)
                                                    )}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                                <Box pt={2}>
                                    <Typography variant={"h6"}>Network Interfaces</Typography>
                                    {host.hostDetails.networkInterfacesList.map((n, i) => {
                                        return (
                                            <Box pt={1}>
                                                <Typography fontWeight={600}>{n.name}</Typography>
                                                <Grid container key={n.name}>
                                                    <Grid item xs={4}>
                                                        {"Address:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {n.addr}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"MTU:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {n.mtu || "N/A"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Mac:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {n.mac || "N/A"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Order:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {n.order || "N/A"}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Box pt={2}>
                                    <Typography variant={"h6"}>{`Storage Devices`}</Typography>
                                    {host.storageConfig.devicesList.map((d, i) => {
                                        return (
                                            <Box pt={2} key={d.blockDevice.deviceName}>
                                                <Typography fontWeight={600}>{d.blockDevice.deviceName}</Typography>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        {"Preferred Device Path:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {d.preferredDevicePath || "N/A"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Capacity:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {formatKnownDataType(d.blockDevice.capacity, KnownDataType.CAPACITY)}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Type:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {d.blockDevice.deviceType}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"FS:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {d.blockDevice.fsType || "N/A"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Role:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {Object.keys(GalaxyMigrateStorageConfig.Device.RoleInfo.Role)[d.role?.role] || "Discovered"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Migration:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {d.role?.migrationSessionUuid ?? "N/A"}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </CollapsibleSubheader>
                        );
                    })}
                </Stack>
            </CollapsibleHeader>
            <ReportFooter reportInfo={reportInfo} />
        </Box>
    );
};
