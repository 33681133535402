// ======================
// MigrateOpsAwsWizard
// ======================

import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import React from "react";
import { StepConfig } from "../../../../common/stepper/StepperHelpers";
import { DynamicStepper } from "../../../../common/stepper/StepperComponents";
import {
    AutoAllocationIntegrationStep,
    AutoAllocationParamsStep,
    ChooseSourceHostStep,
    ChooseSourceVolumesStep,
    CutoverStep,
    GetStartedStep,
    MigrationParametersStep,
} from "./MigrateOpsLocalDataMigrationWizardSteps";
import { ViewGeneratedConfigStep } from "../MigrateOpsWizardCommon";
import { useMigrateOpsLocalDataMigrationWizardState } from "./MigrateOpsLocalDataMigrationWizardState";

export enum LocalDataMigrationWizardStepId {
    GET_STARTED = "get-started",
    SOURCE_SYSTEM = "source-system",
    SOURCE_VOLUMES = "source-volumes",
    INTEGRATION = "auto-alloc-integration",
    AUTO_ALLOC_PARAMS = "auto-alloc-default-params",
    MIGRATION_PARAMS = "migration-params",
    CUTOVER = "cutover",
    FINISH_UP = "finish-up",
}
interface MigrateOpsLocalDataMigrationWizardProps {}

export const MigrateOpsLocalDataMigrationWizard: React.FC<MigrateOpsLocalDataMigrationWizardProps> = (p) => {
    const [currentStep, setCurrentStep] = React.useState(0);

    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson, resetState } = wizardState;
    const getStepConfigs = (): StepConfig[] => {
        const getStartedStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.GET_STARTED,
            renderer: () => <GetStartedStep setCurrentStep={setCurrentStep} />,
        };

        const chooseSourceHostStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.SOURCE_SYSTEM,
            renderer: () => <ChooseSourceHostStep setCurrentStep={setCurrentStep} />,
        };

        const chooseSourceVolumesStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.SOURCE_VOLUMES,
            renderer: () => <ChooseSourceVolumesStep setCurrentStep={setCurrentStep} />,
        };

        const autoAllocIntegrationStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.INTEGRATION,
            renderer: () => <AutoAllocationIntegrationStep setCurrentStep={setCurrentStep} />,
        };

        const autoAllocParamsStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.AUTO_ALLOC_PARAMS,
            renderer: () => <AutoAllocationParamsStep setCurrentStep={setCurrentStep} />,
        };

        const migrationParamsStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.MIGRATION_PARAMS,
            renderer: () => <MigrationParametersStep setCurrentStep={setCurrentStep} />,
        };

        const cutoverStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.CUTOVER,
            renderer: () => <CutoverStep setCurrentStep={setCurrentStep} />,
        };

        const finishUpStep: StepConfig = {
            id: LocalDataMigrationWizardStepId.FINISH_UP,
            label: "Finish Up",
            renderer: () => (
                <ViewGeneratedConfigStep
                    operationJson={operationJson}
                    onGoBack={() => {
                        setCurrentStep(configArray.findIndex((s) => s.id === LocalDataMigrationWizardStepId.CUTOVER));
                    }}
                    submitButtonLabel={"Create Migration Session"}
                    resetState={resetState}
                    id={LocalDataMigrationWizardStepId.FINISH_UP}
                />
            ),
        };

        const configArray = [
            getStartedStep,
            chooseSourceHostStep,
            chooseSourceVolumesStep,
            autoAllocIntegrationStep,
            autoAllocParamsStep,
            migrationParamsStep,
            cutoverStep,
            finishUpStep,
        ];

        return configArray;
    };

    const [stepConfigs, setStepConfigs] = React.useState<StepConfig[]>(getStepConfigs());

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Migrate Local Data"} />
            <DynamicStepper
                hideStepper
                stepperProps={{
                    activeStep: currentStep,
                    stepConfigs: stepConfigs,
                    startingStep: 0,
                    totalSteps: stepConfigs.length,
                }}
                orientation={"horizontal"}
                useTransitions
            />
        </ScreenContainer>
    );
};
