import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { GmMigrationWizardVolumeState } from "../../../GmMigrationService";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { VendorAllocateVolumesStepProps } from "../../GmAutoAllocationCommon";

import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { ColumnDef } from "../../../../../common/table/DataTable";
import { formatKnownDataType, KnownDataType } from "../../../../../common/utils/formatter";
import { SimpleTable } from "../../../../../common/table/SimpleTable";
import {
    AzureCalculatedDiskAssignmentConfig,
    AzureConsumptionPlanDiskAssignmentConfig,
    DiskAssignmentConfig,
    getDiskParamsEnabled,
    getDiskSizeInGiB,
} from "./AzureIntegrationHelpers";
import produce from "immer";
import { AzureRecommendationAlert } from "./AzureRecommendationWizard";
import {
    useGetAzureStorageConsumptionPlan,
    useListAzureStorageConsumptionPlans,
} from "../../../../integrations/azureStoragePlanner/azure_storage_planner_hooks";
import { useCurrentProjectID } from "../../../../project/CurrentProjectState";
import { GetAzureStorageConsumptionPlan } from "gc-web-proto/galaxycompletepb/apipb/planner_api_pb";
import { useMountEffect } from "../../../../../common/hooks/hookslib";

export const AzureAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const allocateNow = p.allocateFunc;
    const projectId = useCurrentProjectID();
    const defaultParams = state.selectedIntegration.defaultVolumeParams.azure;

    const consumptionPlans = useListAzureStorageConsumptionPlans(projectId);
    const [selectedConsumptionPlanId, setSelectedConsumptionPlanId] = useState(null);
    const [selectedConsumptionPlan, setSelectedConsumptionPlan] = useState<GetAzureStorageConsumptionPlan.Response.AsObject>(null);
    const consumptionPlanDetails = useGetAzureStorageConsumptionPlan(selectedConsumptionPlanId);

    const [consumptionPlanError, setConsumptionPlanError] = useState(null);
    const getInitalDiskAssignments = () => {
        const assignments: { [key: string]: DiskAssignmentConfig } = {};
        state.sourceDevices.forEach((device) => {
            assignments[device.source.getBlockDevice().getDeviceName()] = {
                managedDiskStorageType: defaultParams.storageType,
                iops: defaultParams.iops,
                throughput: defaultParams.throughput,
                capacity: getDiskSizeInGiB(device.source.getBlockDevice().getCapacity()),
            };
        });
        return assignments;
    };

    const [diskConfigs, setDiskConfigs] = useState<{ [key: string]: DiskAssignmentConfig }>(getInitalDiskAssignments());

    const setDiskCapacity = (diskName: string, capacity: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[diskName].capacity = capacity;
                } else {
                    draft[diskName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setDiskIops = (diskName: string, iops: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (iops > 0) {
                    draft[diskName].iops = iops;
                } else {
                    draft[diskName].iops = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.getAzure().setIops(iops);
    };

    const setDiskThroughput = (diskName: string, throughput: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (throughput > 0) {
                    draft[diskName].throughput = throughput;
                } else {
                    draft[diskName].throughput = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.getAzure().setThroughput(throughput);
    };

    const setDiskStorageType = (diskName: string, storageType: AutoAlloc.VolumeParams.Azure.StorageType) => {
        setDiskConfigs(
            produce((draft) => {
                draft[diskName].managedDiskStorageType = storageType;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.setAzure(new AutoAlloc.VolumeParams.Azure().setStorageType(storageType));
    };

    // const setDiskAsElasticSan = (diskName: string) => {
    //     setDiskConfigs(
    //         produce((draft) => {
    //             draft[diskName].elasticSan = true;
    //         })
    //     );
    //     const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
    //     sourceDeviceInAutoAllocState.autoAllocParams.setAzuresan(new AutoAlloc.VolumeParams.AzureElasticSAN());
    //     sourceDeviceInAutoAllocState.autoAllocParams.setAzure(undefined);
    // };

    const handleApplyConsumptionPlan = useCallback(() => {
        const deviceAssignments: { [key: string]: AzureConsumptionPlanDiskAssignmentConfig } = {};
        const selectedHost = consumptionPlanDetails.data?.details?.hostsIncludedList.find(
            (host) => host.systemInfo.info.deployment.systemId === state.deploymentDetails.getInfo().getDeployment().getSystemId()
        );
        const selectedHostRec = consumptionPlanDetails.data?.details?.recommendationResult.hostsList.find((host) => host.uuid === selectedHost.uuid);

        if (!selectedHost || !!selectedHostRec.volumesList.find((vol) => vol.volumeType.elasticSan)) {
            setSelectedConsumptionPlanId(null);
            setSelectedConsumptionPlan(null);
            setConsumptionPlanError("The selected consumption plan does not apply to the chosen source host. Please choose a different plan.");
            return;
        }
        setConsumptionPlanError(null);

        selectedHostRec.volumesList.forEach((vol) => {
            const volInfo = selectedHost.volumesList.find((volInfo) => volInfo.uuid === vol.uuid);
            deviceAssignments[volInfo.device.blockDevice.deviceName] = {
                capacity: getDiskSizeInGiB(vol.provisionedCapacity),
                volumeType: vol.volumeType,
                throughput: vol.provisionedThroughput,
                iops: vol.provisionedIops,
            };
        });

        const newDiskConfigs: { [key: string]: DiskAssignmentConfig } = {};

        for (let key in deviceAssignments) {
            newDiskConfigs[key] = {
                capacity: deviceAssignments[key].capacity,
                managedDiskStorageType: deviceAssignments[key].volumeType.managedDisk?.storageType,
                //elasticSan: deviceAssignments[key].volumeType.elasticSan,
                throughput: deviceAssignments[key].throughput,
                iops: deviceAssignments[key].iops,
            };
            const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === key);
            if (!sourceDeviceInAutoAllocState) {
                delete deviceAssignments[key];
            }
            if (!!sourceDeviceInAutoAllocState) {
                if (!!deviceAssignments[key].volumeType.managedDisk) {
                    sourceDeviceInAutoAllocState.autoAllocParams.getAzure()?.setStorageType(deviceAssignments[key].volumeType.managedDisk?.storageType);
                }
                // if (deviceAssignments[key].volumeType.elasticSan) {
                //     sourceDeviceInAutoAllocState.autoAllocParams.setAzure(undefined);
                //     sourceDeviceInAutoAllocState.autoAllocParams.setAzuresan(new AutoAlloc.VolumeParams.AzureElasticSAN());
                // }
                sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = deviceAssignments[key].capacity;
            }
        }
        setDiskConfigs(newDiskConfigs);
    }, [setDiskConfigs, consumptionPlanDetails.data, state.deploymentDetails, state.sourceDevices]);
    const handleConfirmAzureRecSelection = (deviceAssignments: { [key: string]: AzureCalculatedDiskAssignmentConfig }) => {
        const newDiskConfigs: { [key: string]: DiskAssignmentConfig } = {};
        for (let key in deviceAssignments) {
            newDiskConfigs[key] = {
                capacity: deviceAssignments[key].capacity,
                managedDiskStorageType: deviceAssignments[key].product.storageType,
                throughput: deviceAssignments[key].throughput,
                iops: deviceAssignments[key].iops,
            };
            const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === key);
            sourceDeviceInAutoAllocState.autoAllocParams.getAzure()?.setStorageType(deviceAssignments[key].product.storageType);
            sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = deviceAssignments[key].capacity;
        }
        setDiskConfigs(newDiskConfigs);
    };

    const cols: ColumnDef<GmMigrationWizardVolumeState>[] = [
        {
            id: "source",
            label: "Source Volume",
            getter: (d) => `${d.source.getBlockDevice().getDeviceName()} (${d.source.getBlockDevice().getDeviceType()})`,
        },
        {
            id: "capacity",
            label: "Capacity",
            getter: (d) => d.source.getBlockDevice().getCapacity(),
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                const error = diskConfig.capacity < getDiskSizeInGiB(r.source.getBlockDevice().getCapacity());
                return (
                    <TextField
                        value={diskConfig.capacity}
                        type={"number"}
                        variant={"outlined"}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">GiB</InputAdornment>,
                        }}
                        error={error}
                        helperText={error ? `Must be at least ${formatKnownDataType(r.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}` : ""}
                        onChange={(e) => {
                            setDiskCapacity(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                    />
                );
            },
        },
        {
            id: "class",
            label: "New Disk Class",
            getter: (d) => null,
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                const onSelect = (e: SelectChangeEvent<AutoAlloc.VolumeParams.Azure.StorageType | number>) => {
                    setDiskStorageType(r.source.getBlockDevice().getDeviceName(), e.target.value as AutoAlloc.VolumeParams.Azure.StorageType);
                };
                return (
                    <Select
                        defaultValue={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM}
                        value={diskConfig?.managedDiskStorageType}
                        variant={"outlined"}
                        onChange={onSelect}
                    >
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD}>{"Ultra Disk Storage"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2}>{"Premium SSD v2"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM}>{"Premium SSD"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.STANDARD_SSD}>{"Standard SSD"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.STANDARD}>{"Standard HDD"}</MenuItem>
                    </Select>
                );
            },
        },
        {
            id: "iops",
            label: "Disk IOPS",
            getter: (d) => null,
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                return (
                    <TextField
                        value={diskConfig.iops}
                        type={"number"}
                        variant={"outlined"}
                        onChange={(e) => {
                            setDiskIops(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">IOPS</InputAdornment>,
                        }}
                        disabled={!getDiskParamsEnabled(r.autoAllocParams.getAzure()?.getStorageType())}
                    />
                );
            },
        },
        {
            id: "throughput",
            label: "Disk Throughput (MB/s)",
            getter: (d) => null,
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                return (
                    <TextField
                        value={diskConfig.throughput}
                        type={"number"}
                        variant={"outlined"}
                        onChange={(e) => {
                            setDiskThroughput(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">MB/s</InputAdornment>,
                        }}
                        disabled={!getDiskParamsEnabled(r.autoAllocParams.getAzure()?.getStorageType())}
                    />
                );
            },
        },
        {
            id: "encryption",
            label: "At-Rest Encryption",
            getter: (d) => null,
            renderer: (v, r) => {
                return (
                    <Select value={1} variant={"outlined"}>
                        <MenuItem value={1}>{"Platform-Managed Key"}</MenuItem>
                    </Select>
                );
            },
        },
    ];

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setAzure(new AutoAlloc.VolumeParams.Azure().setStorageType(AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM));
        }
    });

    useEffect(() => {
        if (!!selectedConsumptionPlanId && !!consumptionPlanDetails.data && !selectedConsumptionPlan) {
            setSelectedConsumptionPlan(consumptionPlanDetails.data);
            handleApplyConsumptionPlan();
            setSelectedConsumptionPlan(null);
        }
    }, [selectedConsumptionPlanId, consumptionPlanDetails.data, selectedConsumptionPlan, handleApplyConsumptionPlan]);

    return (
        <>
            <Box pt={1} pb={1}>
                <AzureRecommendationAlert autoAllocState={state} handleConfirmAzureSelection={handleConfirmAzureRecSelection} />
            </Box>
            <Typography color={"textSecondary"}>
                {`Azure Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            {!!consumptionPlans.data?.plansList.length && (
                <Box pt={2} pb={1}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <Typography variant={"h6"}>Choose from Consumption Plans</Typography>
                                    <Typography>Apply parameters based on a previously created storage consumption plan.</Typography>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-a-plan">{`Consumption Plan`}</InputLabel>
                                        <Select
                                            labelId={"select-a-plan"}
                                            fullWidth
                                            value={selectedConsumptionPlanId}
                                            label={"Consumption Plan"}
                                            onChange={(e) => setSelectedConsumptionPlanId(e.target.value)}
                                        >
                                            {consumptionPlans.data?.plansList.map((p) => {
                                                return (
                                                    <MenuItem value={p.id}>
                                                        <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                            <Box>
                                                                <Typography>{p.name}</Typography>
                                                                <Typography color={"textSecondary"}>{p.description}</Typography>
                                                            </Box>
                                                            <Box>
                                                                {formatKnownDataType(p.totalMonthlyCost, KnownDataType.CURRENCY_USD)} {"per mo."}
                                                            </Box>
                                                        </Stack>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {consumptionPlanError && (
                                <Box pt={1}>
                                    <Alert severity={"error"}>{consumptionPlanError}</Alert>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            )}{" "}
            <SimpleTable rows={state.sourceDevices} rowIdGetter={(r) => r.source.getBlockDevice().getDeviceName()} cols={cols} />
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});
