import { useAppServices } from "../../app/services";
import { GmMigrationWizardState } from "../GmMigrationService";
import { observer } from "mobx-react-lite";
import {
    getCounterTypeDisplayValue,
    HostBasedLicenseView,
    LicensingLearnMoreLink,
    useCurrentProjectLicenseModel,
    useProjectHasAvailableHostLicense,
    useProjectHasLicenseTimeExtension,
} from "../../license/LicenseCommon";
import {
    getDeploymentSelectionContinueDisabled,
    getHostLicenseExpiredHasExtension,
    getHostLicenseExpiredNoExtension,
    getHostLicenseInsufficientForMigration,
    getHostWillConsumeProjectLicense,
} from "./GmMigrationWizardUtils";
import { Alert, Box, Button, Grid, Radio, Typography, useTheme } from "@mui/material";
import { GalaxyMigrateDeploymentDetails, GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { useNavigateToDeploymentsList } from "../../galaxymigrate/GalaxyMigrateCommon";
import { renderServerDataWithLoadingList, useInitData } from "../../core/data/DataLoaderHooks";
import { GalaxyMigrationDeploymentOSDisplay } from "../../galaxymigrate/GalaxyMigrateDeploymentsList";
import { MdArrowForward } from "react-icons/md";
import { ColumnDef, DataTable } from "../../../common/table/DataTable";
import { WizardStepProp } from "./GmMigrationWizardCommon";
import { useListGalaxyMigrateDeployments } from "../../deployment/deployment_hooks";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import { useState } from "react";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../../common/table/QueryTable";
import { EmptyTableConfig } from "../../../common/table/TableCommon";
import { DeploymentQueryKeys } from "../../../common/QueryKeys";

// ======================
// GmMigrationWizardDeploymentSelectionStep
// ======================

export const GmMigrationWizardDeploymentSelectionStep: React.FC<WizardStepProp> = observer((p) => {
    const wizardState = p.wizardState;
    const { progressService } = useAppServices();
    const licenseModel = useCurrentProjectLicenseModel();
    const isHostLicenseAvailable = useProjectHasAvailableHostLicense();
    const isHostLicenseTimeExtensionAvailable = useProjectHasLicenseTimeExtension();
    const selectedHostLicense = wizardState.deployment.data?.getInfo().getDeployment().getLicense();
    const hostLicenseInsufficientForMigration = getHostLicenseInsufficientForMigration(selectedHostLicense, isHostLicenseAvailable);
    const hostLicenseForMigrationExpiredNoExtension = getHostLicenseExpiredNoExtension(selectedHostLicense, isHostLicenseTimeExtensionAvailable);
    const hostWillConsumeProjectLicense = getHostWillConsumeProjectLicense(selectedHostLicense, isHostLicenseAvailable);
    const hostLicenseForMigrationExpiredHasExtension = getHostLicenseExpiredHasExtension(selectedHostLicense, isHostLicenseTimeExtensionAvailable);
    const continueDisabled = getDeploymentSelectionContinueDisabled(
        licenseModel,
        wizardState,
        hostLicenseInsufficientForMigration,
        hostLicenseForMigrationExpiredNoExtension
    );

    const onContinue = async () => {
        await progressService.track(wizardState.confirmDeploymentSelection());
        wizardState.stepperState.goToNextStep();
    };

    return (
        <>
            <Grid container justifyContent={"space-between"}>
                <Typography paragraph>{"Select the source for this migration session"}</Typography>
                <br />
            </Grid>
            <DeploymentsSelectionTable wizardState={wizardState} />
            <br />
            <Grid container justifyContent={"center"}>
                <Box p={1}>
                    <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                        {"Continue"}
                    </Button>
                </Box>
            </Grid>
            <DeploymentLicenseAlert
                deployment={wizardState.deployment.data}
                hostLicenseInsufficient={hostLicenseInsufficientForMigration}
                hostWillConsumeProjectLicense={hostWillConsumeProjectLicense}
                hostLicenseExpiredNoExtension={hostLicenseForMigrationExpiredNoExtension}
                hostLicenseExpiredHasExtension={hostLicenseForMigrationExpiredHasExtension}
            />
        </>
    );
});

// ======================
// DeploymentLicensingAlert
// ======================

interface DeploymentLicenseAlertProps {
    deployment: GalaxyMigrateDeploymentDetails;
    hostLicenseInsufficient: boolean;
    hostLicenseExpiredNoExtension: boolean;
    hostLicenseExpiredHasExtension: boolean;
    hostWillConsumeProjectLicense: boolean;
}

const DeploymentLicenseAlert: React.FC<DeploymentLicenseAlertProps> = observer((p) => {
    const hostLicenseType = getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE);
    if (!!p.deployment) {
        return (
            <HostBasedLicenseView>
                <Box pt={2}>
                    {p.hostLicenseInsufficient && (
                        <Alert severity={"error"}>
                            Migration session cannot be created because this host is not currently licensed and this project's Project License Key does not have
                            a {hostLicenseType}.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </Alert>
                    )}
                    {p.hostLicenseExpiredNoExtension && (
                        <Alert severity={"error"}>
                            Migration session cannot be created because this host's license has expired and this project's Project License Key does not have any{" "}
                            {hostLicenseType} Extensions.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </Alert>
                    )}
                    {p.hostLicenseExpiredHasExtension && (
                        <Alert severity={"warning"}>
                            This host's license is expired. A new {hostLicenseType} Extension will be consumed from this project's Project License Key when this
                            migration session is created.
                        </Alert>
                    )}
                    {p.hostWillConsumeProjectLicense && (
                        <Alert severity={"warning"}>
                            This host is not currently licensed. A new {hostLicenseType} will be consumed from this project's Project License Key when this
                            migration session is created.
                        </Alert>
                    )}
                </Box>
            </HostBasedLicenseView>
        );
    }

    return null;
});

// ======================
// DeploymentsSelectionTable
// ======================

interface DeploymentsSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

const DeploymentsSelectionTable: React.FC<DeploymentsSelectionTableProps> = observer((props) => {
    const t = useTheme();
    const { wizardState } = props;
    const projectId = useCurrentProjectID();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const deploymentsList = useListGalaxyMigrateDeployments(projectId, true, pageIndex, pageSize, true);
    const navigateToDeploymentsList = useNavigateToDeploymentsList();
    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();
    const currentlySelectedDeploymentId = wizardState.deploymentId;
    const columns = [
        columnHelper.accessor((r) => r, {
            header: "Select",
            id: "select",
            cell: (props) => {
                const deploymentId = props.row.original.deployment.systemId;
                const selected = currentlySelectedDeploymentId === deploymentId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    wizardState.selectDeployment(deploymentId);
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        }),
        columnHelper.accessor((r) => r.deployment.systemName, {
            header: "Name",
            id: "systemName",
            cell: (props) => {
                const hasLicense = !!props.row.original.deployment.license;
                return (
                    <>
                        <Box>
                            <Typography variant={"body1"}>{props.getValue()}</Typography>
                        </Box>
                        <HostBasedLicenseView>
                            <Box>
                                <Typography variant={"body2"} color={hasLicense ? t.palette.success.main : "textSecondary"}>
                                    {hasLicense ? `Host License Activated` : `No Activated License`}
                                </Typography>
                            </Box>
                        </HostBasedLicenseView>
                    </>
                );
            },
        }),
        columnHelper.accessor((r) => r.deployment.version, {
            header: "Version",
            id: "version",
            cell: (props) => {
                return <Typography>{props.getValue()}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "OS",
            id: "os",
            cell: (props) => {
                return (
                    <>
                        <GalaxyMigrationDeploymentOSDisplay deploymentInfo={props.row.original} />
                    </>
                );
            },
        }),
    ];

    const emptyTableConfig: EmptyTableConfig = {
        title: "No Eligible Hosts Found",
        message: <>Hosts must be online and connected in order to be selected for migration. </>,
        actionButton: (
            <Button variant={"contained"} color={"primary"} endIcon={<MdArrowForward />} onClick={navigateToDeploymentsList}>
                {`Go To Hosts`}
            </Button>
        ),
    };

    return (
        <QueryTable
            data={deploymentsList.data?.itemsList}
            columns={columns}
            pagination={{ pageIndex, pageSize }}
            setPagination={setPagination}
            pageCount={deploymentsList.data?.pagerMeta?.totalPages}
            error={deploymentsList.error}
            isError={deploymentsList.isError}
            isLoading={deploymentsList.isLoading}
            emptyTableConfig={emptyTableConfig}
            queryKey={DeploymentQueryKeys.listGmDeployments}
            refetch={deploymentsList.refetch}
        />
    );
});
