import {
    AuthenticatedUserInfo,
    ChangeMyPassword,
    CompleteOnboard,
    DisableMfaTotp,
    ForgotPassword,
    Login,
    RequestMfaToken,
    ResetPassword,
    Signup,
} from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { useMutationAlertError, useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useAuthState } from "./AuthState";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { APP_ROUTES } from "../app/AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { FcCheckmark } from "react-icons/fc";
import { Typography } from "@mui/material";
import React, { FormEvent, useCallback, useEffect } from "react";
import { useAppServices } from "../app/services";
import { AuthMutationKeys, UserQueryKeys } from "../../common/QueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGetMyUserInfo } from "../user/user_hooks";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { GetMyUserInfo } from "../../../packages/gc-web-proto/galaxycompletepb/apipb/auth_api_pb";

export const useSignUp = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.signUp],
        mutationFn: async (req: Signup.Request) => await apis.loginService.signup(req, null),
    });
};

export const useRequestMfaToken = (user?: string, password?: string) => {
    const apis = useGrpcApiStore();
    const req = new RequestMfaToken.Request().setUser(user).setPassword(password);

    const queryFn = async (): Promise<RequestMfaToken.Response> => {
        return await apis.loginService.requestMfaToken(req, null);
    };
    return useQuery<RequestMfaToken.Response>({
        queryKey: [AuthMutationKeys.requestMfaToken],
        queryFn: queryFn,
        enabled: !!user && !!password,
        initialData: new RequestMfaToken.Response(),
    });
};

export const useLogin = () => {
    const { loginService } = useGrpcApiStore();
    const globalDialogState = useGlobalDialogState();
    const { setAwsAuthenticated, setAzureAuthenticated } = useAuthState();
    const updateCurrentUser = useUpdateCurrentAuthUser();
    const navigate = useNavigate();
    const location = useLocation();

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.logIn],
        mutationFn: async (req: Login.Request) => {
            const awsConfirmationMessage = `THIS ACTION IS IRREVERSIBLE. By continuing, your existing account ${req.getUser()} will be connected to your AWS Marketplace Subscription.`;
            const azureConfirmationMessage = `By continuing, your Azure Marketplace Subscription will be activated and connected to your existing account ${req.getUser()}. Depending on Microsoft Azure Marketplace's processing time, this activation process may take up to a few minutes.`;
            const getPostLoginAzureSuccessMessage = async () => {
                const getMessageBody = () => (
                    <Box display={"flex"} alignItems={"center"}>
                        <Box pr={2}>
                            <FcCheckmark size={"1.5em"} />
                        </Box>
                        <Typography>
                            Congratulations! Your Azure Marketplace Subscription has been activated successfully and connected to your Cirrus Data Cloud account{" "}
                            {req.getUser()}.
                        </Typography>
                    </Box>
                );
                return await globalDialogState.addAlertDialog({
                    title: "Azure Marketplace Subscription Activated",
                    renderAdditionalContent: getMessageBody,
                    okButtonLabel: "Done",
                });
            };
            const getPostLoginAwsSuccessMessage = async () => {
                const getMessageBody = () => (
                    <Box display={"flex"} alignItems={"center"}>
                        <Box pr={2}>
                            <FcCheckmark size={"1.5em"} />
                        </Box>
                        <Typography>Congratulations! {req.getUser()} is successfully connected with your AWS Marketplace Subscription.</Typography>
                    </Box>
                );
                return await globalDialogState.addAlertDialog({
                    title: "Account is connected to AWS Marketplace Subscription",
                    renderAdditionalContent: getMessageBody,
                    okButtonLabel: "Done",
                });
            };

            let confirmed;
            if (!!req.getAwsMarketplacePairToken()) {
                confirmed = await globalDialogState.addConfirmDialog({
                    title: "Connect to AWS Marketplace Subscription Confirmation",
                    message: awsConfirmationMessage,
                });
            } else if (!!req.getAzureMarketplacePairToken()) {
                confirmed = await globalDialogState.addConfirmDialog({
                    title: "Azure Marketplace Subscription Activation",
                    message: azureConfirmationMessage,
                });
            } else {
                confirmed = true;
            }

            if (confirmed) {
                const result = await loginService.login(req, null);
                if (!!result) {
                    if (!!req.getAwsMarketplacePairToken()) {
                        setAwsAuthenticated(true);
                    }
                    if (!!req.getAzureMarketplacePairToken()) {
                        setAzureAuthenticated(true);
                    }
                    if (location.pathname === "/") {
                        navigate(APP_ROUTES.PROJECTS);
                    }
                    if (!!req.getAwsMarketplacePairToken()) {
                        const alerted = await getPostLoginAwsSuccessMessage();
                        if (alerted) {
                            navigate(APP_ROUTES.PROJECTS);
                        }
                    }
                    if (!!req.getAzureMarketplacePairToken()) {
                        const alerted = await getPostLoginAzureSuccessMessage();
                        if (alerted) {
                            navigate(APP_ROUTES.PROJECTS);
                        }
                    }

                    return result;
                }
            }
        },
        onSuccess: (res: Login.Response) => {
            updateCurrentUser(res.getUserInfo().toObject());
        },
    });
};

export const useRequestResetPassword = () => {
    const loginService = useGrpcApiStore((s) => s.loginService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.requestResetPassword],
        mutationFn: async (req: ForgotPassword.Request) => await loginService.forgotPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "New Password Instructions Sent",
                message: `We've sent a link to set your new password to ${variables.getUser()}. Please check your email inbox.`,
            });
        },
    });
};

export const useSetNewPassword = () => {
    const loginService = useGrpcApiStore((s) => s.loginService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.setNewPassword],
        mutationFn: async (req: ResetPassword.Request) => await loginService.resetPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "Password Set Successfully",
                message: "You can now use your new password to log in.",
            });
        },
    });
};

export const useChangeMyPassword = () => {
    const authService = useGrpcApiStore((s) => s.authService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.changeMyPassword],
        mutationFn: async (req: ChangeMyPassword.Request) => await authService.changeMyPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "Password Set Successfully",
                message: "You can now use your new password to log in.",
            });
        },
    });
};

export const useActivateUser = () => {
    const apiAuthService = useGrpcApiStore((s) => s.authService);
    const updateCurrentAuthUser = useUpdateCurrentAuthUser();
    const refreshUserSession = useRefreshUserSessionInfo();
    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.activateUser],
        mutationFn: async (req: CompleteOnboard.Request) => await apiAuthService.completeOnboard(req, null),
        onSuccess: async (res) => {
            updateCurrentAuthUser(res.getUserInfo().toObject());
            await refreshUserSession();
        },
    });
};

export const useDisableMfaTotp = () => {
    const authService = useGrpcApiStore((s) => s.authService);

    return useMutationTrackAndAlertError({
        mutationKey: [AuthMutationKeys.disableMfaTotp],
        mutationFn: async (req: DisableMfaTotp.Request) => await authService.disableMfaTotp(req, null),
    });
};

export const useUpdateCurrentAuthUser = () => {
    const updateCurrentUser = useAuthState((s) => s.updateCurrentUser);
    return (user: AuthenticatedUserInfo.AsObject) => {
        updateCurrentUser(user);
    };
};

export const useIsAuthenticated = () => {
    const currentUser = useCurrentUser();
    return !!currentUser;
};

export const useIsOnboardCompleted = () => {
    const authenticated = useIsAuthenticated();
    const currentUser = useCurrentUser();
    return authenticated && currentUser.activated && !!currentUser.lastName && !!currentUser.firstName;
};

export const useLogOut = () => {
    const loginServiceAPI = useGrpcApiStore((s) => s.loginService);
    const logOut = useAuthState((s) => s.logOut);
    return useMutationAlertError({
        mutationKey: [AuthMutationKeys.logOut],
        mutationFn: async () => {
            return await loginServiceAPI.logout(new Empty(), null);
        },
        onSuccess: () => {
            logOut();
        },
    });
};

export const useInitAuthentication = () => {
    const onAuthError = useAuthState((s) => s.onAuthError);
    const authError = useGrpcApiStore((s) => s.authError);
    const updateCurrentUser = useUpdateCurrentAuthUser();
    // set up monitor to change auth state on auth error
    useEffect(() => {
        if (!!authError) {
            console.log("auth error encountered");
            onAuthError(authError);
        }
    }, [authError, onAuthError]);

    const myUserInfo = useGetMyUserInfo();
    return useCallback(async () => {
        const data = await myUserInfo.refetch();
        updateCurrentUser(data.data?.userSessionInfo);
    }, [myUserInfo]);
};

export const useCurrentUser = () => useAuthState((s) => s.currentUser);

export const useRefreshUserSessionInfo = () => {
    const queryClient = useQueryClient();
    const updateCurrentUser = useUpdateCurrentAuthUser();
    const query = useGetMyUserInfo();

    return useCallback(async () => {
        await queryClient.refetchQueries({
            queryKey: [UserQueryKeys.getMyUserInfo],
        });
        const data: GetMyUserInfo.Response.AsObject = queryClient.getQueryData([UserQueryKeys.getMyUserInfo]);
        updateCurrentUser(data.userSessionInfo);
    }, [queryClient]);
};
