// ======================
// CmoMigrationWarningsSummary
// ======================

import { alpha, Avatar, Box, Card, CardProps, darken, Grid, ListSubheader, Stack, Typography, useTheme } from "@mui/material";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { useState } from "react";
import { TbAlertSquareFilled } from "react-icons/tb";
import { FiAlertCircle, FiAlertTriangle, FiServer } from "react-icons/fi";
import { CmoSystemWarningsSummary } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { BiErrorAlt } from "react-icons/bi";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { format } from "date-fns";
import { useGetCmoSystemWarningsSummary } from "../cmo_migration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";

interface CmoMigrationWarningsSummaryProps {
    projectId: string;
    systemId?: string;
    cardProps?: CardProps;
}

export const CmoMigrationWarningsSummary: React.FC<CmoMigrationWarningsSummaryProps> = (p) => {
    const { projectId, systemId, cardProps } = p;
    const warningsSummary = useGetCmoSystemWarningsSummary(projectId, systemId);

    const [focusBar, setFocusBar] = useState(null);
    const [mouseLeave, setMouseLeave] = useState(true);
    const summariesList = warningsSummary.data?.summary?.dailySystemWarningSummariesList || [];
    const theme = useTheme();
    return (
        <QueryResultWrapper queryResult={warningsSummary}>
            <Card {...cardProps}>
                <ListSubheader>{`Recent Alerts Summary (Past ${warningsSummary.data?.summary.totalDaysReturned} Days)`}</ListSubheader>
                <Grid container columnSpacing={4} rowSpacing={2} p={2}>
                    <Grid item>
                        <Stack direction={"row"} spacing={1}>
                            <Avatar
                                variant={"rounded"}
                                sx={{
                                    backgroundColor: theme.palette.cirrus.main,
                                    borderRadius: 3,
                                    border: `2px solid ${theme.palette.primary.main}`,
                                }}
                            >
                                <TbAlertSquareFilled color={"white"} />
                            </Avatar>
                            <Box>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {summariesList
                                        .map((summary) => summary.warningLevelCount + summary.errorLevelCount + summary.criticalLevelCount)
                                        .reduce((a, b) => a + b, 0)}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Total"}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} spacing={1}>
                            <Avatar
                                variant={"rounded"}
                                sx={{
                                    backgroundColor: theme.palette.cirrus.main,
                                    borderRadius: 3,
                                    border: `2px solid ${theme.palette.warning.main}`,
                                }}
                            >
                                <FiAlertTriangle color={"white"} />
                            </Avatar>
                            <Box>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {summariesList.map((summary) => summary.warningLevelCount).reduce((a, b) => a + b, 0)}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Warnings"}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} spacing={1}>
                            <Avatar
                                variant={"rounded"}
                                sx={{
                                    backgroundColor: theme.palette.cirrus.main,
                                    borderRadius: 3,
                                    border: `2px solid ${theme.palette.error.main}`,
                                }}
                            >
                                <FiAlertCircle color={"white"} />
                            </Avatar>
                            <Box>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {summariesList.map((summary) => summary.errorLevelCount).reduce((a, b) => a + b, 0)}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Errors"}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} spacing={1}>
                            <Avatar
                                variant={"rounded"}
                                sx={{
                                    backgroundColor: theme.palette.cirrus.main,
                                    borderRadius: 3,
                                    border: `2px solid ${darken(theme.palette.error.main, 0.2)}`,
                                }}
                            >
                                <BiErrorAlt color={"white"} />
                            </Avatar>
                            <Box>
                                <Typography variant={"h6"} lineHeight={1}>
                                    {summariesList.map((summary) => summary.criticalLevelCount).reduce((a, b) => a + b, 0)}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Critical Alerts"}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    {!systemId && (
                        <Grid item>
                            <Stack direction={"row"} spacing={1}>
                                <Avatar
                                    variant={"rounded"}
                                    sx={{
                                        backgroundColor: theme.palette.cirrus.main,
                                        borderRadius: 3,
                                        border: `2px solid ${theme.palette.primary.main}`,
                                    }}
                                >
                                    <FiServer color={"white"} />
                                </Avatar>
                                <Box>
                                    <Typography variant={"h6"} lineHeight={1}>
                                        {warningsSummary.data?.summary.totalSystemsWithAlerts || 0}
                                    </Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {"Systems"}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    )}
                </Grid>

                <Box p={2}>
                    <ResponsiveContainer height={100} width={"100%"}>
                        <BarChart
                            data={summariesList}
                            onMouseMove={(state) => {
                                if (state.isTooltipActive) {
                                    setFocusBar(state.activeTooltipIndex);
                                    setMouseLeave(false);
                                } else {
                                    setFocusBar(null);
                                    setMouseLeave(true);
                                }
                            }}
                        >
                            <Tooltip cursor={false} content={<TooltipContent />} />

                            <Bar dataKey={"warningLevelCount"} stroke={theme.palette.warning.main} strokeWidth={2} radius={[0, 0, 3, 3]} stackId={"day"}>
                                {summariesList.map((entry, index) => {
                                    return entry.errorLevelCount || entry.criticalLevelCount ? (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={focusBar === index && !mouseLeave ? alpha(theme.palette.warning.main, 0.5) : theme.palette.warning.main}
                                        />
                                    ) : (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={focusBar === index && !mouseLeave ? alpha(theme.palette.warning.main, 0.5) : theme.palette.warning.main}
                                            radius={3}
                                        />
                                    );
                                })}
                            </Bar>
                            <Bar dataKey={"errorLevelCount"} stackId={"day"} stroke={theme.palette.error.main} radius={[3, 3, 0, 0]} strokeWidth={2}>
                                {summariesList.map((entry, index) => {
                                    return entry.criticalLevelCount ? (
                                        <Cell
                                            key={`cell-${index}`}
                                            radius={0}
                                            fill={focusBar === index && !mouseLeave ? alpha(theme.palette.error.main, 0.5) : theme.palette.error.main}
                                        />
                                    ) : entry.warningLevelCount ? (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={focusBar === index && !mouseLeave ? alpha(theme.palette.error.main, 0.5) : theme.palette.error.main}
                                        />
                                    ) : (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={focusBar === index && !mouseLeave ? alpha(theme.palette.error.main, 0.5) : theme.palette.error.main}
                                            radius={3}
                                        />
                                    );
                                })}
                            </Bar>
                            <Bar
                                dataKey={"criticalLevelCount"}
                                stackId={"day"}
                                stroke={darken(theme.palette.error.main, 0.3)}
                                radius={[3, 3, 0, 0]}
                                strokeWidth={2}
                            >
                                {summariesList.map((entry, index) => {
                                    return entry.warningLevelCount ? (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                focusBar === index && !mouseLeave
                                                    ? alpha(theme.palette.secondary.main, 0.5)
                                                    : darken(theme.palette.error.main, 0.3)
                                            }
                                        />
                                    ) : (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                focusBar === index && !mouseLeave
                                                    ? alpha(theme.palette.secondary.main, 0.5)
                                                    : darken(theme.palette.error.main, 0.3)
                                            }
                                            radius={3}
                                        />
                                    );
                                })}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Card>
        </QueryResultWrapper>
    );
};

// ======================
// TooltipContent
// ======================

interface TooltipContentProps {
    active?: any;
    payload?: any;
    label?: any;
    yAxisFormatter?: (value: number) => string;
}

const TooltipContent: React.FC<TooltipContentProps> = (p) => {
    const { active, payload, label, yAxisFormatter } = p;
    const theme = useTheme();
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: alpha("#000000", 0.9),
                    borderRadius: 2,
                    transform: "translate(20px, 0px)",
                }}
            >
                <Box p={1}>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant={"caption"}>{"Total: "}</Typography>
                        <Typography variant={"caption"} fontWeight={600}>
                            {payload[0].payload.warningLevelCount + payload[0].payload.errorLevelCount + payload[0].payload.criticalLevelCount}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant={"caption"}>{"Warnings: "}</Typography>
                        <Typography variant={"caption"} color={theme.palette.warning.main} fontWeight={600}>
                            {payload[0].payload.warningLevelCount}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant={"caption"}>{"Errors: "}</Typography>
                        <Typography variant={"caption"} color={theme.palette.error.main} fontWeight={600}>
                            {payload[0].payload.errorLevelCount}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant={"caption"}>{"Critical Alerts: "}</Typography>
                        <Typography variant={"caption"} color={theme.palette.secondary.main} fontWeight={600}>
                            {payload[0].payload.criticalLevelCount}
                        </Typography>
                    </Stack>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {format(convertTimestampObjectToDate(payload[0].payload.day), "MMM dd yyyy")}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return null;
};

const dummyData = [
    {
        date: "2021-10-01",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-02",
        activities: {
            warnings: 0,
            errors: 5,
            total: 0,
        },
    },
    {
        date: "2021-10-03",
        activities: {
            warnings: 1,
            errors: 5,
            total: 5,
        },
    },
    {
        date: "2021-10-04",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-05",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-06",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-07",
        activities: {
            warnings: 12,
            errors: 5,
            total: 5,
        },
    },
    {
        date: "2021-10-08",
        activities: {
            warnings: 1,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-09",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-10",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-11",
        activities: {
            warnings: 10,
            errors: 9,
            total: 5,
        },
    },
    {
        date: "2021-10-12",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-13",
        activities: {
            warnings: 10,
            errors: 5,
            total: 5,
        },
    },
    {
        date: "2021-10-14",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-15",
        activities: {
            warnings: 10,
            errors: 7,
            total: 5,
        },
    },
    {
        date: "2021-10-16",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-17",
        activities: {
            warnings: 1,
            errors: 7,
            total: 5,
        },
    },
    {
        date: "2021-10-18",
        activities: {
            warnings: 20,
            errors: 0,
            total: 5,
        },
    },
    {
        date: "2021-10-19",
        activities: {
            warnings: 10,
            errors: 8,
            total: 5,
        },
    },
    {
        date: "2021-10-20",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-21",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-22",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-23",
        activities: {
            warnings: 10,
            errors: 4,
            total: 5,
        },
    },
    {
        date: "2021-10-24",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-25",
        activities: {
            warnings: 10,
            errors: 7,
            total: 5,
        },
    },
    {
        date: "2021-10-26",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-27",
        activities: {
            warnings: 10,
            errors: 14,
            total: 5,
        },
    },
    {
        date: "2021-10-28",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-29",
        activities: {
            warnings: 10,
            errors: 2,
            total: 5,
        },
    },
    {
        date: "2021-10-30",
        activities: {
            warnings: 10,
            errors: 12,
            total: 5,
        },
    },
];
