// ======================
// CmoMigrationSystemDetailsApplianceNodesTab
// ======================

import { CmoAppliance, CmoSystemInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { Box, Card, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CmoSystem from "../../../assets/cmo/Appliance_Node.svg";
import { formatKnownDataType, formatPlural, KnownDataType } from "../../../common/utils/formatter";
import React from "react";
import { KVContrastTable } from "../../../common/table/KVContrastTable";
import { DarkFlatOutlinedCard } from "../../../common/card/DarkCard";
import { OutlinedCard } from "../../../common/card/OutlinedCard";
import { OnlineStatusIcon } from "../../../common/CommonIcons";
import { CmoMigrationIoStatChart } from "../ioStats/CmoMigrationIoStatChart";
import { useGetCmoIoStats } from "../cmo_migration_hooks";
import { GetCmoIOStats } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { TruncatedText } from "../../../common/text/TruncatedText";
import { useIsDesktop } from "../../layout/MainLayout";

interface CmoMigrationSystemDetailsApplianceNodesTabProps {
    systemInfo: CmoSystemInfo.AsObject;
}

export const CmoMigrationSystemDetailsApplianceNodesTab: React.FC<CmoMigrationSystemDetailsApplianceNodesTabProps> = (p) => {
    const { systemInfo } = p;
    return (
        <Box pt={1}>
            <ApplianceCard systemId={systemInfo.deployment.systemId} applianceInfo={systemInfo.primaryAppliance} version={systemInfo.deployment.version} />
            {systemInfo.memberAppliance && (
                <ApplianceCard systemId={systemInfo.deployment.systemId} applianceInfo={systemInfo.memberAppliance} version={systemInfo.deployment.version} />
            )}
        </Box>
    );
};

// ======================
// ApplianceCard
// ======================

interface ApplianceCardProps {
    applianceInfo: CmoAppliance.AsObject;
    systemId?: string;
    version?: string;
}

const ApplianceCard: React.FC<ApplianceCardProps> = (p) => {
    const { applianceInfo, systemId, version } = p;
    const theme = useTheme();
    const ioStats = useGetCmoIoStats(
        new GetCmoIOStats.Request().setSystemId(systemId).setAppliance(new GetCmoIOStats.Request.ApplianceStatsParams().setId(applianceInfo.applianceId))
    );

    const showBorder = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box pb={2}>
            <Card>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} md={4} lg={3} xl={2}>
                        <Box p={2}>
                            <Stack direction={"column"} alignItems={"center"}>
                                <img src={CmoSystem} width={75} height={"auto"} />
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <OnlineStatusIcon online={applianceInfo.online} />
                                    <Typography fontWeight={600}>{applianceInfo.applianceName}</Typography>
                                </Stack>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {applianceInfo.primaryAppliance ? "Primary" : "Member"}
                                </Typography>
                                <Stack direction={"column"} alignItems={"left"} p={2}>
                                    <span>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {"IP: "}
                                        </Typography>
                                        <Typography variant={"caption"}>{applianceInfo.primaryIp}</Typography>
                                    </span>
                                    <span>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {"Model: "}
                                        </Typography>
                                        <Typography variant={"caption"}>{applianceInfo.model}</Typography>
                                    </span>
                                    <span>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {"Version: "}
                                        </Typography>
                                        <Typography variant={"caption"}>{version}</Typography>
                                    </span>
                                    <span>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {"Serial #: "}
                                        </Typography>
                                        <TruncatedText characterLimit={8} variant={"caption"} text={applianceInfo.serialNumber} />
                                    </span>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} lg={4}>
                        <Stack height={"100%"} direction={"column"} p={2} justifyContent={"center"} spacing={1}>
                            <table
                                style={{
                                    borderSpacing: 0,
                                    borderRadius: "10px",
                                }}
                            >
                                <thead>
                                    <th
                                        colSpan={2}
                                        style={{
                                            backgroundColor: theme.palette.cirrus.main,
                                            border: `2px solid ${theme.palette.border.main}`,
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={1} justifyContent={"center"} width={"100%"}>
                                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
                                                <Typography variant={"h6"}>{applianceInfo.insertedHosts}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatPlural("Host", applianceInfo.insertedHosts)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
                                                <Typography variant={"h6"}>{applianceInfo.insertedInitiators}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatPlural("Initiator", applianceInfo.insertedInitiators)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
                                                <Typography variant={"h6"}>{applianceInfo.insertedTargets}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatPlural("Target", applianceInfo.insertedTargets)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
                                                <Typography variant={"h6"}>{applianceInfo.insertedVolumes}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatPlural("Volume", applianceInfo.insertedVolumes)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
                                                <Typography variant={"h6"}>{applianceInfo.insertedPaths}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatPlural("Path", applianceInfo.insertedPaths)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                borderRight: `2px solid ${theme.palette.border.main}`,
                                                borderLeft: `2px solid ${theme.palette.border.main}`,
                                                padding: 8,
                                            }}
                                        >
                                            {"CPU"}
                                        </td>
                                        <td
                                            style={{
                                                borderRight: `2px solid ${theme.palette.border.main}`,
                                                padding: 8,
                                            }}
                                        >
                                            {applianceInfo.cpuModel}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                borderRight: `2px solid ${theme.palette.border.main}`,
                                                borderLeft: `2px solid ${theme.palette.border.main}`,
                                                borderBottom: `2px solid ${theme.palette.border.main}`,
                                                padding: 8,
                                                borderBottomLeftRadius: 10,
                                            }}
                                        >
                                            {"Memory"}
                                        </td>
                                        <td
                                            style={{
                                                borderRight: `2px solid ${theme.palette.border.main}`,
                                                borderBottom: `2px solid ${theme.palette.border.main}`,
                                                padding: 8,
                                                borderBottomRightRadius: 10,
                                            }}
                                        >
                                            {formatKnownDataType(applianceInfo.resourcesUsage?.totalMemory, KnownDataType.CAPACITY)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={5} xl={6} sx={{ borderTop: showBorder ? "1px solid black" : "none" }}>
                        <QueryResultWrapper queryResult={ioStats}>
                            <Box p={2}>
                                <CmoMigrationIoStatChart chartHeight={50} ioStats={ioStats.data?.stats} cardProps={{ elevation: 0, sx: {} }} />
                            </Box>
                        </QueryResultWrapper>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};
