// ======================
// CmoMigrationSessionsListGrid
// ======================

import { QueryListTable } from "../../../common/table/QueryListTable";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { useListCmoMigrationSessions } from "../cmo_migration_hooks";
import { CmoMigrationSessionBasicInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { alpha, Box, Card, darken, Grid, LinearProgress, linearProgressClasses, Link, Stack, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import {
    getCmoMigrationSessionStatusColor,
    getCmoMigrationSessionStatusLabel,
    getCmoMigrationSessionTypeLabel,
    useLinearProgressStyle,
    useNavigateToCmoMigrationSessionDetails,
    useNavigateToCmoSystemDetails,
} from "../CmoMigrationCommon";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { getAxesMaxValue } from "../../../common/utils/statsUtil";
import { TruncatedText } from "../../../common/text/TruncatedText";
import { CmoQueryKeys } from "../../../common/QueryKeys";

interface CmoMigrationSessionsListGridProps {
    projectId: string;
    systemId?: string;
}

export const CmoMigrationSessionsListGrid: React.FC<CmoMigrationSessionsListGridProps> = (p) => {
    const { projectId, systemId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 40,
    });
    const queryResult = useListCmoMigrationSessions(projectId, pageIndex, pageSize, systemId);

    return (
        <QueryListTable
            listComponent={CmoMigrationSessionGridCard}
            data={queryResult.data?.itemsList || []}
            error={queryResult.error}
            isLoading={queryResult.isLoading}
            pageCount={queryResult.data?.pagerMeta?.totalPages || 0}
            pagination={{ pageIndex, pageSize }}
            setPagination={setPagination}
            grid
            queryKey={CmoQueryKeys.listCmoMigrationSessions}
            refetch={queryResult.refetch}
        />
    );
};

// ======================
// CmoMigrationSessionGridCard
// ======================

interface CmoMigrationSessionGridCardProps {
    data: CmoMigrationSessionBasicInfo.AsObject;
}

export const CmoMigrationSessionGridCard: React.FC<CmoMigrationSessionGridCardProps> = (p) => {
    const { data } = p;
    const sessionStatus = getCmoMigrationSessionStatusLabel(data.sessionStatus);
    const theme = useTheme();
    const goToCmoSystemDetails = useNavigateToCmoSystemDetails();
    const goToDetailsPage = useNavigateToCmoMigrationSessionDetails();
    const migrationRateMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [data.sessionStats.currentThroughput]);
    const linearProgressStyle = useLinearProgressStyle(false);

    const toolTipContent = (
        <Box p={1}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                {data.deploymentConnected ? (
                    <Link onClick={() => goToDetailsPage(data.globalSessionId)} variant={"subtitle2"} fontWeight={800}>
                        {`#${data.localSessionId}`}
                    </Link>
                ) : (
                    <Typography variant={"subtitle2"} fontWeight={800} color={"textSecondary"}>
                        {`#${data.localSessionId}`}
                    </Typography>
                )}

                <Typography variant={"subtitle2"}>
                    {data.migrationTypeDisplay} {"Migration"}
                </Typography>
            </Stack>
            <Box>
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                    {`Started ${formatKnownDataType(convertTimestampObjectToDate(data.createdAt), KnownDataType.DATE_RELATIVE)}`}
                </Typography>
            </Box>
            <Box>
                <Box pb={1} pt={1}>
                    <LinearProgress
                        variant={"determinate"}
                        sx={{ ...linearProgressStyle }}
                        value={(data.sessionStats.currentThroughput / migrationRateMaxValue) * 100}
                    />
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Current Migration Rate: ${formatKnownDataType(data.sessionStats.currentThroughput, KnownDataType.THROUGHPUT)}`}
                        </Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`${formatKnownDataType(migrationRateMaxValue, KnownDataType.THROUGHPUT)}`}
                        </Typography>
                    </Stack>
                </Box>
                <LinearProgress
                    sx={{
                        backgroundColor: (t: Theme) => t.palette.cirrus.main,
                        [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: (t: Theme) => t.palette.primary.light,
                            borderRight: "3px solid white",
                        },
                        "& .MuiLinearProgress-dashed": {
                            backgroundImage: "none",
                            animation: "none",
                        },
                        "& .MuiLinearProgress-bar2Buffer": {
                            backgroundColor: theme.palette.secondary.main,
                            borderRight: "none",
                        },
                    }}
                    variant={"buffer"}
                    valueBuffer={data.sessionStats.totalBase > 0 ? 100 : 0}
                    value={
                        data.sessionStats.totalBase + data.sessionStats.totalChanged
                            ? (data.sessionStats.totalBase / (data.sessionStats.totalBase + data.sessionStats.totalChanged)) * 100
                            : 0
                    }
                />
                <Stack direction={"row"} justifyContent={"space-between"} pb={1}>
                    <Box>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Total Base: ${formatKnownDataType(data.sessionStats.totalBase, KnownDataType.CAPACITY)}`}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                        <Typography variant={"h6"} sx={{ lineHeight: 1 }}></Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Total Changed: ${formatKnownDataType(data.sessionStats.totalChanged, KnownDataType.CAPACITY)}`}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );

    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <Tooltip
                title={toolTipContent}
                slotProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: theme.palette.cirrus.light,
                            border: `2px solid ${theme.palette.primary.main}`,
                            borderRadius: 3,
                        },
                    },
                }}
            >
                <Card
                    sx={{
                        "&:hover": {
                            backgroundColor: alpha(theme.palette.primary.main, 0.3),
                            cursor: data.deploymentConnected ? "pointer" : "default",
                            border: `2px solid ${theme.palette.primary.main}`,
                        },
                        "&:active": {
                            backgroundColor: data.deploymentConnected
                                ? (t: Theme) => alpha(t.palette.primary.main, 0.2)
                                : alpha(theme.palette.primary.main, 0.3),
                            borderColor: (t: Theme) => t.palette.primary.main,
                            cursor: data.deploymentConnected ? "pointer" : "default",
                        },
                        border: "2px solid transparent",
                    }}
                    onClick={data.deploymentConnected ? () => goToDetailsPage(data.globalSessionId) : null}
                >
                    <Box p={2}>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"flex-end"} pb={1}>
                            <TruncatedText
                                text={data.description === "N/A" ? "No description" : data.description}
                                characterLimit={32}
                                variant={"caption"}
                                color={"textSecondary"}
                            />

                            <Link
                                variant={"caption"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goToCmoSystemDetails(data.deployment.systemId);
                                }}
                            >
                                {data.deployment.systemName}
                            </Link>
                        </Stack>
                        <LinearProgress
                            sx={{
                                height: "10px",
                                borderRadius: 10,
                                backgroundColor: alpha(getCmoMigrationSessionStatusColor(data.sessionStatus, theme), 0.2),
                                [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 10,
                                    backgroundColor: getCmoMigrationSessionStatusColor(data.sessionStatus, theme),
                                },
                            }}
                            variant={"determinate"}
                            value={data.sessionStats.progressPercentage}
                        />
                        <Stack pt={0.5} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"flex-end"} pb={1}>
                            <Typography variant={"caption"} color={getCmoMigrationSessionStatusColor(data.sessionStatus, theme)} fontWeight={600}>
                                {sessionStatus}
                            </Typography>
                            <Typography variant={"caption"} color={darken(getCmoMigrationSessionStatusColor(data.sessionStatus, theme), 0.2)}>
                                {`${formatKnownDataType(data.sessionStats.totalMigrated, KnownDataType.CAPACITY)}/${formatKnownDataType(
                                    data.sessionStats.totalData,
                                    KnownDataType.CAPACITY
                                )}`}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Tooltip>
        </Grid>
    );
};
