// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: DeploymentListScreen

import * as React from "react";
import { useState } from "react";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { GalaxyMigrateDeploymentsList } from "../galaxymigrate/GalaxyMigrateDeploymentsList";
import { useInitData, useServerDataWithLoadingBox } from "../core/data/DataLoaderHooks";
import { Route, Routes, useParams } from "react-router-dom";
import { useAppServices } from "../app/services";
import { GalaxyMigrateDeploymentDetailsScreen } from "../galaxymigrate/GalaxyMigrateDeploymentDetails";
import { NewDeploymentButton } from "./NewDeploymentInstructions";
import { Box } from "@mui/material";
import { OperatorView } from "../auth/AuthenticatedViews";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import { DeleteIcon, ReportIcon } from "../../common/CommonIcons";
import { CreateGalaxyMigrateLinkButton } from "../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import { DEPLOYMENT_DETAILS_SUBROUTE, DEPLOYMENT_SUBROUTE } from "../app/AppRoutes";
import { FullScreenLoading } from "../core/data/DataLoaders";
import { GalaxyMigrateDeploymentLiveLog } from "../galaxymigrate/details/GalaxyMigrateDeploymentLiveLog";
import { TabConfig, TabGroup } from "../../common/tabs/TabComponents";
import { GmDeploymentXrayListScreen } from "../galaxymigrate/details/GalaxyMigrateDeploymentXrayManagement";
import { CreateReportDialog } from "../reports/CreateReportDialog";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import { CreateHostConfigurationReport } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { useDialogState } from "../core/dialog/DialogService";
import { useCreateHostConfigurationReport } from "../reports/report_hooks";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { viewReport } from "../reports/ReportHelpers";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { GalaxyMigrateLinksList } from "../galaxymigrate/GalaxyMigrateLinksList";
import { useListGalaxyMigrateLinks } from "../galaxymigrate/gm_deployment_hooks";
import { PaginationState } from "@tanstack/react-table";
import { useListGalaxyMigrateDeployments, useRemoveAllOfflineDeployments } from "./deployment_hooks";
import { useNavigateToMigrateOpsRecipeWizard } from "../migrateops/wizard/MigrateOpsWizardCommon";

// ======================
// ProjectDeploymentsScreen
// ======================
interface ProjectDeploymentsScreenProps {
    project: ProjectDetails;
}

export const ProjectDeploymentsScreens: React.FC<ProjectDeploymentsScreenProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<MigrationDeploymentsListScreen project={p.project} />} />
            <Route path={DEPLOYMENT_SUBROUTE.CONNECTIONS} element={<DeploymentConnectionsListScreen />} />
            <Route path={":deploymentID"}>
                <Route index element={<DeploymentDetailsScreen />} />
                <Route path={DEPLOYMENT_DETAILS_SUBROUTE.SETTINGS} element={<DeploymentDetailsScreen />} />
                <Route path={DEPLOYMENT_DETAILS_SUBROUTE.LOG} element={<GalaxyMigrateDeploymentLiveLog />} />
                <Route path={DEPLOYMENT_DETAILS_SUBROUTE.XRAYS} element={<GmDeploymentXrayListScreen />} />
            </Route>
        </Routes>
    );
});

// ======================
// DeploymentsListScreen
// ======================
interface MigrationDeploymentsListScreenProps {
    project: ProjectDetails;
}

export const MigrationDeploymentsListScreen: React.FC<MigrationDeploymentsListScreenProps> = observer((p) => {
    const projectId = p.project.getInfo().getProjectId();

    const hasDeployments = useListGalaxyMigrateDeployments(projectId, false, 1, 1).data?.itemsList.length > 0;
    const createReportDialogState = useDialogState();
    const globalDialogState = useGlobalDialogState();
    const removeOfflineDeployments = useRemoveAllOfflineDeployments(projectId);
    const createHostConfigReport = useCreateHostConfigurationReport({
        onSuccess: async (data: CreateHostConfigurationReport.Response.AsObject) => {
            await globalDialogState.addConfirmDialog({
                title: "New Report Generated",
                message: <>{"Host Configuration Report has been generated successfully."}</>,
                onClose: (confirmed) => {
                    if (confirmed) {
                        viewReport(data.reportId);
                    }
                },
                okButtonLabel: "View Report",
                cancelButtonLabel: "Close",
                autoConfirmationQuestionLine: false,
            });
        },
    });
    const getActions = (): ActionConfig[] => {
        return [
            {
                name: "Remove All Offline Deployments",
                id: "remove",
                action: async () => {
                    await removeOfflineDeployments.mutateAsync();
                },
                icon: <DeleteIcon />,
            },
            {
                name: "Create Host Configuration Report",
                id: "report",
                icon: <ReportIcon size={24} />,
                action: () => createReportDialogState.open(),
                hidden: true,
            },
        ];
    };

    const tabs: TabConfig[] = [
        {
            label: "All Hosts",
            renderer: () => <GalaxyMigrateDeploymentsList projectId={projectId} />,
        },
        {
            label: "Management Relays",
            renderer: () => <GalaxyMigrateDeploymentsList projectId={projectId} relayOnly />,
        },
    ];

    const actions = (
        <Box display={"flex"} alignItems={"center"}>
            <OperatorView>
                {hasDeployments && (
                    <Box pr={1}>
                        <NewDeploymentButton />
                    </Box>
                )}
                <ActionMenuButton actions={getActions()} />
            </OperatorView>
        </Box>
    );
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Hosts Deployed`} actions={actions} />
            <TabGroup configs={tabs} />
            {createReportDialogState.isOpen && (
                <CreateReportDialog
                    reportType={ReportType.ReportType.HOST_CONFIGURATIONS_REPORT}
                    reportArg={projectId}
                    dialogState={createReportDialogState}
                    createReportFn={async (args: { reportArg: string; reportNotes: string }) =>
                        createHostConfigReport.mutateAsync(
                            new CreateHostConfigurationReport.Request().setProjectId(args.reportArg).setUserNotes(args.reportNotes)
                        )
                    }
                />
            )}
        </ScreenContainer>
    );
});

// ======================
// DeploymentConnectionsListScreen
// ======================

interface DeploymentConnectionsListScreenProps {}

export const DeploymentConnectionsListScreen: React.FC<DeploymentConnectionsListScreenProps> = observer((props) => {
    const projectId = useCurrentProjectID();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const queryResult = useListGalaxyMigrateLinks(projectId, undefined, false, pageIndex, pageSize);
    const actions = (
        <Box display={"flex"} alignItems={"center"}>
            {queryResult.data?.itemsList.length > 0 ? <CreateGalaxyMigrateLinkButton color={"secondary"} variant={"contained"} /> : null}
        </Box>
    );
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Host-to-Host Connections`} actions={actions} />
            <GalaxyMigrateLinksList queryResult={queryResult} pageIndex={pageIndex} pageSize={pageSize} setPagination={setPagination} />
        </ScreenContainer>
    );
});

// ======================
// DeploymentDetailsScreen
// ======================
interface DeploymentDetailsScreenProps {}

export const DeploymentDetailsScreen: React.FC<DeploymentDetailsScreenProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const { deploymentID } = useParams();

    React.useEffect(() => {
        deploymentService.setCurrentDeploymentID(deploymentID);
        deploymentService.currentDeployment.fetchData();
        return () => deploymentService.setCurrentDeploymentID(null);
    }, [deploymentID, deploymentService]);

    useInitData({
        init: () => deploymentService.setCurrentDeploymentID(deploymentID),
        deinit: () => deploymentService.setCurrentDeploymentID(null),
        pollInterval: 3,
        poll: () => deploymentService.currentDeployment.fetchData(),
    });
    return useServerDataWithLoadingBox(deploymentService.currentDeployment, (data) => {
        // display based on type in the future
        if (deploymentService.currentDeployment.loading) {
            return <FullScreenLoading />;
        }
        return <GalaxyMigrateDeploymentDetailsScreen deployment={data} />;
    });
});
