// ======================
// CmoMigrationSystemDetailsOverviewTab
// ======================

import { CmoSystemInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import React from "react";
import { alpha, Box, Card, CircularProgress, Grid, ListSubheader, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { formatKnownDataType, formatPlural, KnownDataType } from "../../../common/utils/formatter";
import { DarkFlatCard } from "../../../common/card/DarkCard";
import { getAxesMaxValue } from "../../../common/utils/statsUtil";
import { useListCmoNexus } from "../cmo_migration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { NexusGroupDisplay, NexusInfoDialog } from "../CmoMigrationNexusScreen";
import { useDialogState } from "../../core/dialog/DialogService";
import { CmoMigrationActivitesTimeline } from "../activities/CmoMigrationActivitiesTimeline";
import { UseQueryResult } from "@tanstack/react-query";
import { GetCmoIOStats } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { CmoMigrationIoStatChart } from "../ioStats/CmoMigrationIoStatChart";
import { CmoMigrationWarningsSummary } from "../activities/CmoMigrationWarningsSummary";
import { KVTable } from "../../../common/table/KVTable";

interface CmoMigrationSystemDetailsOverviewTabProps {
    systemInfo: CmoSystemInfo.AsObject;
    projectId: string;
    ioStats: UseQueryResult<GetCmoIOStats.Response.AsObject, any>;
}

export const CmoMigrationSystemDetailsOverviewTab: React.FC<CmoMigrationSystemDetailsOverviewTabProps> = (p) => {
    const { systemInfo, projectId, ioStats } = p;
    return (
        <Box pt={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <SystemInfoStats systemInfo={systemInfo} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <PerformanceCard ioStats={ioStats} />
                </Grid>
            </Grid>
            <Grid container pt={2} spacing={2} pb={2}>
                <Grid item xs={12} lg={6}>
                    <NexusInfoCard systemId={systemInfo.deployment.systemId} projectId={projectId} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CmoMigrationWarningsSummary cardProps={{ sx: { height: "100%" } }} systemId={systemInfo.deployment.systemId} projectId={projectId} />
                </Grid>
            </Grid>
            <AlertsCard systemId={systemInfo.deployment.systemId} projectId={projectId} />
        </Box>
    );
};

// ======================
// SystemInfoGrid
// ======================

interface SystemInfoStatsProps {
    systemInfo: CmoSystemInfo.AsObject;
}

export const SystemInfoStats: React.FC<SystemInfoStatsProps> = (p) => {
    const { systemInfo } = p;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("lg"));
    return isXs ? (
        <Grid container spacing={2}>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>{systemInfo.insertionSummary.hosts}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {formatPlural("Host", systemInfo.insertionSummary.hosts)}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>{`${systemInfo.insertionSummary.hostPorts} | ${systemInfo.insertionSummary.storagePorts}`}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {formatPlural("Initiator", systemInfo.insertionSummary.hostPorts)} {"|"}{" "}
                                {formatPlural("Target", systemInfo.insertionSummary.storagePorts)}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>{`${systemInfo.insertionSummary.volumes} | ${systemInfo.insertionSummary.paths}`}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {`${formatPlural("Volume", systemInfo.insertionSummary.volumes)} | ${formatPlural("Path", systemInfo.insertionSummary.paths)}`}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>{systemInfo.migrationSummary.sessionsTotal}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {formatPlural("Migration Session", systemInfo.migrationSummary.sessionsTotal)}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>
                                {formatKnownDataType(systemInfo.migrationSummary.currentThroughput, KnownDataType.THROUGHPUT)}
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {"Current Migration Rate"}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Card>
                    <Box p={1}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h6"}>{formatKnownDataType(systemInfo.insertionSummary.storageCapacity, KnownDataType.CAPACITY)}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {`Total Capacity`}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    ) : (
        <Card>
            <ListSubheader>{"System Stats"}</ListSubheader>
            <KVTable
                dense
                data={[
                    {
                        key: "Hosts",
                        value: systemInfo.insertionSummary.hosts,
                    },
                    {
                        key: "Initiators | Targets",
                        value: `${systemInfo.insertionSummary.hostPorts} | ${systemInfo.insertionSummary.storagePorts}`,
                    },
                    {
                        key: "Paths | Volumes",
                        value: `${systemInfo.insertionSummary.paths} | ${systemInfo.insertionSummary.volumes}`,
                    },
                    {
                        key: "Volumes",
                        value: systemInfo.insertionSummary.volumes,
                    },
                    {
                        key: "Current Migration Rate",
                        value: formatKnownDataType(systemInfo.migrationSummary.currentThroughput, KnownDataType.THROUGHPUT),
                    },
                    {
                        key: "Migration Sessions",
                        value: systemInfo.migrationSummary.sessionsTotal,
                    },
                    {
                        key: "Total Capacity",
                        value: formatKnownDataType(systemInfo.insertionSummary.storageCapacity, KnownDataType.CAPACITY),
                    },
                ]}
            />
        </Card>
    );
};

// ======================
// CmoSystemIoCard
// ======================

interface CmoSystemIoCardProps {
    systemInfo: CmoSystemInfo.AsObject;
}

export const CmoSystemIoCard: React.FC<CmoSystemIoCardProps> = (p) => {
    const { systemInfo } = p;
    const productionIoMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [systemInfo.ioStats.totalThroughput]);
    const migrationRateMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [systemInfo.migrationSummary.currentThroughput]);

    const maxValue = productionIoMaxValue > migrationRateMaxValue ? productionIoMaxValue : migrationRateMaxValue;
    return (
        <Card sx={{ height: "55%" }}>
            <ListSubheader>{"Current I/O"}</ListSubheader>
            <Stack direction={"column"} p={2} pt={0} spacing={2}>
                <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                    <IoCircle value={systemInfo.ioStats.totalThroughput} maxValue={maxValue} color={"success"} />
                    <Typography color={"textSecondary"} variant={"caption"}>
                        {"Current Production I/O"}
                    </Typography>
                </Stack>
                <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                    <IoCircle value={systemInfo.migrationSummary.currentThroughput} maxValue={maxValue} color={"primary"} />
                    <Typography color={"textSecondary"} variant={"caption"}>
                        {"Current Migration Rate"}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
};

// ======================
// IoCircle
// ======================

interface IoCircleProps {
    value: number;
    maxValue: number;
    color: "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

const IoCircle: React.FC<IoCircleProps> = (p) => {
    const { value, maxValue, color } = p;
    const formattedValue = formatKnownDataType(value, KnownDataType.THROUGHPUT);
    const valueNum = formattedValue.split(" ")[0];
    const valueUnit = formattedValue.split(" ")[1];
    const theme = useTheme();
    return (
        <DarkFlatCard sx={{ padding: 1, width: "fit-content", borderRadius: 100 }}>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: alpha(theme.palette[color].main, 0.2),
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                        }}
                        size={120}
                        thickness={6}
                        value={100}
                    />
                    <CircularProgress color={color} thickness={6} size={120} variant={"determinate"} value={(value / maxValue) * 100} />

                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 6,
                            right: 0,
                            position: "absolute",
                        }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Box textAlign={"center"} pt={1}>
                            <Typography sx={{ lineHeight: 0.5 }} variant={"h6"}>
                                {valueNum}
                            </Typography>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {valueUnit}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </DarkFlatCard>
    );
};

// ======================
// NexusInfoCard
// ======================

interface NexusInfoCardProps {
    systemId: string;
    projectId: string;
}

const NexusInfoCard: React.FC<NexusInfoCardProps> = (p) => {
    const { systemId, projectId } = p;
    const queryResult = useListCmoNexus(projectId, 0, 0);
    const nexusList = queryResult.data?.itemsList.filter((nexus) => nexus.deployment.systemId === systemId);
    const primaryApplianceNexuses = nexusList?.filter((nexus) => nexus.fromPrimaryAppliance);
    const memberApplianceNexuses = nexusList?.filter((nexus) => !nexus.fromPrimaryAppliance);
    const nexusDetailsDialogState = useDialogState();
    const nexusListLength = primaryApplianceNexuses?.length;

    return (
        <Card sx={{ height: "100%" }}>
            <ListSubheader>{"Nexus Information"}</ListSubheader>
            <QueryResultWrapper queryResult={queryResult}>
                {nexusList?.length === 0 ? (
                    <Box p={2} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"} width={"100%"}>
                        <DarkFlatCard>
                            <Box p={2}>
                                <Typography variant={"subtitle2"} color={"textSecondary"}>
                                    {"No Nexus Information."}
                                </Typography>
                            </Box>
                        </DarkFlatCard>
                    </Box>
                ) : (
                    <Grid container spacing={2} p={2}>
                        <Grid item xl={6}>
                            <NexusGroupDisplay nexusList={primaryApplianceNexuses} nexusDetailsDialogState={nexusDetailsDialogState} />
                        </Grid>
                        <Grid item xl={6}>
                            <NexusGroupDisplay nexusList={memberApplianceNexuses} nexusDetailsDialogState={nexusDetailsDialogState} />
                        </Grid>
                    </Grid>
                )}
                {nexusDetailsDialogState.isOpen && <NexusInfoDialog systemId={systemId} dialogState={nexusDetailsDialogState} />}
            </QueryResultWrapper>
        </Card>
    );
};

// ======================
// AlertsCard
// ======================

interface AlertsCardProps {
    systemId: string;
    projectId: string;
}

const AlertsCard: React.FC<AlertsCardProps> = (p) => {
    const { systemId, projectId } = p;

    return (
        <Card sx={{ height: "100%" }}>
            <ListSubheader>{"Recent Alerts"}</ListSubheader>
            <CmoMigrationActivitesTimeline systemId={systemId} type={"warnings"} projectId={projectId} pgSize={5} />
        </Card>
    );
};

// ======================
// PerformanceCard
// ======================

interface PerformanceCardProps {
    ioStats: UseQueryResult<GetCmoIOStats.Response.AsObject, any>;
}

const PerformanceCard: React.FC<PerformanceCardProps> = (p) => {
    const { ioStats } = p;
    return (
        <QueryResultWrapper queryResult={ioStats}>
            <CmoMigrationIoStatChart ioStats={ioStats.data?.stats} title={"Performance"} cardProps={{ sx: { height: "100%" } }} />
        </QueryResultWrapper>
    );
};
