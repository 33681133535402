import { observer } from "mobx-react-lite";
import { useQueryParams } from "../app/AppRoutes";
import { useLoginStyles, useNavigateToPreLogin } from "./AuthCommon";
import { Button, DialogContent, Grid, TextField, Typography, Box, Slider, LinearProgress, Card, CardProps } from "@mui/material";
import Logo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";
import React, { FormEvent, useState } from "react";
import zxcvbn from "zxcvbn";
import { useAppServices } from "../app/services";

import { CDSLogo, CDSPrivateEditionLogo } from "../layout/LayoutCommon";
import { getIsPrivateEdition } from "./PrivateEditionView";
import { useChangeMyPassword, useSetNewPassword } from "./auth_hooks";
import { ChangeMyPassword, ResetPassword } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { useMountEffect } from "../../common/hooks/hookslib";

// ======================
// SetNewPasswordScreen
// ======================

interface SetNewPasswordScreenProps {}

export const SetNewPasswordScreen: React.FC<SetNewPasswordScreenProps> = observer((p) => {
    const query = useQueryParams();
    const user = query.get("user");
    const token = query.get("token");
    const styles = useLoginStyles();
    const isPrivateEdition = getIsPrivateEdition();

    const goBackToLogin = useNavigateToPreLogin();

    useMountEffect(() => {
        if (!user || !token) {
            goBackToLogin();
        }
    });

    return (
        <Box sx={styles.root}>
            <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                <Grid item xs={8} sm={6} md={4} lg={3} xl={3}>
                    <Box sx={styles.card}>
                        <Box p={3}>
                            <Box width={"100%"}>
                                {isPrivateEdition ? (
                                    <CDSPrivateEditionLogo imageAttributes={{ width: "100%" }} />
                                ) : (
                                    <CDSLogo imageAttributes={{ width: "100%" }} size={"large"} />
                                )}
                            </Box>{" "}
                        </Box>
                        <Typography paragraph variant={"h5"} align={"center"}>
                            {`Cirrus Data Cloud`}
                        </Typography>
                        <Box pt={2} pb={2} display={"flex"} justifyContent={"center"}>
                            <Typography variant={"h4"}>Set New Password</Typography>
                        </Box>
                        <SetNewPasswordForm user={user} token={token} onSubmitted={goBackToLogin} strengthMeterCardProps={{ elevation: 0 }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});

// ======================
// SetNewPasswordForm
// ======================

interface SetNewPasswordFormProps {
    requireCurrentPassword?: boolean;
    user?: string;
    token?: string;
    onSubmitted?: () => void;
    strengthMeterCardProps?: Partial<CardProps>;
}

export const SetNewPasswordForm: React.FC<SetNewPasswordFormProps> = observer((p) => {
    const setNewPassword = useSetNewPassword();
    const changeMyPassword = useChangeMyPassword();

    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);

    const passwordError = !!password && passwordStrength < 3;
    const confirmPasswordError = !!password && !!confirmPassword && confirmPassword !== password;
    const disableSubmit = !p.requireCurrentPassword
        ? !(passwordStrength >= 3 && !!confirmPassword && confirmPassword === password)
        : !(passwordStrength >= 3 && !!currentPassword && !!confirmPassword && confirmPassword === password);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordStrength(zxcvbn(e.target.value).score);
    };

    const handleChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleChangeCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(e.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        let res;

        if (!currentPassword) {
            res = await setNewPassword.mutateAsync(new ResetPassword.Request().setNewPassword(password).setUser(p.user).setResetPwdToken(p.token));
        } else {
            res = await changeMyPassword.mutateAsync(new ChangeMyPassword.Request().setNewPassword(password).setOldPassword(currentPassword));
        }

        if (res && !!p.onSubmitted) {
            p.onSubmitted();
        }
    };

    return (
        <DialogContent>
            <form onSubmit={handleSubmit}>
                <input value={p.user ?? ""} hidden readOnly type={"text"} autoComplete={"username"} name={"email"} />
                {p.requireCurrentPassword && (
                    <Box pt={2} pb={2}>
                        <TextField
                            variant={"filled"}
                            value={currentPassword}
                            onChange={handleChangeCurrentPassword}
                            label={"Current Password"}
                            type={"password"}
                            fullWidth
                            required
                            autoComplete={"current-password"}
                        />
                    </Box>
                )}

                <Box pt={2} pb={2}>
                    <TextField
                        variant={"filled"}
                        value={password}
                        onChange={handleChange}
                        label={"New Password"}
                        error={passwordError}
                        helperText={passwordError ? "Password is too weak." : "Enter new password."}
                        type={"password"}
                        fullWidth
                        required
                        autoComplete={"new-password"}
                    />
                </Box>
                <Box pt={2} pb={2}>
                    <TextField
                        variant={"filled"}
                        value={confirmPassword}
                        onChange={handleChangeConfirmPassword}
                        label={"Confirm Password"}
                        error={confirmPasswordError}
                        helperText={confirmPasswordError ? `Passwords don't match.` : "Confirm new password."}
                        type={"password"}
                        fullWidth
                        required
                        autoComplete={"new-password"}
                    />
                </Box>
                <Box>
                    <PasswordStrengthMeter password={password} cardProps={p.strengthMeterCardProps} />
                </Box>

                <Box pt={2} pb={2}>
                    <Button variant={"contained"} size={"large"} fullWidth color={"primary"} type={"submit"} disabled={disableSubmit}>
                        Confirm
                    </Button>
                </Box>
            </form>
        </DialogContent>
    );
});

// ======================
// PasswordStrengthMeter
// ======================

interface PasswordStrengthMeterProps {
    password: string;
    cardProps?: Partial<CardProps>;
}

export const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = observer((p) => {
    const strength = zxcvbn(p.password);

    return (
        <Card {...p.cardProps}>
            <Box p={2}>
                <Box pt={1} pb={1}>
                    <LinearProgress variant={"determinate"} value={strength.score * 25} />
                </Box>
                <Box pt={1} pb={1}>
                    {getPasswordStrengthLabel(strength.score)}
                </Box>
                {formatHint(strength.feedback.warning, strength.feedback.suggestions[0])}
            </Box>
        </Card>
    );
});

const getPasswordStrengthLabel = (strength: number) => {
    if (strength === 0) {
        return "Bad";
    } else if (strength === 1) {
        return "Poor";
    } else if (strength === 2) {
        return "Weak";
    } else if (strength === 3) {
        return "Good";
    } else if (strength === 4) {
        return "Excellent";
    }
};

const formatHint = (warning: string, suggestion: string) => {
    if (!!warning || !!suggestion) {
        return `Hint: ${warning ? `${warning}.` : ""} ${suggestion}`;
    }
    return null;
};
