import { AzureStoragePlannerStepProps } from "./AzureStoragePlannerHelpers";
import { useAzureStoragePlannerState } from "./AzureStoragePlannerState";
import { Box, Card, CardContent, CircularProgress, Grid, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCreateAzureStorageConsumptionPlan } from "./azure_storage_planner_hooks";

import { useMountEffect } from "../../../common/hooks/hookslib";

// ======================
// AzureStoragePlannerGeneratePlanStep
// ======================

export const AzureStoragePlannerGeneratePlanStep: React.FC<AzureStoragePlannerStepProps> = (p) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const createConsumptionPlan = useCreateAzureStorageConsumptionPlan();

    useMountEffect(() => {
        createConsumptionPlan.mutate();
    });

    return (
        <Card
            sx={{
                backgroundColor: "black",
            }}
        >
            <CardContent>
                <Grid container spacing={0} alignItems={"center"}>
                    <Grid item xs={12} sm={1}>
                        <Box display={"flex"} justifyContent={"center"} pb={isMobile ? 2 : 0}>
                            <CircularProgress color={"primary"} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Typography variant={"h6"}>{`Your Cost-Optimized Consumption Plan will be Ready Soon...`}</Typography>
                        <Typography>
                            {`We are analyzing your hosts' historic I/O access patterns to come up with the best optimized Azure product recommendation and the associated cost information.`}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
