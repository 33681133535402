import { DialogState } from "../core/dialog/DialogService";
import { observer } from "mobx-react-lite";
import React from "react";
import { useAppServices } from "../app/services";
import * as yup from "yup";
import { Form, Formik, FormikValues } from "formik";
import Box from "@mui/material/Box";
import { Button, Chip, DialogActions, DialogContent, DialogContentText, Grid, Stack, Typography } from "@mui/material";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { FormCheckboxItem, FormTextField } from "../../common/form/FormComponents";
import { useCurrentProject } from "./CurrentProjectState";
import { PROJECT_PRODUCT_TYPE, useCreateNewProject } from "./project_hooks";
import { useSelectProjectProducts, useUpdateProject } from "../settings/project_settings_hooks";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { SwitchCard } from "../../common/card/SwitchCard";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../app/AppGlobalService";
import CMC from "../../assets/products/CMC.svg";
import CMO from "../../assets/products/CMO.svg";

// ======================
// ProjectInfoForm
// ======================

interface ProjectInfoFormProps {
    dialogState: DialogState;
    type: "create" | "edit";
}
export const ProjectInfoForm: React.FC<ProjectInfoFormProps> = observer((props) => {
    const { projectService } = useAppServices();
    const globalDialogState = useGlobalDialogState();
    const { dialogState, type } = props;
    const project = useCurrentProject();
    const createNewProject = useCreateNewProject();
    const updateProject = useUpdateProject();
    const selectProjectProducts = useSelectProjectProducts();
    const projectProductTypes = project?.getInfo().getEnabledProducts()?.toObject() || null;
    const projectProductTypesArray = projectProductTypes
        ? Object.keys(projectProductTypes).map((key) => {
              if (key === "cmo" && projectProductTypes[key]) {
                  return PROJECT_PRODUCT_TYPE.CMO;
              } else if (key === "cmc" && projectProductTypes[key]) {
                  return PROJECT_PRODUCT_TYPE.CMC;
              }
              return;
          })
        : [];
    const [selectedProductTypes, setSelectedProductTypes] = React.useState<PROJECT_PRODUCT_TYPE[]>(projectProductTypesArray);
    const handleSetSelectedProductTypes = (productType: PROJECT_PRODUCT_TYPE) => {
        if (selectedProductTypes.includes(productType)) {
            setSelectedProductTypes(selectedProductTypes.filter((type) => type !== productType));
        } else {
            setSelectedProductTypes([...selectedProductTypes, productType]);
        }
    };

    const isCmoSupported = useIsFeatureEnabled(FeatureFlag.CMO_SUPPORT);

    let initialValues;

    const schema = yup.object({
        name: yup.string().required("project name is required").label("Name"),
        description: yup.string().label("Description").required(),
        emergencyEmail: yup.string().label("Emergency Email").email(),
        deleteLock: yup.boolean(),
    });

    let descriptionText: string;
    let dialogTitle: string;
    let submitText: string;
    let _submit: (values: FormikValues) => Promise<void>;

    if (type === "edit") {
        descriptionText = "Edit project details.";
        dialogTitle = "Edit Project Info";
        submitText = "Update Project";
        _submit = async (values: FormikValues) => {
            await updateProject.mutateAsync({
                name: values.name,
                description: values.description,
                emergencyEmail: values.emergencyEmail,
                emergencyPhone: values.emergencyPhone,
                deleteLock: values.deleteLock,
                projectId: project.getInfo().getProjectId(),
            });
            await selectProjectProducts.mutateAsync(selectedProductTypes);
            await projectService.currentProject.fetchData();
            await globalDialogState.addAlertDialog({
                title: "Project Updated",
                message: "Project details have been updated.",
            });
            dialogState.close();
        };
        initialValues = {
            name: project.getInfo().getName(),
            emergencyEmail: project.getInfo().getEmergencyEmail(),
            emergencyPhone: project.getInfo().getEmergencyPhone(),
            description: project.getInfo().getDescription(),
            deleteLock: project.getInfo().getDeleteLock(),
        };
    } else if (type === "create") {
        descriptionText =
            "Once a project is created, you will be able to add additional users to the project and register supported Cirrus products for remote management";
        dialogTitle = "Create New Project";
        submitText = "Create Project";
        _submit = async (values: FormikValues) => {
            const res = await createNewProject.mutateAsync({
                name: values.name,
                description: values.description,
                emergencyEmail: values.emergencyEmail,
                emergencyPhone: values.emergencyPhone,
                deleteLock: values.deleteLock,
                productSelection: selectedProductTypes,
            });

            if (res) {
                await projectService.myProjects.fetchData();
                dialogState.close();
            }
        };
        initialValues = {
            name: "",
            emergencyEmail: "",
            emergencyPhone: "",
            description: "",
            deleteLock: false,
        };
    }

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_submit}>
            {(props) => (
                <>
                    <Form>
                        <DialogTopBar dialogState={dialogState} title={dialogTitle} />
                        <DialogContent>
                            <Typography variant={"h5"}>{`1. Project Information`}</Typography>
                            <Box pb={3} pt={1}>
                                <FormTextField name={"name"} label={"Project Name"} variant={"filled"} required />
                            </Box>
                            <Box pb={3}>
                                <FormTextField name={"description"} label={"Description"} variant={"filled"} required />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name={"emergencyEmail"} label={"Emergency Contact Email"} variant={"filled"} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name={"emergencyPhone"} label={"Emergency Contact Phone"} variant={"filled"} />
                                </Grid>
                            </Grid>
                            {type !== "edit" && (
                                <Box pb={3}>
                                    <FormCheckboxItem label={"Lock Project"} name={"deleteLock"} helperText={"Project cannot be deleted when locked"} />
                                </Box>
                            )}

                            <br />
                            <Typography variant={"h5"}>{`2. Cirrus Data Products`}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {"Please select at least one of the products below."}
                            </Typography>
                            <Stack direction={"column"} spacing={2} pt={2} width={"100%"}>
                                <SwitchCard
                                    selected={selectedProductTypes.includes(PROJECT_PRODUCT_TYPE.CMC)}
                                    darkBorder
                                    onSelect={() => {
                                        handleSetSelectedProductTypes(PROJECT_PRODUCT_TYPE.CMC);
                                    }}
                                    cardContent={
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            <img src={CMC} alt={"CMC"} style={{ height: "36px" }} />
                                            <Box>
                                                <Typography variant={"h6"}>{"Cirrus Migrate Cloud (CMC)"}</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>
                                                    {"Host-based migration software for Windows/Linux hosts."}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    }
                                />
                                <SwitchCard
                                    selected={isCmoSupported ? selectedProductTypes.includes(PROJECT_PRODUCT_TYPE.CMO) : false}
                                    darkBorder
                                    disabled={!isCmoSupported}
                                    onSelect={() => {
                                        handleSetSelectedProductTypes(PROJECT_PRODUCT_TYPE.CMO);
                                    }}
                                    cardContent={
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            <img src={CMO} alt={"CMO"} style={{ height: "36px" }} />
                                            <Box>
                                                <Typography variant={"h6"}>{"Cirrus Migrate On-Premises (CMO)"}</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>
                                                    {"Physical migration appliances for FC / iSCSI SAN environments."}
                                                </Typography>
                                                {!isCmoSupported && (
                                                    <Box pt={1}>
                                                        <Chip size={"small"} color={"primary"} label={"Coming Soon"} />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Stack>
                                    }
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            {" "}
                            <Box p={2}>
                                <Button type={"submit"} disabled={selectedProductTypes.length === 0} variant={"outlined"} size={"large"}>
                                    {submitText}
                                </Button>
                            </Box>
                        </DialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
});
