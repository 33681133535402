// Project: GalaxyComplete
// Created: 9/29/20 by sammy
// File: ProjectActivities

import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { FiMoreHorizontal } from "react-icons/fi";
import { TiInfoLargeOutline } from "react-icons/ti";
import { renderServerDataWithLoadingList, useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { ListProjectActivities } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { ActivityInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/activity_pb";
import { TableState } from "../../common/table/DataTable";
import { transformStringWithTemplate } from "../../common/utils/templateUtil";
import { getProjectMemberUserFullName, getUserFullName, getUserFullNameFromObject, UserAvatar } from "../settings/ProjectUsers";
import { LeftArrow, RightArrow } from "../../common/CommonIcons";
import { useIsDesktop } from "../layout/MainLayout";
import { TruncatedText } from "../../common/text/TruncatedText";
import { useListProjectActivities } from "./project_management_hooks";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";

// ======================
// ProjectEventLog
// ======================
const useEventLogStyles = () => ({
    oppositeContent: {
        flex: 0.15,
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
    },
    root: {},
});

interface ProjectEventLogProps {}

export const ProjectEventLog: React.FC<ProjectEventLogProps> = observer((p) => {
    const projectId = useCurrentProjectID();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const queryResult = useListProjectActivities(projectId, pageIndex, pageSize);

    return (
        <QueryResultWrapper queryResult={queryResult}>
            <EventTimeline eventsList={queryResult.data} setPage={setPagination} pageSize={pageSize} />
        </QueryResultWrapper>
    );
});

// ======================
// EventTimeline
// ======================
interface EventTimelineProps {
    eventsList: ListProjectActivities.Response;
    setPage: (value: ((prevState: PaginationState) => PaginationState) | PaginationState) => void;
    pageSize: number;
}

const EventTimeline: React.FC<EventTimelineProps> = observer((p) => {
    const { eventsList, setPage, pageSize } = p;
    const isDesktop = useIsDesktop();

    const styles = useEventLogStyles();
    const pageMeta = eventsList.toObject().pagerMeta;
    const hasMorePages = pageMeta.hasMore;
    const currentPageNumber = pageMeta.page;
    const itemsList = eventsList.getItemsList();

    const goToNextPage = () => {
        setPage({ pageIndex: currentPageNumber + 1, pageSize: pageSize });
    };
    const goToPreviousPage = () => {
        setPage({ pageIndex: currentPageNumber - 1, pageSize: pageSize });
    };

    return (
        <>
            <CardHeader
                sx={{ padding: 0 }}
                title={`Project Timeline`}
                subheader={`Contains informational and error events from all deployments from this project`}
            />
            <Timeline sx={{ padding: 0 }}>
                {itemsList.map((item, i, l) => {
                    const isLast = i === l.length - 1;
                    const itemObj = item.toObject();
                    return (
                        <TimelineItem key={itemObj.id}>
                            <TimelineOppositeContent sx={styles.oppositeContent}>
                                <Tooltip title={formatKnownDataType(convertTimestampObjectToDate(itemObj.time), KnownDataType.DATE)} arrow>
                                    <Typography align={"right"} variant={isDesktop ? "body1" : "caption"}>
                                        {formatKnownDataType(convertTimestampObjectToDate(itemObj.time), KnownDataType.DATE_RELATIVE)}
                                    </Typography>
                                </Tooltip>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot variant={"outlined"}>
                                    <SvgIcon>
                                        <TiInfoLargeOutline />
                                    </SvgIcon>
                                </TimelineDot>
                                {<TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent sx={{ paddingRight: 0 }}>
                                <Card>
                                    <List>
                                        <ProjectTimelineItem item={item} />
                                    </List>
                                </Card>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
                {hasMorePages && (
                    <TimelineItem>
                        <TimelineOppositeContent sx={styles.oppositeContent}></TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant={"outlined"}>
                                <SvgIcon>
                                    <FiMoreHorizontal />
                                </SvgIcon>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent></TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
            <Typography align={"center"}>
                <IconButton size={"medium"} disabled={currentPageNumber === 1} onClick={goToPreviousPage} aria-label={"projectActivityPrevButton"}>
                    <LeftArrow />
                </IconButton>
                <IconButton size={"medium"} disabled={!hasMorePages} onClick={goToNextPage} data-testid={"projectActivityNextButton"}>
                    <RightArrow />
                </IconButton>
            </Typography>
        </>
    );
});

// ======================
// ProjectTimelineItem
// ======================
interface ProjectTimelineItemProps {
    item: ActivityInfo;
}

const ProjectTimelineItem: React.FC<ProjectTimelineItemProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const item = p.item;
    const message = transformStringWithTemplate(item.getMessageTemplate(), item.getData().toJavaScript());
    const itemObj = item.toObject();
    return (
        <>
            <ListItem>
                <ListItemText primary={isDesktop ? itemObj.eventCode : <TruncatedText characterLimit={20} text={itemObj.eventCode} />} secondary={message} />
                {isDesktop && <ListItemSecondaryAction>{!!itemObj.userInfo && <UserAvatar user={itemObj.userInfo} tooltip />}</ListItemSecondaryAction>}
            </ListItem>
            {!isDesktop && (
                <Box pl={2} pb={2}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        by {getUserFullNameFromObject(itemObj.userInfo)}
                    </Typography>
                </Box>
            )}
        </>
    );
});
