// Project: GalaxyComplete
// Created: 9/24/20 by sammy
// File: ProjectListScreen

import * as React from "react";
import { ComponentType, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { Box, Card, Chip, Grid, Link, SvgIcon, Tooltip, Typography } from "@mui/material";

import { CreateProjectButton } from "./CreateProjectScreen";
import GetStartedArt from "../../assets/undraw_factory_dy0a.svg";
import { getOsDisplayName, HostOSIcon, useNavigateToProjectDetails } from "./ProjectCommon";
import { APP_ROUTES, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { ProjectListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { MdOutlineTimer, MdPersonOutline, MdVpnKey } from "react-icons/md";
import { ListTable } from "../../common/table/ListTable";
import { ProjectMemberRole } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/project_member_role_pb";
import { AiOutlineCloudServer } from "react-icons/ai";
import { HostEnvironmentIcon } from "../../common/CommonIcons";
import { getHostEnvDisplayName } from "../galaxymigrate/GalaxyMigrateCommon";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { useIsDesktop } from "../layout/MainLayout";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { generatePath, useNavigate } from "react-router-dom";
import { ServerListData } from "../core/data/ListData";
import { ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { getLicenseModelLabel } from "../license/LicenseCommon";
import EmptyTableIcon from "../../assets/empty_states/empty-project-list.svg";
import { useCurrentProjectState } from "./CurrentProjectState";
import { PaginationState } from "@tanstack/react-table";
import { useListMyProjects } from "./project_hooks";
import { QueryListTable } from "../../common/table/QueryListTable";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";
import { ProjectQueryKeys } from "../../common/QueryKeys";
import { UseQueryResult } from "@tanstack/react-query";
import { is } from "date-fns/locale";
import { FullScreenLoading } from "../core/data/DataLoaders";

interface ProjectListScreenProps {}

export const ProjectListScreen: React.FC<ProjectListScreenProps> = observer((p) => {
    const { projectService, appGlobalService } = useAppServices();
    const isPrivateEdition = getIsPrivateEdition();
    const navigate = useNavigate();
    const clearProject = useCurrentProjectState((s) => s.clearCurrentProject);

    useInitData({
        init: () => {
            appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.MANAGEMENT);
            clearProject();
        },
    });

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 15,
    });

    const projectsList = useListMyProjects(pageIndex, pageSize);

    if (isPrivateEdition && projectsList.isLoading) {
        return <FullScreenLoading />;
    }

    if (isPrivateEdition && projectsList.isFetched) {
        navigate(generatePath(`${APP_ROUTES.PROJECTS}/${projectsList.data?.itemsList[0].projectInfo.projectId}`));
    }

    return (
        <ScreenContainer>
            <ScreenTitleBar title={`My Projects`} actions={<Box>{projectsList.data?.itemsList.length > 0 && <CreateProjectButton />}</Box>} />
            <QueryListTable
                listComponent={ProjectCard}
                data={projectsList.data?.itemsList || []}
                error={projectsList.error}
                isLoading={projectsList.isLoading}
                pageCount={projectsList.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                refetch={projectsList.refetch}
                queryKey={ProjectQueryKeys.listMyProjects}
                emptyTableConfig={{
                    iconPath: EmptyTableIcon,
                    title: "Create or get invited to your first Cirrus Data Cloud project",
                    message:
                        "Welcome to Cirrus Data Cloud. Once you have created or joined a project, they will be listed here. To get started, click the button below to create your first project.",
                    actionButton: <CreateProjectButton />,
                }}
            />
        </ScreenContainer>
    );
});

// ======================
// ProjectListTable
// ======================

interface ProjectListTableProps<RowData> {
    serverData: ServerListData<ListProjects.Response.AsObject, ProjectListItem.AsObject, ListProjects.Request>;
    supportProjects: boolean;
    listComponent: ComponentType<{ data: RowData }>;
}

export const ProjectListTable: React.FC<ProjectListTableProps<ProjectListItem.AsObject>> = observer((p) => {
    const { serverData } = p;
    return (
        <>
            <ListTable
                rows={serverData?.data?.itemsList}
                state={serverData.tableState}
                loading={serverData.loading}
                pagerMeta={serverData?.data?.pagerMeta}
                onTableStateChange={() => serverData.fetchData()}
                listComponent={p.listComponent}
                emptyTableConfig={{
                    iconPath: EmptyTableIcon,
                    title: "Create or get invited to your first Cirrus Data Cloud project",
                    message:
                        "Welcome to Cirrus Data Cloud. Once you have created or joined a project, they will be listed here. To get started, click the button below to create your first project.",
                    actionButton: <CreateProjectButton />,
                }}
            />
        </>
    );
});

// ======================
// ProjectCard
// ======================

interface ProjectCardProps {
    data: ProjectListItem.AsObject;
}

export const ProjectCard: React.FC<ProjectCardProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const isSupportProject = p.data.asSupportStaff;
    const goToDetails = useNavigateToProjectDetails(p.data.projectInfo.projectId);
    const getProjectRoleChip = useCallback(() => {
        if (p.data.myProjectRole === ProjectMemberRole.ProjectMemberRole.ADMIN) {
            return <Chip color={"secondary"} label={"Admin"} size={"small"} />;
        } else if (p.data.myProjectRole === ProjectMemberRole.ProjectMemberRole.OPERATOR) {
            return <Chip label={"Operator"} size={"small"} />;
        } else if (p.data.myProjectRole === ProjectMemberRole.ProjectMemberRole.MONITOR) {
            return <Chip variant={"outlined"} label={"Monitor"} size={"small"} />;
        }
        return null;
    }, [p.data]);

    return (
        <Box pt={2}>
            <Card>
                <Box p={2}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item xs={12} md={9}>
                            <Box display={"flex"} alignItems={"center"}>
                                <Box pr={1}>
                                    <Link onClick={goToDetails} variant={"h6"}>
                                        {p.data.projectInfo.name}
                                    </Link>
                                </Box>
                                <Tooltip arrow title={`${p.data.totalMembers} Project Members`}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <MdPersonOutline />
                                        {p.data.totalMembers}
                                    </Box>
                                </Tooltip>
                                {!isSupportProject && <Box pl={1}>{getProjectRoleChip()}</Box>}
                                {isSupportProject && (
                                    <Box pl={1}>
                                        <Chip label={"Support"} size={"small"} />
                                    </Box>
                                )}
                            </Box>
                            <Box pt={1}>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {p.data.projectInfo.description}
                                </Typography>
                            </Box>
                            <Grid container pt={1} alignItems={"center"}>
                                <Grid item alignItems={"center"}>
                                    <Tooltip
                                        title={
                                            <Box>
                                                <Box display={"flex"}>
                                                    <Typography variant={"caption"} color={"textSecondary"}>
                                                        Total Hosts Registered:&nbsp;
                                                    </Typography>
                                                    <Typography variant={"caption"}>{p.data.totalDeployments}</Typography>
                                                </Box>
                                                <Box display={"flex"}>
                                                    <Typography variant={"caption"} color={"textSecondary"}>
                                                        Offline Hosts:&nbsp;
                                                    </Typography>
                                                    <Typography variant={"caption"}>{p.data.totalOfflineDeployments}</Typography>
                                                </Box>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box pr={1} pt={1}>
                                                <SvgIcon>
                                                    <AiOutlineCloudServer />
                                                </SvgIcon>
                                            </Box>
                                            <Box>
                                                <Typography color={"textPrimary"}>
                                                    {p.data.totalDeployments} Host{p.data.totalDeployments !== 1 ? "s" : ""}
                                                </Typography>
                                            </Box>
                                            {!!p.data.totalOfflineDeployments && (
                                                <Box>
                                                    <Typography color={"textSecondary"}>&nbsp;({p.data.totalOfflineDeployments} Offline)</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Grid>
                                {!!p.data.gmOsTypesList.length && (
                                    <Grid item alignItems={"center"}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box pr={2} pl={2}>
                                                <Typography color={"textSecondary"}>|</Typography>
                                            </Box>
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        <Typography variant={"caption"}>OS</Typography>
                                                        <br />
                                                        <Typography variant={"caption"} color={"textSecondary"}>
                                                            {p.data.gmOsTypesList.map((os) => getOsDisplayName(os)).join(", ")}
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <Box display={"flex"} alignItems={"center"}>
                                                    {p.data.gmOsTypesList.map((os, i) => {
                                                        return (
                                                            <Box key={i} pr={1}>
                                                                <HostOSIcon os={os} />
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                )}
                                {!!p.data.deploymentHostEnvironmentsList.length && (
                                    <Grid item alignItems={"center"}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box pr={2} pl={2}>
                                                <Typography color={"textSecondary"}>|</Typography>
                                            </Box>
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        <Typography variant={"caption"}>Host Environments</Typography>
                                                        <br />
                                                        <Typography variant={"caption"} color={"textSecondary"}>
                                                            {p.data.deploymentHostEnvironmentsList.map((env) => getHostEnvDisplayName(env)).join(", ")}
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <Box display={"flex"} alignItems={"center"}>
                                                    {p.data.deploymentHostEnvironmentsList.map((env, i) => {
                                                        return (
                                                            <Box key={i} pr={1} pt={1}>
                                                                <HostEnvironmentIcon env={env} />
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "flex-start"} alignItems={"center"}>
                                <Box pr={1} pt={1}>
                                    <SvgIcon>
                                        <MdOutlineTimer />
                                    </SvgIcon>
                                </Box>
                                <Box>
                                    <Tooltip arrow title={formatKnownDataType(convertTimestampObjectToDate(p.data.projectInfo.createdAt), KnownDataType.DATE)}>
                                        <Typography color={"textSecondary"}>
                                            Created&nbsp;
                                            {formatKnownDataType(convertTimestampObjectToDate(p.data.projectInfo.createdAt), KnownDataType.DATE_RELATIVE)}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </Box>
                            {p.data.projectInfo.licenseModel === LicenseModel.LicenseModel.CAPACITY_BASED && (
                                <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "flex-start"}>
                                    <Tooltip title={`This project uses ${getLicenseModelLabel(p.data.projectInfo.licenseModel)} Licensing`} arrow>
                                        <Chip
                                            icon={<MdVpnKey size={18} />}
                                            label={getLicenseModelLabel(p.data.projectInfo.licenseModel)}
                                            variant={"outlined"}
                                            color={"primary"}
                                        />
                                    </Tooltip>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    );
});
