// ======================
// MigrateOpsNewOperationWizard
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import React, { useEffect, useState } from "react";
import { useCreateNewOperationFromYaml } from "./migrate_ops_hooks";
import Editor from "react-simple-code-editor";
import hljs from "highlight.js/lib/core";
import yaml from "highlight.js/lib/languages/yaml";
import { useNavigateToNewOpScreen, useNavigateToOpsList } from "./MigrateOpsCommon";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import "highlight.js/styles/tokyo-night-dark.css";
import { OperatorView } from "../auth/AuthenticatedViews";
import { MigrateOpsNewOperationBuilderButton } from "./MigrateOpsNewOperationBuilder";
import { useMigrateOpsNewOperationBuilderState } from "./MigrateOpsNewOperationBuilderState";
import * as YAML from "yaml";

hljs.registerLanguage("yaml", yaml);

interface MigrateOpsNewOperationWizardProps {}

export const MigrateOpsNewOperationPage: React.FC<MigrateOpsNewOperationWizardProps> = (p) => {
    const createOperation = useCreateNewOperationFromYaml();
    const generatedJsonFromBuilder = useMigrateOpsNewOperationBuilderState((s) => s.generatedJsonObject);
    const isGeneratedJsonAppliedToEditor = useMigrateOpsNewOperationBuilderState((s) => s.isGeneratedJsonAppliedToEditor);
    const setJsonAppliedToEditor = useMigrateOpsNewOperationBuilderState((s) => s.setJsonAppliedToEditor);
    const resetBuilder = useMigrateOpsNewOperationBuilderState((s) => s.resetBuilderState);

    const [value, setValue] = useState(
        Object.keys(generatedJsonFromBuilder).length > 0
            ? YAML.stringify(generatedJsonFromBuilder, {
                  indent: 2,
                  defaultKeyType: "PLAIN",
                  defaultStringType: "QUOTE_DOUBLE",
              })
            : ""
    );
    const goToOperationsListPage = useNavigateToOpsList();
    const openHelpArticle = useOpenHelpArticle();

    const handleSubmit = async () => {
        const res = await createOperation.mutateAsync(value);
        if (res) {
            goToOperationsListPage();
        }
    };

    useEffect(() => {
        if (isGeneratedJsonAppliedToEditor) {
            resetBuilder();
            setJsonAppliedToEditor(false);
        }
    }, [resetBuilder, isGeneratedJsonAppliedToEditor]);

    const MigrateOpsKbLink = <Link onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS)}>{"here"}</Link>;

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Start New Migration Operations"} />
            <Box>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                        <Typography variant={"h5"}>{"Enter your MigrateOps configurations below to get started."}</Typography>
                        <Typography>
                            {`Configuration must be in valid YAML format. Click`} {MigrateOpsKbLink} {`to learn more about MigrateOps.`}
                        </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <MigrateOpsNewOperationBuilderButton />
                    </Stack>
                </Stack>

                <Box pt={2}>
                    <DarkFlatOutlinedCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                        <Editor
                            value={value}
                            onValueChange={(v) => setValue(v)}
                            placeholder={"Enter your MigrateOps Configuration YAML here..."}
                            highlight={(code) => hljs.highlight(code, { language: "yaml" }).value}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono","Consolas","Menlo", monospace',
                                fontSize: 13,
                                minHeight: "50vh",
                            }}
                        />
                    </DarkFlatOutlinedCard>
                </Box>
            </Box>
            <Box pt={2}>
                <Button variant={"contained"} onClick={handleSubmit} color={"primary"} disabled={value === ""}>{`Create Operations`}</Button>
            </Box>
        </ScreenContainer>
    );
};
interface MigrateOpsNewOperationButtonProps {}

export const MigrateOpsNewOperationButton: React.FC<MigrateOpsNewOperationButtonProps> = (p) => {
    const navigateToNewOpWizard = useNavigateToNewOpScreen();
    return (
        <OperatorView>
            <Button sx={{ width: "fit-content" }} variant={"contained"} color={"secondary"} onClick={navigateToNewOpWizard}>
                {"Start New Operation"}
            </Button>
        </OperatorView>
    );
};
