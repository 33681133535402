import { observer } from "mobx-react-lite";
import React from "react";
import { useInitData, useServerDataWithLoadingBox } from "../../core/data/DataLoaderHooks";
import { useAppServices } from "../../app/services";
import { useParams } from "react-router-dom";
import { DialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { Box, CardHeader, Dialog, Grid, SvgIcon, SvgIconProps, Theme, Typography } from "@mui/material";
import { CloseDialogButton } from "../../core/dialog/DialogComponents";
import { blue, purple, yellow } from "@mui/material/colors";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { AiOutlineDotChart } from "react-icons/ai";
import { GalaxyMigrateManagedVolumeInfo, GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { FormattedDisplay } from "../../../common/FormattedDisplay";
import { renderGMSessionStatus } from "../CmcMigrationCommon";
import { mockVolumeBitmap } from "../../core/testutils/fixtures/MockGmMigrationService";
import { Bin, Bins } from "@visx/mock-data/lib/generators/genBins";
import { GetMigrationVolumeChangesDistributionView } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { scaleLinear } from "@visx/scale";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Group } from "@visx/group";
import { HeatmapRect } from "@visx/heatmap";
import { localPoint } from "@visx/event";

const useGmVolumeBitmapStyles = () => ({
    dialog: {
        height: "500px",
    },
    overline: {
        lineHeight: 1,
    },
    heatmap: {
        overflow: "visible",
    },
});

// ======================
// GmVolumeBitmapDialog
// ======================

interface GmVolumeBitmapDialogProps {
    dialogState: DialogState;
    stats: GalaxyMigrateMigrationSessionInfo.Statistics;
    sourceVol: GalaxyMigrateManagedVolumeInfo;
    status: GalaxyMigrateMigrationSessionInfo.Status;
}

export const GmVolumeBitmapDialog: React.FC<GmVolumeBitmapDialogProps> = observer((props) => {
    const { dialogState, stats, sourceVol, status } = props;
    const fullScreen = useShouldDialogFullScreen();
    const styles = useGmVolumeBitmapStyles();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} fullWidth maxWidth={"lg"}>
            <Box sx={styles.dialog}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <CardHeader title={"Changed Data Map"} subheader={`Data changes for ${sourceVol.getName()}`} />
                    <CloseDialogButton dialogState={dialogState} />
                </Box>
                <Grid container alignItems={"center"}>
                    <Grid item xs={2}>
                        <Box pl={4} pr={2}>
                            <Box pb={2}>
                                <Typography variant={"h6"}>
                                    <FormattedDisplay dataType={KnownDataType.CAPACITY} value={stats.getCurrentChanged()} />
                                </Typography>
                                <Typography variant={"overline"} sx={styles.overline}>
                                    Current Changes
                                </Typography>
                            </Box>
                            <Box pb={2}>
                                <Typography variant={"h6"}>
                                    <FormattedDisplay dataType={KnownDataType.CAPACITY} value={stats.getTotalRemaining()} />
                                </Typography>
                                <Typography variant={"overline"} sx={styles.overline}>
                                    Unsynced Data
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant={"h6"}>{renderGMSessionStatus(status)}</Typography>
                                <Typography variant={"overline"} sx={styles.overline}>
                                    Status
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        {dialogState.isOpen && <GmMigrationSessionVolumeBitmap sourceVol={sourceVol} />}
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
});

// ======================
// GmMigrationSessionVolumeBitmap
// ======================

interface GmVolumeBitmapProps {
    sourceVol: GalaxyMigrateManagedVolumeInfo;
}

export const GmMigrationSessionVolumeBitmap: React.FC<GmVolumeBitmapProps> = observer((props) => {
    const { gmMigrationService } = useAppServices();
    const { sessionId } = useParams();
    const { sourceVol } = props;
    const styles = useGmVolumeBitmapStyles();

    const volUUID = sourceVol.getUuid();
    const volSize = sourceVol.getCapacity();

    useInitData({
        init: () => gmMigrationService.currentSessionVolumeState.initVolumeState(volUUID, sessionId),
        poll: async () => {
            if (!!gmMigrationService.currentSessionVolumeState.currentSessionVolumeUUID) {
                await gmMigrationService.currentSessionVolumeState.sessionVolumeBitmap.fetchData();
            }
        },
        pollInterval: 1,
    });

    return useServerDataWithLoadingBox(gmMigrationService.currentSessionVolumeState.sessionVolumeBitmap, (data) => {
        return (
            <Box width={"100%"} height={"100%"} pl={4}>
                <Typography variant={"overline"}>Beginning of Volume</Typography>
                <Box height={300} sx={styles.heatmap}>
                    <VolumeBitmap width={800} height={300} data={data} />
                </Box>

                <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} pr={"160px"}>
                    <Typography variant={"overline"}>
                        End of Volume (<FormattedDisplay dataType={KnownDataType.CAPACITY} value={volSize} />)
                    </Typography>
                </Box>
            </Box>
        );
    });
});

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
    return Math.max(...data.map(value));
}
const getPointIndex = (row: number, numOfCols: number, index: number) => {
    return row * numOfCols + index;
};

const getBinData = (rows: number, cols: number, points: number[]) => {
    const binData = [];
    for (let i = 0; i < cols; i++) {
        const bin: Bins = {
            bin: i,
            bins: [],
        };
        const bins = [];
        for (let j = 0; j < rows; j++) {
            bins.push({
                bin: j * 150,
                count: points[getPointIndex(j, cols, i)] || 0,
            });
        }
        bin.bins = bins;
        binData.push(bin);
    }
    return binData;
};

const getBlockRange = (x: number, y: number, numOfCols: number, fromLocation: number, bytesPerPoint: number) => {
    const index = getPointIndex(y, numOfCols, x);
    const pointFromLocation = formatKnownDataType(fromLocation + bytesPerPoint * index, KnownDataType.CAPACITY);
    const pointToLocation = formatKnownDataType(fromLocation + bytesPerPoint * (index + 1), KnownDataType.CAPACITY);
    return { pointFromLocation, pointToLocation };
};

export type HeatmapProps = {
    width: number;
    height: number;
    separation?: number;
    events?: boolean;
    data: GetMigrationVolumeChangesDistributionView.Response;
};

interface TooltipData {
    blockRange: { pointFromLocation: string; pointToLocation: string };
    value: number;
}

export const VolumeBitmap: React.FC<HeatmapProps> = observer((p) => {
    const { width, height, separation, events, data } = p;
    const margin = { top: 10, right: 10, left: 10, bottom: 10 };
    const size = width;
    const xMax = size;
    const yMax = height - margin.bottom - margin.top;

    const binData = getBinData(50, 100, data.getPointsList());

    const bins = (d: Bins) => d.bins;
    const count = (d: Bin) => d.count;

    const colorMax = max(binData, (d) => max(bins(d), count));
    const bucketSizeMax = max(binData, (d) => bins(d).length);

    const xScale = scaleLinear<number>({
        domain: [0, binData.length],
    });

    const yScale = scaleLinear<number>({
        domain: [0, bucketSizeMax],
    });

    const rectColorScale = scaleLinear<string>({
        range: [blue["100"], blue["900"]],
        domain: [0, colorMax],
    });

    const binWidth = xMax / binData.length;
    const binHeight = yMax / bucketSizeMax;

    xScale.range([0, xMax]);
    yScale.range([0, yMax]);

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<TooltipData>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
        scroll: true,
    });

    const tooltipStyles = {
        ...defaultStyles,
        backgroundColor: "rgba(0, 0, 0,0.8)",
        color: "white",
        padding: 12,
    };

    return (
        <>
            <svg width={"100%"} height={"100%"} ref={containerRef}>
                <Group top={margin.top}>
                    <HeatmapRect
                        data={binData}
                        xScale={(d) => xScale(d) ?? 0}
                        yScale={(d) => yScale(d) ?? 0}
                        colorScale={rectColorScale}
                        binWidth={binWidth}
                        binHeight={binHeight}
                        gap={0}
                    >
                        {(heatmap) => {
                            return heatmap.map((heatmapBins) => {
                                return heatmapBins.map((bin) => {
                                    return (
                                        <rect
                                            key={`heatmap-rect-${bin.row}-${bin.column}`}
                                            width={binWidth}
                                            height={binHeight}
                                            x={bin.x}
                                            y={bin.y}
                                            onMouseOver={(event) => {
                                                const coords = localPoint(event.currentTarget, event);
                                                showTooltip({
                                                    tooltipLeft: coords.x,
                                                    tooltipTop: coords.y,
                                                    tooltipData: {
                                                        value: bin.count,
                                                        blockRange: getBlockRange(bin.column, bin.row, 100, data.getFromLocation(), data.getBytesPerPoint()),
                                                    },
                                                });
                                            }}
                                            onMouseOut={hideTooltip}
                                            fill={bin.color}
                                            fillOpacity={bin.opacity}
                                        />
                                    );
                                });
                            });
                        }}
                    </HeatmapRect>
                </Group>
            </svg>
            {tooltipOpen && (
                <TooltipInPortal key={Math.random()} top={tooltipTop} left={tooltipLeft} zIndex={100000000000} style={tooltipStyles}>
                    <Box>
                        {tooltipData.value === 0 ? (
                            <Box>
                                <Typography variant={"body1"}>
                                    All {formatKnownDataType(data.getBytesPerPoint(), KnownDataType.CAPACITY)} between
                                    <br />
                                    {tooltipData.blockRange.pointFromLocation} and {tooltipData.blockRange.pointToLocation}
                                    <br />
                                    have been synchronized.
                                </Typography>
                            </Box>
                        ) : (
                            <Box>
                                <Typography variant={"body1"}>
                                    Between {tooltipData.blockRange.pointFromLocation} and {tooltipData.blockRange.pointToLocation},
                                </Typography>
                                <Typography variant={"body1"}>
                                    {formatKnownDataType(tooltipData.value, KnownDataType.CAPACITY)} have not been synced yet.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TooltipInPortal>
            )}
        </>
    );
});

export const BitmapIcon: React.FC<Partial<SvgIconProps>> = (props) => {
    return (
        <SvgIcon {...props}>
            <AiOutlineDotChart />
        </SvgIcon>
    );
};
