import { WizardStepProp } from "./GmMigrationWizardCommon";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import {
    getCounterTypeDisplayValue,
    LicensingLearnMoreLink,
    useCurrentProjectLicenseDetails,
    useCurrentProjectLicenseModel,
} from "../../license/LicenseCommon";
import { renderServerDataWithLoadingBox, renderServerDataWithLoadingList, useInitData } from "../../core/data/DataLoaderHooks";
import {
    getNumOfExtensionsToBeConsumedByMigrationSession,
    getSelectedVolumeCapacityExceedsHostBalance,
    getSelectedVolumeCapacityExceedsProjectBalance,
    getTotalSelectedVolumeCapacity,
    getVolumeSelectionDisabled,
    getVolumesSelectionContinueDisabled,
    useInitiateAutoAllocation,
} from "./GmMigrationWizardUtils";
import { GmMigrationType, GmMigrationVolumeType, GmMigrationWizardState } from "../GmMigrationService";
import { GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    Alert,
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Popover,
    Stack,
    SvgIcon,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { renderBootVolumeChip, renderChipInfo } from "../../../common/chip/CommonChips";
import { HostLicenseInfo, VaultDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { DEFAULT_LICENSE_CAPACITY } from "../../galaxymigrate/hostLicense/HostLicenseCommon";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { AlertColor } from "@mui/material/Alert/Alert";

import { isGmAutoAllocSupported } from "../autoallocation/GmAutoAllocation";
import { isDeploymentGteVersion } from "../../deployment/DeploymentCommon";
import { QuickTipButton } from "../../help/HelpCommon";
import React, { useCallback, useEffect, useState } from "react";
import { SwitchCard } from "../../../common/card/SwitchCard";
import { getGmStorageConfigDeviceType } from "../../galaxymigrate/GalaxyMigrateCommon";
import { FaAngleDoubleDown, FaAngleDoubleRight, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { MdStorage } from "react-icons/md";
import { CloseIcon, EditIcon } from "../../../common/CommonIcons";
import { useGetHostLicenseShareableCapacityAmount } from "../../deployment/deployment_hooks";
import { useGetProjectShareableLicenseEnabled } from "../../project/project_hooks";
import { useMountEffect } from "../../../common/hooks/hookslib";
import { usePopoverState } from "../../../common/popover/PopoverService";
import { getSortOrderLabel, SORT_ORDER } from "../../../common/table/TableSort";
import { TruncatedText } from "../../../common/text/TruncatedText";
import { useIsDesktop } from "../../layout/MainLayout";

// ======================
// VolumesSelectionStep
// ======================

export const GmMigrationWizardVolumesSelectionStep: React.FC<WizardStepProp> = observer((p) => {
    const { progressService, licenseService } = useAppServices();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const hostLicense = p.wizardState.deployment.data?.getInfo().getDeployment().getLicense();
    const projectLicense = useCurrentProjectLicenseDetails();
    const shareableLicenseEnabled = useGetProjectShareableLicenseEnabled();
    const init = async () => {
        if (!!p.wizardState.selectedRemoteDestinationId) {
            await progressService.track(p.wizardState.remoteStorageConfig.fetchData());
            await progressService.track(p.wizardState.remoteDeployment.fetchData());
        }
        await progressService.track(p.wizardState.storageConfig.fetchData());
        await licenseService.projectLicenseDetails.fetchData();
    };

    useInitData({
        init: init,
    });

    const shareableCapacity = useGetHostLicenseShareableCapacityAmount(p.wizardState.deployment.data?.getInfo().getDeployment().getSystemId());
    const onContinue = () => {
        p.wizardState.confirmVolumeSelections();
        p.wizardState.stepperState.goToNextStep();
    };

    const totalSelectedVolumeCapacity = getTotalSelectedVolumeCapacity(p.wizardState.selectedVolumes);

    const continueDisabled = getVolumesSelectionContinueDisabled(
        projectLicenseModel,
        p.wizardState,
        totalSelectedVolumeCapacity,
        projectLicense,
        shareableCapacity.data?.shareableCapacity
    );

    return renderServerDataWithLoadingList(p.wizardState.storageConfig, (storageConfig) => {
        const sourceVolumes = storageConfig.getDevicesList().filter((d) => {
            const isNotBootVolume = p.wizardState.selectedMigrationVolumeType === GmMigrationVolumeType.BOOT ? true : !d.getBlockDevice().getBoot();
            return (
                !d.getRole()?.getMigrationSessionUuid() &&
                (d.getRole()?.getRole() === GalaxyMigrateStorageConfig.Device.RoleInfo.Role.SOURCE || d.getCanInsertAsSource()) &&
                isNotBootVolume
            );
        });

        return (
            <>
                <Grid container justifyContent={"space-between"}>
                    <Typography paragraph>{"Select Volumes to be included in this migration session"}</Typography>
                </Grid>

                <Grid container justifyContent={"space-between"} alignContent={"center"}>
                    {!p.wizardState.isMockMigration && (
                        <>
                            <Grid item lg={6} xs={12}>
                                <AllowSmallerDestinationsToggle wizardState={p.wizardState} /> &nbsp;
                                <LVMSourceSelectionDialogButton wizardState={p.wizardState} storageConfig={storageConfig} />
                            </Grid>
                            <Grid item>
                                <Box display={"flex"}>
                                    <AutoAllocationButton wizardState={p.wizardState} />
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
                {p.wizardState.allowSmallerDestinations && !p.wizardState.isMockMigration && (
                    <Box width={"100%"}>
                        <Alert severity={"info"} variant={"outlined"}>
                            {`You are migrating the source data to a disk that is smaller than the physical size of the source disk. Please make sure there is no useful data on the source disk that is residing beyond the size of the destination disk, during the migration, and before cutover. Data written outside of the migration range may be inconsistent on the source disk when cMotion is invoked.`}
                        </Alert>
                    </Box>
                )}

                <br />
                <VolumeCardsSection sourceVolumes={sourceVolumes} wizardState={p.wizardState} />
                {!sourceVolumes.length && (
                    <Card>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={4}>
                            <Typography color={"textSecondary"}>No eligible volumes found.</Typography>
                        </Box>
                    </Card>
                )}
                <br />
                <Grid container justifyContent={"center"}>
                    <Box p={1} display={"flex"}>
                        <Box pr={2}>
                            <Button variant={"outlined"} color={"neutral"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                                {"Go Back"}
                            </Button>
                        </Box>
                        <Box>
                            <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                                {"Continue"}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {!p.wizardState.isMockMigration &&
                    renderServerDataWithLoadingBox(licenseService.projectLicenseDetails, (data) => {
                        return (
                            <VolumeSelectionLicenseAlert
                                licenseModel={projectLicenseModel}
                                hostLicense={hostLicense}
                                projectLicense={projectLicense}
                                totalVolumeCapacity={totalSelectedVolumeCapacity}
                                hostName={p.wizardState.deployment.data?.getInfo().getDeployment().getSystemName()}
                                shareableCapacity={shareableCapacity.data?.shareableCapacity}
                            />
                        );
                    })}
            </>
        );
    });
});

// ======================
// VolumeSelectionLicenseAlert
// ======================

interface VolumeSelectionLicenseAlertProps {
    totalVolumeCapacity: number;
    hostLicense: HostLicenseInfo;
    licenseModel: LicenseModel.LicenseModel;
    projectLicense: VaultDetails.AsObject;
    hostName: string;
    shareableCapacity: number;
}

export const VolumeSelectionLicenseAlert: React.FC<VolumeSelectionLicenseAlertProps> = observer((p) => {
    const { totalVolumeCapacity, hostLicense, projectLicense, hostName, licenseModel, shareableCapacity } = p;
    const numOfExtensions = getNumOfExtensionsToBeConsumedByMigrationSession(totalVolumeCapacity, hostLicense, projectLicense);
    const volumeCapacityString = formatKnownDataType(totalVolumeCapacity, KnownDataType.CAPACITY);

    const hostLicenseRemainingCapacity = !!hostLicense ? hostLicense?.getMigrationCapacityRemaining() : DEFAULT_LICENSE_CAPACITY;
    const hostLicenseRemainingCapacityString = formatKnownDataType(hostLicenseRemainingCapacity, KnownDataType.CAPACITY);
    const projectLicenseRemainingCapacityString = formatKnownDataType(
        projectLicense?.itemsList.find((c) => c.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION)
            ?.currentBalance,
        KnownDataType.CAPACITY
    );

    const licenseCapacityDifference = totalVolumeCapacity - hostLicenseRemainingCapacity;
    const licenseCapacityDifferenceString = formatKnownDataType(licenseCapacityDifference, KnownDataType.CAPACITY);
    const shareableEnabled = useGetProjectShareableLicenseEnabled();
    const getAlertConfig = (): {
        severity: AlertColor;
        message: React.ReactNode;
    } => {
        if (licenseModel !== LicenseModel.LicenseModel.HOST_BASED) {
            if (getSelectedVolumeCapacityExceedsProjectBalance(totalVolumeCapacity, projectLicense)) {
                return {
                    severity: "error",
                    message: (
                        <>
                            <Typography>
                                Migration session cannot be created because selected migration volume(s) require {volumeCapacityString} of migration capacity
                                license, but only {projectLicenseRemainingCapacityString} is available in your project license key.
                            </Typography>
                            <br />
                            <LicensingLearnMoreLink />
                        </>
                    ),
                };
            }
            return null;
        }
        if (!getSelectedVolumeCapacityExceedsHostBalance(totalVolumeCapacity, hostLicense)) {
            return {
                severity: "info",
                message: (
                    <>
                        <Typography>
                            {formatKnownDataType(totalVolumeCapacity, KnownDataType.CAPACITY)} of Migration Capacity Quota will be deducted from {hostName}
                            's license when the session is created.
                        </Typography>
                    </>
                ),
            };
        }
        if (shareableCapacity > licenseCapacityDifference) {
            return {
                severity: "warning",
                message: (
                    <>
                        <Typography>
                            {hostName}'s license only has {hostLicenseRemainingCapacityString} of remaining Migration Capacity Quota but {volumeCapacityString}{" "}
                            is required to migrate the selected volumes. {licenseCapacityDifferenceString} will be borrowed and consumed from another host on
                            this project when this session is created.
                        </Typography>
                    </>
                ),
            };
        }
        if (numOfExtensions > 0) {
            return {
                severity: "warning",
                message: (
                    <>
                        <Typography>
                            {hostName}'s license only has {hostLicenseRemainingCapacityString} of remaining Migration Capacity Quota but {volumeCapacityString}{" "}
                            is required to migrate the selected volumes. {numOfExtensions} additional{" "}
                            {getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION)} License
                            {numOfExtensions > 1 ? "s" : ""} will be consumed from this project's Project License Key when this session is created.
                        </Typography>
                    </>
                ),
            };
        }
        return {
            severity: "error",
            message: (
                <>
                    <Typography>
                        Migration session cannot be created because selected migration volumes ({volumeCapacityString}) are larger than the remaining Migration
                        Capacity Quota ({hostLicenseRemainingCapacityString}) for this host, and this project has{" "}
                        {shareableEnabled
                            ? `insufficient shareable capacity (
                        ${formatKnownDataType(shareableCapacity, KnownDataType.CAPACITY)} left) and `
                            : ""}
                        insufficient {getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION)}{" "}
                        Licenses.
                    </Typography>
                    <br />
                    <LicensingLearnMoreLink />
                </>
            ),
        };
    };

    return <Box pt={2}>{!!getAlertConfig() && <Alert severity={getAlertConfig().severity}>{getAlertConfig().message}</Alert>}</Box>;
});

// ======================
// VolumeCardsSection
// ======================

interface VolumeCardsSectionProps {
    sourceVolumes: Array<GalaxyMigrateStorageConfig.Device>;
    wizardState: GmMigrationWizardState;
}

enum VOLUME_SORT_OPTIONS {
    NAME = "Name",
    SIZE = "Capacity",
}

export const VolumeCardsSection: React.FC<VolumeCardsSectionProps> = observer((p) => {
    useMountEffect(() => {
        if (p.wizardState.isComputeMigration && !p.wizardState.hasSelectedVolumes) {
            p.sourceVolumes.forEach((v) => {
                if (!p.wizardState.selectedVolumes[v.getBlockDevice().getDeviceName()]) {
                    p.wizardState.addSelectedVolume(v);
                }
            });
        }
    });
    const [sourceVolumes, setSourceVolumes] = useState(p.sourceVolumes);
    return (
        <>
            <Box pb={2}>
                <VolumeSelectionSort volumes={sourceVolumes} setVolumes={setSourceVolumes} />
            </Box>
            {sourceVolumes.map((v) => (
                <VolumeSelectionCard key={v.getBlockDevice().getDeviceName()} sourceVolume={v} wizardState={p.wizardState} />
            ))}
        </>
    );
});

// ======================
// AutoAllocationButton
// ======================

interface AutoAllocationButtonProps {
    wizardState: GmMigrationWizardState;
}

export const AutoAllocationButton: React.FC<AutoAllocationButtonProps> = observer((p) => {
    const { progressService } = useAppServices();

    const selectedSourceVolumes = p.wizardState.selectedVolumes;
    const autoAllocationSupported = !!p.wizardState.selectedRemoteDestinationId
        ? isGmAutoAllocSupported(p.wizardState.remoteDeployment?.data)
        : isGmAutoAllocSupported(p.wizardState.deployment?.data);

    const gotoAutoAllocation = useInitiateAutoAllocation(p.wizardState);

    const autoAllocateVmware = async () => {
        const newVolumesList = await progressService.track(p.wizardState.vmwareState.autoAllocateVmware(), "Auto-Allocating Disks To VMWare Helper...");
        if (!!newVolumesList) {
            await p.wizardState.vmwareState.applyAllocatedVmVolumesToWizard(newVolumesList);
        }
    };

    return (
        <>
            {autoAllocationSupported && (
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={p.wizardState.selectedMigrationType === GmMigrationType.COMPUTE_VMWARE ? autoAllocateVmware : gotoAutoAllocation}
                    disabled={!Object.keys(selectedSourceVolumes)?.length}
                >
                    {"Auto Allocate Destination Volumes"}
                </Button>
            )}
        </>
    );
});
// ======================
// AllowSmallerDestinationsToggle
// ======================

interface AllowSmallerDestinationsToggleProps {
    wizardState: GmMigrationWizardState;
}

export const AllowSmallerDestinationsToggle: React.FC<AllowSmallerDestinationsToggleProps> = observer((p) => {
    const { dialogService } = useAppServices();

    const supported = isDeploymentGteVersion(p.wizardState?.deployment?.data, "5.1.5");
    if (!supported) {
        return null;
    }

    const onClick = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            const confirmed = await dialogService.addConfirmDialog({
                title: "Allow Migration to Smaller Volumes",
                message: `You are migrating the source data to a disk that is smaller than the physical size of the source disk. Please make sure there is no useful data on the source disk that is residing beyond the size of the destination disk, during the migration, and before cutover. Data written outside of the migration range may be inconsistent on the source disk when cMotion is invoked.`,
                typeToConfirm: "CONFIRM",
            });
            if (confirmed) {
                p.wizardState.setAllowSmallerDestinations(checked);
            }
        } else {
            p.wizardState.setAllowSmallerDestinations(checked);
        }
    };

    const toggle = <Switch color={"secondary"} checked={p.wizardState.allowSmallerDestinations} onChange={onClick} />;
    return (
        <Box width={"100%"}>
            <Box display={"flex"} alignItems={"center"}>
                <FormControlLabel control={toggle} label={"Allow Migration to Smaller Volumes"} />
                <QuickTipButton
                    title={
                        "Source data beyond the maximum destination volume size will not be migrated. Ensure source partitions can fit into the destination volume in its entirety to avoid data corruption."
                    }
                />
            </Box>
        </Box>
    );
});

// ======================
// VolumeSelectionCard
// ======================

interface VolumeSelectionCardProps {
    sourceVolume: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

export const VolumeSelectionCard: React.FC<VolumeSelectionCardProps> = observer((p) => {
    const vol = p.sourceVolume;
    const device = vol.getBlockDevice();
    const selected = !!p.wizardState.selectedVolumes[device.getDeviceName()];
    const onSelect = useCallback(() => {
        if (selected) {
            p.wizardState.removeSelectedVolume(vol);
        } else {
            p.wizardState.addSelectedVolume(vol);
        }
    }, [selected, p.wizardState, vol]);

    const isVolumeSelectionDisabled = getVolumeSelectionDisabled(p.wizardState, vol);
    const isMockMigration = p.wizardState.isMockMigration;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <SwitchCard
            selected={selected}
            disabled={isVolumeSelectionDisabled}
            onSelect={onSelect}
            cardContent={
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} lg={4}>
                        <Box display={"flex"} justifyContent={isMobile ? "center" : "flex-start"}>
                            <Box textAlign={"left"}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box pr={2}>
                                        <Typography variant={"body1"}>{device.getDeviceName()}</Typography>
                                    </Box>
                                    <Box>
                                        <VolumeCapacityBadge capacity={device.getCapacity()} />
                                    </Box>
                                </Box>
                                <TruncatedText
                                    truncateStart
                                    characterLimit={32}
                                    variant={"caption"}
                                    color={"textSecondary"}
                                    text={vol.getPreferredDevicePath()}
                                />
                                <Grid container spacing={1} pt={1}>
                                    {renderChipInfo(getGmStorageConfigDeviceType(vol.toObject()))}
                                    {renderChipInfo(device.getFsType())}
                                    {renderChipInfo(device.getLabel())}
                                    {renderChipInfo(device.getMountPoint())}
                                    {renderBootVolumeChip(device.getBoot())}
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    {!isMockMigration && (
                        <>
                            <Grid item xs={12} lg={1}>
                                {selected && (
                                    <Box display={"flex"} justifyContent={"center"} pt={2} pb={2}>
                                        <SvgIcon>{isMobile ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}</SvgIcon>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {selected && (
                                    <Box display={"flex"} justifyContent={isMobile ? "center" : "flex-end"}>
                                        <DestinationSelectionMenu wizardState={p.wizardState} device={vol} />
                                    </Box>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            }
        />
    );
});

// ======================
// LVMSourceSelectionDialog
// ======================
interface LVMSourceSelectionDialogButtonProps {
    wizardState: GmMigrationWizardState;
    storageConfig: GalaxyMigrateStorageConfig;
}

const useLVMSourceSelectionDialogButtonStyles = () => {
    const t = useTheme();
    return {
        content: {
            background: t.palette.background.default,
        },
    };
};
const LVMSourceSelectionDialogButton: React.FC<LVMSourceSelectionDialogButtonProps> = observer((p) => {
    const styles = useLVMSourceSelectionDialogButtonStyles();
    const close = () => (p.wizardState.showLVMSourceSelectionDialog = false);
    const lvmConfig = p.storageConfig.getLvmConfig();

    if (!lvmConfig?.getVolumeGroupsList()?.length) {
        return null;
    }
    return (
        <>
            <Button variant={"outlined"} color={"primary"} onClick={() => (p.wizardState.showLVMSourceSelectionDialog = true)}>
                {"Select LVM Volumes as Source"}
            </Button>
            <Dialog fullScreen={useShouldDialogFullScreen()} maxWidth={"md"} fullWidth open={p.wizardState.showLVMSourceSelectionDialog} onClose={close}>
                {p.wizardState.showLVMSourceSelectionDialog && !!p.storageConfig && (
                    <>
                        <DialogTitle sx={styles.content}>{`Select and add LVM volumes to migration session`}</DialogTitle>
                        <DialogContent sx={styles.content}>
                            <Box p={2}>
                                {lvmConfig.getVolumeGroupsList().map((vg) => {
                                    const addFn = () => {
                                        // find the storage disk
                                        const pvDevicePaths = vg.getPvsList().map((pv) => pv.getDevice());
                                        p.storageConfig
                                            .getDevicesList()
                                            .filter((d) => {
                                                // no need to take care of windows this is lvm
                                                for (const devicePath of [`/dev/${d.getBlockDevice().getDeviceName()}`].concat(
                                                    d.getBlockDevice().getDeviceLinksList()
                                                )) {
                                                    if (pvDevicePaths.includes(devicePath)) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            })
                                            .forEach((d) => p.wizardState.addSelectedVolume(d));

                                        close();
                                    };

                                    return <VGSelectionCard key={vg.getUuid()} vg={vg} addFn={addFn} />;
                                })}
                            </Box>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
});

// ======================
// VGSelectionCard
// ======================
interface VGSelectionCardProps {
    vg: GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup;
    addFn: () => void;
}

const VGSelectionCard: React.FC<VGSelectionCardProps> = observer((p) => {
    const selectButton = (
        <Button variant={"contained"} color={"secondary"} size={"large"} onClick={p.addFn}>
            {`Add VG to Selection`}
        </Button>
    );
    return (
        <Box pb={1}>
            <Card>
                <CardHeader
                    title={`Volume Group: ${p.vg.getName()}`}
                    action={selectButton}
                    subheader={`${formatKnownDataType(p.vg.getCapacity(), KnownDataType.CAPACITY)}`}
                />
                <Divider />
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <List>
                            <ListSubheader>{`${p.vg.getLvsList().length} Logical Volumes`}</ListSubheader>
                            {p.vg.getLvsList().map((lv) => {
                                return (
                                    <ListItem key={lv.getUuid()} dense>
                                        <ListItemText
                                            primary={
                                                <Box pb={1} display={"flex"} alignItems={"center"}>
                                                    <Box pr={2}>
                                                        <Typography variant={"body1"}>{lv.getName()}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <VolumeCapacityBadge capacity={lv.getCapacity()} />
                                                    </Box>
                                                </Box>
                                            }
                                            secondary={lv.getMountPoint() ? `Mounted: ` + lv.getMountPoint() + ` (${lv.getFsType()})` : null}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <List>
                            <ListSubheader>{`${p.vg.getPvsList().length} Physical Volumes`}</ListSubheader>
                            {p.vg.getPvsList().map((pv) => {
                                return (
                                    <ListItem key={pv.getUuid()} dense>
                                        <ListItemText
                                            primary={
                                                <Box pb={1} display={"flex"} alignItems={"center"}>
                                                    <Box pr={2}>
                                                        <Typography variant={"body1"}>{pv.getDevice()}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <VolumeCapacityBadge capacity={pv.getCapacity()} />
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
});

// ======================
// DestinationSelectionMenu
// ======================
interface DestinationSelectionMenuProps {
    device: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

const DestinationSelectionMenu: React.FC<DestinationSelectionMenuProps> = observer((p) => {
    const blockDevice = p.device.getBlockDevice();
    const v = p.wizardState.selectedVolumes[blockDevice.getDeviceName()];
    const enabled = !!v;
    const dialogState = useDialogState();

    const hasDestination = !!v?.destination;
    let candidates = p.wizardState.getDestinationCandidatesForSourceVolume(p.device);
    const [destinationCandidates, setDestinationCandidates] = useState(candidates);

    useEffect(() => {
        if (!dialogState.isOpen) {
            setDestinationCandidates(candidates);
        }
    }, [candidates, dialogState.isOpen, setDestinationCandidates]);

    const [searchValue, setSearchValue] = useState("");
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        if (e.target.value === "") {
            setDestinationCandidates(candidates);
        } else {
            setDestinationCandidates(candidates.filter((c) => c.getBlockDevice().getDeviceName().toUpperCase().includes(e.target.value.toUpperCase())));
        }
    };

    return (
        <>
            <CurrentDestinationDisplay
                enabled={enabled}
                hasDestination={hasDestination}
                candidates={candidates}
                dialogState={dialogState}
                device={p.device}
                wizardState={p.wizardState}
            />
            <Dialog maxWidth={"sm"} fullWidth open={dialogState.isOpen} onClose={dialogState.close} fullScreen={useShouldDialogFullScreen()}>
                <List>
                    <ListSubheader>{`Select Destination Volume`}</ListSubheader>
                    <Box p={2}>
                        <TextField value={searchValue} onChange={handleSearch} variant={"outlined"} fullWidth label={"Search by name"} />
                    </Box>
                    <Box p={2}>
                        <VolumeSelectionSort volumes={destinationCandidates} setVolumes={setDestinationCandidates} />
                    </Box>
                    {destinationCandidates.map((c) => {
                        return (
                            <ListItem
                                key={c.getBlockDevice().getDeviceName()}
                                button
                                onClick={() => {
                                    v.destination = c;
                                    dialogState.close();
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIcon>
                                        <MdStorage />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Stack spacing={1}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Box pr={2}>
                                                    <Typography>{c.getBlockDevice().getDeviceName()}</Typography>
                                                </Box>
                                                <Box>
                                                    <VolumeCapacityBadge capacity={c.getBlockDevice().getCapacity()} />
                                                </Box>
                                            </Box>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {c.getPreferredDevicePath()}
                                            </Typography>
                                            <Box>
                                                {renderChipInfo(getGmStorageConfigDeviceType(c.toObject()))}
                                                {renderChipInfo(c.getBlockDevice().getFsType())}
                                                {renderChipInfo(c.getBlockDevice().getLabel())}
                                                {renderChipInfo(c.getBlockDevice().getMountPoint())}
                                            </Box>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Dialog>
        </>
    );
});

// ======================
// CurrentDestinationDisplay
// ======================

interface CurrentDestinationDisplayProps {
    enabled: boolean;
    hasDestination: boolean;
    candidates: Array<GalaxyMigrateStorageConfig.Device>;
    dialogState: DialogState;
    device: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

export const CurrentDestinationDisplay: React.FC<CurrentDestinationDisplayProps> = observer((p) => {
    const { enabled, hasDestination, candidates, dialogState, device, wizardState } = p;

    const blockDevice = device.getBlockDevice();
    const v = wizardState.selectedVolumes[blockDevice.getDeviceName()];

    const getButtonText = () => {
        if (!enabled) {
            return `Not Selected for Migration`;
        } else if (enabled && !hasDestination && !!candidates.length) {
            return `Select Destination Volume`;
        } else if (enabled && !hasDestination && !candidates.length) {
            return `No Eligible Destination Found`;
        }
    };

    if (!hasDestination) {
        return (
            <Button variant={"outlined"} disabled={!enabled || !candidates.length} onClick={dialogState.open}>
                {getButtonText()}
            </Button>
        );
    } else {
        const d = v.destination;
        return (
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                <Box pr={4} textAlign={"left"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box pr={2}>
                            <Typography variant={"body1"}>{d.getBlockDevice().getDeviceName()}</Typography>
                        </Box>
                        <Box>
                            <VolumeCapacityBadge capacity={d.getBlockDevice().getCapacity()} />
                        </Box>
                    </Box>
                    <TruncatedText truncateStart text={d.getPreferredDevicePath()} characterLimit={32} variant={"caption"} color={"textSecondary"} />
                    <Grid container spacing={1} pt={1}>
                        {renderChipInfo(getGmStorageConfigDeviceType(d.toObject()))} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getFsType())} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getLabel())} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getMountPoint())}
                    </Grid>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Box p={0}>
                        <IconButton onClick={dialogState.open}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Box p={0}>
                        <IconButton onClick={() => (v.destination = null)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        );
    }
});

// ======================
// VolumeCapacityBadge
// ======================

interface VolumeCapacityBadgeProps {
    capacity: number;
}

export const VolumeCapacityBadge: React.FC<VolumeCapacityBadgeProps> = observer((p) => {
    const { capacity } = p;
    return (
        <Tooltip title={`${capacity} B`}>
            <Chip label={formatKnownDataType(capacity, KnownDataType.CAPACITY)} size={"small"} />
        </Tooltip>
    );
});

// ======================
// VolumeSelectionSort
// ======================

interface VolumeSelectionSortProps {
    volumes: GalaxyMigrateStorageConfig.Device[];
    setVolumes: (v: GalaxyMigrateStorageConfig.Device[]) => void;
}

const VolumeSelectionSort: React.FC<VolumeSelectionSortProps> = (p) => {
    const { volumes, setVolumes } = p;
    const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);
    const [sortField, setSortField] = useState(VOLUME_SORT_OPTIONS.NAME);

    const sortPopoverState = usePopoverState();

    const sortSourceVolumes = (sortOrder: SORT_ORDER, sortField: VOLUME_SORT_OPTIONS) => {
        if (sortField === VOLUME_SORT_OPTIONS.NAME) {
            if (sortOrder === SORT_ORDER.ASCENDING) {
                setVolumes(
                    [...volumes].sort((a, b) => {
                        return a.getBlockDevice().getDeviceName().localeCompare(b.getBlockDevice().getDeviceName());
                    })
                );
            } else {
                setVolumes(
                    [...volumes].sort((a, b) => {
                        return b.getBlockDevice().getDeviceName().localeCompare(a.getBlockDevice().getDeviceName());
                    })
                );
            }
        }
        if (sortField === VOLUME_SORT_OPTIONS.SIZE) {
            if (sortOrder === SORT_ORDER.ASCENDING) {
                setVolumes(
                    [...volumes].sort((a, b) => {
                        return a.getBlockDevice().getCapacity() - b.getBlockDevice().getCapacity();
                    })
                );
            } else {
                setVolumes(
                    [...volumes].sort((a, b) => {
                        return b.getBlockDevice().getCapacity() - a.getBlockDevice().getCapacity();
                    })
                );
            }
        }
    };

    const icon =
        sortOrder === SORT_ORDER.DESCENDING ? (
            <SvgIcon fontSize={"small"}>
                <FaSortAmountDown />
            </SvgIcon>
        ) : (
            <SvgIcon fontSize={"small"}>
                <FaSortAmountUp />
            </SvgIcon>
        );

    return (
        <>
            <Stack direction={"row"} spacing={0} alignItems={"flex-end"}>
                <Tooltip title={`Sort Order: ${getSortOrderLabel(sortOrder)}`}>
                    <IconButton
                        sx={{
                            "&:hover": {
                                borderRadius: 1,
                            },
                        }}
                        size={"small"}
                        onClick={() => {
                            setSortOrder(sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING);
                            sortSourceVolumes(sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING, sortField);
                        }}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
                <Button color={"neutral"} onClick={sortPopoverState.open}>
                    {`Sort By: ${sortField}`}
                </Button>
            </Stack>

            <Popover
                open={sortPopoverState.isOpen}
                onClose={sortPopoverState.close}
                anchorEl={sortPopoverState.anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box width={"300px"}>
                    <List>
                        {Object.keys(VOLUME_SORT_OPTIONS).map((key) => {
                            return (
                                <ListItem
                                    key={key}
                                    button
                                    onClick={() => {
                                        sortSourceVolumes(sortOrder, VOLUME_SORT_OPTIONS[key as keyof typeof VOLUME_SORT_OPTIONS]);
                                        setSortField(VOLUME_SORT_OPTIONS[key as keyof typeof VOLUME_SORT_OPTIONS]);
                                        sortPopoverState.close();
                                    }}
                                >
                                    <ListItemText primary={VOLUME_SORT_OPTIONS[key as keyof typeof VOLUME_SORT_OPTIONS]} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Popover>
        </>
    );
};
