import { observer } from "mobx-react-lite";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    FormControlLabel,
    Grid,
    Link,
    ListSubheader,
    Radio,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../../../core/dialog/DialogService";
import { DialogTopBar } from "../../../../core/dialog/DialogComponents";
import { BiExport, BiImport } from "react-icons/bi";
import * as React from "react";
import { useEffect, useState } from "react";
import { SelectableBox, SelectableCard } from "../../../../../common/card/SelectableCard";
import Efficiency from "../../../../../assets/azure/Efficency.png";
import HighPerformance from "../../../../../assets/azure/high_performance.png";
import LowCost from "../../../../../assets/azure/low_cost.png";
import { GmMigrationAutoAllocationState } from "../../../GmMigrationService";
import { renderServerDataWithLoadingBox, useInitData } from "../../../../core/data/DataLoaderHooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatKnownDataType, KnownDataType } from "../../../../../common/utils/formatter";
import { renderChipInfo } from "../../../../../common/chip/CommonChips";
import { getGmStorageConfigDeviceType } from "../../../../galaxymigrate/GalaxyMigrateCommon";
import xbytes from "xbytes";
import ChooseMyOwn from "../../../../../assets/azure/choose_my_own_azure.png";
import { GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { GetAzureManagedDiskRecommendation } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { SimpleTable } from "../../../../../common/table/SimpleTable";
import { ColumnDef } from "../../../../../common/table/DataTable";
import * as galaxycompletepb_apipb_domainpb_azure_pb from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { AzureManagedDiskProduct, AzureManagedDiskRecommendation } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { FcCheckmark } from "react-icons/fc";
import { MdPlaylistPlay } from "react-icons/md";
import { useIsFeatureEnabled } from "../../../../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../../../../app/AppGlobalService";
import { useQuery } from "@tanstack/react-query";
import { QueryResultWrapper } from "../../../../core/data/QueryResultWrapper";
import { useAppServices } from "../../../../app/services";
import { AzureCalculatedDiskAssignmentConfig, getDiskParamsEnabled, getDiskSizeInGiB } from "./AzureIntegrationHelpers";
import { RecommendationPreference } from "gc-web-proto/galaxycompletepb/apipb/domainpb/planner_pb";

// ======================
// AzureRecommendationAlert
// ======================

interface AzureRecommendationAlertProps {
    autoAllocState: GmMigrationAutoAllocationState;
    handleConfirmAzureSelection: (deviceAssignments: { [key: string]: AzureCalculatedDiskAssignmentConfig }) => void;
}

export const AzureRecommendationAlert: React.FC<AzureRecommendationAlertProps> = observer((p) => {
    const dialogState = useDialogState();
    const { autoAllocState, handleConfirmAzureSelection } = p;
    const isFeatureFlagEnabled = useIsFeatureEnabled(FeatureFlag.AZURE_RECOMMENDATION);

    return isFeatureFlagEnabled ? (
        <>
            <Alert
                severity={"info"}
                variant={"outlined"}
                action={
                    <Box pt={1}>
                        <Button variant={"outlined"} onClick={dialogState.open}>
                            Launch Recommendation Wizard
                        </Button>
                    </Box>
                }
            >
                <AlertTitle>Recommendation Wizard</AlertTitle>
                Recommendation based on the history of your IO characteristics
            </Alert>
            {dialogState.isOpen && (
                <AzureRecommendationWizard
                    dialogState={dialogState}
                    autoAllocState={autoAllocState}
                    handleConfirmAzureSelection={handleConfirmAzureSelection}
                />
            )}
        </>
    ) : null;
});

// ======================
// AzureRecommendationWizard
// ======================

interface AzureRecommendationWizardProps {
    dialogState: DialogState;
    autoAllocState: GmMigrationAutoAllocationState;
    handleConfirmAzureSelection: (deviceAssignments: { [key: string]: AzureCalculatedDiskAssignmentConfig }) => void;
}

export const AzureRecommendationWizard: React.FC<AzureRecommendationWizardProps> = observer((p) => {
    const { dialogState, autoAllocState, handleConfirmAzureSelection } = p;
    const fullScreen = useShouldDialogFullScreen();

    const [recommendationMade, setRecommendationMade] = useState(false);

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} fullWidth maxWidth={"lg"}>
            <DialogTopBar dialogState={dialogState} title={"Migration Recommendation Wizard (Azure)"} divider />
            <Box p={2}>
                <ButtonGroup variant={"outlined"}>
                    <Button
                        startIcon={
                            <SvgIcon>
                                <BiExport />
                            </SvgIcon>
                        }
                    >
                        Export Recommendation
                    </Button>
                    <Button
                        startIcon={
                            <SvgIcon>
                                <BiImport />
                            </SvgIcon>
                        }
                    >
                        Import Recommendation
                    </Button>
                </ButtonGroup>
            </Box>

            {!recommendationMade ? (
                <RecommendationParamsForm autoAllocState={autoAllocState} dialogState={dialogState} setRecommendationMade={setRecommendationMade} />
            ) : (
                <>
                    {renderServerDataWithLoadingBox(autoAllocState.azureRecommendation, (data) => {
                        return (
                            <RecommendationResultForm
                                dialogState={dialogState}
                                autoAllocState={autoAllocState}
                                data={data}
                                handleConfirmAzureSelection={handleConfirmAzureSelection}
                            />
                        );
                    })}
                </>
            )}
        </Dialog>
    );
});

// ======================
// RecommendationForm
// ======================

interface RecommendationFormProps {
    autoAllocState: GmMigrationAutoAllocationState;
    setRecommendationMade: (recMade: boolean) => void;
    dialogState: DialogState;
}

const RecommendationParamsForm: React.FC<RecommendationFormProps> = observer((p) => {
    const { autoAllocState, setRecommendationMade, dialogState } = p;
    const { progressService } = useAppServices();
    const expectedGrowthOptions = [-25, -10, 0, 10, 25];
    const additionalStorageOptions = [0, 10, 25, 50, 100];

    const [ioAccessHistory, setIoAccessHistory] = useState<number>(1440);
    const [expectedGrowth, setExpectedGrowth] = useState<number>(0);
    const [additionalStorage, setAdditionalStorage] = useState<number>(0);
    const [defaultChoice, setDefaultChoice] = useState<RecommendationPreference>(RecommendationPreference.EFFICIENCY);

    const onSubmit = async () => {
        await progressService.track(
            autoAllocState.azureRecommendation.fetchData([], ioAccessHistory, expectedGrowth, additionalStorage, defaultChoice),
            "Making Recommendation..."
        );
        setRecommendationMade(true);
    };

    return (
        <>
            <Box p={2}>
                <Box>
                    <Typography>Please select your preferences for the recommendation:</Typography>
                </Box>
                <Box pt={2}>
                    <Typography variant={"h6"}>1. How much I/O access history should this recommendation analyze?</Typography>
                </Box>
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={4}>
                        <SelectableBox selected={ioAccessHistory === 5} onSelect={() => setIoAccessHistory(5)} darkBorder>
                            <Box p={2}>
                                <Typography>5 Minutes</Typography>
                            </Box>
                        </SelectableBox>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectableBox selected={ioAccessHistory === 1440} onSelect={() => setIoAccessHistory(1440)} darkBorder>
                            <Box p={2}>
                                <Typography>1 Day</Typography>
                            </Box>
                        </SelectableBox>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectableBox selected={ioAccessHistory === 7 * 1440} onSelect={() => setIoAccessHistory(7 * 1440)} darkBorder>
                            <Box p={2}>
                                <Typography>7 Days</Typography>
                            </Box>
                        </SelectableBox>
                    </Grid>
                </Grid>
                <Box pt={2}>
                    <Typography variant={"h6"}>2. Do you expect your workload to grow after migration?</Typography>
                </Box>
                <Grid container spacing={2} pt={2} columns={10}>
                    {expectedGrowthOptions.map((option) => {
                        return (
                            <Grid item key={option} xs={2}>
                                <SelectableBox selected={expectedGrowth === option} onSelect={() => setExpectedGrowth(option)} darkBorder>
                                    <Box p={2}>
                                        <Typography>{!!option ? `${option}%` : "No Change"}</Typography>
                                    </Box>
                                </SelectableBox>
                            </Grid>
                        );
                    })}
                </Grid>
                <Box pt={2}>
                    <Typography variant={"h6"}>3. Do you need additional storage capacity for your new destination volumes?</Typography>
                </Box>
                <Grid container spacing={2} pt={2} columns={10}>
                    {additionalStorageOptions.map((option) => {
                        return (
                            <Grid item key={option} xs={2}>
                                <SelectableBox selected={additionalStorage === option} onSelect={() => setAdditionalStorage(option)} darkBorder>
                                    <Box p={2}>
                                        <Typography>{!!option ? `${option}%` : "No Change"}</Typography>
                                    </Box>
                                </SelectableBox>
                            </Grid>
                        );
                    })}
                </Grid>
                <Box pt={2}>
                    <Typography variant={"h6"}>4. What is your default choice for this recommendation?</Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        Select one – you can always change this later.
                    </Typography>
                </Box>
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={4}>
                        <SelectableCard
                            selected={defaultChoice === RecommendationPreference.LOWCOST}
                            onSelect={() => setDefaultChoice(RecommendationPreference.LOWCOST)}
                            title={"Low Cost"}
                            icon={<img src={LowCost} alt={"Low Cost"} height={"100"} width={"auto"} />}
                            description={"Lower cost option that may still fit your needs"}
                            darkBorder
                            cardProps={{
                                sx: {
                                    height: "100%",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectableCard
                            selected={defaultChoice === RecommendationPreference.EFFICIENCY}
                            onSelect={() => setDefaultChoice(RecommendationPreference.EFFICIENCY)}
                            title={"Efficiency"}
                            icon={<img src={Efficiency} alt={"Efficiency"} height={"100"} width={"auto"} />}
                            description={"Recommendation that matches your current needs"}
                            darkBorder
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectableCard
                            selected={defaultChoice === RecommendationPreference.HIGHPERFORMANCE}
                            onSelect={() => setDefaultChoice(RecommendationPreference.HIGHPERFORMANCE)}
                            title={"High Performance"}
                            icon={<img src={HighPerformance} alt={"High Performance"} height={"100"} width={"auto"} />}
                            description={"High performance options with room to grow"}
                            darkBorder
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                    Cancel
                </Button>
                <Button variant={"contained"} onClick={onSubmit}>
                    Make Recommendation
                </Button>
            </DialogActions>
        </>
    );
});

// ======================
// RecommendationResultForm
// ======================

interface RecommendationResultFormProps {
    autoAllocState: GmMigrationAutoAllocationState;
    data: GetAzureManagedDiskRecommendation.Response.AsObject;
    dialogState: DialogState;
    handleConfirmAzureSelection: (deviceAssignments: { [key: string]: AzureCalculatedDiskAssignmentConfig }) => void;
}

export const RecommendationResultForm: React.FC<RecommendationResultFormProps> = observer((p) => {
    const { autoAllocState, data, handleConfirmAzureSelection, dialogState } = p;
    const sourceDevices = autoAllocState.sourceDevices.map((s) => s.source);
    const theme = useTheme();

    const [deviceAssignments, setDeviceAssignments] = useState<{
        [key: string]: AzureCalculatedDiskAssignmentConfig;
    }>(getInitialDeviceAssignments(data));

    const [expanded, setExpanded] = React.useState<string | false>("panel0");
    const [devicesReviewed, setDevicesReviewed] = useState<{
        [key: string]: boolean;
    }>(getInitialDeviceReviewedState(sourceDevices));

    const handleChangePanel = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSetDeviceAssignment = (device: string, config: AzureCalculatedDiskAssignmentConfig) => {
        setDeviceAssignments({ ...deviceAssignments, [device]: config });
    };

    const handleSetDeviceReviewed = (device: string, reviewed: boolean) => {
        setDevicesReviewed({ ...devicesReviewed, [device]: reviewed });
    };

    const onConfirmDiskClasses = () => {
        handleConfirmAzureSelection(deviceAssignments);

        dialogState.close();
    };

    return (
        <>
            <Box p={2}>
                <Typography variant={"h6"}>Review Recommendation:</Typography>
                <Typography color={"textSecondary"}>
                    The following recommendation has been made based on the history of your IO characteristics. Review and modify if needed.
                </Typography>
                <Box pt={2}>
                    <Card>
                        {data.recommendationsMap.map((rec, index) => {
                            return (
                                <AzureStorageRecommendationCard
                                    rec={rec}
                                    stats={data.statsMap[index]}
                                    dataLength={data.recommendationsMap.length}
                                    index={index}
                                    key={index}
                                    handleChangePanel={handleChangePanel}
                                    handleSetDeviceAssignment={handleSetDeviceAssignment}
                                    handleSetDeviceReviewed={handleSetDeviceReviewed}
                                    devicesReviewed={devicesReviewed}
                                    deviceAssignments={deviceAssignments}
                                    setExpanded={setExpanded}
                                    expanded={expanded}
                                    sourceDevices={sourceDevices}
                                    autoAllocState={autoAllocState}
                                />
                            );
                        })}
                    </Card>
                </Box>
            </Box>
            <Divider />
            <Box p={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography variant={"h6"}>Estimated Azure Storage Cost (Subtotal): ${getSubtotal(sourceDevices, deviceAssignments)} / mo.</Typography>
                        <Typography variant={"body2"}>
                            The cost listed is for estimation only. Visit{" "}
                            <Link href={"https://azure.microsoft.com/en-us/pricing/details/managed-disks/"} target={"_blank"}>
                                Azure Storage Pricing
                            </Link>{" "}
                            for the most up-to-date pricing information.
                        </Typography>
                    </Box>

                    <Button variant={"contained"} disabled={Object.values(devicesReviewed).includes(false)} onClick={onConfirmDiskClasses}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </>
    );
});

// ======================
// AzureStorageRecommendationCard
// ======================

interface AzureStorageRecommendationCardProps {
    sourceDevices: GalaxyMigrateStorageConfig.Device[];
    rec: [string, galaxycompletepb_apipb_domainpb_azure_pb.AzureManagedDiskRecommendation.AsObject];
    handleChangePanel: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    index: number;
    expanded: string | boolean;
    setExpanded: (expanded: string | false) => void;
    devicesReviewed: { [key: string]: boolean };
    handleSetDeviceReviewed: (device: string, reviewed: boolean) => void;
    autoAllocState: GmMigrationAutoAllocationState;
    deviceAssignments: { [key: string]: { product: AzureManagedDiskProduct.AsObject; price: number } };
    handleSetDeviceAssignment: (device: string, config: AzureCalculatedDiskAssignmentConfig) => void;
    dataLength: number;
    stats: [string, GetAzureManagedDiskRecommendation.Response.Stats.AsObject];
}

export const AzureStorageRecommendationCard: React.FC<AzureStorageRecommendationCardProps> = observer((p) => {
    const {
        sourceDevices,
        rec,
        handleSetDeviceReviewed,
        handleChangePanel,
        expanded,
        setExpanded,
        index,
        dataLength,
        devicesReviewed,
        autoAllocState,
        deviceAssignments,
        handleSetDeviceAssignment,
        stats,
    } = p;

    const disk = rec[0];
    const deviceInfo = sourceDevices.find((d) => d.getBlockDevice().getDeviceName() === disk);
    const recValue = rec[1];
    const theme = useTheme();

    const handleClickNext = () => {
        handleSetDeviceReviewed(disk, true);
        setExpanded(`panel${index + 1}`);
    };

    return (
        <Accordion
            disableGutters
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChangePanel(`panel${index}`)}
            sx={{
                backgroundColor: theme.palette.cirrus.main,
                borderBottom: `2px solid black`,
                "&:last-child": {
                    borderBottom: 0,
                },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box pr={2}>
                            <SvgIcon>{devicesReviewed[disk] ? <FcCheckmark /> : <MdPlaylistPlay />}</SvgIcon>
                        </Box>
                        <Box>
                            <Typography>
                                {deviceInfo.getBlockDevice().getDeviceName()} (
                                {formatKnownDataType(deviceInfo.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)})
                            </Typography>
                            <Grid container spacing={1}>
                                {renderChipInfo(getGmStorageConfigDeviceType(deviceInfo.toObject()))}
                                {renderChipInfo(deviceInfo.getBlockDevice().getFsType())}
                                {renderChipInfo(deviceInfo.getBlockDevice().getLabel())}
                                {renderChipInfo(deviceInfo.getBlockDevice().getMountPoint())}
                            </Grid>
                        </Box>
                    </Box>
                    <Box textAlign={"right"} pr={2}>
                        {!!deviceAssignments[disk].product ? (
                            <Box>
                                <Typography sx={{ color: theme.palette.success.main }}>Azure {deviceAssignments[disk].product.name}</Typography>
                                <Typography color={"textSecondary"}>{`$${deviceAssignments[disk].price?.toFixed(2) || "--"} / mo.`}</Typography>
                            </Box>
                        ) : (
                            <Typography> --</Typography>
                        )}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Alert severity={"info"}>
                        You are recommended to use storage options that support at least {recValue.iops} IOPS and{" "}
                        {formatKnownDataType(recValue.throughput, KnownDataType.THROUGHPUT)} of bandwidth.
                    </Alert>
                </Box>
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={12} md={6}>
                        <SelectableCard
                            selected={deviceAssignments[disk]?.product?.storageType === recValue.product.storageType}
                            onSelect={() =>
                                handleSetDeviceAssignment(disk, {
                                    capacity: recValue.capacity,
                                    iops: recValue.iops,
                                    throughput: recValue.throughput,
                                    product: recValue.product,
                                    price: recValue.pricePerMonth,
                                })
                            }
                            title={`Azure ${recValue.product.name}`}
                            header={"Recommended"}
                            description={
                                <>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        Estimated cost:
                                    </Typography>
                                    <Typography variant={"h3"} color={"textPrimary"}>
                                        ${recValue.pricePerMonth.toFixed(2)}
                                    </Typography>
                                    <Typography variant={"caption"}>per month</Typography>
                                    <br />
                                    <Typography variant={"caption"}>
                                        ({formatKnownDataType(deviceInfo.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)} x $
                                        {getDiskCostEstimatePerGiB(deviceInfo.getBlockDevice().getCapacity(), recValue.pricePerMonth)} /mo.)
                                    </Typography>
                                    <Box pt={2}>
                                        <Typography variant={"caption"}>Max IOPS: {recValue.product.maxIops}</Typography>
                                        <br />
                                        <Typography variant={"caption"}>
                                            Max Throughput: {formatKnownDataType(recValue.product.maxThroughput, KnownDataType.THROUGHPUT)}
                                        </Typography>
                                    </Box>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectableCard
                            selected={deviceAssignments[disk]?.product?.storageType !== recValue.product.storageType}
                            onSelect={() =>
                                handleSetDeviceAssignment(disk, {
                                    product: null,
                                    price: null,
                                    throughput: null,
                                    capacity: null,
                                    iops: null,
                                })
                            }
                            cardProps={{
                                sx: { height: "100%" },
                            }}
                            title={`Choose Your Own`}
                            icon={<img src={ChooseMyOwn} alt={"Choose My Own"} height={100} width={"auto"} />}
                            description={<Typography variant={"caption"}>Choose from other Azure managed disk types</Typography>}
                        />
                    </Grid>
                </Grid>
                {deviceAssignments[disk]?.product?.storageType !== recValue.product.storageType && (
                    <Box pt={2}>
                        <AzureStorageTypeSelectionTable
                            onSelect={handleSetDeviceAssignment}
                            deviceAssignment={deviceAssignments[disk]}
                            deviceInfo={deviceInfo}
                            currentRecommendation={recValue}
                            autoAllocState={autoAllocState}
                            stats={stats}
                        />
                    </Box>
                )}
                <Box display={"flex"} justifyContent={"flex-end"} pt={2}>
                    <Button variant={"contained"} disabled={!deviceAssignments[disk].product || !deviceAssignments[disk].price} onClick={handleClickNext}>
                        {dataLength === index + 1 ? "Finish" : "Next"}
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
});

// ======================
// AzureStorageTypeSelectionTable
// ======================

interface AzureStorageTypeSelectionTableProps {
    onSelect: (device: string, config: AzureCalculatedDiskAssignmentConfig) => void;
    autoAllocState: GmMigrationAutoAllocationState;
    currentRecommendation: AzureManagedDiskRecommendation.AsObject;
    deviceInfo: GalaxyMigrateStorageConfig.Device;
    deviceAssignment: { product: AzureManagedDiskProduct.AsObject; price: number };
    stats: [string, GetAzureManagedDiskRecommendation.Response.Stats.AsObject];
}

export const AzureStorageTypeSelectionTable: React.FC<AzureStorageTypeSelectionTableProps> = observer((p) => {
    const { onSelect, autoAllocState, currentRecommendation, deviceInfo, deviceAssignment, stats } = p;

    useInitData({
        poll: () => autoAllocState.azureProducts.fetchData(),
        pollInterval: 120,
    });

    const cols: ColumnDef<AzureManagedDiskProduct.AsObject>[] = [
        {
            id: "product",
            getter: (d) => d,
            renderer: (d, storageProduct) => {
                return (
                    <AzurePriceTableRow
                        statsMap={stats}
                        product={storageProduct}
                        onSelect={onSelect}
                        deviceAssignment={deviceAssignment}
                        deviceInfo={deviceInfo}
                        autoAllocState={autoAllocState}
                        currentRecommendation={currentRecommendation}
                        selected={deviceAssignment?.product?.storageType === storageProduct.storageType}
                    />
                );
            },
            dataType: KnownDataType.NUMBER,
            label: "",
        },
    ];

    return (
        <>
            {renderServerDataWithLoadingBox(autoAllocState.azureProducts, (data) => {
                return (
                    <Card>
                        <ListSubheader>Select from other Azure disk types below</ListSubheader>
                        <SimpleTable
                            cols={cols}
                            rows={data.productsList.filter(
                                (p: AzureManagedDiskProduct.AsObject) => p.storageType !== currentRecommendation.product.storageType
                            )}
                        />
                    </Card>
                );
            })}
        </>
    );
});

// ======================
// AzurePriceTableContent
// ======================

interface AzurePriceTableContentProps {
    product: AzureManagedDiskProduct.AsObject;
    deviceInfo: GalaxyMigrateStorageConfig.Device;
    autoAllocState: GmMigrationAutoAllocationState;
    currentRecommendation: AzureManagedDiskRecommendation.AsObject;
    statsMap: [string, GetAzureManagedDiskRecommendation.Response.Stats.AsObject];
    selected: boolean;
    deviceAssignment: { product: AzureManagedDiskProduct.AsObject; price: number };
    onSelect: (device: string, config: AzureCalculatedDiskAssignmentConfig) => void;
}

export const AzurePriceTableRow: React.FC<AzurePriceTableContentProps> = observer((p) => {
    const { product, deviceInfo, autoAllocState, statsMap, deviceAssignment, onSelect } = p;
    const [iops, setIops] = useState(getDefaultMinIops(statsMap[1].iops.avg));
    const [throughput, setThroughput] = useState(getDefaultMinThroughput(statsMap[1].throughput.avg));
    const [capacity, setCapacity] = useState(getDiskSizeInGiB(deviceInfo.getBlockDevice().getCapacity()));

    const selected = deviceAssignment?.product?.storageType === product.storageType;
    const capacityError = capacity < getDiskSizeInGiB(deviceInfo.getBlockDevice().getCapacity());

    const getParamsForPricing = (param: number) => {
        if (!getDiskParamsEnabled(product.storageType)) {
            return 1;
        } else {
            return param || 0;
        }
    };

    const queryResult = useQuery({
        queryKey: [`getAzurePrice-${product.name}`, iops, throughput, capacity],
        queryFn: async () => {
            return await autoAllocState.getAzurePrice(
                xbytes.parseSize(`${capacity} GiB`),
                getParamsForPricing(iops),
                getParamsForPricing(throughput),
                autoAllocState.systemID,
                autoAllocState.selectedIntegration.id,
                product.storageType
            );
        },
    });

    useEffect(() => {
        if (queryResult.isSuccess && !queryResult.data?.validationError && !selected) {
            if (!getDiskParamsEnabled(product.storageType)) {
                setIops(queryResult.data?.product?.maxIops);
                setThroughput(queryResult.data?.product?.maxThroughput);
            }
        }
    }, [queryResult, product]);

    useEffect(() => {
        if (selected) {
            onSelect(deviceInfo.getBlockDevice().getDeviceName(), {
                product: product,
                throughput: throughput,
                capacity: capacity,
                iops: iops,
                price: queryResult.data?.pricePerMonth,
            });
        }
    }, [queryResult, product, throughput, capacity, iops, deviceInfo, onSelect, selected]);

    return (
        <Grid container alignItems={"center"}>
            <Grid item xs={3}>
                <Box display={"flex"} justifyContent={"flex-start"}>
                    <FormControlLabel
                        control={
                            <Radio
                                color={"secondary"}
                                checked={selected}
                                onChange={() =>
                                    onSelect(deviceInfo.getBlockDevice().getDeviceName(), {
                                        product: product,
                                        throughput: throughput,
                                        capacity: capacity,
                                        iops: iops,
                                        price: queryResult.data?.pricePerMonth,
                                    })
                                }
                            />
                        }
                        label={`Azure ${product.name}`}
                    />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2} alignItems={"center"} sx={{ textAlign: "left" }}>
                    <Grid item xs={4}>
                        <TextField
                            variant={"filled"}
                            label={"Capacity"}
                            value={capacity}
                            type={"number"}
                            disabled={!p.selected}
                            InputProps={{ endAdornment: <Typography>GiB</Typography> }}
                            error={capacityError}
                            helperText={
                                capacityError
                                    ? `Must be at least ${formatKnownDataType(deviceInfo.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}`
                                    : ""
                            }
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setCapacity(null);
                                } else {
                                    setCapacity(Number(e.target.value));
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        {getDiskParamsEnabled(product.storageType) ? (
                            <TextField
                                variant={"filled"}
                                label={"IOPS"}
                                disabled={!p.selected}
                                value={iops}
                                type={"number"}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setIops(null);
                                    } else {
                                        setIops(Number(e.target.value));
                                    }
                                }}
                            />
                        ) : (
                            <Typography>{`${iops} IOPS`}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {getDiskParamsEnabled(product.storageType) ? (
                            <TextField
                                variant={"filled"}
                                label={"Throughput"}
                                type={"number"}
                                value={throughput}
                                disabled={!p.selected}
                                InputProps={{ endAdornment: <Typography>MB/s</Typography> }}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setThroughput(null);
                                    } else {
                                        setThroughput(Number(e.target.value));
                                    }
                                }}
                            />
                        ) : (
                            <Typography>{`${throughput} MB/s`}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"flex-start"} pt={1} sx={{ textAlign: "left" }}>
                    {queryResult.data?.validationError && (
                        <Typography variant={"caption"} color={"error"}>
                            {queryResult.data?.validationError}
                        </Typography>
                    )}
                </Box>
            </Grid>

            <Grid item xs={3}>
                <QueryResultWrapper
                    queryResult={queryResult}
                    loadingElement={
                        <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                            <CircularProgress />
                        </Box>
                    }
                >
                    <Box>
                        <Typography>{`$${queryResult.data?.pricePerMonth.toFixed(2)} / mo.`}</Typography>
                        <Tooltip
                            title={
                                <>
                                    Max IOPS: {product.maxIops}
                                    <br />
                                    Max Throughput: {formatKnownDataType(product.maxThroughput, KnownDataType.THROUGHPUT)}
                                </>
                            }
                        >
                            <Typography color={"textSecondary"} variant={"caption"} sx={{ textDecoration: "underline" }}>
                                {!!queryResult.data?.pricePerMonth &&
                                    `$${getDiskCostEstimatePerGiB(deviceInfo.getBlockDevice().getCapacity(), queryResult.data?.pricePerMonth)} / GiB per mo.`}
                            </Typography>
                        </Tooltip>
                    </Box>
                </QueryResultWrapper>
            </Grid>
        </Grid>
    );
});

const getDefaultMinIops = (iops: number) => {
    return iops < 120 ? 120 : iops;
};

const getDefaultMinThroughput = (throughputInBytes: number) => {
    const throughputInMb = Math.ceil(throughputInBytes / Math.pow(1000, 2));
    return throughputInMb < 25 ? 25 : throughputInMb;
};

const getInitialDeviceAssignments = (recs: GetAzureManagedDiskRecommendation.Response.AsObject) => {
    const assignmentsObj: { [key: string]: AzureCalculatedDiskAssignmentConfig } = {};
    recs.recommendationsMap.forEach((rec) => {
        assignmentsObj[rec[0]] = {
            product: rec[1].product,
            price: rec[1].pricePerMonth,
            iops: rec[1].iops,
            throughput: rec[1].throughput,
            capacity: getDiskSizeInGiB(rec[1].capacity),
        };
    });
    return assignmentsObj;
};

const getInitialDeviceReviewedState = (deviceList: GalaxyMigrateStorageConfig.Device[]) => {
    const reviewedObj: { [key: string]: boolean } = {};
    deviceList.forEach((device) => {
        reviewedObj[device.getBlockDevice().getDeviceName()] = false;
    });
    return reviewedObj;
};

const getDiskCostEstimatePerGiB = (capacity: number, totalPricePerMonth: number) => {
    const diskSizeInGib = Number(new xbytes.ByteUnitObject(capacity).convertTo("GiB").split(" ")[0]);
    return (totalPricePerMonth / diskSizeInGib).toFixed(2);
};

const getSubtotal = (
    deviceList: GalaxyMigrateStorageConfig.Device[],
    deviceAssignments: { [key: string]: { product: AzureManagedDiskProduct.AsObject; price: number } }
) => {
    let runningTotal = 0;
    for (let device in deviceAssignments) {
        const deviceInfo = deviceList.find((d) => d.getBlockDevice().getDeviceName() === device);
        if (!!deviceAssignments[device]) {
            runningTotal += deviceAssignments[device].price;
        }
    }
    return runningTotal.toFixed(2);
};
