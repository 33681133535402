// ======================
// PrivateEditionLocalNoLicenseWarning
// ======================

import { PrivateEditionView } from "../auth/PrivateEditionView";
import { useGetPrivateEditionLocalStatus } from "./private_edition_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";
import { AppHintID } from "../help/HelpCommon";
import * as React from "react";
import { useNavigateToPeLicenseActivationScreen, useNavigateToPeLicensePendingRequestScreen } from "../management/ProjectManagementCommon";
import { ScreenContainer } from "../layout/ScreenCommon";
import { LICENSE_SUBROUTE, MANAGEMENT_SUBROUTE } from "../app/AppRoutes";

interface PrivateEditionNoLicenseWarningProps {}

export const PrivateEditionLocalNoLicenseWarning: React.FC<PrivateEditionNoLicenseWarningProps> = (p) => {
    const queryResult = useGetPrivateEditionLocalStatus();
    const navToActivate = useNavigateToPeLicenseActivationScreen();

    const isOnActivationPage = window.location.pathname.includes(`${MANAGEMENT_SUBROUTE.LICENSE}/${LICENSE_SUBROUTE.ACTIVATE}`);

    return !isOnActivationPage ? (
        <Box
            sx={{
                minWidth: 600,
                maxWidth: 1920,
                marginLeft: 2,
                marginRight: 2,
            }}
        >
            <QueryResultWrapper queryResult={queryResult}>
                {queryResult.data?.status.licenseActivated ? null : (
                    <Alert
                        variant={"outlined"}
                        severity={"warning"}
                        action={
                            <Button variant={"contained"} color={"secondary"} onClick={navToActivate}>
                                Activate License
                            </Button>
                        }
                        sx={{
                            margin: "16px",
                            "& .MuiAlert-action": {
                                alignItems: "center",
                                paddingBottom: "4px",
                                paddingRight: "4px",
                            },
                            marginBottom: 2,
                        }}
                    >
                        <AlertTitle>{"License Activation Required"}</AlertTitle>
                        {"To get started, please activate your Cirrus Data Cloud Private Edition license."}
                    </Alert>
                )}
            </QueryResultWrapper>
        </Box>
    ) : null;
};
