import * as React from "react";
import { observer } from "mobx-react-lite";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import CdsCloudLogo from "../../../assets/Cirrus_Data_Cloud_Logo_Large_Dark.png";
import { getLicenseModelLabel } from "../../license/LicenseCommon";
import { getHostEnvDisplayName } from "../../galaxymigrate/GalaxyMigrateCommon";
import {
    convertTimestampObjectToDate,
    formatBool,
    formatDurationFromSeconds,
    formatKnownDataType,
    formatTitleCase,
    KnownDataType,
} from "../../../common/utils/formatter";
import { format, formatISO } from "date-fns";
import { getUserFullNameFromObject } from "../../settings/ProjectUsers";
import { getImpactLevelConfig, getStorageConfigBlockDevice, renderGMSessionStatus } from "../../cmcMigration/CmcMigrationCommon";
import { MigrationSession } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { renderChecklistTaskStatusLabel } from "../../cmcMigration/sessionDetails/checklist/GmMigrationCutoverChecklist";
import { CollapsibleHeader, CollapsibleSubheader, ReportFooter, ReportProps } from "../ReportCommon";

export const MigrationSessionReport: React.FC<ReportProps> = (p) => {
    const { reportData, reportInfo } = p;
    const isSourceDeploymentWindows = reportData.msr.sourceSystem?.info.osType === MTDIDeploymentInfo.OSType.WINDOWS;
    const isDestDeploymentWindows = reportData.msr.destSystem?.info.osType === MTDIDeploymentInfo.OSType.WINDOWS;

    return (
        <Box>
            <Box p={4} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h4"}>{`Cirrus Data Cloud`}</Typography>
                    <Typography variant={"h4"}>{`Migration Session Report`}</Typography>
                </Box>
                <Box>
                    <Box>
                        <img src={CdsCloudLogo} width={"200"} height={"auto"} alt={"Cirrus Data Cloud"} />
                    </Box>
                </Box>
            </Box>
            <Box>
                <CollapsibleHeader title={"1. Environment"}>
                    <Stack p={4} spacing={4}>
                        <CollapsibleSubheader title={"1.1 Project"}>
                            {!!reportData.msr.project ? (
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Project:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Project Description:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.description || "N/A"}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`License Model:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getLicenseModelLabel(reportData.msr.project?.info.licenseModel) || "N/A"}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Project ID:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.projectId}
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : (
                                <Typography>{"N/A"}</Typography>
                            )}
                        </CollapsibleSubheader>
                        <CollapsibleSubheader
                            title={`1.2 ${
                                reportData.msr.sourceSystem?.info.deployment.systemId === reportData.msr.destSystem?.info.deployment.systemId
                                    ? "Migration Host"
                                    : "Source Host"
                            }`}
                        >
                            {!!reportData.msr.sourceSystem ? (
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`System Name:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.systemName}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Environment:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getHostEnvDisplayName(reportData.msr.sourceSystem?.info.deployment.hostEnvironment.value)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Timezone:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.systemTimezone}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CPU:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.cpu}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Memory:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(reportData.msr.sourceSystem?.info.memory, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Operating System:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.osClass}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Kernel Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.kernel}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CMC / mTDI Software Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.version} {`/`} {reportData.msr.sourceSystem?.info.mtdiVersion}
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : (
                                <Typography>{"N/A"}</Typography>
                            )}
                        </CollapsibleSubheader>
                        {reportData.msr.sourceSystem?.info.deployment.systemId !== reportData.msr.destSystem?.info.deployment.systemId && (
                            <CollapsibleSubheader title={"1.3 Destination Host"}>
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`System Name:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.deployment.systemName}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Environment:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getHostEnvDisplayName(reportData.msr.destSystem?.info.deployment.hostEnvironment.value)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Timezone:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.deployment.systemTimezone}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CPU:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.cpu}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Memory:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(reportData.msr.destSystem?.info.memory, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Operating System:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.osClass}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Kernel Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.kernel}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CMC / mTDI Software Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.destSystem?.info.deployment.version} {`/`} {reportData.msr.destSystem?.info.mtdiVersion}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CollapsibleSubheader>
                        )}
                    </Stack>
                </CollapsibleHeader>
            </Box>
            <Box>
                <CollapsibleHeader title={"2. Migration Session"}>
                    <Stack p={4} spacing={4}>
                        <CollapsibleSubheader title={"2.1 Session Information"}>
                            {!!reportData.msr.session ? (
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Session ID:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.sessionInfo.sessionId}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Description:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.sessionInfo.description || "N/A"}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Created At:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatISO(convertTimestampObjectToDate(reportData.msr.session?.sessionInfo.createdAt))}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Created By:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getUserFullNameFromObject(reportData.msr.session?.createdBy) || "N/A"}{" "}
                                            {!!reportData.msr.session?.createdBy && `(${reportData.msr.session?.createdBy?.email})`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Total Capacity:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(reportData.msr.session?.totalCapacity, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Total Volumes:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.totalVolumes}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Boot Volume Migration:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatBool(reportData.msr.session?.sessionInfo.bootVolumeSession)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Compute Migration:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!!reportData.msr.session?.sessionInfo.computeMigrationType
                                                ? getHostEnvDisplayName(reportData.msr.session?.sessionInfo.computeMigrationType)
                                                : "No"}
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : (
                                <Typography>{"N/A"}</Typography>
                            )}
                        </CollapsibleSubheader>
                        <CollapsibleSubheader title={"2.2 Session Configuration"}>
                            {!!reportData.msr.session ? (
                                <Stack pt={2} pr={2} pl={2} spacing={2}>
                                    <Box>
                                        <Typography fontWeight={600}>{`Auto Re-Sync`}</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Re-Sync Interval:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {!!reportData.msr.session?.sessionInfo.autoSyncMinutes
                                                    ? `${reportData.msr.session?.sessionInfo.autoSyncMinutes} Minutes`
                                                    : "Disabled"}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {!reportData.msr.session?.sessionInfo.qosSchedule && (
                                        <Box>
                                            <Typography fontWeight={600}>{`iQoS`}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {"iQoS Setting:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {`${getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel).title} – ${
                                                        getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel).description
                                                    }`}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography fontWeight={600}>{`Migration Schedule`}</Typography>
                                        {!!reportData.msr.session?.sessionInfo.qosSchedule ? (
                                            <Grid container>
                                                {Object.keys(reportData.msr.session?.sessionInfo.qosSchedule).map((key) => {
                                                    const schedule =
                                                        reportData.msr.session?.sessionInfo.qosSchedule[
                                                            key as keyof MigrationSession.ConfigParams.QosSchedule.AsObject
                                                        ];
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <Grid item xs={4}>
                                                                {`${formatTitleCase(key)}:`}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {schedule.intervalsList.map((interval, index) => {
                                                                    return (
                                                                        <Typography key={index}>
                                                                            {format(new Date(2000, 1, 1, interval.start / 60, interval.start % 60), "hh:mm aa")}{" "}
                                                                            -{" "}
                                                                            {format(
                                                                                new Date(2000, 1, 1, interval.end / 60, (interval.end % 60) + 1),
                                                                                "hh:mm aa"
                                                                            )}{" "}
                                                                            {getImpactLevelConfig(interval.qosLevel).title}
                                                                        </Typography>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Grid>
                                        ) : (
                                            <Typography>{"Migration schedule not enabled."}</Typography>
                                        )}
                                    </Box>
                                    {!!reportData.msr.session?.sessionInfo.computeMigrationSpec && (
                                        <Box>
                                            <Typography fontWeight={600}>
                                                {`Compute Migration Specification (${getHostEnvDisplayName(
                                                    reportData.msr.session?.sessionInfo.computeMigrationType
                                                )})`}
                                            </Typography>
                                            <Typography>Upon cutover, the new virtual machine is made based on the following specification:</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {"Virtual Machine Name:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmName}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Virtual Machine Description:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmDescription}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Datastore:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.datastore}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"VM Folder:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmFolder || "Default"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Resource Pool:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.resourcePool || "Default"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"CPU Sockets:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.cpuCount}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"CPU Cores per Socket:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.cpuCoresPerSocket}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Memory:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.memoryMib} {"MiB"}
                                                </Grid>
                                                {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.networksList.map((network, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Grid item xs={4}>
                                                                {`Network ${index + 1}`}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography>{`${network.networkName} (${network.adapterType})`}</Typography>
                                                                {!!network.customMacAddress && <Typography>{network.customMacAddress}</Typography>}
                                                            </Grid>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Grid>
                                        </Box>
                                    )}
                                </Stack>
                            ) : (
                                <Typography>{"N/A"}</Typography>
                            )}
                        </CollapsibleSubheader>
                        <CollapsibleSubheader title={"2.3 Session State"}>
                            {!!reportData.msr.session ? (
                                <Stack pt={2} pr={2} pl={2} spacing={2}>
                                    <Box>
                                        <Typography fontWeight={600}>{"Status"}</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Current Status:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {renderGMSessionStatus(reportData.msr.session?.sessionInfo.sessionStatus)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Current iQoS:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel)?.title || "N/A"}
                                            </Grid>
                                            {!!reportData.msr.session?.sessionInfo.errorText && (
                                                <>
                                                    <Grid item xs={4}>
                                                        {"Migration Error:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.errorText}
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={4}>
                                                {"Last Completed Synchronization:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {reportData.msr.session?.sessionInfo.lastSynchronizedTime.seconds === 0
                                                    ? "N/A"
                                                    : formatISO(convertTimestampObjectToDate(reportData.msr.session?.sessionInfo.lastSynchronizedTime))}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Total Sync Time:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatDurationFromSeconds(reportData.msr.session?.sessionInfo.sessionStats.totalSyncTime?.seconds)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>{"Migration Progress"}</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Total Base Data:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalBase, KnownDataType.CAPACITY)}{" "}
                                                {`(${reportData.msr.session?.sessionInfo.sessionStats.totalBase} bytes)`}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Total Changed Data:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalChanged, KnownDataType.CAPACITY)}
                                                {!!reportData.msr.session?.sessionInfo.sessionStats.currentChanged
                                                    ? `(${formatKnownDataType(
                                                          reportData.msr.session?.sessionInfo.sessionStats.currentChanged,
                                                          KnownDataType.CAPACITY
                                                      )} New)`
                                                    : ""}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Total Data Migrated:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalSynced, KnownDataType.CAPACITY)}{" "}
                                                {`(${reportData.msr.session?.sessionInfo.sessionStats.totalSynced} bytes)`}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Remaining Data:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalRemaining, KnownDataType.CAPACITY)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Progress:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {`${reportData.msr.session?.sessionInfo.sessionStats.progressPercentage}%`}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>{"Migration Rate"}</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Current Migration Rate:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.currentThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Current Transfer Rate:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.currentTransferThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Recent Migration Rate (60s):"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.recentThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Recent Transfer Rate (60s):"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.recentTransferThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Average Migration Rate:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.averageThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Average Transfer Rate:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(
                                                    reportData.msr.session?.sessionInfo.sessionStats.averageTransferThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Estimated Time Remaining:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {!!reportData.msr.session?.sessionInfo.sessionStats.currentThroughput
                                                    ? formatDurationFromSeconds(
                                                          Math.round(
                                                              reportData.msr.session.sessionInfo.sessionStats.totalRemaining /
                                                                  reportData.msr.session.sessionInfo.sessionStats.currentThroughput
                                                          )
                                                      )
                                                    : `N/A`}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>{"Data Transfer Reduction"}</Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {"Thin Data Ratio:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.thinPercentage, KnownDataType.PERCENT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Total Thin Data:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalThinData, KnownDataType.CAPACITY)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {"Total Data Transferred:"}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalTransferred, KnownDataType.CAPACITY)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>{"Session Timeline"}</Typography>
                                        {reportData.msr.allEventsList
                                            ?.filter((e) => !e.volumeUuid)
                                            .map((eventItem, index) => {
                                                return (
                                                    <Grid
                                                        container
                                                        key={index}
                                                        pb={1}
                                                        sx={{
                                                            "@media print": {
                                                                width: "auto",
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                float: "none",
                                                                position: "static",
                                                                overflow: "visible",
                                                            },
                                                        }}
                                                    >
                                                        <Grid item xs={4}>
                                                            <Typography>{formatISO(convertTimestampObjectToDate(eventItem.time))}</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>{eventItem.message}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }) || "N/A"}
                                    </Box>
                                </Stack>
                            ) : (
                                <Typography>{"N/A"}</Typography>
                            )}
                        </CollapsibleSubheader>
                    </Stack>
                </CollapsibleHeader>
            </Box>
            <Box>
                <CollapsibleHeader title={"3. Migration Session Volumes"}>
                    <Stack p={4} spacing={4}>
                        {reportData.msr.session?.sessionInfo.sessionVolumesList.map((vol, index) => {
                            const sourceDeviceInfo = getStorageConfigBlockDevice(
                                vol.source.devicePath,
                                reportData.msr.sourceVolumesList,
                                isSourceDeploymentWindows
                            );
                            const destinationDeviceInfo = getStorageConfigBlockDevice(
                                vol.destination.devicePath,
                                reportData.msr.destinationVolumesList,
                                isDestDeploymentWindows
                            );
                            return (
                                <CollapsibleSubheader title={`${index + 1}. ${vol.source.name} –> ${vol.destination.name}`} key={index}>
                                    <Stack p={2} spacing={2}>
                                        <Box>
                                            <Typography fontWeight={600}>{`Source Volume`}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {`Volume:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {vol.source.name}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`Capacity:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.source.capacity, KnownDataType.CAPACITY)} {`(${vol.source.capacity} bytes)`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`Type:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {sourceDeviceInfo?.blockDevice.deviceType || "N/A"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`FS Type:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {sourceDeviceInfo?.blockDevice.fsType || "N/A"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`FS Label:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {sourceDeviceInfo?.blockDevice.label || "N/A"}
                                                </Grid>
                                                {!!sourceDeviceInfo?.blockDevice.mountPoint && (
                                                    <>
                                                        <Grid item xs={4}>
                                                            {`Mount Point:`}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {sourceDeviceInfo?.blockDevice.mountPoint || "N/A"}
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={4}>
                                                    {`Path:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {vol.source.devicePath}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{`Destination Volume`}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {`Volume:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {vol.destination.name}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`Capacity:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.destination.capacity, KnownDataType.CAPACITY)}{" "}
                                                    {`(${vol.destination.capacity} bytes)`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`Type:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {destinationDeviceInfo?.blockDevice.deviceType || "N/A"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`FS Type:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {destinationDeviceInfo?.blockDevice.fsType || "N/A"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`FS Label:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {destinationDeviceInfo?.blockDevice.label || "N/A"}
                                                </Grid>
                                                {!!destinationDeviceInfo?.blockDevice.mountPoint && (
                                                    <>
                                                        <Grid item xs={4}>
                                                            {`Mount Point:`}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {destinationDeviceInfo?.blockDevice.mountPoint || "N/A"}
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={4}>
                                                    {`Path:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {vol.destination.devicePath}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{"Migration Status"}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {`Current Status:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {renderGMSessionStatus(vol.volumeStatus)}
                                                </Grid>
                                                {!!vol.volumeErrorText && (
                                                    <>
                                                        <Grid item xs={4}>
                                                            {"Migration Error:"}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {vol.volumeErrorText}
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={4}>
                                                    {`Last Completed Synchronization:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {!!vol.lastSynchronizedTime ? formatISO(convertTimestampObjectToDate(vol.lastSynchronizedTime)) : "N/A"}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {`Total Sync Time:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatDurationFromSeconds(vol.volumeStats.totalSyncTime.seconds)}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{"Migration Progress"}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {"Total Base Data:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalBase, KnownDataType.CAPACITY)}{" "}
                                                    {`(${vol.volumeStats.totalBase} bytes)`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Total Changed Data:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalChanged, KnownDataType.CAPACITY)}
                                                    {!!vol.volumeStats.currentChanged
                                                        ? `(${formatKnownDataType(vol.volumeStats.currentChanged, KnownDataType.CAPACITY)} New)`
                                                        : ""}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Total Data Migrated:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalSynced, KnownDataType.CAPACITY)}{" "}
                                                    {`(${vol.volumeStats.totalSynced} bytes)`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Remaining Data:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalRemaining, KnownDataType.CAPACITY)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Progress:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {`${vol.volumeStats.progressPercentage}%`}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{"Migration Rate"}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {"Current Migration Rate:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.currentThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Current Transfer Rate:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.currentTransferThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Recent Migration Rate (60s):"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.recentThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Recent Transfer Rate (60s):"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.recentTransferThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Average Migration Rate:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.averageThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Average Transfer Rate:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.averageTransferThroughput, KnownDataType.THROUGHPUT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Estimated Time Remaining:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {!!vol.volumeStats.currentThroughput
                                                        ? formatDurationFromSeconds(
                                                              Math.round(vol.volumeStats.totalRemaining / vol.volumeStats.currentThroughput)
                                                          )
                                                        : `N/A`}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{"Data Transfer Reduction"}</Typography>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {"Thin Data Ratio:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.thinPercentage, KnownDataType.PERCENT)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Total Thin Data:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalThinData, KnownDataType.CAPACITY)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {"Total Data Transferred:"}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {formatKnownDataType(vol.volumeStats.totalTransferred, KnownDataType.CAPACITY)}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={600}>{"Migration Timeline"}</Typography>
                                            {reportData.msr.allEventsList
                                                ?.filter((e) => e.volumeUuid === vol.source.uuid)
                                                .map((eventItem, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            key={index}
                                                            pb={1}
                                                            sx={{
                                                                "@media print": {
                                                                    width: "auto",
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    float: "none",
                                                                    position: "static",
                                                                    overflow: "visible",
                                                                },
                                                            }}
                                                        >
                                                            <Grid item xs={4}>
                                                                <Typography>
                                                                    {formatISO(convertTimestampObjectToDate(eventItem.time), { format: "extended" })}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography>{eventItem.message}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }) || "N/A"}
                                        </Box>
                                    </Stack>
                                </CollapsibleSubheader>
                            );
                        }) || "N/A"}
                    </Stack>
                </CollapsibleHeader>
            </Box>
            {reportData.msr?.checklistInfo?.checklistId && (
                <Box>
                    <CollapsibleHeader title={"4. Cutover Process"}>
                        <Stack p={4} spacing={4}>
                            <CollapsibleSubheader title={"4.1. Selected Cutover Process"}>
                                <Box pt={1} pl={2}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {"Title:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr?.checklistInfo?.checklistDescriptor.title}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Type:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr?.checklistInfo?.checklistDescriptor.type}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Description"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr?.checklistInfo?.checklistDescriptor.description}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Tags:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr?.checklistInfo?.checklistDescriptor.tagsList?.join(", ") || "N/A"}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {"Created At:"}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatISO(convertTimestampObjectToDate(reportData.msr?.checklistInfo?.createdAt))}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CollapsibleSubheader>
                            <CollapsibleSubheader title={"4.2. Tasks"}>
                                <Box pt={1} pl={2}>
                                    {reportData.msr?.checklistInfo?.tasksList?.map((task, index) => {
                                        return (
                                            <Box pb={2}>
                                                <Typography fontWeight={600}>{task.taskDescriptor.title}</Typography>
                                                <Typography>{task.taskDescriptor.subtitle}</Typography>
                                                <Box pt={1}>
                                                    <Typography variant={"caption"}>{task.taskDescriptor.description}</Typography>
                                                </Box>
                                                <Grid container pt={1} pb={2}>
                                                    <Grid item xs={4}>
                                                        {"Status:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {renderChecklistTaskStatusLabel(task.status)}
                                                    </Grid>
                                                    {task.finished && (
                                                        <>
                                                            <Grid item xs={4}>
                                                                {"Finished At:"}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {formatISO(convertTimestampObjectToDate(task.finishedAt))}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                {"Finished By:"}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {getUserFullNameFromObject(task.finishedBy)}
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid item xs={4}>
                                                        {"Related Links:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {task.taskDescriptor.linksList.length > 0
                                                            ? task.taskDescriptor.linksList?.map((link, index) => {
                                                                  return (
                                                                      <span>
                                                                          <Link key={index} href={link.url} target={"_blank"}>
                                                                              {link.title}
                                                                          </Link>{" "}
                                                                          ({link.url})
                                                                      </span>
                                                                  );
                                                              })
                                                            : "N/A"}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {"Notes:"}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {task.notes || "N/A"}
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </CollapsibleSubheader>
                        </Stack>
                    </CollapsibleHeader>
                </Box>
            )}
            <Box></Box>
            <ReportFooter reportInfo={reportInfo} />
        </Box>
    );
};
