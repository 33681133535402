// ======================
// AwsMpConnectAccountScreen
// ======================

import { observer } from "mobx-react-lite";
import { CirrusGradient } from "../app/AppTheme";
import { Grid, SvgIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Logo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";
import { CirrusFooter } from "../layout/Footer";
import React, { useEffect, useState } from "react";
import { SelectableCard } from "../../common/card/SelectableCard";
import { RiUserAddLine } from "react-icons/ri";
import { CgLink } from "react-icons/cg";
import { UserSignUpScreen } from "./UserSignUpScreen";
import { LoginView } from "./LoginScreen";

import { useAppServices } from "../app/services";
import { useNavigate, useParams } from "react-router-dom";
import { useIsDesktop } from "../layout/MainLayout";
import { useAuthState } from "./AuthState";

interface AwsMpConnectAccountScreenProps {}

export const AwsMpConnectAccountScreen: React.FC<AwsMpConnectAccountScreenProps> = observer((p) => {
    const [signUp, setSignUp] = useState(false);
    const [logIn, setLogIn] = useState(false);

    const { awsToken } = useParams();
    const authService = useAuthState();

    useEffect(() => {
        if (!!authService.currentUser && !authService.isAwsAuthenticated) {
            authService.logOut();
        }
    }, [authService.currentUser, authService.isAwsAuthenticated, authService]);

    const onSelectSignUp = () => {
        setLogIn(false);
        setSignUp(true);
    };

    const onSelectLogIn = () => {
        setSignUp(false);
        setLogIn(true);
    };

    const navigateBackToSelectionScreen = () => {
        setLogIn(false);
        setSignUp(false);
    };
    if (signUp) {
        return <UserSignUpScreen loginRedirectFunction={onSelectLogIn} awsToken={awsToken} navigateBackFunction={navigateBackToSelectionScreen} />;
    } else if (logIn) {
        return <LoginView signUpRedirectFunction={onSelectSignUp} awsToken={awsToken} navigateBackFunction={navigateBackToSelectionScreen} />;
    } else {
        return <SelectionScreen onSelectLogIn={onSelectLogIn} onSelectSignUp={onSelectSignUp} />;
    }
});

// ======================
// SelectionScreen
// ======================

interface SelectionScreenProps {
    onSelectSignUp: () => void;
    onSelectLogIn: () => void;
}

export const SelectionScreen: React.FC<SelectionScreenProps> = observer((p) => {
    const isDesktop = useIsDesktop();

    return (
        <Box
            sx={{
                background: CirrusGradient,
                height: isDesktop ? "100vh" : "",
                minHeight: isDesktop ? "" : "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Box height={"100%"} flexGrow={1}>
                <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                        <Grid item xs={8} sm={6} lg={4} xl={3}>
                            <Box sx={{}}>
                                <Box p={3} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <img src={Logo} style={{ maxWidth: "100%", width: "auto" }} alt={"logo"} />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant={"h5"} textAlign={"center"}>
                                        Connect Cirrus Data Cloud Account to AWS Marketplace Subscription
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={3} padding={4}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <SelectableCard
                                selected={false}
                                icon={<RiUserAddLine size={"4em"} />}
                                description={"Create a New Cirrus Data Cloud account."}
                                title={"New User"}
                                disableHoverEffects
                                actions={[
                                    {
                                        id: "create-account",
                                        name: "Create New Account",
                                        action: p.onSelectSignUp,
                                        buttonProps: { variant: "contained", color: "primary" },
                                    },
                                ]}
                                cardProps={{ sx: { height: 520 } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <SelectableCard
                                selected={false}
                                icon={<CgLink size={"4em"} />}
                                description={"Connect to my current Cirrus Data Cloud account."}
                                title={"Existing User"}
                                disableHoverEffects
                                actions={[
                                    {
                                        id: "link-account",
                                        name: "Connect Account",
                                        action: p.onSelectLogIn,
                                        buttonProps: { variant: "contained", color: "primary" },
                                    },
                                ]}
                                cardProps={{ sx: { height: 520 } }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <CirrusFooter />
        </Box>
    );
});
