import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { AutoAlloc, BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import produce from "immer";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import PowerFlexVolumeType = AutoAlloc.VolumeParams.EMCPowerFlex.PowerFlexVolumeType;
import { QuickTipButton } from "../../../help/HelpCommon";

// ======================
// NutanixAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

interface NutanixDiskAssignmentConfig {
    capacity: number;
    volumeGroupName: string;
    nativeVdisk: boolean;
    storageContainerName: string;
}

export const NutanixAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.nutanix;

    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setNutanix(
                new AutoAlloc.VolumeParams.Nutanix()
                    .setVolumeGroupName(defaultParams.volumeGroupName)
                    .setNativeVdisk(defaultParams.nativeVdisk)
                    .setStorageContainerName(defaultParams.storageContainerName)
            );
        }
    });

    const getInitialDeviceAssignments = () => {
        const assignments: { [key: string]: NutanixDiskAssignmentConfig } = {};
        for (let device of state.sourceDevices) {
            const blockDevice = device.source.getBlockDevice();
            assignments[blockDevice.getDeviceName()] = {
                capacity: blockDevice.getCapacity() / Math.pow(1024, 3),
                volumeGroupName: defaultParams.volumeGroupName,
                storageContainerName: defaultParams.storageContainerName,
                nativeVdisk: defaultParams.nativeVdisk,
            };
        }
        return assignments;
    };

    const [deviceConfigs, setDeviceConfigs] = useState<{ [key: string]: NutanixDiskAssignmentConfig }>(getInitialDeviceAssignments());

    const setDeviceCapacity = (deviceName: string, capacity: number) => {
        setDeviceConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[deviceName].capacity = capacity;
                } else {
                    draft[deviceName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setVolumeGroupName = (deviceName: string, volumeGroupName: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].volumeGroupName = volumeGroupName;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getNutanix().setVolumeGroupName(volumeGroupName);
    };

    const setStorageContainerName = (deviceName: string, storageContainerName: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].storageContainerName = storageContainerName;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getNutanix().setStorageContainerName(storageContainerName);
    };

    const setNativeVdisk = (deviceName: string, nativeVdisk: boolean) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].nativeVdisk = nativeVdisk;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getNutanix().setNativeVdisk(nativeVdisk);
    };

    const getHasError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        }
        for (let device in deviceConfigs) {
            const blockDevice = state.sourceDevices.find((d) => d.source.getBlockDevice().getDeviceName() === device);
            if (deviceConfigs[device].capacity < blockDevice?.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <List>
                {state.sourceDevices.map((device) => {
                    const blockDevice = device.source.getBlockDevice();
                    return (
                        <PowerFlexDiskCard
                            state={state}
                            blockDevice={blockDevice}
                            setStorageContainerName={setStorageContainerName}
                            currentStorageContainerName={deviceConfigs[blockDevice.getDeviceName()].storageContainerName}
                            setDeviceCapacity={setDeviceCapacity}
                            currentDeviceCapacity={deviceConfigs[blockDevice.getDeviceName()].capacity}
                            currentVolumeGroupName={deviceConfigs[blockDevice.getDeviceName()].volumeGroupName}
                            setVolumeGroupName={setVolumeGroupName}
                            currentNativeVdisk={deviceConfigs[blockDevice.getDeviceName()].nativeVdisk}
                            setNativeVdisk={setNativeVdisk}
                        />
                    );
                })}
            </List>
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow} disabled={getHasError()}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});

// ======================
// PowerFlexDiskCard
// ======================

interface PowerFlexDiskCardProps {
    state: GmMigrationAutoAllocationState;
    blockDevice: BlockDeviceInfo;
    currentDeviceCapacity: number;
    setDeviceCapacity: (deviceName: string, capacity: number) => void;
    currentStorageContainerName: string;
    setStorageContainerName: (deviceName: string, storageContainerName: string) => void;
    currentVolumeGroupName: string;
    setVolumeGroupName: (deviceName: string, volumeGroupName: string) => void;
    currentNativeVdisk: boolean;
    setNativeVdisk: (deviceName: string, nativeVdisk: boolean) => void;
}

const PowerFlexDiskCard: React.FC<PowerFlexDiskCardProps> = observer((p) => {
    const {
        state,
        blockDevice,
        currentDeviceCapacity,
        setDeviceCapacity,
        currentStorageContainerName,
        setStorageContainerName,
        setVolumeGroupName,
        currentVolumeGroupName,
        currentNativeVdisk,
        setNativeVdisk,
    } = p;

    const defaultHelperText = "Set a custom disk size.";

    const [capacityHelperText, setCapacityHelperText] = useState(defaultHelperText);

    const getCapacityError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        } else {
            return currentDeviceCapacity < blockDevice.getCapacity() / Math.pow(1024, 3);
        }
    };

    return (
        <Card sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <ListItem key={blockDevice.getDeviceName()}>
                        <ListItemIcon>
                            <DiskIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                            secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                        />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2} p={2} justifyContent={"flex-end"}>
                        <Grid item xs={12} lg={6}>
                            <Box>
                                <TextField
                                    fullWidth
                                    label={"Disk Size"}
                                    variant={"filled"}
                                    error={getCapacityError()}
                                    value={currentDeviceCapacity}
                                    type={"number"}
                                    onChange={(e) => {
                                        setDeviceCapacity(blockDevice.getDeviceName(), Number(e.target.value));
                                        if (!state.allowSmallerDestinations && Number(e.target.value) < blockDevice.getCapacity() / Math.pow(1024, 3)) {
                                            setCapacityHelperText("Must be greater than or equal to source volume size.");
                                        } else {
                                            setCapacityHelperText(defaultHelperText);
                                        }
                                    }}
                                    helperText={capacityHelperText}
                                    InputProps={{ endAdornment: <Typography>{`GiB`}</Typography> }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box>
                                <TextField
                                    fullWidth
                                    label={"Storage Container Name"}
                                    variant={"filled"}
                                    value={currentStorageContainerName}
                                    onChange={(e) => {
                                        setStorageContainerName(blockDevice.getDeviceName(), e.target.value);
                                    }}
                                    helperText={"If not specified, disks will be created in same storage container as the virtual machine."}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={currentNativeVdisk}
                                            onChange={(e) => {
                                                setNativeVdisk(blockDevice.getDeviceName(), e.target.checked);
                                                if (e.target.checked) {
                                                    setVolumeGroupName(blockDevice.getDeviceName(), "");
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>{"Native Virtual Disk"}</Typography>
                                            <QuickTipButton
                                                title={
                                                    "If checked, the volume will be created as a disk directly attached to the VM instead of a volume group virtual disk. You will not be able to share these disks with other VMs."
                                                }
                                            />
                                        </Stack>
                                    }
                                />
                                {/*<FormHelperText>*/}
                                {/*    {'If checked, the volume will be created as a disk directly attached to the VM instead of a volume group virtual disk. You will not be able to share these disks with other VMs.'}*/}
                                {/*</FormHelperText>*/}
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box>
                                <TextField
                                    fullWidth
                                    label={"Volume Group Name"}
                                    variant={"filled"}
                                    value={currentVolumeGroupName}
                                    disabled={currentNativeVdisk === true}
                                    InputProps={{
                                        endAdornment: (
                                            <QuickTipButton
                                                title={
                                                    "Only applicable if Native Virtual Disk is unchecked. If not specified or if the group does not exist, a new volume group will be created for the disk. If an existing group with the same name already exists, and this vm already has access to the vg, it will be reused."
                                                }
                                            />
                                        ),
                                    }}
                                    onChange={(e) => {
                                        setVolumeGroupName(blockDevice.getDeviceName(), e.target.value);
                                    }}
                                    // helperText={"Only applicable if Native Virtual Disk is unchecked. If not specified or if the group does not exist, a new volume group will be created for the disk. If an existing group with the same name already exists, and this vm already has access to the vg, it will be reused."}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
});
