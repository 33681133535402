// ======================
// MigrateOpsTaskLog
// ======================

import { CustomOperationLogLine, useListOperationLogLines } from "../migrate_ops_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { DarkFlatOutlinedCard } from "../../../common/card/DarkCard";
import { alpha, Box, Button, Card, CardContent, Dialog, DialogContent, Grid, Link, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { convertTimestampObjectToDate, formatKnownDataType, formatSnakeCaseToPascalCase, KnownDataType } from "../../../common/utils/formatter";
import { MarkdownText } from "../../../common/text/MarkdownText";
import { OperationLogLineLevel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_log_line_level_pb";
import React, { useMemo } from "react";
import { FcCheckmark } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import { useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { TooltipTimeText } from "../../../common/text/TooltipTimeText";
import { viewReport } from "../../reports/ReportHelpers";

interface MigrateOpsTaskLogProps {
    taskId?: number;
    opId: number;
    queryInterval?: number;
}

export const MigrateOpsTaskLog: React.FC<MigrateOpsTaskLogProps> = (p) => {
    const { taskId, opId, queryInterval } = p;
    const queryResult = useListOperationLogLines(opId, taskId, queryInterval);
    const theme = useTheme();
    const systemMessageColor = theme.palette.grey[400];

    const groupedMessages = useMemo(() => {
        return getMessagesGroupedByRelativeTime(queryResult.data?.itemsList || []);
    }, [queryResult.data?.itemsList]);

    return (
        <QueryResultWrapper queryResult={queryResult}>
            <CardContent>
                {Object.keys(groupedMessages).map((m) => {
                    const timestamp = m;
                    const messages = groupedMessages[m];
                    return (
                        <Box key={timestamp} width={"100%"}>
                            <Box display={"flex"} justifyContent={"center"} pb={2}>
                                <Typography variant={"subtitle2"} color={"textSecondary"}>
                                    {m}
                                </Typography>
                            </Box>
                            {messages.map((line) => {
                                return <LogLineCard line={line} key={`${line.timestamp}${line.message}`} />;
                            })}
                        </Box>
                    );
                })}
            </CardContent>
        </QueryResultWrapper>
    );
};

// ======================
// LogLineCard
// ======================

interface LogLineCardProps {
    line: CustomOperationLogLine;
}

const LogLineCard: React.FC<LogLineCardProps> = (p) => {
    const { line } = p;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    const timeStamp = <TooltipTimeText time={line.timestamp} dateFormat={"h:mm aaaa"} variant={"caption"} color={"textSecondary"} />;

    const plainSystemMessage = (
        <Grid container spacing={isMobile ? 0 : 4} alignItems={"center"} pb={isMobile ? 2 : 1} pt={isMobile ? 0 : 1}>
            <Grid item xs={12} lg={2} xl={1}>
                <Box sx={{ paddingBottom: "2px" }}>{timeStamp}</Box>
            </Grid>

            <Grid item xs={12} lg={10} xl={11}>
                <Typography color={"textSecondary"}>
                    <MarkdownText markdown={line.message} />
                </Typography>
            </Grid>
        </Grid>
    );

    if (getIsPlainSystemMessage(line)) {
        return plainSystemMessage;
    }

    const messageSection = (
        <Box sx={getLogMessageStyle(line, theme)}>
            <MarkdownText markdown={line.message} />
        </Box>
    );

    const metadataSection = !!line.data ? (
        <Grid container spacing={2} pt={1}>
            {Object.keys(line.data)
                .filter((key) => !shouldHideKeyValueChip(key, line.data[key]))
                .map((key) => {
                    const value = line.data[key];
                    return (
                        <Grid item key={key}>
                            <KeyValueChip value={value} keyString={key} />
                        </Grid>
                    );
                })}
        </Grid>
    ) : null;

    const specialActionsSection = !!line.data ? (
        <Grid container spacing={2} pt={2}>
            {Object.keys(line.data)
                .filter((key) => key.includes("cdc"))
                .map((key) => {
                    const value = line.data[key];
                    return (
                        <Grid item key={key}>
                            <SpecialActionDisplay value={value} knownMessageKey={key as KnownMessageKey} />
                        </Grid>
                    );
                })}
        </Grid>
    ) : null;

    return (
        <Grid container spacing={isMobile ? 0 : 4} alignItems={"center"}>
            <Grid item xs={12} lg={2} xl={1}>
                <Box pb={1}>{timeStamp}</Box>
            </Grid>
            <Grid item xs={12} lg={10} xl={11}>
                <Box pb={isMobile ? 2 : 1} pt={isMobile ? 0 : 1} width={"fit-content"}>
                    <Card
                        elevation={0}
                        key={line.message}
                        sx={{
                            backgroundColor: getLogMessageBackgroundColor(line, theme),
                            ...getLogMessageBorderStyle(line, theme),
                        }}
                    >
                        <Box p={2}>
                            {messageSection}
                            {metadataSection}
                            {specialActionsSection}
                        </Box>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

const getMessagesGroupedByRelativeTime = (messages: CustomOperationLogLine[]) => {
    const groupedMessages: { [key: string]: CustomOperationLogLine[] } = {};
    messages.forEach((message) => {
        const timestamp = convertTimestampObjectToDate(message.timestamp);
        const key = formatKnownDataType(timestamp, KnownDataType.DATE_RELATIVE);
        if (!groupedMessages[key]) {
            groupedMessages[key] = [];
        }
        groupedMessages[key].push(message);
    });
    return groupedMessages;
};

const getIsPlainSystemMessage = (message: CustomOperationLogLine) => {
    const excludedLevels = [
        OperationLogLineLevel.OperationLogLineLevel.SUCCESS,
        OperationLogLineLevel.OperationLogLineLevel.WARN,
        OperationLogLineLevel.OperationLogLineLevel.ERROR,
    ];
    return !excludedLevels.includes(message.level) && message.systemMessage;
};

const getLogMessageBackgroundColor = (message: CustomOperationLogLine, theme: Theme) => {
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.ERROR) {
        return alpha(theme.palette.error.dark, 0.8);
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.WARN) {
        return alpha(theme.palette.warning.dark, 0.8);
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.SUCCESS) {
        return alpha(theme.palette.success.dark, 0.8);
    }
    return "#43475E";
};

const getLogMessageStyle = (message: CustomOperationLogLine, theme: Theme) => {
    const boldFont = {
        fontWeight: 600,
    };
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.ERROR) {
        return {
            //color: 'white',
            ...boldFont,
        };
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.WARN) {
        return {
            //color: theme.palette.warning.light,
            ...boldFont,
        };
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.SUCCESS) {
        return {
            //color: theme.palette.success.light,
            ...boldFont,
        };
    }
    return {};
};

const getLogMessageBorderStyle = (message: CustomOperationLogLine, theme: Theme) => {
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.ERROR) {
        return {
            borderLeft: `5px solid ${theme.palette.error.main}`,
        };
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.WARN) {
        return {
            borderLeft: `5px solid ${theme.palette.warning.main}`,
        };
    }
    if (message.level === OperationLogLineLevel.OperationLogLineLevel.SUCCESS) {
        return {
            borderLeft: `5px solid ${theme.palette.success.main}`,
        };
    }
    return {};
};

// ======================
// KeyValueChip
// ======================

interface KeyValueChipProps {
    keyString: string;
    value: any;
}

export const KeyValueChip: React.FC<KeyValueChipProps> = (p) => {
    const { keyString, value } = p;
    const keyName = formatSnakeCaseToPascalCase(keyString, true);
    const theme = useTheme();
    const detailsDialogState = useDialogState();
    const isDialogFullScreen = useShouldDialogFullScreen();
    const formatValue = (value: any) => {
        if (typeof value === "object") {
            return <Link onClick={() => detailsDialogState.open()}>View Details</Link>;
        }
        if (typeof value === "string" || typeof value === "number") {
            return <Typography>{value}</Typography>;
        }
        if (typeof value === "boolean") {
            return value ? <FcCheckmark size={18} /> : <MdClose color={theme.palette.error.main} size={18} />;
        }
    };

    return (
        <>
            <Card>
                <Box display={"flex"}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.cirrus.main,
                            height: "100%",
                            color: "white",
                        }}
                    >
                        <Box p={1} pl={2} pr={2}>
                            <Typography fontWeight={600}>{keyName}</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.cirrus.light,
                            height: "100%",
                        }}
                    >
                        <Box p={1} pr={2} pl={2}>
                            {formatValue(value)}
                        </Box>
                    </Box>
                </Box>
            </Card>
            {detailsDialogState.isOpen && (
                <Dialog open={detailsDialogState.isOpen} onClose={detailsDialogState.close} maxWidth={"md"} fullWidth fullScreen={isDialogFullScreen}>
                    <DialogTopBar dialogState={detailsDialogState} title={keyName} />
                    <DialogContent>
                        <DarkFlatOutlinedCard>
                            <CardContent>
                                <pre>{JSON.stringify(value, null, 2)}</pre>
                            </CardContent>
                        </DarkFlatOutlinedCard>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

const shouldHideKeyValueChip = (key: string, value: any) => {
    if (key.includes("cdc")) {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (typeof value === "object") {
        return Object.keys(value).length === 0;
    }
    return false;
};

// ======================
// SpecialActionDisplay
// ======================

interface SpecialActionDisplayProps {
    knownMessageKey: KnownMessageKey;
    value: any;
}

export const SpecialActionDisplay: React.FC<SpecialActionDisplayProps> = (p) => {
    const { knownMessageKey, value } = p;

    if (knownMessageKey === KnownMessageKey.REPORT_ID) {
        return (
            <Button variant={"outlined"} color={"neutral"} onClick={() => viewReport(value)}>
                {"View Report"}
            </Button>
        );
    }
    return null;
};

enum KnownMessageKey {
    REPORT_ID = "_cdc_report_id",
}
