import React, { useEffect, useState } from "react";
import { useStepperContext } from "../../../../common/stepper/StepperState";
import { SlideAndFadeInStep, StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    LinearProgress,
    Radio,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { LocalDataMigrationWizardStepId } from "./MigrateOpsLocalDataMigrationWizard";
import { DeploymentQueryKeys } from "../../../../common/QueryKeys";
import { QueryTable } from "../../../../common/table/QueryTable";
import { getResourceUsageColor, HostInfoDisplay } from "../../../galaxymigrate/GalaxyMigrateDeploymentsList";
import { BlackTooltip } from "../../../../common/tooltip/ColorTooltip";
import { parseCpuCountsFromCpuString } from "../../../deployment/DeploymentCommon";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { SwitchCard } from "../../../../common/card/SwitchCard";
import { renderBootVolumeChip, renderChipInfo } from "../../../../common/chip/CommonChips";
import { getGmStorageConfigDeviceType } from "../../../galaxymigrate/GalaxyMigrateCommon";
import { VolumeCapacityBadge } from "../../../cmcMigration/wizard/GmMigrationWizardVolumeSelectionStep";
import { getModuleConfigByModule, IntegrationCard } from "../../../integrations/IntegrationsCommon";
import { SelectableCard } from "../../../../common/card/SelectableCard";
import { renderAutoAllocParametersOptions } from "./AutoAllocationParameters";
import ProtoDoc from "../../../../assets/migrateOps/migrateops-proto.json";
import { getOperationConfigJson } from "../../MigrateOpsGeneratedProtobufJsonHelpers";
import { useMigrateOpsLocalDataMigrationWizardState } from "./MigrateOpsLocalDataMigrationWizardState";
import { useGetEnabledIntegrationDetails, useListIntegrationModules, useListProjectIntegrations } from "../../../integrations/integration_hooks";
import { useIsDesktop } from "../../../layout/MainLayout";
import { IntegrationDefinition } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { useCurrentProjectID } from "../../../project/CurrentProjectState";
import { getSyncQosImpactLevelLabel, useLinearProgressStyle } from "../../../cmcMigration/CmcMigrationCommon";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { useListGalaxyMigrateDeployments } from "../../../deployment/deployment_hooks";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { useGetDeploymentStorageConfig } from "../../../galaxymigrate/gm_deployment_hooks";
import { ViewCurrentYamlButton } from "../MigrateOpsWizardCommon";
import { AutoAlloc, SyncQos } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { OutlinedCard } from "../../../../common/card/OutlinedCard";
import { Theme } from "@mui/material/styles";
import { QuickTipButton } from "../../../help/HelpCommon";
import EnableAutoResync from "../../../../assets/migrationSession/enable_auto_resync.png";
import DisableAutoResync from "../../../../assets/migrationSession/disable_auto_resync.png";
import { FormSelectableCardConfig, FormTextField } from "../../../../common/form/FormComponents";
import MinimumMode from "../../../../assets/migrationSession/minimum.png";
import ModerateMode from "../../../../assets/migrationSession/moderate.png";
import AggressiveMode from "../../../../assets/migrationSession/aggressive.png";
import RelentlessMode from "../../../../assets/migrationSession/relentless.png";
import { AddHostActions } from "./HostActions";

interface MigrateOpsLocalDataMigrationWizardStepProps {
    setCurrentStep: (step: number) => void;
}

// ======================
// GetStartedStep
// ======================

export const GetStartedStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.GET_STARTED;

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"}>{"Let's get started."}</Typography>
                    <Typography variant={"body1"}>
                        {"In just a few questions, we'll be able to set up the basic configuration to create your local data migration operation."}
                    </Typography>
                </Stack>
                <Box>
                    <StepperNavButtons
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.SOURCE_SYSTEM));
                            },
                        }}
                    />
                </Box>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// ChooseSourceHostStep
// ======================

export const ChooseSourceHostStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.SOURCE_SYSTEM;
    const operationJson = useMigrateOpsLocalDataMigrationWizardState((s) => s.operationJson);
    const sourceSystem = operationJson.config.system_id;

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"}>{"Choose your source system."}</Typography>
                </Stack>
                <SelectSystemTable />
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.GET_STARTED));
                            },
                        }}
                        nextButtonProps={{
                            disabled: !sourceSystem,
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.SOURCE_VOLUMES));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// SelectSystemsTable
// ======================

interface SelectSystemTableProps {}

export const SelectSystemTable: React.FC<SelectSystemTableProps> = (p) => {
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson, setOperationJsonField } = wizardState;
    const systemId = operationJson.config.system_id;
    const setSystemId = (systemId: string) => {
        setOperationJsonField(["config", "system_id"], systemId);
    };

    const projectId = useCurrentProjectID();
    const progressStyle = useLinearProgressStyle(true);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const onlineSystems = useListGalaxyMigrateDeployments(projectId, true, pageIndex, pageSize);

    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();

    const cols = [
        columnHelper.display({
            id: "select",
            cell: (props) => {
                const selected = systemId === props.row.original.deployment.systemId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSystemId(props.row.original.deployment.systemId);
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "host",
            header: "Host",
            cell: (props) => {
                const deploymentInfo = props.row.original;
                return <HostInfoDisplay deploymentInfo={deploymentInfo} />;
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "resource",
            header: "Resource Usage",
            meta: {
                style: {
                    textAlign: "center",
                },
            },
            cell: (props) => {
                const deploymentInfo = props.row.original;
                const hasCpuPercentage = !!deploymentInfo.deployment.systemUptime?.seconds;

                const memoryUsagePercentage = (deploymentInfo.memoryUsed / deploymentInfo.memory) * 100;
                return (
                    <Stack
                        minWidth={250}
                        alignItems={"flexStart"}
                        sx={{
                            opacity: deploymentInfo.deployment.connected ? 1 : 0.3,
                        }}
                    >
                        {hasCpuPercentage ? (
                            <BlackTooltip
                                title={
                                    <Box>
                                        <Typography variant={"caption"} fontWeight={600}>
                                            {deploymentInfo.cpu}
                                        </Typography>
                                        <br />
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`CPU Model`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <Stack direction={"row"} spacing={1} alignItems={"flex-end"}>
                                    <Typography variant={"caption"}>{"CPU "}&nbsp;</Typography>
                                    <Stack>
                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {parseCpuCountsFromCpuString(deploymentInfo.cpu).cores} {"Cores"}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}.main`}>
                                                {formatKnownDataType(deploymentInfo.cpuUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={deploymentInfo.cpuUsagePercentage}
                                            color={getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}
                                        />
                                    </Stack>
                                </Stack>
                            </BlackTooltip>
                        ) : null}
                        {deploymentInfo.memoryUsed >= 0 && (
                            <Box pt={1}>
                                <Stack alignItems={"flex-start"} direction={"row"} spacing={1}>
                                    <Typography variant={"caption"}>{"Mem."}</Typography>
                                    <Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={memoryUsagePercentage}
                                            color={getResourceUsageColor(memoryUsagePercentage)}
                                        />
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {`${formatKnownDataType(deploymentInfo.memoryUsed, KnownDataType.CAPACITY)}/${formatKnownDataType(
                                                    deploymentInfo.memory,
                                                    KnownDataType.CAPACITY
                                                )}`}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(memoryUsagePercentage)}.main`}>
                                                {formatKnownDataType(memoryUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                );
            },
        }),
    ];

    return (
        <Box pt={0} width={"100%"}>
            <QueryTable
                data={onlineSystems.data?.itemsList}
                columns={cols}
                emptyTableConfig={{}}
                error={onlineSystems.error}
                isLoading={onlineSystems.isLoading}
                pageCount={onlineSystems.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                refetch={onlineSystems.refetch}
                queryKey={DeploymentQueryKeys.listGmDeployments}
            />
        </Box>
    );
};

// ======================
// ChooseSourceVolumesStep
// ======================

export const ChooseSourceVolumesStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson } = wizardState;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.SOURCE_VOLUMES;
    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={2}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"}>{"Select your source volumes."}</Typography>
                </Stack>
                <SelectVolumesTable />
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.SOURCE_SYSTEM));
                            },
                        }}
                        nextButtonProps={{
                            disabled: wizardState.operationJson.config.migrateops_local_data_migration.source.migrate_all_volumes === undefined,
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.INTEGRATION));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// SelectVolumesTable
// ======================

interface SelectVolumesTableProps {}

export const SelectVolumesTable: React.FC<SelectVolumesTableProps> = (p) => {
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const systemId = wizardState.operationJson.config.system_id;
    const { setOperationJsonField, operationJson } = wizardState;
    const storageConfig = useGetDeploymentStorageConfig(systemId);

    const getInitialVolumeState = () => {
        if (operationJson.config.migrateops_local_data_migration.source.migrate_all_volumes) {
            return storageConfig.data?.devicesList.map((d) => d.blockDevice.deviceName);
        } else {
            return operationJson.config.migrateops_local_data_migration.source.source_volume_names;
        }
    };

    const [selectedVolumes, setSelectedVolumes] = useState(getInitialVolumeState());

    const handleToggleVolume = (volName: string) => {
        if (selectedVolumes.includes(volName)) {
            const newArray = selectedVolumes.filter((v) => v !== volName);
            setSelectedVolumes(newArray);
            setOperationJsonField(["config", "migrateops_local_data_migration", "source", "source_volume_names"], newArray);
            if (newArray.length === 0) {
                setOperationJsonField(["config", "migrateops_local_data_migration", "source", "migrate_all_volumes"], undefined);
            }
        } else {
            setSelectedVolumes([...selectedVolumes, volName]);
            if (operationJson.config.migrateops_local_data_migration.source.migrate_all_volumes === undefined) {
                setOperationJsonField(["config", "migrateops_local_data_migration", "source", "migrate_all_volumes"], false);
            }
            setOperationJsonField(["config", "migrateops_local_data_migration", "source", "source_volume_names"], [...selectedVolumes, volName]);
        }
    };

    const handleSelectAllVolumes = () => {
        if (selectedVolumes.length === storageConfig.data?.devicesList.length) {
            setSelectedVolumes([]);
            setOperationJsonField(["config", "migrateops_local_data_migration", "source", "source_volume_names"], []);
        } else {
            setSelectedVolumes(storageConfig.data?.devicesList.map((d) => d.blockDevice.deviceName) || []);
            setOperationJsonField(["config", "migrateops_local_data_migration", "source", "source_volume_names"], []);
            setOperationJsonField(["config", "migrateops_local_data_migration", "source", "migrate_all_volumes"], true);
        }
    };

    return (
        <Box>
            <QueryResultWrapper queryResult={storageConfig}>
                <Box>
                    <Box p={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color={"secondary"}
                                    checked={selectedVolumes.length === storageConfig.data?.devicesList.length}
                                    onClick={handleSelectAllVolumes}
                                />
                            }
                            label={selectedVolumes.length === storageConfig.data?.devicesList.length ? "Unselect All" : "Select All"}
                        />
                    </Box>
                    {storageConfig.data?.devicesList.map((d) => {
                        const device = d.blockDevice;
                        return (
                            <SwitchCard
                                cardProps={{
                                    sx: {
                                        backgroundColor: (t: Theme) => t.palette.cirrus.light,
                                        border: "2px solid",
                                        borderColor: (t: Theme) =>
                                            selectedVolumes.includes(device.deviceName) ? t.palette.primary.main : t.palette.cirrus.light,
                                        marginBottom: 2,
                                    },
                                }}
                                selected={selectedVolumes.includes(device.deviceName)}
                                onSelect={() => handleToggleVolume(device.deviceName)}
                                cardContent={
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={4}>
                                            <Box display={"flex"} justifyContent={"flex-start"}>
                                                <Box textAlign={"left"}>
                                                    <Box pb={1} display={"flex"} alignItems={"center"}>
                                                        <Box pr={2}>
                                                            <Typography variant={"body1"}>{device.deviceName}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <VolumeCapacityBadge capacity={device.capacity} />
                                                        </Box>
                                                    </Box>
                                                    <Grid container spacing={1}>
                                                        {renderChipInfo(getGmStorageConfigDeviceType(d))}
                                                        {renderChipInfo(device.fsType)}
                                                        {renderChipInfo(device.label)}
                                                        {renderChipInfo(device.mountPoint)}
                                                        {renderBootVolumeChip(device.boot)}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        );
                    })}
                </Box>
            </QueryResultWrapper>
        </Box>
    );
};

// ======================
// AutoAllocationIntegrationStep
// ======================

export const AutoAllocationIntegrationStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.INTEGRATION;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const projectIntegrations = useListProjectIntegrations();
    const integrationDefs = useListIntegrationModules();
    const isDesktop = useIsDesktop();

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"} textAlign={"center"}>
                        {"Do you want to auto-allocate volumes?"}
                    </Typography>
                    <Typography>
                        {
                            "If you wish to auto-allocate your source volumes, you can choose the integration you would like to use below. If not, you can skip this step."
                        }
                    </Typography>
                </Stack>
                <QueryResultWrapper queryResult={projectIntegrations}>
                    <QueryResultWrapper queryResult={integrationDefs}>
                        <Box>
                            <Grid container spacing={2}>
                                {projectIntegrations.data?.itemsList.map((i, index) => {
                                    const moduleConfig = getModuleConfigByModule(i.module, integrationDefs.data?.itemsList);
                                    const ModuleIcon = moduleConfig.icon;
                                    const actionButton = (
                                        <Button
                                            onClick={() => {
                                                wizardState.setOperationJsonField(["config", "integration_id"], i.id);
                                                wizardState.setOperationJsonField(
                                                    ["config", "migrateops_local_data_migration", "destination", "auto_allocate_volumes"],
                                                    true
                                                );
                                                setSlideDirection("down");
                                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.AUTO_ALLOC_PARAMS));
                                            }}
                                            variant={"contained"}
                                            color={"primary"}
                                            disableElevation={true}
                                        >
                                            {"Select Integration"}
                                        </Button>
                                    );
                                    return (
                                        <Grid item xs={12}>
                                            <Card elevation={0}>
                                                <Stack p={2} direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                        <ModuleIcon size={"small"} />
                                                        <Box>
                                                            <Typography variant={"subtitle2"} color={"textSecondary"}>{`id: #${i.id}`}</Typography>
                                                            <Typography>{i.friendlyName}</Typography>
                                                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                                                {moduleConfig?.moduleDefinition?.name}
                                                            </Typography>
                                                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                                                {moduleConfig?.moduleDefinition?.description}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Box>{actionButton}</Box>
                                                </Stack>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </QueryResultWrapper>
                </QueryResultWrapper>
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.SOURCE_VOLUMES));
                            },
                        }}
                        nextButtonProps={{
                            label: "Skip",
                            onClick: () => {
                                wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "auto_allocate_volumes"], false);
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.MIGRATION_PARAMS));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={wizardState.operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// AutoAllocationParamsStep
// ======================

export const AutoAllocationParamsStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.AUTO_ALLOC_PARAMS;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const integrationDetails = useGetEnabledIntegrationDetails(22);
    const integrationDefs = useListIntegrationModules();
    const isDesktop = useIsDesktop();
    const [autoPrepareSupported, setAutoPrepareSupported] = useState<boolean>(undefined);

    useEffect(() => {
        const refetch = async () => {
            integrationDetails.refetch();
        };
        refetch();
    }, [wizardState.operationJson.config.integration_id]);

    useEffect(() => {
        const integrationDef = integrationDefs.data?.itemsList.find((i) => i.module === integrationDetails.data?.info.module);
        if (!!integrationDef) {
            setAutoPrepareSupported(
                integrationDef?.capabilitiesList.map((c) => c.capability).includes(IntegrationDefinition.CapabilityDefinition.Capability.PREPARE)
            );
        }
    }, [integrationDefs.data, integrationDetails.data?.info.module]);

    let currSectionNumber = 0;
    const makeSectionTitle = (title: React.ReactNode) => {
        currSectionNumber += 1;
        return (
            <Typography variant={"h5"}>
                {currSectionNumber}. {title} &nbsp;
            </Typography>
        );
    };

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"} textAlign={"center"}>
                        {"Set your auto-allocation options."}
                    </Typography>
                </Stack>
                {autoPrepareSupported && (
                    <Box>
                        {makeSectionTitle(`Host Auto-Preparation`)}
                        <Grid pt={2} container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SelectableCard
                                    selected={wizardState.operationJson.config.migrateops_local_data_migration.destination.auto_prepare_host}
                                    onSelect={() => {
                                        wizardState.setOperationJsonField(
                                            ["config", "migrateops_local_data_migration", "destination", "auto_prepare_host"],
                                            true
                                        );
                                    }}
                                    cardProps={{
                                        sx: {
                                            height: "100%",
                                        },
                                    }}
                                    title={"Auto-Prepare Host"}
                                    description={`Automatically configure your host according to the storage vendors' default deployment configuration. Additional packages may be installed on your host.`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectableCard
                                    selected={wizardState.operationJson.config.migrateops_local_data_migration.destination.auto_prepare_host === false}
                                    onSelect={() => {
                                        wizardState.setOperationJsonField(
                                            ["config", "migrateops_local_data_migration", "destination", "auto_prepare_host"],
                                            false
                                        );
                                    }}
                                    cardProps={{
                                        sx: {
                                            height: "100%",
                                        },
                                    }}
                                    title={"Skip Auto-Prepare"}
                                    description={`[Placeholder text]`}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <QueryResultWrapper queryResult={integrationDetails}>
                    <QueryResultWrapper queryResult={integrationDefs}>
                        <Box>
                            <Box pb={2}>{makeSectionTitle(`Default Volume Parameters`)}</Box>
                            {renderAutoAllocParametersOptions(integrationDetails.data?.info.module, integrationDetails.data?.info.defaultVolumeParams)}
                            <Box pt={2}>
                                <Alert severity={"info"}>
                                    <AlertTitle>{"Volume Mappings"}</AlertTitle>
                                    {
                                        "If you would like to configure auto-allocation parameters for individual volumes, you can refer to the MigrateOps Reference to see how to do so by editing the final generated YAML."
                                    }
                                </Alert>
                            </Box>
                        </Box>
                    </QueryResultWrapper>
                </QueryResultWrapper>
                <Box>
                    <Box pb={2}>
                        {makeSectionTitle(`Before Auto-Allocation Actions`)}
                        <Typography color={"textSecondary"}>{"Select any actions to run before auto-allocation."}</Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "hooks", "before_destination_auto_allocation"]} />
                </Box>
                <Box>
                    <Box pb={2}>
                        {makeSectionTitle(`After Auto-Allocation Actions`)}
                        <Typography color={"textSecondary"}>{"Select any actions to run after auto-allocation."}</Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "hooks", "after_destination_auto_allocation"]} />
                </Box>
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.INTEGRATION));
                            },
                        }}
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.MIGRATION_PARAMS));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={wizardState.operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// MigrationParametersStep
// ======================

export const MigrationParametersStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.MIGRATION_PARAMS;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson, setOperationJsonField } = wizardState;

    const [autoResyncSeconds, setAutoResyncSeconds] = useState(1440);
    const impactLevelOptions = [
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MINIMUM),
            description: "Application will have absolute priority",
            icon: <img src={MinimumMode} height={72} alt={"Minimum Mode"} />,
            value: `MINIMUM`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MODERATE),
            description: "Migration will compete with Application for up to 30%",
            icon: <img src={ModerateMode} height={72} alt={"Moderate Mode"} />,
            value: `MODERATE`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.AGGRESSIVE),
            description: "Migration will compete with Application for up to 75%",
            icon: <img src={AggressiveMode} height={72} alt={"Aggressive Mode"} />,
            value: `AGGRESSIVE`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.RELENTLESS),
            description: "Migrate as fast as possible",
            icon: <img src={RelentlessMode} height={72} alt={"Relentless Mode"} />,
            value: `RELENTLESS`,
        },
    ];

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Typography variant={"h2"} textAlign={"center"}>
                    {"Set your migration parameters."}
                </Typography>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"1. Session Description"}</Typography>
                    </Box>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        label={"Session Description"}
                        value={operationJson.config.migrateops_local_data_migration.migration.session_description}
                        onChange={(e) => {
                            setOperationJsonField(["config", "migrateops_local_data_migration", "migration", "session_description"], e.target.value);
                        }}
                    />
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"2. Automatic Re-Synchronization"}</Typography>
                        <Typography color={"textSecondary"}>{"Periodically trigger synchronization after initial migration"}</Typography>
                    </Box>
                    <TextField
                        fullWidth
                        helperText={`All volumes will be synchronized every ${(autoResyncSeconds / 60).toFixed(2)} hour(s).`}
                        type={"number"}
                        value={autoResyncSeconds}
                        label={"Auto Re-sync Interval"}
                        variant={"filled"}
                        onChange={(e) => {
                            setAutoResyncSeconds(Number(e.target.value));
                            setOperationJsonField(["config", "migrateops_local_data_migration", "migration", "auto_resync_interval"], `${e.target.value}s`);
                        }}
                        InputProps={{
                            endAdornment: <Box pl={1}>{`Seconds`}</Box>,
                        }}
                    />
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"3. iQoS Level"}</Typography>
                        <Typography color={"textSecondary"}>{"Intelligently scale migration activity to minimize impact to production workload"}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {impactLevelOptions.map((o) => {
                            return (
                                <Grid item xs={12} sm={6} lg={3}>
                                    <SelectableCard
                                        onSelect={() => {
                                            setOperationJsonField(["config", "migrateops_local_data_migration", "migration", "qos_level"], o.value);
                                        }}
                                        selected={operationJson.config.migrateops_local_data_migration.migration.qos_level === o.value}
                                        title={o.title}
                                        description={o.description}
                                        icon={o.icon}
                                        cardProps={{
                                            sx: {
                                                height: "100%",
                                            },
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"4. Before Migration Actions"}</Typography>
                        <Typography color={"textSecondary"}>{"Select any actions to run before the migration session is created."}</Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "hooks", "before_migration_creation"]} />
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"5. After Migration Actions"}</Typography>
                        <Typography color={"textSecondary"}>{"Select any actions to run after the migration session is created."}</Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "hooks", "after_migration_creation"]} />
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{"6. Generate Report"}</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={operationJson.config.migrateops_local_data_migration.generate_report}
                                    onChange={(e) => {
                                        setOperationJsonField(["config", "migrateops_local_data_migration", "generate_report"], e.target.checked);
                                    }}
                                />
                            }
                            label={"Generate a report of this migration session after completion."}
                        />
                    </Box>
                </Box>
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                if (operationJson.config.migrateops_local_data_migration.destination.auto_allocate_volumes) {
                                    setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.AUTO_ALLOC_PARAMS));
                                } else {
                                    setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.SOURCE_VOLUMES));
                                }
                            },
                        }}
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.CUTOVER));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={wizardState.operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};

// ======================
// CutoverStep
// ======================

interface CutoverStepProps {}

export const CutoverStep: React.FC<MigrateOpsLocalDataMigrationWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = LocalDataMigrationWizardStepId.CUTOVER;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson, setOperationJsonField } = wizardState;

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Typography variant={"h2"} textAlign={"center"}>
                    {"Set your cutover options."}
                </Typography>
                <Box>
                    <Typography variant={"h5"}>{`1. Cutover`}</Typography>
                    <Grid pt={2} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <SelectableCard
                                selected={wizardState.operationJson.config.migrateops_local_data_migration.cutover.manual_cutover === false}
                                onSelect={() => {
                                    wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "cutover", "manual_cutover"], false);
                                }}
                                cardProps={{
                                    sx: {
                                        height: "100%",
                                    },
                                }}
                                title={"Automatic Cutover"}
                                description={`[Placeholder text]`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectableCard
                                selected={wizardState.operationJson.config.migrateops_local_data_migration.cutover.manual_cutover}
                                onSelect={() => {
                                    wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "cutover", "manual_cutover"], true);
                                }}
                                cardProps={{
                                    sx: {
                                        height: "100%",
                                    },
                                }}
                                title={"Manual Cutover"}
                                description={`No automatic cutover necessary. Operation will be done when ready to cutover.`}
                            />
                        </Grid>
                    </Grid>
                    <Box pt={1}>
                        <FormControlLabel control={<Checkbox />} label={"Reboot During Cutover"} />
                        <FormHelperText>{`If this option is used, no stop/start application commands will be executed.`}</FormHelperText>
                    </Box>
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{`2. Before Cutover Actions`}</Typography>
                        <Typography color={"textSecondary"}>
                            {`Host actions to stop workload before cutover.
                     User must ensure that this set of actions can successfully stop all application and filesystems that uses the source volumes being migrated. 
                     Because changes to the source volumes will no longer be migrated once cutover has occured, failure to do so may result in data integrity issues.`}
                        </Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "cutover", "before_cutover"]} />
                </Box>
                <Box>
                    <Box pb={2}>
                        <Typography variant={"h5"}>{`3. After Cutover Actions`}</Typography>
                        <Typography color={"textSecondary"}>
                            {`Host actions to start workload after cutover has completed.
                         User must ensure that this set of actions can successfully mount all relevant filesystems that reside on destination volumes and start all applications. 
                         Source volumes must not be accessed because cutover has already been completed. 
                        Failure to do so may result in business continuity and data integrity issues.`}
                        </Typography>
                    </Box>
                    <AddHostActions hostActionField={["config", "migrateops_local_data_migration", "cutover", "after_cutover"]} />
                </Box>
                <Box>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.MIGRATION_PARAMS));
                            },
                        }}
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === LocalDataMigrationWizardStepId.FINISH_UP));
                            },
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"center"}>
                    <ViewCurrentYamlButton operationJson={wizardState.operationJson} />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};
