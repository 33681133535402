import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import {
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import { AutoAlloc, BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import produce from "immer";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import PowerFlexVolumeType = AutoAlloc.VolumeParams.EMCPowerFlex.PowerFlexVolumeType;

// ======================
// PowerFlexAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

interface PowerFlexDiskAssignmentConfig {
    capacity: number;
    storagePoolName: string;
    protectionDomainName: string;
    volumeType: PowerFlexVolumeType;
}

export const PowerFlexAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.powerflex;

    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setPowerflex(
                new AutoAlloc.VolumeParams.EMCPowerFlex()
                    .setVolumeType(defaultParams.volumeType)
                    .setStoragePoolName(defaultParams.storagePoolName)
                    .setProtectionDomainName(defaultParams.protectionDomainName)
            );
        }
    });

    const getInitialDeviceAssignments = () => {
        const assignments: { [key: string]: PowerFlexDiskAssignmentConfig } = {};
        for (let device of state.sourceDevices) {
            const blockDevice = device.source.getBlockDevice();
            assignments[blockDevice.getDeviceName()] = {
                capacity: blockDevice.getCapacity() / Math.pow(1024, 3),
                storagePoolName: defaultParams.storagePoolName,
                protectionDomainName: defaultParams.protectionDomainName,
                volumeType: defaultParams.volumeType,
            };
        }
        return assignments;
    };

    const [deviceConfigs, setDeviceConfigs] = useState<{ [key: string]: PowerFlexDiskAssignmentConfig }>(getInitialDeviceAssignments());

    const setDeviceCapacity = (deviceName: string, capacity: number) => {
        setDeviceConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[deviceName].capacity = capacity;
                } else {
                    draft[deviceName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setProtectionDomainName = (deviceName: string, protectionDomainName: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].protectionDomainName = protectionDomainName;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getPowerflex().setProtectionDomainName(protectionDomainName);
    };

    const setStoragePoolName = (deviceName: string, storagePoolName: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].storagePoolName = storagePoolName;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getPowerflex().setStoragePoolName(storagePoolName);
    };

    const setVolumeType = (deviceName: string, volumeType: PowerFlexVolumeType) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].volumeType = volumeType;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getPowerflex().setVolumeType(volumeType);
    };

    const getHasError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        }
        for (let device in deviceConfigs) {
            const blockDevice = state.sourceDevices.find((d) => d.source.getBlockDevice().getDeviceName() === device);
            if (deviceConfigs[device].capacity < blockDevice?.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <List>
                {state.sourceDevices.map((device) => {
                    const blockDevice = device.source.getBlockDevice();
                    return (
                        <PowerFlexDiskCard
                            state={state}
                            blockDevice={blockDevice}
                            setStoragePoolName={setProtectionDomainName}
                            currentStoragePoolName={deviceConfigs[blockDevice.getDeviceName()].storagePoolName}
                            setDeviceCapacity={setDeviceCapacity}
                            currentDeviceCapacity={deviceConfigs[blockDevice.getDeviceName()].capacity}
                            currentProtectionDomainName={deviceConfigs[blockDevice.getDeviceName()].protectionDomainName}
                            setProtectionDomainName={setProtectionDomainName}
                            currentVolumeType={deviceConfigs[blockDevice.getDeviceName()].volumeType}
                            setVolumeType={setVolumeType}
                        />
                    );
                })}
            </List>
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow} disabled={getHasError()}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});

// ======================
// PowerFlexDiskCard
// ======================

interface PowerFlexDiskCardProps {
    state: GmMigrationAutoAllocationState;
    blockDevice: BlockDeviceInfo;
    currentDeviceCapacity: number;
    setDeviceCapacity: (deviceName: string, capacity: number) => void;
    currentStoragePoolName: string;
    setStoragePoolName: (deviceName: string, storagePoolName: string) => void;
    currentProtectionDomainName: string;
    setProtectionDomainName: (deviceName: string, protectionDomainName: string) => void;
    currentVolumeType: PowerFlexVolumeType;
    setVolumeType: (deviceName: string, volumeType: PowerFlexVolumeType) => void;
}

const PowerFlexDiskCard: React.FC<PowerFlexDiskCardProps> = observer((p) => {
    const {
        state,
        blockDevice,
        currentDeviceCapacity,
        setDeviceCapacity,
        currentStoragePoolName,
        setStoragePoolName,
        setProtectionDomainName,
        currentProtectionDomainName,
        currentVolumeType,
        setVolumeType,
    } = p;

    const defaultHelperText = "Set a custom disk size.";

    const [capacityHelperText, setCapacityHelperText] = useState(defaultHelperText);

    const getCapacityError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        } else {
            return currentDeviceCapacity < blockDevice.getCapacity() / Math.pow(1024, 3);
        }
    };

    return (
        <Card sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <ListItem key={blockDevice.getDeviceName()}>
                        <ListItemIcon>
                            <DiskIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                            secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                        />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={1} justifyContent={"flex-end"}>
                        <Grid item xs={12} lg={4}>
                            <Box p={2}>
                                <TextField
                                    fullWidth
                                    label={"Disk Size"}
                                    variant={"filled"}
                                    error={getCapacityError()}
                                    value={currentDeviceCapacity}
                                    type={"number"}
                                    onChange={(e) => {
                                        setDeviceCapacity(blockDevice.getDeviceName(), Number(e.target.value));
                                        if (!state.allowSmallerDestinations && Number(e.target.value) < blockDevice.getCapacity() / Math.pow(1024, 3)) {
                                            setCapacityHelperText("Must be greater than or equal to source volume size.");
                                        } else {
                                            setCapacityHelperText(defaultHelperText);
                                        }
                                    }}
                                    helperText={capacityHelperText}
                                    InputProps={{ endAdornment: <Typography>{`GiB`}</Typography> }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box p={2}>
                                <TextField
                                    fullWidth
                                    label={"Storage Pool Name / ID"}
                                    variant={"filled"}
                                    value={currentStoragePoolName}
                                    onChange={(e) => {
                                        setStoragePoolName(blockDevice.getDeviceName(), e.target.value);
                                    }}
                                    helperText={"If not specified, first pool will be used."}
                                />
                            </Box>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Box p={2}>*/}
                        {/*        <TextField*/}
                        {/*            fullWidth*/}
                        {/*            label={"Protection Domain Name"}*/}
                        {/*            variant={"filled"}*/}
                        {/*            value={currentProtectionDomainName}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setProtectionDomainName(blockDevice.getDeviceName(), e.target.value);*/}
                        {/*            }}*/}
                        {/*            helperText={"Required if you have more than one pool with the same name."}*/}
                        {/*        />*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} lg={4}>
                            <Box p={2}>
                                <FormControl fullWidth variant={"filled"}>
                                    <InputLabel>Volume Type</InputLabel>
                                    <Select
                                        fullWidth
                                        label={"Volume Type"}
                                        value={currentVolumeType}
                                        variant={"filled"}
                                        onChange={(e) => setVolumeType(blockDevice.getDeviceName(), e.target.value as PowerFlexVolumeType)}
                                    >
                                        <MenuItem value={PowerFlexVolumeType.THICK_PROVISIONED}>Thick-Provisioned</MenuItem>
                                        <MenuItem value={PowerFlexVolumeType.THIN_PROVISIONED}>Thin-Provisioned</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
});
