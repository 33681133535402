// Project: GalaxyComplete
// Created: 11/13/20 by sammy
// File: GmAutoAllocationProgress

import * as React from "react";
import { useAppServices } from "../../app/services";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { useInitData, useServerDataWithLoadingList } from "../../core/data/DataLoaderHooks";
import { GmAutoAllocationActionType } from "../GmMigrationService";
import { AutoAllocStatus } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { sleepMS } from "../../../common/utils/util";
import { CloseIcon } from "../../../common/CommonIcons";

const useGmAutoAllocationState = () => {
    const { gmMigrationService } = useAppServices();
    return gmMigrationService.autoAllocationState;
};

// ======================
// GalaxyMigrateAutoAllocationProgressDialog
// ======================
interface GalaxyMigrateAutoAllocationProgressDialogProps {
    noActions?: boolean;
    actionLabel?: string;
}

export const GalaxyMigrateAutoAllocationProgressDialog: React.FC<GalaxyMigrateAutoAllocationProgressDialogProps> = observer((p) => {
    const allocationState = useGmAutoAllocationState();
    const fullScreen = useShouldDialogFullScreen();
    return (
        <Dialog open={allocationState.showProgressDialog} maxWidth={"md"} fullWidth fullScreen={fullScreen}>
            {allocationState.showProgressDialog && (
                <AllocationProgressView fullScreen={fullScreen} noActions={p.noActions} actionLabel={p.actionLabel} inDialog />
            )}
        </Dialog>
    );
});

// ======================
// AllocationProgressView
// ======================
interface AllocationProgressViewProps {
    noActions?: boolean;
    actionLabel?: string;
    inDialog?: boolean;
    fullScreen?: boolean;
}

export const AllocationProgressView: React.FC<AllocationProgressViewProps> = observer((p) => {
    const allocationState = useGmAutoAllocationState();

    const getCloseDialogDisabled = () => {
        if (allocationState.currentProgress.inError) {
            return false;
        } else if (allocationState.currentProgress.ready) {
            if (!allocationState.currentProgress.data.getCompleted()) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    };
    useInitData({
        pollInterval: 0.5,
        init: async () => {
            allocationState.currentProgress.resetData();
        },
        poll: async () => {
            await sleepMS(500);
            await allocationState.currentProgress.fetchData();
        },
    });
    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"}>
                <DialogTitle>
                    {"Operation: "}
                    {allocationState.currentAction === GmAutoAllocationActionType.CONNECT && `Connect to Storage`}
                    {allocationState.currentAction === GmAutoAllocationActionType.VALIDATE && `Validating Host Readiness`}
                    {allocationState.currentAction === GmAutoAllocationActionType.PREPARE && `Prepare Host for Auto Allocation`}
                    {allocationState.currentAction === GmAutoAllocationActionType.ALLOCATE && `Allocate Volumes`}
                </DialogTitle>
                {p.inDialog && (
                    <Box p={2}>
                        <IconButton disabled={getCloseDialogDisabled()} onClick={() => allocationState.setShowProgressDialog(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>

            <DialogContent
                sx={{
                    "&::-webkit-scrollbar": {
                        display: "none",
                        width: "10px",
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        border: "2px solid transparent",
                        backgroundColor: "rgba(255,255,255,.1)",
                        backgroundClip: `content-box`,
                    },
                }}
            >
                {useServerDataWithLoadingList(allocationState.currentProgress, (data) => {
                    return (
                        <>
                            <Box mb={1}>
                                <Banner status={data} />
                            </Box>
                            <Box
                                p={2}
                                sx={{
                                    backgroundColor: (t: Theme) => t.palette.background.default,
                                    maxHeight: p.fullScreen ? "70vh" : "50vh",
                                    overflowX: "auto",
                                    "&::-webkit-scrollbar": {
                                        width: "10px",
                                        backgroundColor: "transparent",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        borderRadius: "10px",
                                        border: "2px solid transparent",
                                        backgroundColor: "rgba(255,255,255,.1)",
                                        backgroundClip: `content-box`,
                                    },
                                }}
                            >
                                {data.getProgressLinesList().map((line, i) => {
                                    return (
                                        <Box width={"100%"} key={i}>
                                            <Typography>{line.getMessage()}</Typography>
                                        </Box>
                                    );
                                })}
                                <br />
                            </Box>
                        </>
                    );
                })}
                <br />
            </DialogContent>
            {!p.noActions && (
                <DialogActions>
                    <Grid container justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => allocationState.setShowProgressDialog(false)}
                            disabled={getCloseDialogDisabled()}
                        >
                            {p.actionLabel ?? "Continue"}
                        </Button>
                    </Grid>
                    <br />
                </DialogActions>
            )}
        </>
    );
});

// ======================
// Banner
// ======================
interface BannerProps {
    status: AutoAllocStatus;
}

const Banner: React.FC<BannerProps> = observer((p) => {
    if (!p.status.getCompleted()) {
        return (
            <Alert severity={"info"}>
                <AlertTitle>{"Operation In Progress..."}</AlertTitle>
            </Alert>
        );
    }
    if (!!p.status.getError()) {
        return (
            <Alert severity={"error"}>
                <AlertTitle>{"Error Encountered"}</AlertTitle>
                <Typography sx={{ wordBreak: "break-word" }}>{p.status.getError()}</Typography>
            </Alert>
        );
    }
    if (!!p.status.getCompleted()) {
        return (
            <Alert severity={"success"}>
                <AlertTitle>{"Operation Completed Successfully"}</AlertTitle>
            </Alert>
        );
    }
    return null;
});
