import { observer } from "mobx-react-lite";
import { FilterState, TableState } from "./DataTable";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../modules/core/dialog/DialogService";
import {
    Alert,
    AutocompleteChangeReason,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    Stack,
    SvgIcon,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { MdAdd, MdFilterList } from "react-icons/md";
import { DialogTopBar } from "../../modules/core/dialog/DialogComponents";
import { FieldArray, Form, Formik } from "formik";
import { FormAutocompleteField, FormDateTimePicker, FormSelect, FormTextField } from "../form/FormComponents";
import { EnumFilterField, FilterParamType, FilterType, ListFilterableField, NumberFilterField } from "../../modules/core/data/ListData";
import * as yup from "yup";
import { formatKnownDataType, KnownDataType } from "../utils/formatter";
import { CloseIcon } from "../CommonIcons";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import xbytes from "xbytes";
import { useGlobalTableSortFilterState } from "./TableFilterState";
import { ta } from "date-fns/locale";

// ======================
// TableFilter
// ======================

interface QueryTableFilterProps<RowData> {
    queryKey?: string;
    refetchQuery?: () => void;
}

export const QueryTableFilter = observer(<RowData,>(props: QueryTableFilterProps<RowData>) => {
    const { refetchQuery, queryKey } = props;
    const filterDialogState = useDialogState();
    const sortFilterState = useGlobalTableSortFilterState();

    if (!queryKey) {
        return <Box />;
    }

    const onDelete = (index: number) => {
        sortFilterState.removeFilter(queryKey, index);
        refetchQuery();
    };

    return (
        <>
            <Box>
                <Button
                    color={"inherit"}
                    startIcon={
                        <SvgIcon>
                            <MdFilterList />
                        </SvgIcon>
                    }
                    onClick={filterDialogState.open}
                >
                    {`Filter`}
                </Button>
            </Box>
            {sortFilterState.getIsFilterActive(queryKey) && (
                <Box display={"flex"} pb={2}>
                    {sortFilterState.tableStateMap.get(queryKey).filters.map((f, i) => {
                        return (
                            <Box pr={1}>
                                <Chip label={getDisplayValueFromFilterState(f)} color={"primary"} onDelete={() => onDelete(i)} />
                            </Box>
                        );
                    })}
                </Box>
            )}
            <TableFilterDialog dialogState={filterDialogState} refetchQuery={refetchQuery} queryKey={queryKey} />
        </>
    );
});

// ======================
// TableFilterDialog
// ======================

interface TableFilterDialogProps<RowData> {
    queryKey?: string;
    dialogState: DialogState;
    refetchQuery?: () => void;
}

export const TableFilterDialog = observer(<RowData,>(props: TableFilterDialogProps<RowData>) => {
    const { dialogState, refetchQuery, queryKey } = props;

    return (
        <Dialog
            fullScreen={useShouldDialogFullScreen()}
            fullWidth
            maxWidth={"md"}
            open={dialogState.isOpen}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    dialogState.close();
                }
            }}
        >
            <DialogTopBar dialogState={dialogState} title={"Filter"} divider={true} />
            <DialogContent>
                <TableFilterForm closeDialog={dialogState.close} refetchQuery={refetchQuery} queryKey={queryKey} />
            </DialogContent>
        </Dialog>
    );
});

// ======================
// TableFilterForm
// ======================

interface TableFilterFormProps<RowData> {
    closeDialog: () => void;
    refetchQuery?: () => void;
    queryKey?: string;
}

export interface FilterFormValue {
    fieldConfig: ListFilterableField<any, any, any>;
    op: {
        label: string;
        value: any;
    };
    value: any;
    unit?: string;
}

export const TableFilterForm = observer(<RowData,>(props: TableFilterFormProps<RowData>) => {
    const { closeDialog, refetchQuery, queryKey } = props;
    const globalSortFilterState = useGlobalTableSortFilterState();
    const tableSortFilterState = globalSortFilterState.tableStateMap.get(queryKey);
    const schema = yup.object({
        filters: yup.array(
            yup.object({
                fieldConfig: yup.object({
                    label: yup.string().required("Select criteria."),
                    fieldId: yup.mixed(),
                    filterType: yup.string(),
                    addFilterToRequest: yup.mixed(),
                }),
                op: yup.object({
                    value: yup.mixed(),
                    label: yup.string(),
                }),
                value: yup
                    .mixed()
                    .required("Enter a value.")
                    .test({
                        name: "percentage",
                        message: "Invalid value.",
                        test: (value, context) => {
                            const filterField = tableSortFilterState.sortFilterConfig?.filter.find((f) => f.fieldId === context.parent.fieldConfig.fieldId);
                            if (!filterField) {
                                return true;
                            }
                            if (filterField.filterType === "number") {
                                if (filterField.numType === "percentage") {
                                    if (value < 0 || value > 100) {
                                        return false;
                                    }
                                }
                                return !isNaN(value);
                            }
                            return true;
                        },
                    }),
                unit: yup.string().nullable(),
            })
        ),
    });

    const getInitialFilterEntry = () => ({
        fieldConfig: {
            label: "",
            fieldId: null as any,
            filterType: null as FilterType,
            addFilterToRequest: null as (request: any, filterParam: FilterParamType) => void,
        },
        op: {
            label: "",
            value: null as any,
        },
        value: null as any,
        unit: null as string,
    });

    const _getInitialValues = (): { filters: FilterFormValue[] } => {
        if (!globalSortFilterState.getIsFilterActive(queryKey)) {
            return {
                filters: [getInitialFilterEntry()],
            };
        } else {
            return {
                filters: tableSortFilterState.filters.map((f) => ({
                    fieldConfig: f.fieldConfig,
                    op: {
                        label: getOpLabelFromFilterState(f),
                        value: getOpValueFromFilterstate(f),
                    },
                    value: getFormValueFromParam(f),
                    unit: getUnitValueFromParam(f),
                })),
            };
        }
    };

    const _getSubmitDisabled = (values: { filters: FilterFormValue[] }, hasErrors: boolean): boolean => {
        const hasAnyNullValue = !!values.filters.find((v) => v.value === null || v.value === undefined || v.value === "");
        const anyDataNumberWithoutUnit = !!values.filters.find((v) => {
            if (v.fieldConfig.filterType === "number") {
                if (v.fieldConfig.numType === "capacity" || v.fieldConfig.numType === "throughput") {
                    return v.unit === null;
                }
                return false;
            }
            return false;
        });
        return hasErrors || hasAnyNullValue || anyDataNumberWithoutUnit;
    };

    return (
        <Formik
            initialValues={_getInitialValues()}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values, helpers) => {
                globalSortFilterState.clearFilters(queryKey);
                for (let filter of values.filters) {
                    globalSortFilterState.setFilter(queryKey, {
                        fieldConfig: filter.fieldConfig,
                        param: getParamFromFormValue(filter),
                    });
                }
                closeDialog();
                refetchQuery();
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <FieldArray
                            name={"filters"}
                            render={({ push, remove }) => {
                                const addFilter = () => {
                                    push(getInitialFilterEntry());
                                };

                                const removeFilter = (index: number) => {
                                    if (!!props.values.filters[index].value) {
                                        const existingFilterIndex = getExistingFilterIndex(props.values.filters[index], tableSortFilterState.filters);
                                        if (existingFilterIndex > -1) {
                                            globalSortFilterState.removeFilter(queryKey, existingFilterIndex);
                                        }
                                    }
                                    remove(index);
                                };

                                return (
                                    <Box pb={2}>
                                        {props.values.filters.map((f, i) => {
                                            const resetFieldsOnColumnChange = (
                                                event: SyntheticEvent<Element, Event>,
                                                value: any,
                                                reason: AutocompleteChangeReason
                                            ) => {
                                                if (!tableSortFilterState.filters[i]) {
                                                    props.setFieldValue(`filters[${i}].op`, {
                                                        label: "",
                                                        value: null as number,
                                                    });
                                                    props.setFieldValue(`filters[${i}].value`, "");
                                                    props.setFieldTouched(`filters[${i}].op`, false);
                                                    props.setFieldTouched(`filters[${i}].value`, false);
                                                } else if (tableSortFilterState.filters[i].fieldConfig.label !== value) {
                                                    props.setFieldValue(`filters[${i}].op`, {
                                                        label: "",
                                                        value: null as number,
                                                    });
                                                    props.setFieldValue(`filters[${i}].value`, "");
                                                    props.setFieldTouched(`filters[${i}].op`, false);
                                                    props.setFieldTouched(`filters[${i}].value`, false);
                                                }
                                            };
                                            return (
                                                <Box pb={2}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={3}>
                                                            <FormAutocompleteField<ListFilterableField<any, any, any>, false, true, false>
                                                                label={"Criteria"}
                                                                name={`filters[${i}].fieldConfig`}
                                                                options={tableSortFilterState.sortFilterConfig.filter || []}
                                                                getOptionLabel={(o: ListFilterableField<any, any, any>) => o.label}
                                                                errorGetter={(v) => v.label}
                                                                disableClearable
                                                                blurOnSelect
                                                                onChange={resetFieldsOnColumnChange}
                                                            />
                                                            {props.values.filters[i]?.fieldConfig.helperText && (
                                                                <FormHelperText>{props.values.filters[i]?.fieldConfig.helperText}</FormHelperText>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            {renderOpField(`filters[${i}].op`, props.values.filters[i])}
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            {renderValueField(
                                                                `filters[${i}].value`,
                                                                props.values.filters[i],
                                                                (unit: string) => props.setFieldValue(`filters[${i}].unit`, unit),
                                                                props.values.filters[i].unit
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {i !== 0 && (
                                                                <IconButton onClick={() => removeFilter(i)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            );
                                        })}
                                        <Box>
                                            <Button
                                                startIcon={<MdAdd />}
                                                variant={"outlined"}
                                                color={"primary"}
                                                onClick={addFilter}
                                                disabled={props.values.filters.length === 10}
                                            >
                                                Add Another Filter
                                            </Button>
                                            {props.values.filters.length === 10 && (
                                                <Box pt={1}>
                                                    <Alert severity={"error"}>{`You have reached the maximum number of filters (10).`}</Alert>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            }}
                        />
                        <Divider />
                        <Box pt={2}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item>
                                    <Button
                                        variant={"outlined"}
                                        color={"neutral"}
                                        onClick={() => {
                                            props.setFieldValue("filters", []);
                                            globalSortFilterState.clearFilters(queryKey);
                                            refetchQuery();
                                        }}
                                        disabled={!props.values.filters[0]?.fieldConfig.label}
                                    >
                                        Clear All Filters
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                        disabled={_getSubmitDisabled(props.values, Object.values(props.errors).length > 0)}
                                    >
                                        Apply All
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
});

// ======================
// FilterFormDataNumField
// ======================

interface FilterFormDataNumFieldProps {
    name: string;
    disabled: boolean;
    numType: "capacity" | "throughput";
    setUnitFieldValue: (unit: string) => void;
    defaultUnit?: string;
}

export const FilterFormDataNumField: React.FC<FilterFormDataNumFieldProps> = observer((p) => {
    const { name, disabled, numType, defaultUnit, setUnitFieldValue } = p;
    const unitOptions = ["KiB", "MiB", "GiB", "TiB"];
    const [unit, setUnit] = useState(defaultUnit);

    const handleSetUnit = (e: React.MouseEvent<HTMLElement>, newUnit: string) => {
        setUnitFieldValue(newUnit);
        setUnit(newUnit);
    };

    return (
        <Stack direction={"row"} spacing={1}>
            <FormTextField
                label={"Value"}
                name={name}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <ToggleButtonGroup color={"primary"} value={unit} onChange={handleSetUnit} exclusive>
                                {unitOptions.map((unit) => (
                                    <ToggleButton key={unit} value={unit} sx={{ textTransform: "none" }}>
                                        {unit}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                            {numType === "throughput" && <Typography>{`/s`}</Typography>}
                        </Stack>
                    ),
                }}
            />
        </Stack>
    );
});

interface OperationSelectionType {
    label: string;
    value: number;
}

enum BooleanSelection {
    TRUE,
    FALSE,
}

const getNumOpsSelectionLabel = (numOp: FilterParams.NumberFilter.Op) => {
    if (numOp === FilterParams.NumberFilter.Op.EQUAL) {
        return "Equal To (=)";
    } else if (numOp === FilterParams.NumberFilter.Op.LESS_THAN) {
        return "Less Than (<)";
    } else if (numOp === FilterParams.NumberFilter.Op.GREATER_THAN) {
        return "Greater Than (>)";
    } else if (numOp === FilterParams.NumberFilter.Op.NOT_EQUAL) {
        return "Not Equal To (!=)";
    }
};

const getSimpleNumberOpsSelectionLabel = (numOp: FilterParams.SimpleNumberFilter.Op) => {
    if (numOp === FilterParams.SimpleNumberFilter.Op.EQUAL) {
        return "Equal To (=)";
    } else if (numOp === FilterParams.SimpleNumberFilter.Op.NOT_EQUAL) {
        return "Not Equal To (!=)";
    }
};

const getNumOpsSelection = (): OperationSelectionType[] => [
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.GREATER_THAN),
        value: FilterParams.NumberFilter.Op.GREATER_THAN,
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.LESS_THAN),
        value: FilterParams.NumberFilter.Op.LESS_THAN,
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.EQUAL),
        value: FilterParams.NumberFilter.Op.EQUAL,
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.NOT_EQUAL),
        value: FilterParams.NumberFilter.Op.NOT_EQUAL,
    },
];

const getSimpleNumberOpsSelection = (): OperationSelectionType[] => [
    {
        label: getSimpleNumberOpsSelectionLabel(FilterParams.SimpleNumberFilter.Op.EQUAL),
        value: FilterParams.SimpleNumberFilter.Op.EQUAL,
    },
    {
        label: getSimpleNumberOpsSelectionLabel(FilterParams.SimpleNumberFilter.Op.NOT_EQUAL),
        value: FilterParams.SimpleNumberFilter.Op.NOT_EQUAL,
    },
];

const getStringOpsSelectionLabel = (stringOp: FilterParams.StringFilter.Op) => {
    if (stringOp === FilterParams.StringFilter.Op.INCLUDES) {
        return "Includes";
    } else if (stringOp === FilterParams.StringFilter.Op.EXCLUDES) {
        return "Excludes";
    } else if (stringOp === FilterParams.StringFilter.Op.EQUAL) {
        return "Equals";
    } else if (stringOp === FilterParams.StringFilter.Op.NOT_EQUAL) {
        return "Does Not Equal";
    }
};

const getStringOpsSelection = (): OperationSelectionType[] => {
    return [
        {
            label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.INCLUDES),
            value: FilterParams.StringFilter.Op.INCLUDES,
        },
        {
            label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.EXCLUDES),
            value: FilterParams.StringFilter.Op.EXCLUDES,
        },
        {
            label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.EQUAL),
            value: FilterParams.StringFilter.Op.EQUAL,
        },
        {
            label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.NOT_EQUAL),
            value: FilterParams.StringFilter.Op.NOT_EQUAL,
        },
    ];
};

const getSimpleStringOpsSelectionLabel = (stringOp: FilterParams.SimpleStringFilter.Op) => {
    if (stringOp === FilterParams.SimpleStringFilter.Op.INCLUDES) {
        return "Includes";
    } else if (stringOp === FilterParams.SimpleStringFilter.Op.EQUAL) {
        return "Equals";
    }
};

const getSimpleStringOpsSelection = (): OperationSelectionType[] => [
    {
        label: getSimpleStringOpsSelectionLabel(FilterParams.SimpleStringFilter.Op.INCLUDES),
        value: FilterParams.SimpleStringFilter.Op.INCLUDES,
    },
    {
        label: getSimpleStringOpsSelectionLabel(FilterParams.SimpleStringFilter.Op.EQUAL),
        value: FilterParams.SimpleStringFilter.Op.EQUAL,
    },
];

const getDateOpsSelectionLabel = (dateOp: FilterParams.DateFilter.Op) => {
    if (dateOp === FilterParams.DateFilter.Op.SINCE) {
        return "Since";
    } else if (dateOp === FilterParams.DateFilter.Op.BEFORE) {
        return "Before";
    }
};

const getDateOpsSelection = (): OperationSelectionType[] => [
    {
        label: getDateOpsSelectionLabel(FilterParams.DateFilter.Op.SINCE),
        value: FilterParams.DateFilter.Op.SINCE,
    },
    {
        label: getDateOpsSelectionLabel(FilterParams.DateFilter.Op.BEFORE),
        value: FilterParams.DateFilter.Op.BEFORE,
    },
];

const getBoolOpsSelectionLabel = (bool: BooleanSelection) => {
    if (bool === BooleanSelection.TRUE) {
        return "Is";
    }
};

const getBoolOpsSelection = (): OperationSelectionType[] => [
    {
        label: getBoolOpsSelectionLabel(BooleanSelection.TRUE),
        value: BooleanSelection.TRUE,
    },
];

const getDurationOpsSelectionLabel = (durationOp: FilterParams.DurationFilter.Op) => {
    if (durationOp === FilterParams.DurationFilter.Op.SHORTER_THAN) {
        return "Shorter Than";
    } else if (durationOp === FilterParams.DurationFilter.Op.LONGER_THAN) {
        return "Longer Than";
    }
};

const getDurationOpsSelection = (): OperationSelectionType[] => [
    {
        label: getDurationOpsSelectionLabel(FilterParams.DurationFilter.Op.SHORTER_THAN),
        value: FilterParams.DurationFilter.Op.SHORTER_THAN,
    },
    {
        label: getDurationOpsSelectionLabel(FilterParams.DurationFilter.Op.LONGER_THAN),
        value: FilterParams.DurationFilter.Op.LONGER_THAN,
    },
];

const getSelectionList = (t: FilterType): OperationSelectionType[] => {
    if (t === "number") {
        return getNumOpsSelection();
    } else if (t === "bool") {
        return getBoolOpsSelection();
    } else if (t === "date") {
        return getDateOpsSelection();
    } else if (t === "duration") {
        return getDurationOpsSelection();
    } else if (t === "simpleString") {
        return getSimpleStringOpsSelection();
    } else if (t === "simpleNumber") {
        return getSimpleNumberOpsSelection();
    } else {
        return getStringOpsSelection();
    }
};

const renderOpField = (name: string, filterValue: FilterFormValue) => {
    const isBool = filterValue.fieldConfig.filterType === "bool";
    const defaultValue = isBool ? getBoolOpsSelection()[0] : null;
    if (filterValue.fieldConfig.filterType === "enum") {
        return (
            <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Typography>{"Is"}</Typography>
            </Box>
        );
    }
    return (
        <FormAutocompleteField<OperationSelectionType, false, true, false>
            label={"Condition"}
            name={name}
            options={getSelectionList(filterValue.fieldConfig.filterType)}
            getOptionLabel={(o: OperationSelectionType) => o.label}
            defaultValue={defaultValue}
            disableClearable
            blurOnSelect
            valueGetter={(v: OperationSelectionType) => v?.value || v}
            errorGetter={(v: OperationSelectionType) => v.label}
            disabled={isBool ? true : !filterValue.fieldConfig.label}
        />
    );
};

const renderValueField = (name: string, filterValue: FilterFormValue, unitSetter: (unit: string) => void, defaultUnit?: string) => {
    const filterType = filterValue.fieldConfig.filterType;

    if (filterType === "bool") {
        return (
            <FormSelect
                label={"Value"}
                name={name}
                selectionList={[
                    { label: "True", value: BooleanSelection.TRUE },
                    {
                        label: "False",
                        value: BooleanSelection.FALSE,
                    },
                ]}
                disabled={!filterValue.fieldConfig.label}
                defaultValue={{ label: "True", value: BooleanSelection.TRUE }}
            />
        );
    } else if (filterType === "date") {
        return <FormDateTimePicker label={"Value"} name={name} disabled={!filterValue.op.label} defaultValue={null} />;
    } else if (filterValue.fieldConfig.filterType === "enum") {
        const getSelectionList = () => {
            const enumValuesList = (filterValue.fieldConfig as EnumFilterField<any, any, any>).enumValuesList.filter((v) => v !== 1000);
            return enumValuesList.map((v) => {
                return {
                    label: (filterValue.fieldConfig as EnumFilterField<any, any, any>).getFormValueDisplayLabel(v),
                    value: v,
                };
            });
        };
        return <FormSelect label={`Select ${filterValue.fieldConfig.enumLabel}`} name={name} selectionList={getSelectionList()} />;
    } else if (filterType === "number") {
        const numType = filterValue.fieldConfig.numType;
        const endAdornment = numType === "percentage" ? "%" : null;
        if (numType === "capacity" || numType === "throughput") {
            return (
                <FilterFormDataNumField
                    name={name}
                    disabled={!filterValue.op.label}
                    numType={numType}
                    setUnitFieldValue={unitSetter}
                    defaultUnit={defaultUnit}
                />
            );
        }
        return <FormTextField InputProps={{ endAdornment: endAdornment }} type={"number"} label={"Value"} name={name} disabled={!filterValue.op.label} />;
    } else if (filterType === "duration") {
        return (
            <FormTextField
                InputProps={{ endAdornment: filterValue.fieldConfig.durationUnit || null }}
                label={"Value"}
                name={name}
                disabled={!filterValue.op.label}
            />
        );
    } else {
        return <FormTextField label={"Value"} name={name} disabled={!filterValue.op.label} />;
    }
};

const getOpLabelFromFilterState = (s: FilterState) => {
    if (s.fieldConfig.filterType === "enum") {
        return "is";
    }
    return getSelectionList(s.fieldConfig.filterType).find((v) => v.value === s.param.getOp()).label;
};

const getOpValueFromFilterstate = (s: FilterState) => {
    if (s.fieldConfig.filterType === "enum") {
        return null;
    }
    return s.param.getOp();
};

const getDisplayValueFromFilterState = (s: FilterState): string => {
    let value;
    if (s.fieldConfig.filterType === "enum") {
        value = s.fieldConfig.getFormValueDisplayLabel(s.param.getHas().getValue());
    } else if (s.fieldConfig.filterType === "number") {
        value = s.param.getValue();
        if (s.fieldConfig.numType === "capacity" || s.fieldConfig.numType === "throughput") {
            value = xbytes(Number(value), { iec: true });
        }
        if (s.fieldConfig.numType === "percentage") {
            value = `${value}%`;
        }
    } else if (!!s.fieldConfig.formatDisplayValue) {
        value = s.fieldConfig.formatDisplayValue(value);
    } else {
        value = s.param.getValue();
    }

    let opLabel = getOpLabelFromFilterState(s);
    if (s.fieldConfig.filterType === "date") {
        value = formatKnownDataType(new Date(value.getSeconds() * 1000), KnownDataType.DATE);
    }
    return `${s.fieldConfig.label} ${opLabel} ${value}`;
};

const getIsNewFilter = (formValue: FilterFormValue, filterStates: FilterState[]) => {
    return !filterStates.find((filterState) => {
        if (formValue.fieldConfig.filterType === "enum") {
            return (
                getFormComparisonValue(formValue) === getFilterStateComparisonValue(filterState) &&
                formValue.fieldConfig.fieldId === filterState.fieldConfig.fieldId
            );
        }
        return (
            getFormComparisonValue(formValue) === getFilterStateComparisonValue(filterState) &&
            formValue.op.value === filterState.param.getOp() &&
            formValue.fieldConfig.fieldId === filterState.fieldConfig.fieldId
        );
    });
};

const getExistingFilterIndex = (formValue: FilterFormValue, filterStates: FilterState[]) => {
    return filterStates.findIndex(
        (filterState) =>
            getFormComparisonValue(formValue) === getFilterStateComparisonValue(filterState) &&
            formValue.op.value === filterState.param.getOp() &&
            formValue.fieldConfig.fieldId === filterState.fieldConfig.fieldId
    );
};

export const getFilterParamValue = (f: FilterFormValue) => {
    if (f.fieldConfig.filterType === "date") {
        return new Timestamp().setSeconds(f.value.getTime() / 1000);
    } else if (f.fieldConfig.filterType === "number" || f.fieldConfig.filterType === "enum") {
        if ((f.fieldConfig as NumberFilterField).numType === "capacity" || (f.fieldConfig as NumberFilterField).numType === "throughput") {
            return xbytes.parseSize(`${f.value} ${f.unit}`);
        }
        return parseInt(f.value);
    } else if (f.fieldConfig.filterType === "duration") {
        return f.fieldConfig.createDurationFromFormValue(parseFloat(f.value));
    } else {
        return f.value;
    }
};

export const getFormComparisonValue = (f: FilterFormValue) => {
    if (f.fieldConfig.filterType === "date") {
        return new Timestamp().setSeconds(f.value.getTime() / 1000).getSeconds();
    } else if (f.fieldConfig.filterType === "number") {
        return parseInt(f.value);
    } else {
        return f.value;
    }
};

export const getFilterStateComparisonValue = (f: FilterState) => {
    if (f.fieldConfig.filterType === "enum") {
        return f.param.getHas();
    }
    if (f.fieldConfig.filterType === "date") {
        return f.param.getValue().getSeconds();
    } else {
        return f.param.getValue();
    }
};

const getParamFromFormValue = (f: FilterFormValue) => {
    const value = getFilterParamValue(f);
    if (f.fieldConfig.filterType === "number") {
        return new FilterParams.NumberFilter().setValue(value).setOp(f.op.value);
    } else if (f.fieldConfig.filterType === "bool") {
        return new FilterParams.BoolFilter().setValue(value);
    } else if (f.fieldConfig.filterType === "string") {
        return new FilterParams.StringFilter().setValue(value).setOp(f.op.value);
    } else if (f.fieldConfig.filterType === "simpleString") {
        return new FilterParams.SimpleStringFilter().setValue(value).setOp(f.op.value);
    } else if (f.fieldConfig.filterType === "date") {
        return new FilterParams.DateFilter().setValue(value).setOp(f.op.value);
    } else if (f.fieldConfig.filterType === "duration") {
        return new FilterParams.DurationFilter().setValue(value).setOp(f.op.value);
    } else if (f.fieldConfig.filterType === "enum") {
        return f.fieldConfig.createParamFromFormValue(value);
    } else if (f.fieldConfig.filterType === "simpleNumber") {
        return new FilterParams.SimpleNumberFilter().setValue(value).setOp(f.op.value);
    }
};

const getFormValueFromParam = (f: FilterState) => {
    if (f.fieldConfig.filterType === "date") {
        return new Date(f.param.getValue().getSeconds() * 1000);
    }
    if (f.fieldConfig.filterType === "enum") {
        return f.param.getHas().getValue();
    }
    if (f.fieldConfig.filterType === "duration") {
        return f.fieldConfig.createFormValueFromDuration(f.param.getValue());
    }
    if (f.fieldConfig.filterType === "number") {
        if (f.fieldConfig.numType === "capacity" || f.fieldConfig.numType === "throughput") {
            return xbytes(f.param.getValue(), { iec: true }).split(" ")[0];
        }
        return f.param.getValue();
    } else {
        return f.param.getValue();
    }
};

const getUnitValueFromParam = (f: FilterState) => {
    if (f.fieldConfig.filterType === "number") {
        if (f.fieldConfig.numType === "capacity" || f.fieldConfig.numType === "throughput") {
            return xbytes(f.param.getValue(), { iec: true }).split(" ")[1];
        }
    }
    return null;
};
