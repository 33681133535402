// ======================
// CmoMigrationSystemsListScreen
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { useListCmoSystems, useRemoveAllOfflineDeployments, useRemoveCmoDeployment } from "./cmo_migration_hooks";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { alpha, Avatar, Box, Card, CircularProgress, Divider, Grid, LinearProgress, Link, Stack, Typography, useTheme } from "@mui/material";
import { QueryListTable } from "../../common/table/QueryListTable";
import { CmoAppliance, CmoSystemInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { MdList } from "react-icons/md";
import { DeleteIcon } from "../../common/CommonIcons";
import { DarkFlatCard } from "../../common/card/DarkCard";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getAxesMaxValue } from "../../common/utils/statsUtil";
import { MigrationIcon } from "../project/ProjectCommon";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { GoDotFill } from "react-icons/go";
import { CmoSystemHealthIconButton, useLinearProgressStyle, useNavigateToCmoSystemDetails } from "./CmoMigrationCommon";
import { TruncatedTextArray } from "../../common/text/TruncatedText";
import { OperatorView } from "../auth/AuthenticatedViews";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { CmoMigrationRegistrationDialogButton } from "./CmoMigrationRegistrationDialog";

interface CmoMigrationSystemsScreenProps {
    projectId: string;
}

export const CmoMigrationSystemsListScreen: React.FC<CmoMigrationSystemsScreenProps> = (p) => {
    const { projectId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const queryResult = useListCmoSystems(projectId, pageIndex, pageSize);
    const removeAllOfflineDeployments = useRemoveAllOfflineDeployments(projectId);
    const globalDialogState = useGlobalDialogState();

    const getActions = (): ActionConfig[] => {
        return [
            {
                name: "Remove All Offline Deployments",
                id: "remove",
                action: async () => {
                    const confirmed = await globalDialogState.addConfirmDialog({
                        title: "Remove All Offline Deployments",
                        message: "Are you sure you want to remove all offline deployments?",
                        autoConfirmationQuestionLine: false,
                    });

                    if (confirmed) {
                        await removeAllOfflineDeployments.mutateAsync();
                        await queryResult.refetch();
                    }
                },
                icon: <DeleteIcon />,
            },
        ];
    };

    const actions = (
        <Box display={"flex"} alignItems={"center"}>
            <OperatorView>
                <Box pr={1}>
                    <CmoMigrationRegistrationDialogButton />
                </Box>
                <ActionMenuButton actions={getActions()} />
            </OperatorView>
        </Box>
    );
    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Cirrus Migrate On-Premises Systems"} actions={actions} />
            <QueryListTable
                listComponent={CmoSystemListCard}
                data={queryResult.data?.itemsList || []}
                error={queryResult.error}
                isLoading={queryResult.isLoading}
                pageCount={queryResult.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                grid
            />
        </ScreenContainer>
    );
};

// ======================
// CmoSystemListCard
// ======================

interface CmoSystemListCardProps {
    data: CmoSystemInfo.AsObject;
}

const CmoSystemListCard: React.FC<CmoSystemListCardProps> = (p) => {
    const { data } = p;
    const hasMemberAppliance = !!data.memberAppliance;
    const goToSystemDetails = useNavigateToCmoSystemDetails();
    const removeDeployment = useRemoveCmoDeployment();

    const globalDialogState = useGlobalDialogState();
    const online = data.deployment.connected;

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ height: "100%" }}>
                <Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"}>
                    <Box p={2}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box>
                                <Link onClick={() => goToSystemDetails(data.deployment.systemId)} variant={"h6"}>
                                    {data.deployment.systemName}
                                </Link>
                                <Grid container spacing={0.5}>
                                    <Grid item>
                                        <Stack direction={"row"} spacing={0.5}>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {data.primaryAppliance.primaryIp}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {`•`}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction={"row"} spacing={0.5}>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {data.deployment.version}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {data.primaryAppliance.insertionMethod}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Stack direction={"row"} spacing={0}>
                                <ActionMenuButton
                                    actions={[
                                        {
                                            name: "Remove Deployment",
                                            id: "remove",
                                            action: async () => {
                                                const confirmed = await globalDialogState.addConfirmDialog({
                                                    title: "Remove Deployment",
                                                    message: "Are you sure you want to remove this deployment?",
                                                    autoConfirmationQuestionLine: false,
                                                });

                                                if (confirmed) {
                                                    removeDeployment.mutate(data.deployment.systemId);
                                                }
                                            },
                                            icon: <DeleteIcon />,
                                        },
                                    ]}
                                />
                                <CmoSystemHealthIconButton healthStatus={data.healthStatus} systemId={data.deployment.systemId} />
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} />
                    {online ? (
                        <>
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={hasMemberAppliance ? 4 : 6}>
                                        <CmoApplianceStats data={data.primaryAppliance} />
                                    </Grid>
                                    <Grid item xs={hasMemberAppliance ? 4 : 6}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <NexusCountCircle activeNexus={data.insertionSummary.nexusActive} totalNexus={data.insertionSummary.nexusTotal} />
                                        </Stack>
                                    </Grid>
                                    {hasMemberAppliance && (
                                        <Grid item xs={4}>
                                            <CmoApplianceStats data={data.memberAppliance} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Box p={2} pt={0}>
                                <CmoSystemMigrationIoStats data={data} />
                            </Box>
                            <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} />
                            <Box p={2}>
                                <CmoSystemInsertionInfo data={data} />
                            </Box>
                            <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} />
                            <Box p={2}>
                                <CmoSystemMigrationProgress data={data} />
                            </Box>
                        </>
                    ) : (
                        <Box p={2} display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"} flexGrow={1}>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {"This system is currently offline."}
                            </Typography>
                        </Box>
                    )}

                    <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} />
                    <Box p={2}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"column"}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {data.primaryAppliance.model}
                                </Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`Last Check-in ${formatKnownDataType(
                                        convertTimestampObjectToDate(data.deployment.lastCheckin),
                                        KnownDataType.DATE_RELATIVE
                                    )} (${formatKnownDataType(data.deployment.connectionLatency, KnownDataType.DURATION_MILLISECONDS)})`}
                                </Typography>
                            </Stack>
                            <BlackTooltip
                                title={
                                    <Box>
                                        {`${data.deployment.location?.city}, ${data.deployment.location?.region}`}
                                        <br />
                                        {data.deployment.location?.countryName}
                                    </Box>
                                }
                            >
                                <Box>{data.deployment.location?.countryFlag}</Box>
                            </BlackTooltip>
                        </Stack>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};

// ======================
// CmoApplianceStats
// ======================

interface CmoApplianceStatsProps {
    data: CmoAppliance.AsObject;
}

const CmoApplianceStats: React.FC<CmoApplianceStatsProps> = (p) => {
    const { data } = p;
    const progressStyle = useLinearProgressStyle(true);
    if (!data.resourcesUsage) {
        return (
            <Box>
                <Stack direction={"row"} justifyContent={"center"} pb={2}>
                    <Typography variant={"subtitle2"} fontWeight={600}>
                        {data.applianceName}
                    </Typography>
                </Stack>
                <DarkFlatCard>
                    <Box p={1} display={"flex"} justifyContent={"center"} textAlign={"center"}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {"No resource usage data."}
                        </Typography>
                    </Box>
                </DarkFlatCard>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant={"subtitle2"} fontWeight={600}>
                {data.applianceName}
            </Typography>
            <Box pt={1}>
                <LinearProgress
                    sx={progressStyle}
                    color={getResourceUsageColor(data.resourcesUsage.cpuUsagePercent)}
                    variant={"determinate"}
                    value={data.resourcesUsage.cpuUsagePercent}
                />
                <Typography variant={"caption"} color={"textSecondary"}>
                    {`CPU:`}
                </Typography>
                <Typography variant={"caption"} color={`${getResourceUsageColor(data.resourcesUsage.cpuUsagePercent)}.main`}>
                    {` ${data.resourcesUsage.cpuUsagePercent.toFixed(2)}%`}
                </Typography>
            </Box>
            <Box pt={1}>
                <LinearProgress
                    sx={progressStyle}
                    color={getResourceUsageColor(data.resourcesUsage.memoryUsagePercent)}
                    variant={"determinate"}
                    value={data.resourcesUsage.memoryUsagePercent}
                />
                <Typography variant={"caption"} color={"textSecondary"}>
                    {`Memory:`}
                </Typography>
                <Typography variant={"caption"} color={`${getResourceUsageColor(data.resourcesUsage.memoryUsagePercent)}.main`}>
                    {` ${data.resourcesUsage.memoryUsagePercent.toFixed(1)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

// ======================
// NexusCountCircle
// ======================

interface NexusCountCircleProps {
    activeNexus: number;
    totalNexus: number;
}

const NexusCountCircle: React.FC<NexusCountCircleProps> = (p) => {
    const { activeNexus, totalNexus } = p;
    const theme = useTheme();
    return (
        <DarkFlatCard sx={{ padding: 0.5, width: "fit-content", borderRadius: 100 }}>
            <Stack sx={{ height: "100%" }} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: alpha(theme.palette.primary.main, 0.2),
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                        }}
                        size={80}
                        thickness={4}
                        value={100}
                    />
                    <CircularProgress thickness={4} size={80} variant={"determinate"} value={(activeNexus / totalNexus) * 100} />

                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 6,
                            right: 0,
                            position: "absolute",
                        }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Box textAlign={"center"} pt={1}>
                            <Typography sx={{ lineHeight: 0.5 }} variant={"h6"}>{`${activeNexus}/${totalNexus}`}</Typography>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {"Nexus"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </DarkFlatCard>
    );
};

// ======================
// CmoSystemMigrationStats
// ======================

interface CmoSystemMigrationIoStatsProps {
    data: CmoSystemInfo.AsObject;
}

const CmoSystemMigrationIoStats: React.FC<CmoSystemMigrationIoStatsProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    const productionIoMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [data.ioStats.totalThroughput]);
    const migrationRateMaxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [data.migrationSummary.currentThroughput]);

    const maxValue = productionIoMaxValue > migrationRateMaxValue ? productionIoMaxValue : migrationRateMaxValue;
    const progressStyle = useLinearProgressStyle(true);
    return (
        <>
            <Box width={"100%"}>
                <LinearProgress
                    sx={{
                        ...progressStyle,
                        "& .MuiLinearProgress-dashed": {
                            backgroundImage: "none",
                            animation: "none",
                        },
                        "& .MuiLinearProgress-bar2Buffer": {
                            backgroundColor: theme.palette.primary.light,
                        },
                    }}
                    variant={"buffer"}
                    valueBuffer={(data.ioStats.totalThroughput / maxValue) * 100}
                    value={(data.ioStats.writeThroughput / maxValue) * 100}
                />
                <Stack direction={"row"} spacing={1} pb={0.5} pt={0.5}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {`Production I/O: `}
                    </Typography>
                    <Typography variant={"caption"} fontWeight={600}>
                        {formatKnownDataType(data.ioStats.totalThroughput, KnownDataType.THROUGHPUT)}
                    </Typography>
                    <BlackTooltip
                        title={
                            <Box p={1}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <GoDotFill color={theme.palette.primary.main} />
                                    <Typography variant={"subtitle2"}>{`Write I/O: ${formatKnownDataType(
                                        data.ioStats.writeThroughput,
                                        KnownDataType.THROUGHPUT
                                    )}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"} pt={1}>
                                    <GoDotFill color={theme.palette.primary.light} />
                                    <Typography variant={"subtitle2"}>{`Read I/O: ${formatKnownDataType(
                                        data.ioStats.readThroughput,
                                        KnownDataType.THROUGHPUT
                                    )}`}</Typography>
                                </Stack>
                            </Box>
                        }
                        arrow
                    >
                        <Box>
                            <AiOutlineInfoCircle />
                        </Box>
                    </BlackTooltip>
                </Stack>
            </Box>
            <Box pb={1} pt={1} width={"100%"}>
                <LinearProgress sx={progressStyle} variant={"determinate"} value={(data.migrationSummary.currentThroughput / maxValue) * 100} />
                <Stack direction={"row"} justifyContent={"space-between"} pt={0.5}>
                    <Stack direction={"row"} spacing={1} pb={0.5}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Migration Rate: `}
                        </Typography>
                        <Typography variant={"caption"} fontWeight={600}>
                            {formatKnownDataType(data.migrationSummary.currentThroughput, KnownDataType.THROUGHPUT)}{" "}
                        </Typography>
                    </Stack>
                    <Box pb={1} display={"flex"} justifyContent={"flex-end"}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {formatKnownDataType(maxValue, KnownDataType.THROUGHPUT)}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        </>
    );
};

// ======================
// CmoSystemInsertionInfo
// ======================

interface CmoSystemInsertionInfoProps {
    data: CmoSystemInfo.AsObject;
}

const CmoSystemInsertionInfo: React.FC<CmoSystemInsertionInfoProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    return (
        <>
            <Stack direction={"row"} spacing={6} justifyContent={"center"}>
                <Box textAlign={"center"}>
                    <Typography variant={"h6"}>{formatKnownDataType(data.insertionSummary.storageCapacity, KnownDataType.CAPACITY)}</Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {"Inserted"}
                    </Typography>
                </Box>
                <Box textAlign={"center"}>
                    <Typography variant={"h6"}>{data.insertionSummary.volumes}</Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {"Volumes"}
                    </Typography>
                </Box>
                <Box textAlign={"center"}>
                    <Typography variant={"h6"}>{data.insertionSummary.paths}</Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {"Paths"}
                    </Typography>
                </Box>
            </Stack>
            <Stack pt={1} spacing={1} direction={"row"} alignItems={"center"}>
                <Avatar
                    variant={"rounded"}
                    sx={{
                        borderRadius: 3,
                        background: theme.palette.cirrus.main,
                        border: `1.5px solid ${theme.palette.primary.main}`,
                    }}
                >
                    <MdList color={"white"} />
                </Avatar>
                <Stack direction={"row"} alignItems={"flex-start"} width={"100%"}>
                    <Stack width={"100%"} direction={"column"} pb={1}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {"Inserted Storage"}
                        </Typography>
                        <TruncatedTextArray variant={"caption"} emptyString={"--"} arrayLimit={2} text={data.insertionSummary.storageVendorsList || []} />
                    </Stack>
                    <Stack width={"100%"} direction={"column"}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {"Destination Storage"}
                        </Typography>
                        <TruncatedTextArray
                            variant={"caption"}
                            emptyString={"--"}
                            arrayLimit={2}
                            text={data.destinationSummary.connectedStorageVendorsList || []}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

// ======================
// CmoSystemMigrationProgress
// ======================

interface CmoSystemMigrationProgressProps {
    data: CmoSystemInfo.AsObject;
}

const CmoSystemMigrationProgress: React.FC<CmoSystemMigrationProgressProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    const progressStyle = useLinearProgressStyle(true);

    return (
        <Stack pt={1} spacing={2} direction={"row"} alignItems={"center"}>
            <Avatar
                variant={"rounded"}
                sx={{
                    borderRadius: 3,
                    background: theme.palette.cirrus.main,
                    border: `1.5px solid ${theme.palette.primary.main}`,
                }}
            >
                <MigrationIcon color={"white"} />
            </Avatar>
            <Box width={"100%"}>
                <Stack direction={"row"} spacing={1} alignItems={"baseline"}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {data.migrationSummary.progressPercentage}%
                    </Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {`(${formatKnownDataType(data.migrationSummary.totalMigrated, KnownDataType.CAPACITY)} / ${formatKnownDataType(
                            data.migrationSummary.totalData,
                            KnownDataType.CAPACITY
                        )} migrated)`}
                    </Typography>
                </Stack>
                <LinearProgress variant={"determinate"} sx={progressStyle} value={data.migrationSummary.progressPercentage} />
                <Typography variant={"caption"} color={"textSecondary"}>
                    {`${data.migrationSummary.sessionsActive} Active Migration Sessions (${data.migrationSummary.volumesTotal} Volumes)`}
                </Typography>
            </Box>
        </Stack>
    );
};

const getResourceUsageColor = (usagePercent: number) => {
    if (usagePercent < 75) {
        return "success";
    }
    if (usagePercent < 90) {
        return "warning";
    }
    return "error";
};
