// ======================
// MigrateOpsNewOperationBuilderPage
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import {
    Box,
    Button,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { getOperationRecipeLabel, useNavigateToNewOpBuilder, useNavigateToNewOpScreen } from "./MigrateOpsCommon";
import { CodeCard, DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { generateJsonObjectFromConfigFieldMap, useMigrateOpsNewOperationBuilderState } from "./MigrateOpsNewOperationBuilderState";
import React from "react";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import { MdAdd, MdClose, MdRemove } from "react-icons/md";
import ProtoDoc from "../../assets/migrateOps/migrateops-proto.json";
import { ConfigField, ConfigFieldMap, getCurrentRecipeConfigFieldMap } from "./MigrateOpsGeneratedProtobufJsonHelpers";
import * as YAML from "yaml";
import { formatSnakeCaseToPascalCase } from "../../common/utils/formatter";
import { ClipboardButton, ClipboardText, CopyToClipIcon } from "../../common/clipboard/ClipboardComponents";

// ======================
// NewMigrateOpsYamlBuilderButton
// ======================

interface MigrateOpsNewOperationBuilderButtonProps {}

export const MigrateOpsNewOperationBuilderButton: React.FC<MigrateOpsNewOperationBuilderButtonProps> = (p) => {
    const goToYamlBuilder = useNavigateToNewOpBuilder();
    return (
        <Button variant={"contained"} color={"secondary"} onClick={goToYamlBuilder}>
            {"Open Config Builder"}
        </Button>
    );
};

interface MigrateOpsNewOperationBuilderPageProps {}

export const MigrateOpsNewOperationBuilderPage: React.FC<MigrateOpsNewOperationBuilderPageProps> = (p) => {
    const createFinalJson = useMigrateOpsNewOperationBuilderState((s) => s.createFinalJsObject);
    const setJsonAppliedToEditor = useMigrateOpsNewOperationBuilderState((s) => s.setJsonAppliedToEditor);
    const finalJsObject = useMigrateOpsNewOperationBuilderState((s) => s.generatedJsonObject);
    const operations = useMigrateOpsNewOperationBuilderState((s) => s.operations);
    const goToCreateOperationsPage = useNavigateToNewOpScreen();
    const handleGenerateYaml = () => {
        createFinalJson();
        setJsonAppliedToEditor(true);
        goToCreateOperationsPage();
    };

    const yamlId = "newGeneratedConfigId";

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"MigrateOps Configuration Builder"} />
            <Typography>{"Fill out the following information to generate the YAML for your configurations."}</Typography>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={7}>
                    {operations.map((op, idx) => {
                        return <OperationCard opIndex={idx} key={idx} />;
                    })}
                    <Box display={"flex"} justifyContent={"center"}>
                        <AddOperationButton />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box width={"100%"} sx={{ position: "sticky", top: 100 }}>
                        <CodeCard sx={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}>
                            <CardContent sx={{ height: "100%", overflow: "auto" }}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Typography variant={"h6"}>{"YAML"}</Typography>
                                </Box>
                                <Box>
                                    <pre>
                                        {YAML.stringify(finalJsObject, {
                                            indent: 2,
                                            defaultKeyType: "PLAIN",
                                            defaultStringType: "QUOTE_DOUBLE",
                                        })}
                                    </pre>
                                </Box>
                            </CardContent>
                        </CodeCard>
                        <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                            <ClipboardButton
                                clipboardText={YAML.stringify(finalJsObject, null, 2)}
                                label={"Copy"}
                                clipboardId={yamlId}
                                startIcon={<CopyToClipIcon />}
                            />
                            <Button variant={"contained"} color={"primary"} onClick={() => handleGenerateYaml()}>
                                {"Apply To Editor"}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ScreenContainer>
    );
};

// ======================
// OperationCard
// ======================

interface OperationCardProps {
    opIndex: number;
}

export const OperationCard: React.FC<OperationCardProps> = (p) => {
    const { opIndex } = p;
    const excludedRecipes = [OperationRecipeID.SAMPLE_RECIPE, OperationRecipeID.UNKNOWN_RECIPE];
    const removeOperation = useMigrateOpsNewOperationBuilderState((s) => s.removeOperation);
    const operation = useMigrateOpsNewOperationBuilderState((s) => s.operations[opIndex]);
    const setOperationField = useMigrateOpsNewOperationBuilderState((s) => s.setOperationField);
    const setOperationSelectedOneOfState = useMigrateOpsNewOperationBuilderState((s) => s.setOperationConfigFieldMapFromRecipe);
    const operationConfigJson = ProtoDoc.files
        .find((f) => f.name === "galaxycompletepb/operationpb/operation.proto")
        .messages.find((m) => m.name === "OperationConfig");

    return (
        <Box pb={2}>
            <DarkFlatOutlinedCard>
                <CardContent>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant={"h6"}>{`Operation #${opIndex + 1}`}</Typography>
                        <IconButton onClick={() => removeOperation(opIndex)}>
                            <MdClose />
                        </IconButton>
                    </Stack>
                    <Stack spacing={2} pt={2}>
                        <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                            {"Name"}
                        </Typography>
                        <Typography variant={"body2"}>{"Specify a name for this operation."}</Typography>
                        <TextField
                            value={operation.name}
                            onChange={(e) => setOperationField(opIndex, "name", e.target.value)}
                            variant={"filled"}
                            fullWidth
                            label={"Name"}
                        />
                        <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                            {"Notes"}
                        </Typography>
                        <Typography variant={"body2"}>{"Optionally, add descriptive notes for future references."}</Typography>
                        <TextField
                            value={operation.notes}
                            onChange={(e) => setOperationField(opIndex, "notes", e.target.value)}
                            variant={"filled"}
                            fullWidth
                            label={"Notes"}
                        />
                        <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                            {"Recipe"}
                        </Typography>
                        <Typography variant={"body2"}>
                            {"Select the recipe for this operation. A recipe consists of predefined automation steps for your data mobility operation."}
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel variant={"filled"} id={"recipe-label"}>
                                {"Recipe"}
                            </InputLabel>
                            <Select
                                value={operation.recipe}
                                onChange={(e) => {
                                    setOperationField(opIndex, "recipe", e.target.value);
                                    setOperationSelectedOneOfState(getCurrentRecipeConfigFieldMap(ProtoDoc, operationConfigJson, e.target.value), opIndex);
                                }}
                                fullWidth
                                variant={"filled"}
                                labelId={"recipe-label"}
                            >
                                {Object.keys(OperationRecipeID)
                                    .filter((id) => !excludedRecipes.includes(OperationRecipeID[id as keyof typeof OperationRecipeID]))
                                    .map((recipe) => {
                                        return (
                                            <MenuItem key={recipe} value={recipe}>
                                                {getOperationRecipeLabel(OperationRecipeID[recipe as keyof typeof OperationRecipeID])}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                            {"Config"}
                        </Typography>
                        {operation.recipe && (
                            <DarkFlatOutlinedCard>
                                <CardContent>
                                    <ConfigFieldsBuilder
                                        opIndex={opIndex}
                                        currentConfig={getCurrentRecipeConfigFieldMap(ProtoDoc, operationConfigJson, operation.recipe)}
                                    />
                                </CardContent>
                            </DarkFlatOutlinedCard>
                        )}
                    </Stack>
                </CardContent>
            </DarkFlatOutlinedCard>
        </Box>
    );
};

// ======================
// ConfigFieldsBuilder
// ======================

interface ConfigFieldsBuilderProps {
    opIndex: number;
    currentConfig: ConfigFieldMap;
    nestedFieldNames?: Array<string | number>;
    objectArrayIndex?: number;
}

export const ConfigFieldsBuilder: React.FC<ConfigFieldsBuilderProps> = (p) => {
    const { opIndex, currentConfig, nestedFieldNames, objectArrayIndex } = p;
    const builderState = useMigrateOpsNewOperationBuilderState();
    const operation = builderState.operations[opIndex];
    const setConfigField = builderState.setConfigField;
    const getConfigField = builderState.getConfigField;
    const addToArray = builderState.addItemToArrayField;
    const removeFromArray = builderState.removeItemFromArrayField;
    const setSelectedOneOfValue = builderState.setSelectedOneOfValue;
    const getSelectedOneOfValue = builderState.getSelectedOneOfValue;
    const currentSelectedOneOfValue = getSelectedOneOfValue(opIndex, nestedFieldNames);
    const setExpandedState = builderState.setObjectFieldExpanded;
    const getExpandedState = builderState.getObjectFieldExpanded;
    const theme = useTheme();

    return (
        <>
            {Object.keys(currentConfig).map((key) => {
                const fieldNames = nestedFieldNames ? [...nestedFieldNames, key] : [key];
                const isObjectExpanded = getExpandedState(opIndex, fieldNames);
                if (currentConfig[key].dataType === "oneOf") {
                    return (
                        <Box pb={2}>
                            <DarkFlatOutlinedCard>
                                <Box key={key} p={2}>
                                    <ConfigFieldsBuilder
                                        objectArrayIndex={objectArrayIndex}
                                        opIndex={opIndex}
                                        currentConfig={currentConfig[key].oneOfFields}
                                        nestedFieldNames={fieldNames}
                                    />
                                </Box>
                            </DarkFlatOutlinedCard>
                        </Box>
                    );
                }
                if (currentConfig[key].dataType === "array") {
                    if (currentConfig[key].arrayType === "object") {
                        return (
                            <Box key={key}>
                                <Box pb={2}>
                                    <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                                        {formatSnakeCaseToPascalCase(key, true)}
                                    </Typography>
                                </Box>
                                {!!currentConfig[key].description && (
                                    <Box pb={2}>
                                        <Typography variant={"body2"}>{currentConfig[key].description}</Typography>
                                    </Box>
                                )}
                                {getConfigField(opIndex, fieldNames)?.map((item: any, arrayIndex: number) => {
                                    return (
                                        <Box key={arrayIndex} pt={1} pb={1}>
                                            <DarkFlatOutlinedCard>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2} pb={0}>
                                                    <Typography>{`Item ${arrayIndex + 1}`}</Typography>
                                                    <IconButton
                                                        color={"secondary"}
                                                        onClick={() => {
                                                            removeFromArray(opIndex, fieldNames, arrayIndex);
                                                        }}
                                                    >
                                                        <MdClose />
                                                    </IconButton>
                                                </Box>

                                                <Box p={1}>
                                                    <ConfigFieldsBuilder
                                                        opIndex={opIndex}
                                                        currentConfig={currentConfig[key].arrayItemType}
                                                        nestedFieldNames={[...fieldNames, arrayIndex]}
                                                        objectArrayIndex={arrayIndex}
                                                    />
                                                </Box>
                                            </DarkFlatOutlinedCard>
                                        </Box>
                                    );
                                })}
                                <Box pt={1} pb={1}>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            addToArray(opIndex, fieldNames, {});
                                        }}
                                    >
                                        {"Add Item"}
                                    </Button>
                                </Box>
                            </Box>
                        );
                    } else {
                        return (
                            <Box key={key}>
                                <Box pb={2}>
                                    <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                                        {formatSnakeCaseToPascalCase(key, true)}
                                    </Typography>
                                </Box>
                                {!!currentConfig[key].description && (
                                    <Box pb={2}>
                                        <Typography variant={"body2"}>{currentConfig[key].description}</Typography>
                                    </Box>
                                )}
                                {getConfigField(opIndex, fieldNames)?.map((item: any, arrayIndex: number) => {
                                    return (
                                        <Box pt={1} pb={1} key={arrayIndex}>
                                            <Stack direction={"row"} spacing={1} pb={1} alignItems={"center"}>
                                                <Box display={"flex"} flexGrow={1} width={"100%"}>
                                                    <ConfigFieldInput
                                                        configField={currentConfig[key]}
                                                        fieldName={[...fieldNames, arrayIndex]}
                                                        value={item}
                                                        opIndex={opIndex}
                                                        currentOneOfCheckedValue={currentSelectedOneOfValue}
                                                    />
                                                </Box>
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={() => {
                                                        removeFromArray(opIndex, fieldNames, arrayIndex);
                                                    }}
                                                >
                                                    <MdClose />
                                                </IconButton>
                                            </Stack>
                                        </Box>
                                    );
                                })}
                                <Box pt={1} pb={1}>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            addToArray(opIndex, fieldNames, null);
                                        }}
                                    >
                                        {"Add Item"}
                                    </Button>
                                </Box>
                            </Box>
                        );
                    }
                }
                if (currentConfig[key].dataType === "object") {
                    return (
                        <Box key={key}>
                            <Stack direction={"row"} spacing={1} pb={1} alignItems={"center"}>
                                {currentConfig[key].isOneOf && (
                                    <Radio
                                        checked={currentSelectedOneOfValue === key}
                                        onChange={(e) => {
                                            if (Object.keys(currentConfig[key].properties).length === 0) {
                                                setConfigField(opIndex, fieldNames, {});
                                            }
                                            setSelectedOneOfValue(opIndex, nestedFieldNames, key);
                                            setExpandedState(opIndex, fieldNames, true);
                                        }}
                                    />
                                )}
                                <Typography
                                    sx={{
                                        fontFamily: "monospace",
                                        "&:hover":
                                            Object.keys(currentConfig[key].properties).length > 0
                                                ? {
                                                      cursor: "pointer",
                                                      color: theme.palette.primary.light,
                                                  }
                                                : {},
                                    }}
                                    fontWeight={600}
                                    color={"primary"}
                                    onClick={() => {
                                        if (currentConfig[key].isOneOf && currentSelectedOneOfValue !== key) {
                                            return;
                                        }
                                        setExpandedState(opIndex, fieldNames, !isObjectExpanded);
                                    }}
                                >
                                    {formatSnakeCaseToPascalCase(key, true)}
                                </Typography>
                                {currentConfig[key].isOneOf && currentSelectedOneOfValue !== key ? null : Object.keys(currentConfig[key].properties).length ===
                                  0 ? null : (
                                    <IconButton
                                        onClick={() => {
                                            setExpandedState(opIndex, fieldNames, !isObjectExpanded);
                                        }}
                                        disableRipple
                                    >
                                        {isObjectExpanded ? <MdRemove /> : <MdAdd />}
                                    </IconButton>
                                )}
                            </Stack>
                            {!!currentConfig[key].description && (
                                <Box pb={2}>
                                    <Typography variant={"body2"}>{currentConfig[key].description}</Typography>
                                </Box>
                            )}
                            <Box
                                p={1}
                                sx={{
                                    visibility: isObjectExpanded && !!Object.keys(currentConfig[key].properties).length ? "visible" : "hidden",
                                    height: isObjectExpanded && !!Object.keys(currentConfig[key].properties).length ? "auto" : 0,
                                }}
                            >
                                <DarkFlatOutlinedCard>
                                    <Box p={2}>
                                        <ConfigFieldsBuilder
                                            opIndex={opIndex}
                                            currentConfig={currentConfig[key].properties}
                                            nestedFieldNames={fieldNames}
                                            objectArrayIndex={objectArrayIndex}
                                        />
                                    </Box>
                                </DarkFlatOutlinedCard>
                            </Box>
                        </Box>
                    );
                }
                return (
                    <Box pb={2} key={key}>
                        <Stack direction={"row"} spacing={1} pb={1} alignItems={"center"}>
                            {currentConfig[key].isOneOf && (
                                <Radio
                                    checked={currentSelectedOneOfValue === key}
                                    onChange={(e) => {
                                        setSelectedOneOfValue(opIndex, nestedFieldNames, key);
                                        setExpandedState(opIndex, fieldNames, true);
                                    }}
                                />
                            )}
                            <Typography sx={{ fontFamily: "monospace" }} fontWeight={600} color={"primary"}>
                                {formatSnakeCaseToPascalCase(key, true)}
                            </Typography>
                        </Stack>
                        {!!currentConfig[key].description && (
                            <Box pb={2}>
                                <Typography variant={"body2"}>{currentConfig[key].description}</Typography>
                            </Box>
                        )}

                        <Box pl={currentConfig[key].isOneOf ? 0 : 0}>
                            <ConfigFieldInput
                                configField={currentConfig[key]}
                                fieldName={fieldNames}
                                value={operation.config[key]}
                                opIndex={opIndex}
                                currentOneOfCheckedValue={currentSelectedOneOfValue}
                            />
                        </Box>
                    </Box>
                );
            })}
        </>
    );
};

// ======================
// ConfigFieldInput
// ======================

interface ConfigFieldInputProps {
    configField: ConfigField;
    fieldName: string | Array<string | number>;
    value: any;
    opIndex: number;
    currentOneOfCheckedValue?: string;
}

export const ConfigFieldInput: React.FC<ConfigFieldInputProps> = (p) => {
    const { configField, fieldName, value, opIndex } = p;
    const fieldType = configField.dataType === "array" ? configField.arrayType : configField.dataType;
    const setConfigField = useMigrateOpsNewOperationBuilderState((s) => s.setConfigField);
    const fieldDisabled = configField.isOneOf && p.currentOneOfCheckedValue !== fieldName[fieldName.length - 1];
    const fieldDisplayName = Array.isArray(fieldName)
        ? isNaN(Number(fieldName[fieldName.length - 1]))
            ? fieldName[fieldName.length - 1]
            : fieldName[fieldName.length - 2]
        : fieldName;

    const renderField = () => {
        if (configField.enum) {
            return (
                <FormControl fullWidth>
                    <InputLabel variant={"filled"} id={`${fieldDisplayName}-label`}>
                        {fieldDisplayName}
                    </InputLabel>
                    <Select
                        value={value}
                        onChange={(e) => setConfigField(opIndex, fieldName, e.target.value)}
                        fullWidth
                        disabled={fieldDisabled}
                        variant={"filled"}
                        labelId={`${fieldDisplayName}-label`}
                    >
                        {configField.enum.values.map((enumValue) => {
                            return (
                                <MenuItem key={enumValue.name} value={enumValue.name}>
                                    {enumValue.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            );
        }

        if (fieldType === "string") {
            return (
                <TextField
                    value={value}
                    variant={"filled"}
                    fullWidth
                    disabled={fieldDisabled}
                    label={fieldDisplayName}
                    onChange={(e) => {
                        setConfigField(opIndex, fieldName, e.target.value);
                    }}
                />
            );
        }
        if (fieldType === "integer") {
            return (
                <TextField
                    type={"number"}
                    value={value}
                    variant={"filled"}
                    disabled={fieldDisabled}
                    fullWidth
                    label={fieldDisplayName}
                    onChange={(e) => setConfigField(opIndex, fieldName, Number(e.target.value))}
                />
            );
        }
        if (fieldType === "boolean") {
            return (
                <FormControl disabled={fieldDisabled}>
                    <RadioGroup
                        row
                        aria-labelledby={`boolean-label`}
                        name={`boolean`}
                        value={value}
                        onChange={(e) => setConfigField(opIndex, fieldName, e.target.value === "true")}
                    >
                        <FormControlLabel value={true} control={<Radio />} label={"Yes"} />
                        <FormControlLabel value={false} control={<Radio />} label={"No"} />
                    </RadioGroup>
                </FormControl>
            );
        }
        if (fieldType === "timestamp") {
            return (
                <TextField
                    helperText={"RFC3339 format, e.g. 2019-10-12T07:20:50.52Z"}
                    value={value}
                    variant={"filled"}
                    disabled={fieldDisabled}
                    fullWidth
                    label={fieldDisplayName}
                    onChange={(e) => setConfigField(opIndex, fieldName, e.target.value)}
                />
            );
        }
        if (fieldType === "duration") {
            return (
                <TextField
                    helperText={"duration format in [s | ms | us], e.g. 30s"}
                    value={value}
                    variant={"filled"}
                    disabled={fieldDisabled}
                    fullWidth
                    label={fieldDisplayName}
                    onChange={(e) => {
                        setConfigField(opIndex, fieldName, e.target.value);
                    }}
                />
            );
        }
    };

    return <Box width={"100%"}>{renderField()}</Box>;
};

// ======================
// AddOperationButton
// ======================
interface AddOperationButtonProps {}

export const AddOperationButton: React.FC<AddOperationButtonProps> = (p) => {
    const addOperation = useMigrateOpsNewOperationBuilderState((s) => s.addOperation);

    return (
        <Button variant={"outlined"} color={"secondary"} onClick={() => addOperation()}>
            {"Add Operation"}
        </Button>
    );
};
