// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectManagementScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { ProjectEventLog } from "./ProjectActivities";
import { MigrationLicenseCard, MigrationLicenseDetailScreen } from "../license/ProjectLicenseScreen";
import { Route, Routes, useParams } from "react-router-dom";
import { LICENSE_SUBROUTE, MANAGEMENT_SUBROUTE } from "../app/AppRoutes";
import { PrivateEditionLocalRequestLicenseScreen } from "../privateEdition/PrivateEditionLocalRequestLicenseScreen";
import { useAppServices } from "../app/services";
import { renderServerDataWithLoadingBox, useInitData } from "../core/data/DataLoaderHooks";
import { useNavigateToUserLicensePage } from "../user/UserCommon";
import { AppHintID } from "../help/HelpCommon";
import { useOpenHelpSearch } from "../help/hooks/help_hooks";
import { HideInPrivateEdition } from "../auth/PrivateEditionView";
import { OperatorView } from "../auth/AuthenticatedViews";
import { PrivateEditionLocalPendingRequestScreen } from "../privateEdition/PrivateEditionLocalPendingRequestScreen";
import { useCurrentProjectID } from "../project/CurrentProjectState";

// ======================
// ProjectManagementScreen
// ======================
interface ProjectManagementScreenProps {
    project: ProjectDetails;
}

export const ProjectManagementScreens: React.FC<ProjectManagementScreenProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<ProjectManagementMainScreen project={p.project} />} />
            <Route path={MANAGEMENT_SUBROUTE.LICENSE}>
                <Route index element={<MigrationLicenseDetailScreen />} />
                <Route path={LICENSE_SUBROUTE.REQUEST} element={<PrivateEditionLocalPendingRequestScreen />} />
                <Route path={LICENSE_SUBROUTE.WITHDRAW} element={<PrivateEditionLocalRequestLicenseScreen type={"Withdrawal"} />} />
                <Route path={LICENSE_SUBROUTE.RENEW} element={<PrivateEditionLocalRequestLicenseScreen type={"Renewal"} />} />
                <Route path={LICENSE_SUBROUTE.ACTIVATE} element={<PrivateEditionLocalRequestLicenseScreen type={"Activation"} />} />
            </Route>
        </Routes>
    );
});

// ======================
// ProjectManagementMainScreen
// ======================

interface ProjectManagementMainScreenProps {
    project: ProjectDetails;
}

export const ProjectManagementMainScreen: React.FC<ProjectManagementMainScreenProps> = observer((p) => {
    const { project } = p;
    const projectInfo = project.getInfo();

    return (
        <ScreenContainer>
            <ScreenTitleBar title={projectInfo.getName()} />
            <Box height={"100%"}>
                <NoLicenseWarning projectId={projectInfo.getProjectId()} />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Card
                            sx={{
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <List>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <ListSubheader>{`Project`}</ListSubheader>
                                </Box>

                                <ListItem>
                                    <ListItemText primary={projectInfo.getDescription()} secondary={`Description`}></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={formatKnownDataType(projectInfo.getCreatedAt().toDate(), KnownDataType.DATE)}
                                        secondary={`Created At`}
                                    ></ListItemText>
                                </ListItem>
                                <Divider />
                                <ListSubheader>{`Emergency Contact`}</ListSubheader>
                                <ListItem>
                                    <ListItemText primary={projectInfo.getEmergencyEmail() || "Not Set"} secondary={`Email`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={projectInfo.getEmergencyPhone() || "Not Set"} secondary={`Phone`} />
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Box height={"100%"} display={"flex"} flexDirection={"column"}>
                            <Box>
                                <Card>
                                    <ListSubheader>{"Announcements"}</ListSubheader>
                                    <CardContent>
                                        <Alert severity="info">No New Announcements at this moment</Alert>
                                    </CardContent>
                                    <br />
                                    <br />
                                    <br />
                                </Card>
                            </Box>
                            <Box display={"flex"} flexGrow={1} pt={2} width={"100%"}>
                                <MigrationLicenseCard projectId={projectInfo.getProjectId()} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <br />
            <ProjectEventLog />
        </ScreenContainer>
    );
});

// ======================
// NoLicenseWarning
// ======================

interface NoLicenseWarningProps {
    projectId: string;
}

export const NoLicenseWarning: React.FC<NoLicenseWarningProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const { projectId } = p;
    const goToUserLicenseKey = useNavigateToUserLicensePage();
    const openHelpSearch = useOpenHelpSearch();
    console.debug(projectId);

    useInitData({
        poll: () => licenseService.projectLicenseDetails.fetchData(projectId),
        pollInterval: 300,
    });

    return renderServerDataWithLoadingBox(licenseService.projectLicenseDetails, (data) => {
        if (!data.itemsList.find((c) => c.currentBalance > 0)) {
            return (
                <HideInPrivateEdition>
                    <OperatorView>
                        <Alert
                            severity={"warning"}
                            variant={"outlined"}
                            sx={{
                                "& .MuiAlert-message": {
                                    width: `100%`,
                                },
                                marginBottom: 2,
                            }}
                        >
                            <AlertTitle>{"License Required"}</AlertTitle>

                            <Grid container spacing={2} width={"100%"}>
                                <Grid item xs={12} md={6}>
                                    {`License is required to perform a migration in this project. Transfer license from your User License Key or use our guide to learn more about Cirrus Migrate Cloud Licensing.`}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container justifyContent={"flex-end"} spacing={2}>
                                        <Grid item>
                                            <Button variant={"outlined"} color={"secondary"} onClick={() => openHelpSearch(AppHintID.LICENSE_KEY)}>
                                                Learn More
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"contained"} color={"secondary"} onClick={() => goToUserLicenseKey()}>
                                                Transfer License
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Alert>
                    </OperatorView>
                </HideInPrivateEdition>
            );
        }
        return null;
    });
});
