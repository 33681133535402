import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useAppServices } from "../../app/services";
import { useParams } from "react-router-dom";
import { renderServerDataWithLoadingBox, useInitData } from "../../core/data/DataLoaderHooks";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { BackButton } from "../../../common/CommonButtons";
import { useNavigateToDeploymentDetails } from "../GalaxyMigrateCommon";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { ViewLogsRequest, ViewLogsResponse } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { Box, Button, ButtonGroup, Card, CardContent, Typography, Grid, Tooltip, alpha, Theme, ListSubheader, TextField, Chip } from "@mui/material";
import { MdRefresh, MdFileCopy, MdVerticalAlignTop, MdVerticalAlignBottom, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { ViewLogs } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { sleepMS } from "../../../common/utils/util";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { JavaScriptValue } from "google-protobuf/google/protobuf/struct_pb";

// ======================
// GalaxyMigrateDeploymentLiveLog
// ======================

interface GalaxyMigrateDeploymentLiveLogProps {}

export const GalaxyMigrateDeploymentLiveLog: React.FC<GalaxyMigrateDeploymentLiveLogProps> = observer((p) => {
    const { deploymentService, gmDeploymentService } = useAppServices();
    const { deploymentID } = useParams();
    const goBackToDetails = useNavigateToDeploymentDetails();

    useInitData({
        init: async () => {
            if (!deploymentService.currentDeploymentID) {
                await deploymentService.currentDeployment.fetchData(deploymentID);
            }
        },
    });

    return renderServerDataWithLoadingBox(deploymentService.currentDeployment, (data) => {
        const deployment = data.getInfo();
        const isDeploymentWindows = deployment.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS;

        const tabConfigs: TabConfig[] = [
            {
                label: "Application Log",
                renderer: () => <LogSection deploymentId={deploymentID} source={ViewLogsRequest.LogSource.GALAXY_MIGRATE} />,
            },
            {
                label: "System Log",
                renderer: () => (
                    <LogSection
                        deploymentId={deploymentID}
                        source={isDeploymentWindows ? ViewLogsRequest.LogSource.WINDOWS_EVENT_LOG : ViewLogsRequest.LogSource.SYSLOG}
                    />
                ),
            },
            {
                label: "MTDI Log",
                renderer: () => <LogSection deploymentId={deploymentID} source={ViewLogsRequest.LogSource.MTDI} />,
            },
            {
                label: "Integrations Log",
                renderer: () => <></>,
                hidden: true,
            },
        ];

        return (
            <ScreenContainer>
                <BackButton navFunction={() => goBackToDetails(deploymentID)} label={"Back To Host Details"} />
                <ScreenTitleBar title={deployment.getDeployment().getSystemName()} />
                <br />
                <TabGroup
                    configs={tabConfigs}
                    border
                    onChange={() => gmDeploymentService.deploymentLogs.resetData()}
                    tabProps={{
                        sx: {
                            width: 200,
                        },
                    }}
                />
            </ScreenContainer>
        );
    });
});

// ======================
// LogSection
// ======================

interface LogSectionProps {
    source: ViewLogsRequest.LogSource;
    deploymentId: string;
}

export const LogSection: React.FC<LogSectionProps> = observer((p) => {
    const { gmDeploymentService, progressService } = useAppServices();
    const [currentFilter, setCurrentFilter] = useState("");
    const [filterOn, setFilterOn] = useState(false);

    const onChangeTab = useCallback(async () => {
        return await gmDeploymentService.deploymentLogs.fetchData(p.deploymentId, p.source, "");
    }, [p.deploymentId, p.source, gmDeploymentService.deploymentLogs]);

    const refresh = async (filterString: string) => {
        if (!!filterString) {
            setFilterOn(true);
        } else {
            setFilterOn(false);
        }
        return await progressService.track(gmDeploymentService.deploymentLogs.fetchData(p.deploymentId, p.source, filterString), "Refreshing Logs...");
    };

    useInitData({
        init: () => gmDeploymentService.deploymentLogs.fetchData(p.deploymentId, p.source, currentFilter),
    });

    useEffect(() => {
        if (!gmDeploymentService.deploymentLogs.data) {
            onChangeTab();
        }
    }, [gmDeploymentService.deploymentLogs.data, onChangeTab]);

    return renderServerDataWithLoadingBox(gmDeploymentService.deploymentLogs, (data) => {
        const copyLogContentToClipboard = () => {
            const lines = data.getLogsResponse().getMessagesList();
            const content = lines.join("\n");
            window?.navigator?.clipboard?.writeText(content);
        };

        return (
            <Box>
                <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                    <LogActionsButtonGroup refresh={async () => await refresh(currentFilter)} copyContent={copyLogContentToClipboard} />
                    <Button color={"neutral"} startIcon={<MdVerticalAlignBottom />} onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                        Scroll To Bottom
                    </Button>
                </Box>
                <Box pt={4} display={"flex"} alignItems={"center"}>
                    <Box pr={2} width={"100%"}>
                        <TextField
                            value={currentFilter}
                            onChange={(e) => setCurrentFilter(e.target.value)}
                            variant={"outlined"}
                            fullWidth
                            label={"Enter filter keyword or phrase"}
                        />
                    </Box>
                    <Box>
                        <Button variant={"contained"} color={"primary"} disabled={!currentFilter} onClick={async () => await refresh(currentFilter)}>
                            Filter
                        </Button>
                    </Box>
                    <Box pl={1}>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            disabled={!filterOn}
                            onClick={async () => {
                                await refresh("");
                                setCurrentFilter("");
                            }}
                        >
                            Clear
                        </Button>
                    </Box>
                </Box>
                <Box pt={2} pb={2}>
                    <Card
                        sx={{
                            backgroundColor: "#000",
                        }}
                    >
                        <CardContent
                            sx={{
                                overflowWrap: "break-word",
                            }}
                        >
                            {data
                                .getLogsResponse()
                                .getMessagesList()
                                .map((m, i) => {
                                    if (m.getProperties()?.toJavaScript().length > 0) {
                                        return <ExpandableLogLine message={m} key={i} />;
                                    } else {
                                        return (
                                            <Typography
                                                key={i}
                                                sx={{
                                                    lineHeight: 2,
                                                    paddingLeft: 3,
                                                }}
                                            >
                                                {m.getMessage()}
                                            </Typography>
                                        );
                                    }
                                })}
                        </CardContent>
                    </Card>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Button color={"neutral"} startIcon={<MdVerticalAlignTop />} onClick={() => window.scrollTo(0, 0)}>
                        Scroll To Top
                    </Button>
                </Box>
            </Box>
        );
    });
});

// ======================
// ExpandableLogLine
// ======================

interface ExpandableLogLineProps {
    message: ViewLogsResponse.Message;
}

export const ExpandableLogLine: React.FC<ExpandableLogLineProps> = observer((p) => {
    const { message } = p;
    const [expanded, setExpanded] = useState(false);

    const handleClickLine = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                    "&:hover": {
                        backgroundColor: alpha("#fff", 0.2),
                        cursor: "pointer",
                    },
                }}
                onClick={handleClickLine}
            >
                <Box pr={1}>{expanded ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</Box>
                <Box>
                    <Typography
                        sx={{
                            lineHeight: 2,
                        }}
                    >
                        {message.getMessage()}
                    </Typography>
                </Box>
            </Box>
            {expanded && (
                <Box pt={1} pb={1} pl={2} width={"100%"}>
                    <Card
                        sx={{
                            overflowWrap: "break-word",
                            backgroundColor: (t: Theme) => t.palette.cirrus.light,
                        }}
                    >
                        <ListSubheader>Properties</ListSubheader>
                        <Box pr={2} pl={2} pb={2}>
                            <pre>{formatProperties(message.getProperties().toJavaScript())}</pre>
                        </Box>
                    </Card>
                </Box>
            )}
        </>
    );
});

// ======================
// LogActionsButtonGroup
// ======================

interface LogActionsButtonGroupProps {
    refresh: () => Promise<ViewLogs.Response>;
    copyContent: () => void;
}

export const LogActionsButtonGroup: React.FC<LogActionsButtonGroupProps> = observer((p) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleCopyContent = async () => {
        p.copyContent();
        handleTooltipOpen();
        await sleepMS(1000);
        handleTooltipClose();
    };

    return (
        <ButtonGroup variant={"outlined"}>
            <Button startIcon={<MdRefresh />} onClick={p.refresh}>
                Refresh
            </Button>
            <Tooltip
                title={"Logs Copied"}
                open={open}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <Button startIcon={<MdFileCopy />} onClick={handleCopyContent}>
                    Copy
                </Button>
            </Tooltip>
        </ButtonGroup>
    );
});

const formatProperties = (properties: Array<JavaScriptValue>) => {
    return JSON.stringify(properties, null, 2);
};
