// ======================
// VmConfigSection
// ======================

import { GalaxyMigrateDeploymentDetails, GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import * as React from "react";
import { useAppServices } from "../../app/services";
import { useNavigateToSessionEditVmConfigScreen } from "../CmcMigrationCommon";
import { Box, Button, Card, Grid, ListItem, ListItemIcon, ListItemText, ListSubheader, SvgIcon, Typography } from "@mui/material";
import { KVTable } from "../../../common/table/KVTable";
import { renderServerDataWithLoadingList } from "../../core/data/DataLoaderHooks";
import { BsFillHddNetworkFill } from "react-icons/bs";
import { FaAngleDoubleRight } from "react-icons/fa";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { observer } from "mobx-react-lite";
import { ComputeMigrationSpec } from "gc-web-proto/galaxycompletepb/apipb/domainpb/compute_pb";
import { TruncatedText } from "../../../common/text/TruncatedText";

interface VmConfigSectionProps {
    sessionInfo: GalaxyMigrateMigrationSessionInfo;
    deploymentInfo: GalaxyMigrateDeploymentDetails;
}

export const GmMigrationSessionVmConfig: React.FC<VmConfigSectionProps> = (p) => {
    const { sessionInfo, deploymentInfo } = p;
    const { deploymentService } = useAppServices();
    const computeMigrationType = sessionInfo.getComputeMigrationType();

    const vmConfigInfo = getVmConfigInfo(sessionInfo);

    const goToVmwareConfigEditPage = useNavigateToSessionEditVmConfigScreen();

    return (
        <Box pt={2}>
            <Box pb={2}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"h6"}>Destination VM Configuration</Typography>
                    <Button variant={"contained"} color={"secondary"} onClick={goToVmwareConfigEditPage}>
                        Edit VM Configuration
                    </Button>
                </Box>
                {computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE && (
                    <VmwareConfigInfo vmConfigInfo={vmConfigInfo as ComputeMigrationSpec.Vmware} />
                )}
                {computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE && (
                    <AzureConfigInfo vmConfigInfo={vmConfigInfo as ComputeMigrationSpec.Azure} />
                )}
            </Box>
        </Box>
    );
};

// ======================
// AzureConfigInfo
// ======================

interface AzureConfigInfoProps {
    vmConfigInfo: ComputeMigrationSpec.Azure;
}

export const AzureConfigInfo: React.FC<AzureConfigInfoProps> = observer((p) => {
    const { vmConfigInfo } = p;
    const { deploymentService } = useAppServices();

    return (
        <>
            <Box pt={2} pb={2}>
                <Card sx={{ height: "100%" }}>
                    <ListSubheader>VM Information</ListSubheader>
                    <KVTable
                        data={[
                            {
                                key: "Host Name",
                                value: vmConfigInfo.getHostName(),
                            },
                            {
                                key: "Description",
                                value: vmConfigInfo.getHostDescription(),
                            },
                            {
                                key: "Resource Group",
                                value: vmConfigInfo.getResourceGroupName() || `Default`,
                            },
                            {
                                key: "Machine Type",
                                value: vmConfigInfo.getMachineType() || `Default`,
                            },
                        ]}
                    />
                </Card>
            </Box>
            <Box>
                <Box pb={2}>
                    <Typography variant={"h6"}>Matching Network Interfaces</Typography>
                </Box>
                {renderServerDataWithLoadingList(deploymentService.currentDeployment, (data) => {
                    return (
                        <>
                            {data.getNetworkInterfacesList().map((n, i) => {
                                if (!vmConfigInfo.getNetworksList()[i]?.getName()) {
                                    return null;
                                }
                                const subnet = vmConfigInfo.getNetworksList()[i]?.getSubnetId();
                                const subnetDisplayName = subnet?.split("/virtualNetworks/")[subnet?.split("/virtualNetworks/").length - 1];
                                const networkSecurityGroup = vmConfigInfo.getNetworksList()[i]?.getNetworkSecurityGroupId();
                                const networkSecurityGroupDisplayName =
                                    networkSecurityGroup?.split("/networkSecurityGroups/")[networkSecurityGroup?.split("/networkSecurityGroups/").length - 1];
                                return (
                                    <Box pb={2} key={i}>
                                        <Card>
                                            <Box p={2}>
                                                <Grid container spacing={3} alignItems={"center"}>
                                                    <Grid item xs={12} lg={4}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <SvgIcon>
                                                                    <BsFillHddNetworkFill />
                                                                </SvgIcon>
                                                            </ListItemIcon>
                                                            <ListItemText primary={n.getName()} secondary={n.getAddr()} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid item xs={12} lg={1}>
                                                        <Box>
                                                            <SvgIcon>
                                                                <FaAngleDoubleRight />
                                                            </SvgIcon>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} lg={7}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <SvgIcon>
                                                                    <BsFillHddNetworkFill />
                                                                </SvgIcon>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={vmConfigInfo.getNetworksList()[i]?.getName()}
                                                                secondary={
                                                                    <Box>
                                                                        {`Subnet: ${subnetDisplayName}`}
                                                                        {`Network Security Group: ${networkSecurityGroupDisplayName}`}
                                                                    </Box>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Box>
                                );
                            })}
                        </>
                    );
                })}
            </Box>
        </>
    );
});

// ======================
// VmwareConfigInfo
// ======================

interface VmwareConfigInfoProps {
    vmConfigInfo: ComputeMigrationSpec.Vmware;
}

export const VmwareConfigInfo: React.FC<VmwareConfigInfoProps> = observer((p) => {
    const { vmConfigInfo } = p;
    const { deploymentService } = useAppServices();

    return (
        <>
            <Box pb={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ height: "100%" }}>
                            <ListSubheader>VM Information</ListSubheader>
                            <KVTable
                                data={[
                                    {
                                        key: "VM Name",
                                        value: vmConfigInfo.getVmName(),
                                    },
                                    {
                                        key: "Datastore",
                                        value: vmConfigInfo.getDatastore(),
                                    },
                                    {
                                        key: "Resource Pool",
                                        value: vmConfigInfo.getResourcePool() || `Default`,
                                    },
                                    {
                                        key: "VM Folder",
                                        value: vmConfigInfo.getVmFolder() || `Default`,
                                    },
                                ]}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ height: "100%" }}>
                            <ListSubheader>VM Hardware</ListSubheader>
                            <KVTable
                                data={[
                                    {
                                        key: "Total Number of CPUs",
                                        value: vmConfigInfo.getCpuCount(),
                                    },
                                    {
                                        key: "Cores Per CPU",
                                        value: vmConfigInfo.getCpuCoresPerSocket(),
                                    },
                                    {
                                        key: "Memory",
                                        value: `${vmConfigInfo.getMemoryMib()} MiB`,
                                    },
                                ]}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Box pb={2}>
                    <Typography variant={"h6"}>Matching Network Interfaces</Typography>
                </Box>
                {renderServerDataWithLoadingList(deploymentService.currentDeployment, (data) => {
                    return (
                        <>
                            {data.getNetworkInterfacesList().map((n, i) => {
                                if (!vmConfigInfo.getNetworksList()[i]?.getNetworkName()) {
                                    return null;
                                }
                                return (
                                    <Box pb={2} key={i}>
                                        <Card>
                                            <Box p={2}>
                                                <Grid container spacing={3} alignItems={"center"}>
                                                    <Grid item xs={3}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <SvgIcon>
                                                                    <BsFillHddNetworkFill />
                                                                </SvgIcon>
                                                            </ListItemIcon>
                                                            <ListItemText primary={n.getName()} secondary={n.getAddr()} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Box>
                                                            <SvgIcon>
                                                                <FaAngleDoubleRight />
                                                            </SvgIcon>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <SvgIcon>
                                                                    <BsFillHddNetworkFill />
                                                                </SvgIcon>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={vmConfigInfo.getNetworksList()[i]?.getNetworkName()}
                                                                secondary={`${vmConfigInfo.getNetworksList()[i]?.getAdapterType()}, ${
                                                                    vmConfigInfo.getNetworksList()[i]?.getCustomMacAddress() || "Auto Generate MAC"
                                                                }`}
                                                            />
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Box>
                                );
                            })}
                        </>
                    );
                })}
            </Box>
        </>
    );
});

const getVmConfigInfo = (sessionInfo: GalaxyMigrateMigrationSessionInfo) => {
    const computeMigrationType = sessionInfo.getComputeMigrationType();
    if (computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
        return sessionInfo.getComputeMigrationSpec().getAzureSpec();
    } else if (computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
        return sessionInfo.getComputeMigrationSpec().getVmwareSpec();
    } else {
        return null;
    }
};
