import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useAppServices } from "../../app/services";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { Box, Button, ButtonProps, DialogActions, DialogContent, DialogContentText, Grid } from "@mui/material";
import { FormTextField } from "../../../common/form/FormComponents";
import { getGalaxyMigrateHelperNodeOSName } from "../GalaxyMigrateCommon";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { useListIntegrationModules, useListProjectIntegrations } from "../../integrations/integration_hooks";
import {
    getModuleConfigByModule,
    IntegrationCard,
    INTEGRATIONS_TYPE_SUBROUTE,
    useNavigateToCreateIntegrationForm,
} from "../../integrations/IntegrationsCommon";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { SelectableBox } from "../../../common/card/SelectableCard";
import { useConfigureVmwareHelper, useConfigureAzureHelper } from "../gm_deployment_hooks";
import { ConfigureVmwareHelper } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { VmwareCompute } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";

interface ConfigureGalaxyMigrateCmcHelperButtonProps {
    systemID: string;
    os: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const ConfigureGalaxyMigrateCmcHelperButton = (p: ConfigureGalaxyMigrateCmcHelperButtonProps & Partial<ButtonProps>) => {
    const { systemID, os } = p;

    const helperName = `${getGalaxyMigrateHelperNodeOSName(os)}`;
    const dialogState = useDialogState();
    return (
        <>
            <Button variant={"outlined"} color={"secondary"} onClick={() => dialogState.open()} {...p}>
                {`Configure ${helperName}`}
            </Button>
            <ConfigureCmcHelperDialog dialogState={dialogState} systemID={systemID} os={os} />
        </>
    );
};

// ======================
// ConfigureGalaxyMigrateVmwareHelperDialog
// ======================
interface ConfigureCmcHelperDialogProps {
    dialogState: DialogState;
    systemID: string;
    os: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const ConfigureCmcHelperDialog: React.FC<ConfigureCmcHelperDialogProps> = observer((p) => {
    const { dialogState, systemID, os } = p;

    const getFormContent = (os: DeploymentHostEnvironment.DeploymentHostEnvironment) => {
        if (os === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
            return <ConfigureGalaxyMigrateVmwareHelperForm dialogState={dialogState} systemID={systemID} />;
        } else if (os === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
            return <ConfigureGalaxyMigrateAzureHelperForm dialogState={dialogState} systemID={systemID} />;
        }
    };

    return (
        <Dialog open={dialogState.isOpen} fullScreen={useShouldDialogFullScreen()} maxWidth={"md"} fullWidth onClose={dialogState.close}>
            {dialogState.isOpen && systemID && <Box height={"100%"}>{getFormContent(os)}</Box>}
        </Dialog>
    );
});

// ======================
// ConfigureGalaxyMigrateAzureHelperForm
// ======================

interface ConfigureGalaxyMigrateAzureHelperFormProps {
    dialogState: DialogState;
    systemID: string;
}

export const ConfigureGalaxyMigrateAzureHelperForm: React.FC<ConfigureGalaxyMigrateAzureHelperFormProps> = observer((p) => {
    const { dialogState, systemID } = p;
    const projectIntegrations = useListProjectIntegrations();

    const navigateToIntegrationForm = useNavigateToCreateIntegrationForm(INTEGRATIONS_TYPE_SUBROUTE.AZURE);

    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const configureAzureHelper = useConfigureAzureHelper();

    const configureHelper = async () => {
        const res = await configureAzureHelper.mutateAsync({
            systemId: systemID,
            integrationId: selectedIntegration,
        });
        if (!!res) {
            dialogState.close();
        }
    };

    const integrationDefs = useListIntegrationModules();

    return (
        <Box>
            <DialogTopBar dialogState={dialogState} divider title={"Configure CMC Helper For Azure Integration"} />
            <DialogContent>
                <QueryResultWrapper queryResult={projectIntegrations}>
                    <QueryResultWrapper queryResult={integrationDefs}>
                        <Box pb={2}>
                            <DialogContentText>
                                {projectIntegrations.data?.itemsList?.length > 0
                                    ? `Please select the Azure Integration that this helper node belongs to.`
                                    : `No Azure Integrations found. Please add an integration to continue.`}
                            </DialogContentText>
                        </Box>
                        {projectIntegrations.data?.itemsList?.length > 0 && (
                            <Grid container spacing={3}>
                                {projectIntegrations.data?.itemsList?.map((i) => {
                                    return (
                                        <Grid item xs={12} md={4}>
                                            <SelectableBox
                                                selected={selectedIntegration === i.id}
                                                checkmark
                                                onSelect={() => setSelectedIntegration(i.id)}
                                                darkBorder
                                            >
                                                <IntegrationCard
                                                    module={getModuleConfigByModule(IntegrationModule.AZURE, integrationDefs.data?.itemsList)}
                                                    instance={i}
                                                    dividerColor={"rgba(255, 255, 255, .2)"}
                                                    cardProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            background: "none",
                                                        },
                                                    }}
                                                />
                                            </SelectableBox>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </QueryResultWrapper>
                </QueryResultWrapper>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Box>
                    <Button variant={"outlined"} onClick={navigateToIntegrationForm}>
                        {"Add Integration"}
                    </Button>
                </Box>
                {projectIntegrations.data?.itemsList?.length > 0 && (
                    <Box>
                        <Button variant={"contained"} disabled={selectedIntegration === null} onClick={configureHelper}>{`Configure`}</Button>
                    </Box>
                )}
            </DialogActions>
        </Box>
    );
});

// ======================
// ConfigureGalaxyMigrateVmwareHelperForm
// ======================
interface ConfigureGalaxyMigrateVmwareHelperFormProps {
    systemID: string;
    dialogState: DialogState;
}

const ConfigureGalaxyMigrateVmwareHelperForm: React.FC<ConfigureGalaxyMigrateVmwareHelperFormProps> = observer((p) => {
    const initialValues = {
        ip: "",
        username: "",
        password: "",
    };
    const validationSchema = yup.object();

    const { progressService, gmDeploymentService, deploymentService } = useAppServices();
    const helperName = `${getGalaxyMigrateHelperNodeOSName(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE)}`;

    const configureVmwareHelper = useConfigureVmwareHelper();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                const req = new ConfigureVmwareHelper.Request()
                    .setSystemId(p.systemID)
                    .setConnInfo(new VmwareCompute.ConnInfo().setUser(values.username).setPassword(values.password).setHost(values.ip));
                await configureVmwareHelper.mutateAsync(req);
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <DialogTopBar dialogState={p.dialogState} title={`Configure ${helperName} Integration`} divider />
                        <DialogContent>
                            <DialogContentText>
                                {
                                    "Please provide the ip address and user credentials to the VMware vCenter that this helper node belongs to. This step is required for VMware integration to work."
                                }
                            </DialogContentText>
                            <br />
                            <DialogContentText>
                                {`Credentials provided here will be encrypted and stored locally on the ${helperName}. They will not be stored or logged in this cloud portal.`}
                            </DialogContentText>
                            <br />
                            <Box pb={2}>
                                <FormTextField name={"ip"} label={"vCenter IP Address / Host"} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField name={"username"} label={"vCenter User"} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField name={"password"} type={"password"} label={"vCenter User Password"} />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box p={2}>
                                <Button type={"submit"} variant={"outlined"}>
                                    {"Configure"}
                                </Button>
                            </Box>
                        </DialogActions>
                    </Form>
                );
            }}
        </Formik>
    );
});
