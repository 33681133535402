// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: ProjectCmcMigrationSessionsListScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { ColumnDef, DataTable } from "../../common/table/DataTable";
import { GalaxyMigrateMigrationSessionBasicInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { Box, Button, Card, Link, Stack, Typography } from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import {
    getGmSessionIDSlug,
    renderGMSessionStatus,
    useHasMigrationSessions,
    useNavigateToMigrationSessionDetails,
    useNavigateToMigrationWizardScreen,
} from "./CmcMigrationCommon";
import { MigrationIcon } from "../project/ProjectCommon";
import { OperatorView } from "../auth/AuthenticatedViews";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { generateDeploymentDetailsPath } from "../galaxymigrate/GalaxyMigrateCommon";
import { GmMigrationWizardStep } from "./GmMigrationService";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { checkIfLicenseModelUndecided, useCurrentProjectLicenseModel } from "../license/LicenseCommon";
import { ServerListData } from "../core/data/ListData";
import { ListMigrationSessions } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { useNavigateToDeploymentDetails } from "../galaxymigrate/GalaxyMigrateCommon";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { LabelChip } from "../labels/labelCommon";
import { MdLabelOutline } from "react-icons/md";
import { EmptyTableConfig } from "../../common/table/TableCommon";
import EmptyTableIcon from "../../assets/empty_states/empty-migration-session.svg";

// ======================
// ProjectCmcMigrationSessionsListScreen
// ======================
interface ProjectMigrationSessionsListScreenProps {}

export const ProjectCmcMigrationSessionsListScreen: React.FC<ProjectMigrationSessionsListScreenProps> = observer((p) => {
    const { gmMigrationService, licenseService } = useAppServices();
    const hasMigrationSessions = useHasMigrationSessions();

    const poll = async () => {
        await gmMigrationService.sessions.fetchData();
        await licenseService.projectLicenseDetails.fetchData();
    };

    useInitData({
        poll: poll,
        pollInterval: 5,
        deinit: () => {
            gmMigrationService.sessions.resetData();
        },
    });
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Migration Sessions`} actions={<>{hasMigrationSessions && <NewSessionButton />}</>} />
            <MigrationSessionsTable serverData={gmMigrationService.sessions} />
        </ScreenContainer>
    );
});

// ======================
// SessionsTable
// ======================
interface SessionsTableProps {
    serverData: ServerListData<ListMigrationSessions.Response, GalaxyMigrateMigrationSessionBasicInfo, ListMigrationSessions.Request>;
    emptyTableConfig?: EmptyTableConfig;
}

export const MigrationSessionsTable: React.FC<SessionsTableProps> = observer((p) => {
    const { serverData, emptyTableConfig } = p;
    const goToMigrationSessionDetails = useNavigateToMigrationSessionDetails();
    const goToDeploymentDetails = useNavigateToDeploymentDetails();
    const getHostConnectionStyle = (connected: boolean) => {
        if (!connected) {
            return {
                color: "#757575",
            };
        }
        return {};
    };

    const cols: ColumnDef<GalaxyMigrateMigrationSessionBasicInfo>[] = [
        {
            id: "session",
            label: "Session",
            getter: (r) => r.getSessionId(),
            renderer: (sessionID, r) => {
                const sessionName = !r.getDeploymentConnected() ? (
                    <Typography variant={"body2"} sx={getHostConnectionStyle(r.getDeploymentConnected())}>
                        {getGmSessionIDSlug(r.getSessionId())}
                    </Typography>
                ) : (
                    <Link onClick={() => goToMigrationSessionDetails(r.getSessionId())}>{getGmSessionIDSlug(r.getSessionId())}</Link>
                );

                return (
                    <Box>
                        {sessionName}
                        <Typography variant={"body2"} color={r.getDeploymentConnected() ? "textSecondary" : "#757575"}>
                            {r.getDescription()}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "deployment",
            label: "Host",
            getter: (r) => r.getDeployment().getSystemName(),
            renderer: (_, r) => {
                let hostName;
                if (!!r.getDestinationDeployment()) {
                    hostName = (
                        <span>
                            <Link onClick={() => goToDeploymentDetails(r.getDeployment().getSystemId())}>{r.getDeployment().getSystemName()}</Link>
                            &nbsp; &nbsp;
                            {`>>`}
                            &nbsp; &nbsp;
                            <Link onClick={() => goToDeploymentDetails(r.getDestinationDeployment().getSystemId())}>
                                {r.getDestinationDeployment().getSystemName()}
                            </Link>
                        </span>
                    );
                } else {
                    hostName = <Link onClick={() => goToDeploymentDetails(r.getDeployment().getSystemId())}>{r.getDeployment().getSystemName()}</Link>;
                }
                return (
                    <Box>
                        {hostName}
                        <Typography variant={"body2"} color={"#757575"}>
                            {!!r.getChangedDataTrackingOnly() ? "(Change-Tracking Only)" : null}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "volumes",
            label: "Volumes",
            getter: (r) => r.getTotalVolumes(),
            renderer: (volumes, r) => {
                return <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(volumes, KnownDataType.NUMBER)}</Typography>;
            },
            dataType: KnownDataType.NUMBER,
        },
        {
            id: "totalCapacity",
            label: "Total Capacity",
            getter: (r) => r.getTotalCapacity(),
            renderer: (capacity, r) => {
                return <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(capacity, KnownDataType.CAPACITY)}</Typography>;
            },
        },
        {
            id: "migrated",
            label: "Migrated",
            getter: (r) => r.getSessionStats().getTotalSynced(),
            renderer: (totalSynced, r) => {
                return (
                    <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(totalSynced, KnownDataType.CAPACITY)}</Typography>
                );
            },
        },
        {
            id: "remaining",
            label: "Remaining",
            getter: (r) => r.getSessionStats().getTotalRemaining(),
            renderer: (remaining, r) => {
                return (
                    <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(remaining, KnownDataType.CAPACITY)}</Typography>
                );
            },
        },
        {
            id: "status",
            label: "Status",
            getter: (r) => r.getSessionStatus(),
            renderer: (v, r) => {
                return (
                    <Typography component={"span"} sx={getHostConnectionStyle(r.getDeploymentConnected())}>
                        {renderGMSessionStatus(v)}
                    </Typography>
                );
            },
        },
        {
            id: "label",
            label: "Labels",
            getter: (r) => r.getLabelsList(),
            renderer: (labels: Label[], r) => {
                if (!labels || labels.length === 0) {
                    return "--";
                }
                return (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <BlackTooltip
                            title={
                                <Box p={1}>
                                    <Stack spacing={1}>
                                        {labels.map((l) => (
                                            <LabelChip key={l.getName()} label={l.getName()} color={l.getColor()} />
                                        ))}
                                    </Stack>
                                </Box>
                            }
                            arrow
                        >
                            <Box>
                                <MdLabelOutline size={24} />
                            </Box>
                        </BlackTooltip>
                    </Stack>
                );
            },
        },
    ];
    return (
        <DataTable
            rows={serverData?.data?.getItemsList()}
            cols={cols}
            state={serverData.tableState}
            pagerMeta={serverData?.data?.getPagerMeta().toObject()}
            loading={serverData.loading}
            emptyTableConfig={{
                title: "Create your first Migration session",
                message:
                    "There are no Migration sessions in this project currently. To get started, deploy Cirrus Migrate Cloud to your Windows or Linux hosts and come back here to create new Migration sessions.",
                actionButton: <NewSessionButton />,
                iconPath: EmptyTableIcon,
            }}
            onTableStateChange={() => serverData.fetchData()}
        />
    );
});

// ======================
// NewSessionButton
// ======================

interface NewSessionButtonProps {}

const NewSessionButton: React.FC<NewSessionButtonProps> = observer((props) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const navigateToWizard = useNavigateToMigrationWizardScreen();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const projectId = useCurrentProjectID();

    const createNewSession = async () => {
        await checkIfLicenseModelUndecided(projectLicenseModel, dialogService, projectId);

        if (projectLicenseModel !== LicenseModel.LicenseModel.UNDECIDED) {
            gmMigrationService.initWizard();
            gmMigrationService.wizardState.stepperState.setStartingStepIndex(GmMigrationWizardStep.SOURCE_DEPLOYMENT_SELECTION);
            navigateToWizard();
        }
    };

    return (
        <OperatorView>
            <Button variant={"contained"} color={"secondary"} startIcon={<MigrationIcon />} onClick={() => createNewSession()}>
                New Migration Session
            </Button>
        </OperatorView>
    );
});
