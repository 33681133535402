import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { Box, Card, CardContent, CardProps, Grid, Stack, SvgIcon, Switch, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

// ======================
// SwitchCard
// ======================

interface SwitchCardProps {
    selected: boolean;
    disabled?: boolean;
    onSelect: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    cardContent: React.ReactNode;
    cardProps?: Partial<CardProps>;
    darkBorder?: boolean;
}

export const SwitchCard: React.FC<SwitchCardProps> = observer((p) => {
    const { selected, disabled, onSelect, cardContent, cardProps } = p;
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: "rgba(0,0,0,0)",
                border: "2px solid",
                borderColor: (t: Theme) => (selected ? t.palette.primary.main : p.darkBorder ? t.palette.cirrus.main : t.palette.cirrus.light),
                marginBottom: 2,
            }}
            {...cardProps}
        >
            <CardContent>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                    <Switch color={"secondary"} checked={selected} disabled={disabled} onChange={onSelect} />
                    <Box width={"100%"}>{cardContent}</Box>
                </Stack>
            </CardContent>
        </Card>
    );
});
