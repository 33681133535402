import { observer } from "mobx-react-lite";
import React, { ComponentType } from "react";
import { DataTableProps } from "./DataTable";
import { Box, Card, Grid, Link, Typography } from "@mui/material";
import { TableFilter } from "./TableFilter";
import { TableSort } from "./TableSort";
import { PaginationControl } from "./PaginationControl";
import NoResult from "../../assets/image_no_result.png";
import EmptyTable from "../../assets/image_empty_folder.png";
import { HELP_CENTER_URL } from "../../modules/help/HelpCommon";
import { LoadingListItems } from "../progress/LoadingIndicators";
import { EmptyTableContent } from "./TableCommon";

// ======================
// ListTable
// ======================

interface ListTableProps<RowData, RowIDType = any> extends Omit<DataTableProps<RowData, RowIDType>, "cols"> {
    listComponent: ComponentType<{ data: RowData }>;
}

export const ListTable = observer(<RowData, RowIDType>(p: ListTableProps<RowData, RowIDType>) => {
    const pagerOn = p.state && p.pagerMeta;

    if (p.loading) {
        return <LoadingListItems count={2} />;
    }

    return (
        <Box>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <TableFilter state={p.state} onTableStateChange={p.onTableStateChange} />
                </Grid>
                <Grid item>
                    <TableSort state={p.state} onTableStateChange={p.onTableStateChange} />
                </Grid>
            </Grid>
            {!p.loading && !p.rows?.length && <EmptyTableContent emptyTableConfig={p.emptyTableConfig} isFilterActive={p.state?.isFilterActive} />}
            {p.rows?.map((r, i) => {
                return <p.listComponent key={i} data={r} />;
            })}
            {pagerOn && (
                <Box pt={2}>
                    <Card variant={"outlined"}>
                        <PaginationControl pagerMeta={p.pagerMeta} onTableStateChange={p.onTableStateChange} state={p.state} />
                    </Card>
                </Box>
            )}
        </Box>
    );
});

const _renderEmptyTableMessage = (isFilterActive: boolean, actionButton: React.ReactNode, customTitle: React.ReactNode, customMessage: React.ReactNode) => {
    if (isFilterActive) {
        return (
            <Box>
                <Card>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box pt={6} pb={6} textAlign={"center"}>
                            <Box pb={2}>
                                <img src={NoResult} alt={"No Results Found"} height={"50px"} />
                            </Box>
                            <Box pb={1}>
                                <Typography variant={"h6"}>No Results Found</Typography>
                            </Box>
                            <Box>
                                <Typography variant={"body1"}>Try adjusting or clearing your filter(s) to find what you're looking for.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        );
    }
    return (
        <Box>
            <Card>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box pt={6} pb={6} textAlign={"center"}>
                        <Box pb={2}>
                            <img src={EmptyTable} alt={"No Data Yet"} height={"50px"} />
                        </Box>
                        <Box pb={1}>
                            <Typography variant={"h6"}>{customTitle ? customTitle : <>No Data Found</>}</Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography variant={"body1"}>
                                {customMessage ? (
                                    customMessage
                                ) : (
                                    <>
                                        Get started now or visit our{" "}
                                        <Link href={HELP_CENTER_URL} target={"_blank"}>
                                            Help Center
                                        </Link>{" "}
                                        for additional information.
                                    </>
                                )}
                            </Typography>
                        </Box>
                        <Box p={2}>{actionButton}</Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};
