import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import React, { ChangeEvent, useState } from "react";
import {
    Alert,
    Avatar,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import Box from "@mui/material/Box";
import { FormTextField } from "../../common/form/FormComponents";
import { useAppServices } from "../app/services";
import * as yup from "yup";
import { renderServerDataWhenReady, useInitData, useServerDataWithLoadingBox } from "../core/data/DataLoaderHooks";
import { red } from "@mui/material/colors";
import { HubUser } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { getUserFullName, getUserFullNameFromObject } from "../settings/ProjectUsers";
import { CloseDialogButton, DialogTopBar } from "../core/dialog/DialogComponents";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { useIsDesktop } from "../layout/MainLayout";
import { SetNewPasswordForm } from "../auth/SetNewPasswordScreen";
import { RecoveryCodeList, TwoFactorAuthDialog } from "../auth/TwoFactorAuth";
import { MfaAuthMethod } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/mfa_auth_method_pb";

import { UserSessionsList } from "./UserSessionsList";
import { HideInPrivateEdition, PrivateEditionView } from "../auth/PrivateEditionView";
import { useAuthState } from "../auth/AuthState";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { DisableMfaTotp } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { useGenerateApiToken, useGetMyUserInfo, useRemoveAvatar, useRevokeApiToken, useUpdateAvatar, useUpdateMyUserInfo } from "./user_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { useRefreshUserSessionInfo, useUpdateCurrentAuthUser } from "../auth/auth_hooks";
import { useMountEffect } from "../../common/hooks/hookslib";
import { useEffectOnce } from "react-use";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";

const useUserSettingsStyles = () => {
    const t = useTheme();
    return {
        avatar: {
            width: t.spacing(20),
            height: t.spacing(20),
            backgroundColor: t.palette.secondary.main,
        },
        removeAvatar: {
            color: red["500"],
            borderColor: red["500"],
        },
        card: {
            height: "100%",
        },
        apiToken: {
            background: t.palette.background.default,
            wordBreak: "break-all",
        },
        revokeToken: {
            color: red["500"],
            borderColor: red["500"],
        },
        input: {
            display: "none",
        },
        strengthMeter: {
            backgroundColor: `rgba(255, 255, 255, .1)`,
        },
    };
};

// ======================
// UserSettings
// ======================

interface UserSettingsProps {}

export const UserSettings: React.FC<UserSettingsProps> = observer((props) => {
    const { projectService } = useAppServices();
    const getMyUserInfo = useGetMyUserInfo();

    useEffectOnce(() => {
        projectService.clearCurrentProject();
    });

    const updateUserDialogState = useDialogState();
    const apiTokenDialogState = useDialogState();
    const resetPasswordDialogState = useDialogState();
    const twoFactorAuthDialogState = useDialogState();
    return (
        <QueryResultWrapper queryResult={getMyUserInfo}>
            <ScreenContainer>
                <ScreenTitleBar title={"My Profile"} />
                <Grid container spacing={2}>
                    <HideInPrivateEdition>
                        <Grid item xs={12} sm={6} md={4}>
                            <AvatarCard currentUser={getMyUserInfo?.data?.hubUser} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <UserInfoCard currentUser={getMyUserInfo?.data?.hubUser} dialogState={updateUserDialogState} />
                        </Grid>
                    </HideInPrivateEdition>
                    <PrivateEditionView>
                        <Grid item xs={12} sm={6} md={8}>
                            <UserInfoCard currentUser={getMyUserInfo?.data?.hubUser} dialogState={updateUserDialogState} />
                        </Grid>
                    </PrivateEditionView>
                    <Grid item xs={12}>
                        <AccountSecurityCard changePasswordDialogState={resetPasswordDialogState} twoFactorAuthDialogState={twoFactorAuthDialogState} />
                    </Grid>
                    <Grid item xs={12}>
                        <UserApiTokenCard dialogState={apiTokenDialogState} />
                    </Grid>
                    <Grid item xs={12}>
                        <UserSessionsList />
                    </Grid>
                </Grid>
                <UserSettingsForm currentUser={getMyUserInfo?.data?.hubUser} dialogState={updateUserDialogState} />
                <ChangePasswordDialog dialogState={resetPasswordDialogState} currentUser={getMyUserInfo?.data?.hubUser.email} />
            </ScreenContainer>
        </QueryResultWrapper>
    );
});

// ======================
// AvatarCard
// ======================

interface AvatarCardProps {
    currentUser: HubUser.AsObject;
}

export const AvatarCard: React.FC<AvatarCardProps> = observer((props) => {
    const { currentUser } = props;
    const styles = useUserSettingsStyles();
    const refreshUserSessionInfo = useRefreshUserSessionInfo();
    const updateAvatar = useUpdateAvatar();

    const removeAvatar = useRemoveAvatar();
    const noAvatarString = `${currentUser.firstName[0]}${currentUser.lastName[0]}`;
    const globalDialogState = useGlobalDialogState();

    const handleCapture = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files[0].size > 2000000) {
            await globalDialogState.addAlertDialog({ title: "File Size Limit Exceeded", message: "Maximum file size is 2MiB." });
            e.target.value = null;
        } else {
            const res = await updateAvatar(e.target.files[0]);
            if (res) {
                e.target.value = null;
                await refreshUserSessionInfo();
            }
        }
    };

    const handleRemove = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            message: "Are you sure you want to remove your profile picture?",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            const res = await removeAvatar.mutateAsync();
            if (res) {
                await refreshUserSessionInfo();
            }
        }
    };

    return (
        <Card sx={styles.card}>
            <ListSubheader>Profile Picture</ListSubheader>
            <Box p={2} display={"flex"} justifyContent={"center"}>
                <Avatar sx={styles.avatar} src={`${currentUser.avatar}`}>
                    {noAvatarString}
                </Avatar>
            </Box>
            <Box p={2} justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"}>
                <Box>
                    <label htmlFor={"upload-image"}>
                        <input accept={"image/*"} id={"upload-image"} type={"file"} onChange={handleCapture} style={styles.input} />
                        <Button variant={"outlined"} component={"span"}>
                            Upload New Image
                        </Button>
                    </label>
                </Box>
                <Box pt={1}>
                    <Typography variant={"caption"}>Maximum file size is 2MiB.</Typography>
                </Box>
                <br />
                <Box>
                    <Button variant={"outlined"} color={"error"} onClick={handleRemove}>
                        Remove Image
                    </Button>
                </Box>
            </Box>
        </Card>
    );
});

// ======================
// UserInfoCard
// ======================

interface UserInfoCardProps {
    currentUser: HubUser.AsObject;
    dialogState: DialogState;
}

export const UserInfoCard: React.FC<UserInfoCardProps> = observer((props) => {
    const { currentUser, dialogState } = props;
    const styles = useUserSettingsStyles();

    return (
        <Card sx={styles.card}>
            <Box display={"flex"} justifyContent={"space-between"}>
                <ListSubheader>User Info</ListSubheader>
                <Box p={2}>
                    <Button variant={"outlined"} onClick={dialogState.open}>
                        Edit User Info
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={getUserFullNameFromObject(currentUser)} secondary={"Name"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={currentUser.email} secondary={"Email"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={currentUser.phoneNumber} secondary={"Phone"} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={currentUser.companyName} secondary={"Company"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={currentUser.jobTitle} secondary={"Title"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={currentUser.location} secondary={"Location"} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Card>
    );
});

// ======================
// AccountSecurityCard
// ======================

interface AccountSecurityCardProps {
    changePasswordDialogState: DialogState;
    twoFactorAuthDialogState: DialogState;
}

export const AccountSecurityCard: React.FC<AccountSecurityCardProps> = observer((p) => {
    const isDesktop = useIsDesktop();

    return (
        <>
            <Card>
                <ListSubheader>Account Security</ListSubheader>
                <Box p={2}>
                    <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item xs={12} md={9}>
                            <Typography variant={"h6"}>Change Password</Typography>
                            <Typography variant={"body1"}>It's a good idea to use a strong password that you are not using elsewhere.</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                                <Button variant={"outlined"} onClick={p.changePasswordDialogState.open}>
                                    Change Password
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <TwoFactorAuthSection />
            </Card>
        </>
    );
});

// ======================
// TwoFactorAuthSection
// ======================

interface TwoFactorAuthSectionProps {}

export const TwoFactorAuthSection: React.FC<TwoFactorAuthSectionProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const setupDialogState = useDialogState();
    const recoveryCodesDialogState = useDialogState();
    const getMyUserInfo = useGetMyUserInfo();

    return (
        <>
            <Box p={2}>
                <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12} md={9}>
                        <Typography variant={"h6"}>Two-Factor Authentication</Typography>
                        <Typography variant={"body1"}>Additional security verification from a registered device.</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                            <TwoFactorAuthButton dialogState={setupDialogState} />
                        </Box>
                    </Grid>
                    {getMyUserInfo?.data?.method === MfaAuthMethod.MfaAuthMethod.TOTP && (
                        <Grid item xs={12}>
                            <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                                <Button variant={"outlined"} onClick={recoveryCodesDialogState.open}>
                                    Generate Recovery Codes
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
            {setupDialogState.isOpen && <TwoFactorAuthDialog dialogState={setupDialogState} />}
            {recoveryCodesDialogState.isOpen && <RecoveryCodesDialog dialogState={recoveryCodesDialogState} />}
        </>
    );
});

// ======================
// TwoFactorAuthButton
// ======================

interface TwoFactorAuthButtonProps {
    dialogState: DialogState;
}

export const TwoFactorAuthButton: React.FC<TwoFactorAuthButtonProps> = observer((p) => {
    const globalDialogState = useGlobalDialogState();
    const authServiceAPI = useGrpcApiStore((s) => s.authService);
    const getMyUserInfo = useGetMyUserInfo();
    const disable = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            message: "Are you sure you want to disable two-factor authentication?",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            await authServiceAPI.disableMfaTotp(new DisableMfaTotp.Request(), null);
            await getMyUserInfo.refetch();
        }
    };

    if (getMyUserInfo?.data?.method !== MfaAuthMethod.MfaAuthMethod.TOTP) {
        return (
            <Button variant={"outlined"} onClick={p.dialogState.open}>
                Enable 2FA
            </Button>
        );
    } else {
        return (
            <Box>
                <Button variant={"outlined"} color={"error"} onClick={disable}>
                    Disable 2FA
                </Button>
            </Box>
        );
    }
});

// ======================
// RecoveryCodesDialog
// ======================

interface RecoveryCodesDialogProps {
    dialogState: DialogState;
}

export const RecoveryCodesDialog: React.FC<RecoveryCodesDialogProps> = observer((p) => {
    const { authService } = useAppServices();
    const fullScreen = useShouldDialogFullScreen();

    useMountEffect(() => {
        if (!authService.twoFactorAuthState) {
            authService.initTwoFactorAuthState();
        }
    });
    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={"sm"} fullScreen={fullScreen}>
            <DialogTopBar dialogState={p.dialogState} title={"Recovery Codes"} />
            <DialogContent>
                <Box pb={2}>
                    <Typography variant={"body1"}>
                        Copy your recovery code and keep them safe. If you lose access to your device, using these codes will be the only way to recover your
                        account.{" "}
                    </Typography>
                    {!!authService.twoFactorAuthState && <RecoveryCodeList state={authService.twoFactorAuthState} />}
                </Box>
                <Box pb={2}>
                    <Alert severity={"info"}>All previously generated recovery codes are no longer valid.</Alert>
                </Box>
            </DialogContent>
        </Dialog>
    );
});

// ======================
// ChangePasswordDialog
// ======================

interface ChangePasswordDialogProps {
    dialogState: DialogState;
    currentUser: string;
}

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = observer((p) => {
    const fullScreen = useShouldDialogFullScreen();
    const styles = useUserSettingsStyles();

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={"sm"} fullWidth fullScreen={fullScreen}>
            <DialogTopBar dialogState={p.dialogState} title={"Reset Password"} />
            <SetNewPasswordForm
                user={p.currentUser}
                onSubmitted={p.dialogState.close}
                strengthMeterCardProps={{ elevation: 0, sx: styles.strengthMeter }}
                requireCurrentPassword
            />
        </Dialog>
    );
});

// ======================
// UserApiTokenCard
// ======================

interface UserApiTokenCardProps {
    dialogState: DialogState;
}

export const UserApiTokenCard: React.FC<UserApiTokenCardProps> = observer((props) => {
    const globalDialogState = useGlobalDialogState();
    const { dialogState } = props;
    const [generatedApiToken, setGeneratedApiToken] = useState(null);
    const generateApiToken = useGenerateApiToken();
    const revokeApiToken = useRevokeApiToken();

    const fullScreen = useShouldDialogFullScreen();
    const styles = useUserSettingsStyles();
    const isDesktop = useIsDesktop();

    const open = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            message: "By generating a new token, all previous tokens will be revoked. Are you sure you want to continue?",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            const res = await generateApiToken.mutateAsync();
            setGeneratedApiToken(res.token);
            dialogState.open();
        }
    };

    const revoke = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            message:
                "Are you sure you want to revoke your current personal access token? Removing your token will cause you to lose your API access privileges.",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            const revoked = await revokeApiToken.mutateAsync();
            if (revoked) {
                await globalDialogState.addAlertDialog({ title: "Token Revoked", message: "Your personal access token has been revoked." });
            }
        }
    };
    return (
        <Card>
            <Box display={"flex"} justifyContent={"space-between"}>
                <ListSubheader>{`Personal Access Token`}</ListSubheader>
            </Box>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Typography variant={"body1"}>
                            {`Here you can create a new personal access token for API access.`}
                            <br />
                            {`Note that by generating a new token, all previously generated tokens will be revoked.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                            <Button variant={"outlined"} onClick={open}>
                                {`Generate New Token`}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <Divider />
                        <br />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant={"body1"}>{`If you already have an existing token, you can choose to revoke it here.`}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                            <Button variant={"outlined"} onClick={revoke} color={"error"}>
                                {`Revoke Current Token`}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {generatedApiToken && dialogState.isOpen && (
                <Dialog
                    open={dialogState.isOpen}
                    onClose={() => {
                        setGeneratedApiToken(null);
                        dialogState.close();
                    }}
                    fullWidth
                    maxWidth={"md"}
                    fullScreen={fullScreen}
                >
                    <DialogTopBar dialogState={dialogState} title={"Personal Access Token"} />
                    <DialogContent>
                        <DialogContentText>
                            {`Below is your new personal access token. Make sure to save it somewhere safe, as you won't be able to access it again.`}
                        </DialogContentText>
                        <Box pb={2}>
                            <Card sx={styles.apiToken}>
                                <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box width={"90%"}>
                                        <ClipboardText clipboardId={"apiToken"}>{generatedApiToken}</ClipboardText>
                                    </Box>
                                    <Box>
                                        <ClipboardButton clipboardId={"apiToken"} iconButton />
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Card>
    );
});

// ======================
// UserSettingsForm
// ======================

interface UserSettingsFormProps {
    currentUser: HubUser.AsObject;
    dialogState: DialogState;
}

export const UserSettingsForm: React.FC<UserSettingsFormProps> = observer((props) => {
    const { currentUser, dialogState } = props;
    const updateMyUserInfo = useUpdateMyUserInfo();
    const refreshUserSessionInfo = useRefreshUserSessionInfo();

    const initialValues = {
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        companyName: currentUser.companyName || "",
        phoneNumber: currentUser.phoneNumber || "",
        location: currentUser.location || "",
        jobTitle: currentUser.jobTitle || "",
    };

    const schema = yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        companyName: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        location: yup.string().nullable(),
        jobTitle: yup.string().nullable(),
    });

    const _submit = async (values: FormikValues, actions: FormikHelpers<typeof initialValues>) => {
        const newValues = new HubUser()
            .setPhoneNumber(values.phoneNumber)
            .setJobTitle(values.jobTitle)
            .setFirstName(values.firstName)
            .setLastName(values.lastName)
            .setCompanyName(values.companyName)
            .setLocation(values.location);

        await updateMyUserInfo.mutateAsync(newValues);
        dialogState.close();
        await refreshUserSessionInfo();
    };

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"sm"} fullScreen={useShouldDialogFullScreen()}>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_submit}>
                {(props) => (
                    <>
                        <Form>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <DialogTitle>Edit User Info</DialogTitle>
                                <CloseDialogButton dialogState={dialogState} />
                            </Box>

                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormTextField name={"firstName"} label={"First Name"} variant={"filled"} required />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormTextField name={"lastName"} label={"Last Name"} variant={"filled"} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField name={"phoneNumber"} label={"Phone Number"} variant={"filled"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField name={"companyName"} label={"Company Name"} variant={"filled"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField name={"jobTitle"} label={"Title"} variant={"filled"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField name={"location"} label={"Location"} variant={"filled"} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <br />
                            <DialogActions>
                                <Box p={2}>
                                    <Button type={"submit"} variant={"outlined"}>{`Update User Settings`}</Button>
                                </Box>
                            </DialogActions>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
});
