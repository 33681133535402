import * as React from "react";
import { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, IconButton, SvgIcon, Typography, useTheme } from "@mui/material";
import { CDSPrivateEditionLogo } from "../layout/LayoutCommon";
import { FiLogOut } from "react-icons/fi";
import { LightDivider } from "../../common/misc";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useLogOut } from "../auth/auth_hooks";
import { useGetPendingPeLicenseRequest, useGetPrivateEditionLocalStatus, useInitiatePeLicenseRequest } from "./private_edition_hooks";
import { PrivateEditionLocalPendingLicenseRequestTxBox } from "./PrivateEditionLocalPendingRequestScreen";
import { useIsPELicenseNeedAuthentication } from "./PrivateEditionCommon";
import { PeLicenseInteractionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/pe_license_interaction_type_pb";
import { InitiatePeLicenseRequest } from "gc-web-proto/galaxycompletepb/apipb/pelocal_api_pb";

const usePrivateEditionLocalLicenseReAuthScreenStyle = () => {
    const t = useTheme();
    return {
        root: {
            // background: CirrusGradient,
            //height : '100vh',
            width: "100vw",
            display: "flex",
        },
        card: {},
        login: {
            textAlign: "center",
        },
        center: {
            display: "flex",
            justifyContent: "center",
        },
        logo: {
            maxWidth: "100%",
            width: t.spacing(40),
        },
    };
};

interface PrivateEditionLocalLicenseCheckInScreenProps {}

export const PrivateEditionLocalLicenseCheckInScreen = (p: PrivateEditionLocalLicenseCheckInScreenProps) => {
    const styles = usePrivateEditionLocalLicenseReAuthScreenStyle();
    const isPrivateEdition = getIsPrivateEdition();
    const logOut = useLogOut();
    const peLocalStatusQ = useGetPrivateEditionLocalStatus();
    useAutoInitiatePELocalCheckInLicenseRequest();
    // never show this page in SaaS
    if (!isPrivateEdition) {
        return null;
    }
    // should never happen therefore we don't need spinner (because we prefetch in init)
    if (!peLocalStatusQ.isSuccess) {
        return null;
    }
    const peLocalStatus = peLocalStatusQ?.data;
    return (
        <Box sx={styles.root} pt={4} pl={2} pr={2} pb={10}>
            <Grid container justifyContent={"center"} direction={"row"}>
                <Grid item xs={12} sm={10} md={9}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item xs={6}>
                            <Box width={"100%"}>
                                <CDSPrivateEditionLogo style={styles.logo} />
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => logOut.mutate()}>
                                <SvgIcon>
                                    <FiLogOut />
                                </SvgIcon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <br />
                    <LightDivider />
                    <br />
                    <Typography variant={"h3"} paragraph>
                        {peLocalStatus.status.systemName}
                    </Typography>
                    <Typography variant={"h5"} paragraph>
                        {"Welcome back to your Cirrus Data Cloud Private Edition. Please re-authenticate your private edition license to continue."}
                    </Typography>
                    <br />
                    <PrivateEditionLocalPendingLicenseRequestTxBox
                        onTxFinished={() => {
                            peLocalStatusQ.refetch();
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const useAutoInitiatePELocalCheckInLicenseRequest = () => {
    const pendingLicenseRequest = useGetPendingPeLicenseRequest();
    const peLocalStatus = useGetPrivateEditionLocalStatus();
    const initiatePeLicenseRequest = useInitiatePeLicenseRequest();
    const needCheckIn = useIsPELicenseNeedAuthentication();

    const currentPendingLicenseRequestType = pendingLicenseRequest.data?.licenseRequest?.interactionType;
    const initiatePeLicenseRequestMutateAsync = initiatePeLicenseRequest.mutateAsync;
    const initiateCheckIn = useCallback(async () => {
        const req = new InitiatePeLicenseRequest.Request().setInteractionType(PeLicenseInteractionType.PeLicenseInteractionType.CHECK_IN);
        await initiatePeLicenseRequestMutateAsync(req);
    }, [initiatePeLicenseRequestMutateAsync]);

    return useEffect(() => {
        if (!needCheckIn) {
            return;
        }
        if (initiatePeLicenseRequest.isIdle === false) {
            return;
        }
        // only if pending request is loaded
        if (!pendingLicenseRequest.isSuccess) {
            return;
        }
        // if already a pending check in, exit
        if (currentPendingLicenseRequestType === PeLicenseInteractionType.PeLicenseInteractionType.CHECK_IN) {
            return;
        }

        console.log(currentPendingLicenseRequestType);

        // otherwise initiate a checkin
        initiateCheckIn();
    }, [
        pendingLicenseRequest.isSuccess,
        currentPendingLicenseRequestType,
        peLocalStatus.isSuccess,
        needCheckIn,
        initiateCheckIn,
        initiatePeLicenseRequest.isIdle,
    ]);
};
