// ======================
// LabelChip
// ======================

import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    darken,
    DialogActions,
    DialogContent,
    Divider,
    ListSubheader,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { Label, LabelDetails, LabelListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { blue, cyan, green, lime, orange, purple, red, yellow } from "@mui/material/colors";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { MdCheck } from "react-icons/md";
import { DialogState } from "../core/dialog/DialogService";
import { CreateLabel, UpdateLabel } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { useCreateNewLabel, useListProjectLabels, useUpdateLabel } from "./label_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { getScopeFromLabel, getScopeListFromLabels } from "./labelHelpers";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";

interface LabelChipProps {
    label: string;
    description?: string;
    color: string;
    onClick?: () => Promise<any>;
    hostCount?: number;
    migrationSessionCount?: number;
}

export const LabelChip: React.FC<LabelChipProps> = (p) => {
    const { label, description, color, onClick, hostCount, migrationSessionCount } = p;
    const theme = useTheme();

    const showHostMigrationCount = useMemo(() => {
        return hostCount !== undefined && migrationSessionCount !== undefined;
    }, [hostCount, migrationSessionCount]);

    const chip = (
        <Chip
            size={"small"}
            clickable={!!onClick}
            onClick={onClick || null}
            sx={{
                backgroundColor: color || null,
                color: color ? theme.palette.getContrastText(color) : "white",
            }}
            label={
                <Typography variant={"subtitle2"} fontWeight={600}>
                    {label}
                </Typography>
            }
        />
    );

    return (
        <BlackTooltip
            arrow
            title={
                <Stack spacing={1}>
                    <Stack>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {"Description"}
                        </Typography>
                        <Typography variant={"body2"}>{description || "N/A"}</Typography>
                    </Stack>
                    {showHostMigrationCount && (
                        <Stack>
                            <Typography variant={"body2"} color={"textSecondary"}>{`Currently used on:`}</Typography>
                            <Typography variant={"body2"} color={"primary"}>{`${hostCount || "0"} Host(s)`}</Typography>
                            <Typography variant={"body2"} color={"primary"}>{`${migrationSessionCount || "0"} Migration Session(s)`}</Typography>
                        </Stack>
                    )}
                </Stack>
            }
        >
            {chip}
        </BlackTooltip>
    );
};
