// ======================
// AzureStoragePlanDetailsPage
// ======================

import { useParams } from "react-router-dom";
import { useDeleteAzureStorageConsumptionPlan, useGetAzureStorageConsumptionPlan } from "./azure_storage_planner_hooks";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { getAzureRegionDisplayNameFromEnumValue, getAzureStorageRedundancyDisplayName, useNavigateToAzureStoragePlanList } from "./AzureStoragePlannerHelpers";
import { BackButton } from "../../../common/CommonButtons";
import {
    Alert,
    alpha,
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    ListSubheader,
    Stack,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { MdArchive, MdDelete, MdStorage, MdTextSnippet, MdTimer } from "react-icons/md";
import { convertTimestampObjectToDate, formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { AzureStorageConsumptionPlan } from "gc-web-proto/galaxycompletepb/apipb/domainpb/planner_pb";
import { GetAzureStorageConsumptionPlan } from "gc-web-proto/galaxycompletepb/apipb/planner_api_pb";
import { AiOutlineCloudServer } from "react-icons/ai";
import { AzureStorageConsumptionRecommendation } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import ManagedDisks from "../../../assets/azure/managed_disks.png";
import ElasticSan from "../../../assets/azure/elastic_san.png";
import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { KVContrastTable } from "../../../common/table/KVContrastTable";
import { getAzureStorageTypeDisplayName } from "../../cmcMigration/autoallocation/Integrations/azure/AzureIntegrationHelpers";
import { getGmStorageConfigDeviceTypeFromObject, getHostEnvDisplayName } from "../../galaxymigrate/GalaxyMigrateCommon";
import { getMtdiDeploymentOsDisplayName } from "../../project/ProjectCommon";
import { CollapsibleCard } from "../../../common/collapsible/CollapsibleCard";
import { renderBootVolumeChip, renderChipInfo } from "../../../common/chip/CommonChips";
import { SelectableCard } from "../../../common/card/SelectableCard";
import { ActionMenuButton } from "../../../common/actions/CommonActions";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";

interface AzureStoragePlanDetailsPageProps {}

export const AzureStoragePlanDetailsPage: React.FC<AzureStoragePlanDetailsPageProps> = (p) => {
    const { consumptionPlanId } = useParams();
    const queryResult = useGetAzureStorageConsumptionPlan(consumptionPlanId);
    const goBackToPlansList = useNavigateToAzureStoragePlanList();

    return (
        <ScreenContainer>
            <BackButton navFunction={goBackToPlansList} label={`Back To Azure Consumption Plans`} />
            <QueryResultWrapper queryResult={queryResult}>
                <AzureStoragePlanDetailsBasicInfo data={queryResult.data} />
                <Box width={"100%"} pt={1} pb={2}>
                    <Divider sx={{ borderColor: (t: Theme) => t.palette.cirrus.light, borderWidth: 1.2 }} />
                </Box>
                <Stack spacing={2}>
                    <AzureStoragePlanDetailsSummaryCard data={queryResult.data?.details} />
                    <AzureStoragePlanDetailsPreferencesCard data={queryResult.data?.details} />
                    <AzureProductBreakdownSection data={queryResult.data?.details.recommendationResult} />
                    <AzureStorageHostBreakdownSection
                        recommendationData={queryResult.data?.details.recommendationResult.hostsList}
                        hostInfo={queryResult.data?.details.hostsIncludedList}
                    />
                </Stack>
            </QueryResultWrapper>
        </ScreenContainer>
    );
};

// ======================
// AzureStoragePlanActions
// ======================

interface AzureStoragePlanActionsProps {
    data: GetAzureStorageConsumptionPlan.Response.AsObject;
}

export const AzureStoragePlanActions: React.FC<AzureStoragePlanActionsProps> = (p) => {
    const { data } = p;
    const deleteStoragePlan = useDeleteAzureStorageConsumptionPlan();
    const addConfirmDialog = useGlobalDialogState((s) => s.addConfirmDialog);
    const actions = [
        {
            id: "delete",
            name: "Delete Plan",
            icon: <MdDelete />,
            action: async () => {
                const confirmed = await addConfirmDialog({
                    title: "Delete Consumption Plan",
                    message: "Are you sure you want to delete this consumption plan?",
                    okButtonLabel: "Delete",
                    autoConfirmationQuestionLine: false,
                    okButtonProps: { variant: "outlined", color: "error" },
                });
                if (confirmed) {
                    deleteStoragePlan.mutate(data.details.planId);
                }
            },
        },
    ];

    return <ActionMenuButton actions={actions} title={"Plan Actions"} />;
};

// ======================
// AzureStoragePlanDetailsBasicInfo
// ======================

interface AzureStoragePlanDetailsBasicInfoProps {
    data: GetAzureStorageConsumptionPlan.Response.AsObject;
}

export const AzureStoragePlanDetailsBasicInfo: React.FC<AzureStoragePlanDetailsBasicInfoProps> = (p) => {
    const { data } = p;
    const details = data?.details;
    const metadata = data?.metadata;
    return (
        <>
            <ScreenTitleBar title={details.planName} actions={<AzureStoragePlanActions data={data} />} />
            <Stack alignItems={"flex-start"} spacing={0.5}>
                <Chip
                    sx={{
                        fontWeight: 600,
                        backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.2),
                        color: (t: Theme) => t.palette.primary.light,
                    }}
                    label={`Plan ID: ${details.planId}`}
                />
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Tooltip title={"Plan Description"}>
                        <MdTextSnippet />
                    </Tooltip>
                    <Typography color={"textSecondary"}>{details.planDescription || `N/A`}</Typography>
                </Stack>
                <Tooltip title={`Created ${formatKnownDataType(convertTimestampObjectToDate(metadata.createdAt), KnownDataType.DATE)}`}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <MdTimer />
                        <Typography color={"textSecondary"}>
                            {`Created `}
                            {formatKnownDataType(convertTimestampObjectToDate(metadata.createdAt), KnownDataType.DATE_RELATIVE)}
                            {details.lastUpdated
                                ? ` | Last Updated ${formatKnownDataType(convertTimestampObjectToDate(details.lastUpdated), KnownDataType.DATE_RELATIVE)}`
                                : ""}
                        </Typography>
                    </Stack>
                </Tooltip>
            </Stack>
        </>
    );
};

// ======================
// AzureStoragePlanDetailsSummaryCard
// ======================

interface AzureStoragePlanDetailsSummaryCardProps {
    data: AzureStorageConsumptionPlan.AsObject;
}

export const AzureStoragePlanDetailsSummaryCard: React.FC<AzureStoragePlanDetailsSummaryCardProps> = (p) => {
    const { data } = p;
    return (
        <Card>
            <ListSubheader>{`Consumption Plan Summary`}</ListSubheader>
            <CardContent>
                <Typography fontWeight={600} variant={"subtitle2"} color={"textSecondary"}>
                    {`Total Estimated Storage Cost:`}
                </Typography>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant={"h4"} fontWeight={"bold"}>
                        {`US${formatKnownDataType(data.recommendationResult.totalPricePerMonth, KnownDataType.CURRENCY_USD)}`}
                    </Typography>
                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {`per month`}
                    </Typography>
                </Stack>
                <Box pt={2} pb={2}>
                    <Alert severity={"info"}>{`The estimated cost only includes the storage base price.`}</Alert>
                </Box>
                <Grid spacing={2} container>
                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 1.5,
                                borderStyle: "solid",
                                borderColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                            elevation={0}
                        >
                            <CardContent>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Avatar sx={{ bgcolor: (t: Theme) => t.palette.cirrus.main, color: "white" }}>
                                        <AiOutlineCloudServer size={24} />
                                    </Avatar>
                                    <Typography variant={"h6"}>
                                        {`${data.recommendationResult.totalHosts} Host${data.recommendationResult.totalHosts > 1 ? "s" : ""}`}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 1.5,
                                borderStyle: "solid",
                                borderColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                            elevation={0}
                        >
                            <CardContent>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Avatar sx={{ bgcolor: (t: Theme) => t.palette.cirrus.main, color: "white" }}>
                                        <MdStorage size={24} />
                                    </Avatar>
                                    <Typography variant={"h6"}>
                                        {`${data.recommendationResult.totalVolumes} Volume${data.recommendationResult.totalVolumes > 1 ? "s" : ""}`}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 1.5,
                                borderStyle: "solid",
                                borderColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                            elevation={0}
                        >
                            <CardContent>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Avatar sx={{ bgcolor: (t: Theme) => t.palette.cirrus.main, color: "white" }}>
                                        <MdArchive size={24} />
                                    </Avatar>
                                    <Typography variant={"h6"}>
                                        {formatKnownDataType(data.recommendationResult.totalCapacity, KnownDataType.CAPACITY)}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

// ======================
// AzureStoragePlanDetailsPreferencesCard
// ======================

interface AzureStoragePlanDetailsPreferencesCardProps {
    data: AzureStorageConsumptionPlan.AsObject;
}

const AzureStoragePlanDetailsPreferencesCard: React.FC<AzureStoragePlanDetailsPreferencesCardProps> = (p) => {
    const { data } = p;
    const tableData = [
        {
            io: {
                key: <Typography fontWeight={600}>{`Analyzed I/O Access History`}</Typography>,
                value: (
                    <>
                        <Typography>{formatDurationFromSeconds(data.analyzePeriodMinutes * 60)}</Typography>
                        <Typography color={"textSecondary"}>
                            {`(${formatKnownDataType(convertTimestampObjectToDate(data.analyzedFrom), KnownDataType.DATE)} to ${formatKnownDataType(
                                convertTimestampObjectToDate(data.analyzedTo),
                                KnownDataType.DATE
                            )})`}
                        </Typography>
                    </>
                ),
            },
            product: {
                key: <Typography fontWeight={600}>{`Azure Storage Product`}</Typography>,
                value: <Typography>{`${!!data.recommendationResult.elasticSan ? `Azure Elastic SAN + ` : ""}Azure Managed Disks`}</Typography>,
            },
        },
        {
            storageGrowth: {
                key: <Typography fontWeight={600}>{`Expected Storage Growth`}</Typography>,
                value: <Typography>{`${data.globalParams.storageGrowthPercent}%`}</Typography>,
            },
            region: {
                key: <Typography fontWeight={600}>{`Azure Region`}</Typography>,
                value: <Typography>{getAzureRegionDisplayNameFromEnumValue(data.globalParams.region)}</Typography>,
            },
        },
        {
            workloadGrowth: {
                key: <Typography fontWeight={600}>{`Expected Workload Growth`}</Typography>,
                value: <Typography>{`${data.globalParams.workloadGrowthPercent}%`}</Typography>,
            },
            redundancy: {
                key: <Typography fontWeight={600}>{`Redundancy`}</Typography>,
                value: <Typography>{getAzureStorageRedundancyDisplayName(data.globalParams.redundancy)}</Typography>,
            },
        },
    ];
    return (
        <Card>
            <ListSubheader>{`Preferences`}</ListSubheader>
            <CardContent>
                <KVContrastTable data={tableData} />
            </CardContent>
        </Card>
    );
};

// ======================
// ProductBreakdownSection
// ======================

interface AzureProductBreakdownSectionProps {
    data: AzureStorageConsumptionRecommendation.AsObject;
}

export const AzureProductBreakdownSection: React.FC<AzureProductBreakdownSectionProps> = (p) => {
    const { data } = p;
    const hasElasticSan = !!data.elasticSan;

    return (
        <Stack>
            <Typography variant={"h6"}>{`Product Breakdown`}</Typography>

            <Grid container spacing={2} mt={1}>
                {hasElasticSan ? (
                    <Grid item xs={12} md={6}>
                        <AzureElasticSanProductCard data={data.elasticSan} />
                    </Grid>
                ) : null}

                <Grid item xs={12} md={hasElasticSan ? 6 : 12}>
                    <AzureManagedDisksProductCard data={data} />
                </Grid>
            </Grid>
        </Stack>
    );
};

// ======================
// AzureElasticSanProductCard
// ======================

interface AzureElasticSanProductCardProps {
    data: AzureStorageConsumptionRecommendation.ElasticSanRecommendation.AsObject;
}

export const AzureElasticSanProductCard: React.FC<AzureElasticSanProductCardProps> = (p) => {
    const { data } = p;

    const kvTableData = [
        {
            baseUnit: {
                key: <Typography fontWeight={600}>{`Premium Base Unit x ${data.baseUnit}`}</Typography>,
                value: <Typography>{formatKnownDataType(data.totalBaseUnitPricePerMonth, KnownDataType.CURRENCY_USD)}</Typography>,
            },
            scaleUnit: {
                key: <Typography fontWeight={600}>{`Premium Capacity Scale Unit x ${data.scaleUnit}`}</Typography>,
                value: <Typography>{formatKnownDataType(data.totalScaleUnitPricePerMonth, KnownDataType.CURRENCY_USD)}</Typography>,
            },
            total: {
                key: <Typography fontWeight={600}>{`Total`}</Typography>,
                value: <Typography>{formatKnownDataType(data.totalPricePerMonth, KnownDataType.CURRENCY_USD)}</Typography>,
            },
        },
    ];
    return (
        <Card sx={{ height: "100%" }}>
            <Box display={"flex"} justifyContent={"center"} p={2}>
                <Typography color={"textPrimary"} variant={"overline"} fontWeight={600}>{`Azure Elastic SAN`}</Typography>
            </Box>
            <Box width={"100%"}>
                <Divider />
            </Box>
            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    <Avatar sx={{ bgcolor: (t: Theme) => t.palette.cirrus.main }}>
                        <img src={ElasticSan} alt={"Elastic SAN"} width={24} height={24} />
                    </Avatar>
                    <Stack spacing={0}>
                        <Typography fontWeight={600} variant={"subtitle2"} color={"textSecondary"}>
                            {`Total Estimated Storage Cost:`}
                        </Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} pb={2}>
                            <Typography variant={"h4"} fontWeight={"bold"}>
                                {`US${formatKnownDataType(data.totalPricePerMonth, KnownDataType.CURRENCY_USD)}`}
                            </Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {`per month`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <KVContrastTable data={kvTableData} />
            </CardContent>
        </Card>
    );
};

// ======================
// AzureManagedDisksProductCard
// ======================

interface AzureManagedDisksProductCardProps {
    data: AzureStorageConsumptionRecommendation.AsObject;
}

export const AzureManagedDisksProductCard: React.FC<AzureManagedDisksProductCardProps> = (p) => {
    const { data } = p;

    const createManagedDisksRecord = () => {
        const tableData: { [key: string]: { price: number; count: number } } = {};
        data.hostsList.forEach((host) => {
            host.volumesList.forEach((volume) => {
                if (!volume.volumeType?.elasticSan) {
                    const key = Object.keys(AutoAlloc.VolumeParams.Azure.StorageType).find(
                        (k) =>
                            AutoAlloc.VolumeParams.Azure.StorageType[k as keyof typeof AutoAlloc.VolumeParams.Azure.StorageType] ===
                            volume.volumeType?.managedDisk?.storageType
                    );
                    if (tableData[key]) {
                        tableData[key] = {
                            price: (tableData[key].price += volume.totalPricePerMonth),
                            count: (tableData[key].count += 1),
                        };
                    } else {
                        tableData[key] = {
                            price: volume.totalPricePerMonth,
                            count: 1,
                        };
                    }
                }
            });
        });
        const totalPricePerMonth = Object.keys(tableData)
            .map((key) => tableData[key].price)
            .reduce((a, b) => a + b, 0);
        tableData["total"] = {
            price: totalPricePerMonth,
            count: null,
        };
        return tableData;
    };

    const createManagedDisksTableInfo = (tableData: { [key: string]: { price: number; count: number } }) => {
        const kvTableData = Object.keys(tableData).map((key) => {
            if (key === "total") {
                return {
                    total: {
                        key: <Typography fontWeight={600}>{`Total`}</Typography>,
                        value: <Typography>{`${formatKnownDataType(tableData["total"].price, KnownDataType.CURRENCY_USD)} per month`}</Typography>,
                    },
                };
            }
            return {
                [key]: {
                    key: (
                        <Typography fontWeight={600}>{`${getAzureStorageTypeDisplayName(
                            AutoAlloc.VolumeParams.Azure.StorageType[key as keyof typeof AutoAlloc.VolumeParams.Azure.StorageType]
                        )} x ${tableData[key].count}`}</Typography>
                    ),
                    value: <Typography>{`${formatKnownDataType(tableData[key].price, KnownDataType.CURRENCY_USD)} per month`}</Typography>,
                },
            };
        });

        return kvTableData;
    };

    const tableData = createManagedDisksRecord();
    const totalManagedDiskPricePerMonth = tableData["total"].price;

    const kvTableData = createManagedDisksTableInfo(tableData);

    return (
        <Card sx={{ height: "100%" }}>
            <Box display={"flex"} justifyContent={"center"} p={2}>
                <Typography color={"textPrimary"} variant={"overline"} fontWeight={600}>{`Azure Managed Disks`}</Typography>
            </Box>
            <Box width={"100%"}>
                <Divider />
            </Box>
            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    <Avatar sx={{ bgcolor: (t: Theme) => t.palette.cirrus.main }}>
                        <img src={ManagedDisks} alt={"Elastic SAN"} width={24} height={24} />
                    </Avatar>
                    <Stack spacing={0}>
                        <Typography fontWeight={600} variant={"subtitle2"} color={"textSecondary"}>
                            {`Total Estimated Storage Cost:`}
                        </Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"} pb={2}>
                            <Typography variant={"h4"} fontWeight={"bold"}>
                                {`US${formatKnownDataType(totalManagedDiskPricePerMonth, KnownDataType.CURRENCY_USD)}`}
                            </Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {`per month`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <KVContrastTable data={kvTableData} />
            </CardContent>
        </Card>
    );
};

// ======================
// AzureStorageHostBreakdownSection
// ======================

interface AzureStorageHostBreakdownSectionProps {
    recommendationData: AzureStorageConsumptionRecommendation.Host.AsObject[];
    hostInfo: AzureStorageConsumptionPlan.Host.AsObject[];
}

export const AzureStorageHostBreakdownSection: React.FC<AzureStorageHostBreakdownSectionProps> = (p) => {
    const { recommendationData, hostInfo } = p;
    return (
        <Stack spacing={2}>
            <Typography variant={"h6"}>{`Host Breakdown`}</Typography>
            {recommendationData.map((rec) => {
                const hostData = hostInfo.find((host) => host.uuid === rec.uuid);
                return <AzureStorageHostBreakdownCard recommendationData={rec} hostInfo={hostData} />;
            })}
        </Stack>
    );
};

// ======================
// AzureStorageHostBreakdownCard
// ======================

interface AzureStorageHostBreakdownCardProps {
    recommendationData: AzureStorageConsumptionRecommendation.Host.AsObject;
    hostInfo: AzureStorageConsumptionPlan.Host.AsObject;
}

export const AzureStorageHostBreakdownCard: React.FC<AzureStorageHostBreakdownCardProps> = (p) => {
    const { recommendationData, hostInfo } = p;
    const numOfElasticSan = recommendationData.volumesList.filter((volume) => volume.volumeType?.elasticSan).length;
    const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down("sm"));

    const priceInfo = (
        <Grid item xs={12} md={3} justifyContent={"flex-end"}>
            <Stack spacing={1} alignItems={"flex-end"}>
                <Typography fontWeight={600} variant={"subtitle2"} color={"textSecondary"}>
                    {`Azure Managed Disks`}
                </Typography>
                <Typography variant={"h4"} fontWeight={600}>
                    {`US${formatKnownDataType(recommendationData.totalPricePerMonth, KnownDataType.CURRENCY_USD)}`}
                </Typography>
                <Typography fontWeight={400} variant={"subtitle2"} color={"textSecondary"}>
                    {numOfElasticSan > 0 ? `+ ${numOfElasticSan} Azure Elastic SAN` : ""}
                </Typography>
            </Stack>
        </Grid>
    );

    return (
        <CollapsibleCard
            arrowPlacement={"top-left"}
            collapsibleElement={
                <CardContent>
                    <Stack spacing={2}>
                        {hostInfo.volumesList.map((volume) => {
                            return (
                                <VolumeCard volumeInfo={volume} recommendationInfo={recommendationData.volumesList.find((vol) => vol.uuid === volume.uuid)} />
                            );
                        })}
                    </Stack>
                </CardContent>
            }
        >
            <Box p={2} width={"100%"}>
                <Typography variant={"h6"}>{hostInfo.name}</Typography>
                <Grid container spacing={2} mt={1}>
                    {isMobile ? priceInfo : null}
                    <Grid item xs={12} md={9}>
                        <Card
                            elevation={0}
                            sx={{
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 1.5,
                                borderStyle: "solid",
                                borderColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                        >
                            <CardContent>
                                <Grid container spacing={2} alignItems={"flex-end"}>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography>{getHostEnvDisplayName(hostInfo.systemInfo.info.deployment.hostEnvironment.value)}</Typography>
                                            <Typography color={"textSecondary"}>{`Host Environment`}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography>{getMtdiDeploymentOsDisplayName(hostInfo.systemInfo.info.osType)}</Typography>
                                            <Typography color={"textSecondary"}>{`OS`}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography>{hostInfo.volumesList.length}</Typography>
                                            <Typography color={"textSecondary"}>{`Volumes`}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography>{formatKnownDataType(hostInfo.totalCapacity, KnownDataType.CAPACITY)}</Typography>
                                            <Typography color={"textSecondary"}>{`Capacity`}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {!isMobile ? priceInfo : null}
                </Grid>
            </Box>
        </CollapsibleCard>
    );
};

// ======================
// VolumeCard
// ======================

interface VolumeCardProps {
    volumeInfo: AzureStorageConsumptionPlan.Volume.AsObject;
    recommendationInfo: AzureStorageConsumptionRecommendation.Volume.AsObject;
}

export const VolumeCard: React.FC<VolumeCardProps> = (p) => {
    const { volumeInfo, recommendationInfo } = p;
    const targetThroughput = formatKnownDataType(recommendationInfo?.targetThroughput, KnownDataType.THROUGHPUT);
    const provisionedThroughput = formatKnownDataType(recommendationInfo?.provisionedThroughput, KnownDataType.THROUGHPUT);
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: `#3B4252`,
            }}
        >
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant={"h6"}>{volumeInfo.device.blockDevice.deviceName}</Typography>
                        <Grid container spacing={1}>
                            {renderChipInfo(getGmStorageConfigDeviceTypeFromObject(volumeInfo.device))}
                            {renderChipInfo(volumeInfo.device.blockDevice.fsType)}
                            {renderChipInfo(volumeInfo.device.blockDevice.label)}
                            {renderChipInfo(volumeInfo.device.blockDevice.mountPoint)}
                            {renderBootVolumeChip(volumeInfo.device.blockDevice.boot)}
                        </Grid>
                        <Grid container spacing={2} mt={1} mb={1}>
                            <Grid item xs={12} md={4}>
                                <Typography>{formatKnownDataType(volumeInfo.device.blockDevice.capacity, KnownDataType.CAPACITY)}</Typography>
                                <Typography color={"textSecondary"}>{`Capacity`}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography>
                                    {recommendationInfo.targetIops} {`IOPS`}
                                </Typography>
                                <Typography color={"textSecondary"}>{`Required IOPS`}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography>{targetThroughput}</Typography>
                                <Typography color={"textSecondary"}>{`Required Throughput`}</Typography>
                            </Grid>
                        </Grid>
                        <Alert severity={"info"}>
                            {recommendationInfo.volumeType.elasticSan
                                ? `Azure Elastic SAN is the most cost effective option.`
                                : `This volume requires a minimum of ${recommendationInfo.targetIops} IOPS and ${targetThroughput} of throughput`}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectableCard
                            cardProps={{ sx: { height: "100%" } }}
                            disableHoverEffects
                            selected={true}
                            header={"Recommendation"}
                            title={
                                recommendationInfo.volumeType.elasticSan
                                    ? `Azure Elastic SAN`
                                    : `${getAzureStorageTypeDisplayName(recommendationInfo.volumeType.managedDisk?.storageType)}`
                            }
                            description={
                                <>
                                    {recommendationInfo.volumeType.elasticSan ? null : (
                                        <>
                                            <Typography color={"textPrimary"}>
                                                {formatKnownDataType(recommendationInfo.totalPricePerMonth, KnownDataType.CURRENCY_USD)} {` per mo.`}
                                            </Typography>
                                        </>
                                    )}
                                    <Typography color={"textSecondary"}>
                                        {`(${formatKnownDataType(recommendationInfo.provisionedCapacity, KnownDataType.CAPACITY)}, ${
                                            recommendationInfo.provisionedIops
                                        } IOPS, ${provisionedThroughput})`}
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
